import React from 'react'

export const EmojiAddIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11 22a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 0 1 1 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0 1 11 2c.75 0 1.48.079 2.188.237a9.574 9.574 0 0 1 2.012.688 2.715 2.715 0 0 0-.15.525c-.033.183-.05.367-.05.55 0 .233.025.454.075.662.05.209.117.405.2.588a8.731 8.731 0 0 0-2-.913A7.562 7.562 0 0 0 11 4c-2.217 0-4.104.779-5.662 2.337C3.779 7.896 3 9.783 3 12s.78 4.104 2.338 5.663C6.896 19.221 8.783 20 11 20s4.104-.779 5.663-2.337C18.221 16.104 19 14.217 19 12c0-.667-.079-1.308-.237-1.925a8.356 8.356 0 0 0-.663-1.75 2.942 2.942 0 0 0 2.425.625c.15.483.267.98.35 1.488.083.508.125 1.029.125 1.562a9.733 9.733 0 0 1-.788 3.9 10.092 10.092 0 0 1-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0 1 11 22Zm3.5-11c.433 0 .792-.142 1.075-.425.283-.283.425-.642.425-1.075 0-.433-.142-.792-.425-1.075C15.292 8.142 14.933 8 14.5 8c-.433 0-.792.142-1.075.425-.283.283-.425.642-.425 1.075 0 .433.142.792.425 1.075.283.283.642.425 1.075.425Zm-7 0c.433 0 .792-.142 1.075-.425C8.858 10.292 9 9.933 9 9.5c0-.433-.142-.792-.425-1.075C8.292 8.142 7.933 8 7.5 8c-.433 0-.792.142-1.075.425C6.142 8.708 6 9.067 6 9.5c0 .433.142.792.425 1.075.283.283.642.425 1.075.425Zm3.5 6.5c1.183 0 2.229-.325 3.137-.975a5.478 5.478 0 0 0 1.658-1.863c.167-.309-.073-.662-.425-.662H6.63c-.352 0-.592.353-.425.662a5.478 5.478 0 0 0 1.658 1.863c.908.65 1.954.975 3.137.975ZM19 5h-1a.965.965 0 0 1-.712-.288A.965.965 0 0 1 17 4c0-.283.096-.521.288-.713A.967.967 0 0 1 18 3h1V2c0-.283.096-.521.288-.713A.967.967 0 0 1 20 1c.283 0 .52.096.712.287.192.192.288.43.288.713v1h1c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 22 5h-1v1a.968.968 0 0 1-.288.713A.967.967 0 0 1 20 7a.967.967 0 0 1-.712-.287A.968.968 0 0 1 19 6V5Z" />
    </svg>
  )
}
