import { useGetMessageList } from '@api/messages/hooks/use-get-message-list'
import { useAppDispatch, useAppSelector } from '@app/flow/hooks'
import { useConversationId } from '@hooks/use-conversation-id'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { sortByDate } from '../../../shared/lib/sort-utils/sort-by-date'
import { syncMessages as syncAction } from '../model/slice'
import { selectConversationById, selectMessages } from '../model/slice'
import { syncMessages } from './sync-messages'

const MESSAGES_QUERY_KEY = 'Messages'

export const useSyncAndGetMessages = (chatId: string) => {
  const conversationId = useConversationId()
  const dispatch = useAppDispatch()

  const conversationMessaging = useAppSelector(({ conversation }) =>
    selectConversationById(conversation, conversationId || chatId)
  )

  const { pages, fetchNextPage, isLoading, isFetchingNextPage } = useGetMessageList({
    chatId,
    lastFetchedPage: conversationMessaging?.lastFetchedPage,
  })

  const messages = useMemo(() => {
    if (conversationMessaging) {
      return sortByDate(selectMessages(conversationMessaging.messages), 'updatedAt', 'asc')
    }
    return []
  }, [conversationMessaging])

  const lastMessage = messages[messages.length - 1] || {
    messageId: '',
    updatedAt: '',
  }

  const initialPagesNotFetched = !pages
    ? true
    : !!conversationMessaging && conversationMessaging?.lastFetchedPage === pages

  if (initialPagesNotFetched) {
    fetchNextPage()
  }

  useQuery(
    [MESSAGES_QUERY_KEY],
    () => {
      if (initialPagesNotFetched && !lastMessage.messageId) {
        return // Prevent sync if no last message ID
      }

      return syncMessages(
        20,
        lastMessage.updatedAt.toString(),
        conversationId || chatId,
        lastMessage.messageId
      )
    },
    {
      enabled: !!lastMessage.messageId || !initialPagesNotFetched,
      onSuccess: (data) => {
        //data object never returns as undefined
        dispatch(syncAction({ data: data!, chatId: conversationId || chatId }))
      },
    }
  )

  return {
    messages,
    pages,
    lastFetchedPage: conversationMessaging?.lastFetchedPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  }
}
