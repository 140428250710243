import { GetMessageListProps } from '@api/messages/get-message-list'
import { MessagesRepository } from './messages-repository'
import { NativeChatsRepository } from './native-chats-repository'
import { NativeFlowRepository } from './native-flow-repository'

class MessagesService {
  messagesRepository: MessagesRepository

  constructor(messagesRepository: MessagesRepository) {
    this.messagesRepository = messagesRepository
  }

  getMessagesList(props: GetMessageListProps) {
    return this.messagesRepository.getMessagesList(props)
  }
}

export const messagesService = new MessagesService(
  process.env.APP_ENV === 'chats' ? new NativeChatsRepository() : new NativeFlowRepository()
)
