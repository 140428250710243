import { useAddUsersToChannel } from '@api/chats/hooks/use-add-users'
import { useGetChatDetails } from '@api/chats/hooks/use-get-chat-details'
import { useAppDispatch } from '@app/flow/hooks'
import { InviteToChannelSelect, InviteToChannelTextContent } from '@components/invite-select'
import { InviteChannelMembersValues } from '@components/invite-select/types'
import { Form, Formik, FormikHelpers } from 'formik'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { addConversationMember } from 'src/entities/conversations/model/slice'
import { User } from 'src/entities/teammates/api/model/user-type'
import { Button, Container, useModal } from 'ui'
import { initialValues, validationSchema } from './data'

interface AddChannelMembersFormProps {
  channelId: string
  users: User[]
}

export const AddChannelMembersForm: FC<AddChannelMembersFormProps> = ({ channelId, users }) => {
  const { mutateAsync, isLoading } = useAddUsersToChannel(channelId)
  const dispatch = useAppDispatch()
  const { data } = useGetChatDetails(channelId)
  const { closeModal } = useModal()
  const { t } = useTranslation(['modal-add-channel-members', 'common'])

  const formSelectTexts: InviteToChannelTextContent = {
    noResultsMsg: t('noResultsMsg'),
    inChannel: t('inChannel'),
  }

  const usersToAdd = useMemo(() => {
    const channelUsers = data?.chatUsers ?? []
    return users
      .map((user) => {
        const channelUser = channelUsers.find(
          ({ user: chatUser }) => chatUser.userId === user.userId
        )
        const isActiveChannelMember = channelUser && !channelUser.leftChat

        return {
          id: user.userId,
          value: {
            firstName: user.firstName,
            lastName: user.lastName,
            fullName: `${user.firstName} ${user.lastName}`,
          },
          online: user.online,
          label: `${user.firstName} ${user.lastName}`,
          inChannel: isActiveChannelMember,
          avatar: user.avatar ?? { mediaUri: '', mediaType: '', mediaSize: 0 },
          color: user.color ?? '',
        }
      })
      .sort((a, b) => {
        return Number(Boolean(a.inChannel)) - Number(Boolean(b.inChannel))
      })
  }, [data, users])

  const handleSubmit = async (
    values: InviteChannelMembersValues,
    formikHelpers: FormikHelpers<InviteChannelMembersValues>
  ) => {
    const userIds = values.users.map(({ id }) => id)
    const selectedUsers = users.filter((user) => userIds.includes(user.userId))
    try {
      await mutateAsync(userIds)
      dispatch(addConversationMember({ id: channelId, usersToAdd: selectedUsers }))
      closeModal()
      formikHelpers.resetForm()
      toast.success(`${t('changesSaved', { ns: 'common' })}`)
    } catch (_error) {
      toast.error(`${t('errors.somethingWentWrong', { ns: 'common' })}`)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, formikHelpers) => handleSubmit(values, formikHelpers)}
    >
      {({ setFieldValue, values, isValid }) => {
        const isSubmitDisabled = isLoading || !isValid || values.users.length === 0
        return (
          <Form>
            <InviteToChannelSelect
              label={t('memberLabel')}
              loadOptions={usersToAdd}
              value={values.users}
              onChange={(val) => setFieldValue('users', val)}
              placeholder={t('memberPlaceholder')}
              mb="4rem"
              disabled={isLoading}
              texts={formSelectTexts}
            />
            <Container display="flex" flexGap="1.2rem">
              <Button variant="secondary" width="100%" onClick={closeModal} disabled={isLoading}>
                {t('cancelButton')}
              </Button>
              <Button
                type="submit"
                width="100%"
                disabled={isSubmitDisabled}
                loading={isLoading}
                loadText={t('submitButton')}
                animationSize={24}
              >
                {t('submitButton')}
              </Button>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}
