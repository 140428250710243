import { MediaType } from '@api/messages/types/attachment-response'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PlayIcon } from 'ui'

interface AttachmentPreviewProps {
  type?: MediaType
  uri: string
}

export const AttachmentPreview: FC<AttachmentPreviewProps> = ({ type = MediaType.IMG, uri }) => {
  const { t } = useTranslation('attachments')

  if (type === MediaType.IMG) {
    return <img src={uri} alt={t('image')} />
  }

  if (type === MediaType.VIDEO) {
    return (
      <>
        <video src={uri} />
        <VideoIndicator>
          <PlayIcon />
        </VideoIndicator>
      </>
    )
  }

  return null
}

const VideoIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`
