import { cookieStorage } from '@api/auth/oauth/auth-service'
import {
  accessTokenExpiresIn,
  getExpiresDate,
  refreshTokenExpiresIn,
  TokenType,
} from '@native-chats-api/auth-api/utils/tokens'

interface Tokens {
  accessToken: string
  refreshToken: string
}

const setToken = (tokenType: TokenType, token: string, expires?: Date) => {
  cookieStorage.setItem(tokenType, token, {
    secure: true,
    sameSite: 'Strict',
    path: '/',
    expires,
  })
}

export const setTokens = (tokens: Tokens) => {
  const { accessToken, refreshToken } = tokens

  setToken(TokenType.AccessToken, accessToken, getExpiresDate(accessTokenExpiresIn))
  setToken(TokenType.RefreshToken, refreshToken, getExpiresDate(refreshTokenExpiresIn))
}
