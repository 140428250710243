import {
  jobTitleLimits,
  nameLimits,
  nameRegex,
  phoneNumberLimits,
  phoneRegExp,
} from '@modules/forms/variables'
import { TFunction } from 'i18next'
import timezones from 'timezones.json'
import * as Yup from 'yup'

export const timezoneOptions = timezones.map((timezone) => ({
  value: timezone.value,
  label: timezone.text,
}))

const charsLimit = 'validation.charsLimit'
const digitsLimit = 'validation.digitsLimit'
const specialCharsNotAllowed = 'validation.specialCharsNotAllowed'
const invalidEmail = 'validation.invalidEmail'
const invalidPhone = 'validation.invalidPhone'

export const flowPersonalValidationSchema = (t: TFunction) => () =>
  Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(t(charsLimit, nameLimits))
      .min(nameLimits.min, t(charsLimit, nameLimits))
      .max(nameLimits.max, t(charsLimit, nameLimits))
      .matches(nameRegex, t(specialCharsNotAllowed)),
    lastName: Yup.string()
      .trim()
      .required(t(charsLimit, nameLimits))
      .min(nameLimits.min, t(charsLimit, nameLimits))
      .max(nameLimits.max, t(charsLimit, nameLimits))
      .matches(nameRegex, t(specialCharsNotAllowed)),
    phoneNumber: Yup.string()
      .trim()
      .min(phoneNumberLimits.min, t(digitsLimit, phoneNumberLimits))
      .max(phoneNumberLimits.max, t(digitsLimit, phoneNumberLimits))
      .matches(phoneRegExp, t(invalidPhone)),
    email: Yup.string().trim().email(t(invalidEmail)),
    jobTitle: Yup.string()
      .trim()
      .min(jobTitleLimits.min, t(charsLimit, jobTitleLimits))
      .max(jobTitleLimits.max, t(charsLimit, jobTitleLimits)),
    timezone: Yup.object().shape({
      id: Yup.string(),
      displayName: Yup.string(),
    }),
  })

export const chatsPersonalValidationSchema = (t: TFunction) => () =>
  Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(t(charsLimit, nameLimits))
      .min(nameLimits.min, t(charsLimit, nameLimits))
      .max(nameLimits.max, t(charsLimit, nameLimits))
      .matches(nameRegex, t(specialCharsNotAllowed)),
    lastName: Yup.string()
      .trim()
      .required(t(charsLimit, nameLimits))
      .min(nameLimits.min, t(charsLimit, nameLimits))
      .max(nameLimits.max, t(charsLimit, nameLimits))
      .matches(nameRegex, t(specialCharsNotAllowed)),
    phoneNumber: Yup.string()
      .trim()
      .min(phoneNumberLimits.min, t(digitsLimit, phoneNumberLimits))
      .max(phoneNumberLimits.max, t(digitsLimit, phoneNumberLimits))
      .matches(phoneRegExp, t(invalidPhone)),
    email: Yup.string().trim().email(t(invalidEmail)),
  })
