import React from 'react'

export const DownloadIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 14a1 1 0 0 0-1 1v3.334c0 .725.31 1.415.836 1.908A2.831 2.831 0 0 0 5.777 21h12.446c.72 0 1.415-.264 1.941-.758A2.624 2.624 0 0 0 21 18.334V15a1 1 0 0 0-1-1 1 1 0 0 0-1 1v3.334c0 .16-.065.316-.205.447a1 1 0 0 0 0 .002.846.846 0 0 1-.572.217H5.777a.846.846 0 0 1-.572-.217 1 1 0 0 0 0-.002.604.604 0 0 1-.205-.447V15a1 1 0 0 0-1-1Z" />
      <path d="M8 10a1 1 0 0 0-.707.293 1 1 0 0 0 0 1.414l4 4a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0L12 13.586l-3.293-3.293A1 1 0 0 0 8 10Z" />
      <path d="M12 3a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1 1 1 0 0 0 1-1V4a1 1 0 0 0-1-1Z" />
    </svg>
  )
}
