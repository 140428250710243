import { AttachmentResponse } from '@api/messages/types/attachment-response'
import React, { FC, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'
import { useGetAttachments } from 'src/features/chats/hooks/use-get-attachments'

interface AttachmentFetcherProps {
  children: (attachments: AttachmentResponse[]) => ReactNode
  attachmentIds: string[]
}

export const AttachmentFetcher: FC<AttachmentFetcherProps> = ({ children, attachmentIds }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  })

  const { data, isLoading } = useGetAttachments(attachmentIds, inView)

  if (!inView || isLoading) {
    return <div ref={ref} style={{ minHeight: '1rem' }}></div>
  }

  if (!data || data.length === 0) {
    return null
  }

  // Ensure that attachments array and fetchedAttachment will be of the same order, needed for gallery functionality for now
  const attachmentMap = new Map<string, AttachmentResponse>(
    data.filter((att) => att.id).map((att) => [att.id!, att])
  )

  const orderedAttachments = attachmentIds
    .map((id) => attachmentMap.get(id))
    .filter((attachment): attachment is AttachmentResponse => Boolean(attachment))

  return <>{children(orderedAttachments)}</>
}
