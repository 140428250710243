import { UsersResponse } from '@api/account/generated'
import { User } from '../model/user-type'
import { mapUserLanguages } from './map-user-languages'

export function mapUsers(response: UsersResponse[]): User[] {
  return response
    .flatMap((response: UsersResponse) =>
      response.users?.map((user) => {
        const mappedUser = {
          ...user,
          avatar: user.avatar ?? { id: undefined, uri: undefined },
          joiningDate: user?.joiningDate ? new Date(user.joiningDate) : new Date(),
          online: {
            status: user.online?.status ?? 0,
            lastOnline: user.online?.lastOnline ? new Date(user.online.lastOnline) : undefined,
          },
          languages:
            user.languages && user.primaryLanguageCode
              ? mapUserLanguages(user.languages, user.primaryLanguageCode)
              : [],
        }
        return mappedUser as User
      })
    )
    .filter((user): user is User => user !== undefined)
}
