import { Container } from '@mui/material'
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react'
import React, { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import styled from 'styled-components'

interface EmojiPopoverProps {
  onEmojiClick: (clickData: EmojiClickData, event: MouseEvent, onClose?: VoidFunction) => void
  messageId?: string
  children: ReactNode
  setMessageActionsShow?: Dispatch<SetStateAction<boolean>>
  isReactionsLimit?: boolean
}

interface EmojiButtonProps {
  isReactionsLimit?: boolean
}

export const EmojiPopover: FC<EmojiPopoverProps> = ({
  onEmojiClick,
  children,
  messageId,
  setMessageActionsShow,
  isReactionsLimit,
}) => {
  const { t } = useTranslation('modal-select-language')
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    if (isReactionsLimit) return
    setIsOpen(!isOpen)
  }

  const handleEmojiClick = (emoji: EmojiClickData, event: MouseEvent) => {
    onEmojiClick(emoji, event, () => setIsOpen(false))
  }

  const handleClose = () => {
    setMessageActionsShow?.(false)
    setIsOpen(false)
  }

  return (
    <>
      <EmojiButton
        isReactionsLimit={isReactionsLimit}
        data-tooltip-id={`emoji-popover-${messageId}`}
        data-tooltip-place="top-start"
        onClick={handleOpen}
        disabled={isReactionsLimit}
      >
        {children}
      </EmojiButton>

      {isOpen && (
        <>
          <Overlay onClick={handleClose} />
          {createPortal(
            <Tooltip
              clickable
              style={{ background: 'none', zIndex: 1000 }}
              opacity="1"
              isOpen={isOpen}
              id={`emoji-popover-${messageId}`}
              role="presentation"
            >
              <EmojiPickerWrap>
                <EmojiPicker
                  height={363}
                  width={320}
                  autoFocusSearch={false}
                  onEmojiClick={handleEmojiClick}
                  searchPlaceHolder={t('search')}
                />
              </EmojiPickerWrap>
            </Tooltip>,
            document.getElementById('popover') || document.body
          )}
        </>
      )}
    </>
  )
}

//It is the only I found to change the text size, Types inside EmojiPicker does not provide this props, even though docs say it should(className, style)
const EmojiPickerWrap = styled(Container)`
  & li > div {
    font-size: 1.3rem;
    line-height: 1.385;
    letter-spacing: -0.008em;
    font-weight: 400 !important;
  }
`
// Restricting all actions and scrolling, inspired by slack behavior
const Overlay = styled(Container)`
  position: fixed;
  width: max-content;
  min-width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
`

const EmojiButton = styled.button<EmojiButtonProps>`
  outline: none;
  &:disabled {
    cursor: auto;
  }
`
