import { Chat } from '@api/chats/types/chat'
import { useMutation } from '@tanstack/react-query'
import { chatApi } from '../../../features/chats/repositories/chat-service'

type DeleteChatProps = {
  chatId: string
}

interface UseDeleteChatParams {
  onSuccess?: (
    data: Chat,
    variables: DeleteChatProps,
    context: unknown
  ) => Promise<unknown> | unknown
  onError?: CallableFunction
}

export const useDeleteChat = ({ onSuccess, onError }: UseDeleteChatParams) => {
  return useMutation({
    mutationFn: ({ chatId }: DeleteChatProps) => chatApi.deleteChat({ chatId }),
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context)
    },
    onError: () => {
      onError?.()
    },
  })
}
