import { passwordRegExp } from '@modules/forms/variables'
import * as Yup from 'yup'

export const initialValues = {
  password: '',
}

export const validationSchema = Yup.object().shape({
  password: Yup.string().required('').matches(passwordRegExp, ''),
})
