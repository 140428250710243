import { useDeleteMessageReaction } from '@api/messages/hooks/use-delete-message-reaction'
import { Reaction, UserReactionTime } from '@api/messages/types/message-detail'
import { EmojiPopover } from '@components/emoji-popover'
import { useEmojiClickReaction } from '@hooks/use-emoji-click-reaction'
import { LimitModal } from '@modules/modals/limit'
import { BaseUser } from '@shared/types/base-user'
import { unifiedToUnicode } from '@utils/unified-to-unicode'
import { MAX_SHOWN_REACT_NAMES, MAX_USER_REACTIONS } from '@utils/variables'
import { Emoji } from 'emoji-picker-react'
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetUsers } from 'src/entities/teammates/api/hooks/use-get-users'
import { Container, EmojiAddIcon, Text, Tooltip, useModal } from 'ui'
import { ReactionPill, ReactionsContainer } from './styled'

export interface MessageReactionsProps {
  reactions?: Reaction[]
  postFix?: JSX.Element
  messageId: string
  isSender: boolean
  clientMessageId: string
  setMessageActionsShow?: Dispatch<SetStateAction<boolean>>
  numberOfReactionsIHaveAdded?: number
  isReactionsLimit?: boolean
}

export interface ReactionStylesProps {
  isSender: boolean
  haveIReacted?: boolean
}

export interface ReactionButtonStylesProps {
  isSender: boolean
}

export const MessageReactions: FC<MessageReactionsProps> = ({
  reactions,
  postFix,
  messageId,
  isSender,
  setMessageActionsShow,
  numberOfReactionsIHaveAdded,
  isReactionsLimit,
}) => {
  const { mutate: deleteMessageReaction } = useDeleteMessageReaction()
  const { t } = useTranslation('reactions')
  const { users } = useGetUsers()
  const { onEmojiClick, haveIReactedTheEmoji, addMessageReaction, meData } = useEmojiClickReaction({
    messageId,
    reactions,
  })
  const { openModal } = useModal()

  const openLimitModal = () => {
    openModal({
      content: (
        <LimitModal
          title={t('modalReactions.title')}
          text={t('modalReactions.subtitle', { maxLimit: MAX_USER_REACTIONS })}
          buttonLabel={t('modalReactions.submitButton')}
        />
      ),
    })
  }

  const onReactionClick = useCallback(
    (reaction: Reaction) => {
      const { reactionCode } = reaction
      if (haveIReactedTheEmoji(reaction)) {
        deleteMessageReaction({ messageId, reactionCode })
      } else if (
        !haveIReactedTheEmoji(reaction) &&
        numberOfReactionsIHaveAdded &&
        numberOfReactionsIHaveAdded >= MAX_USER_REACTIONS
      ) {
        openLimitModal()
      } else {
        addMessageReaction({ reactionCode, messageId })
      }
    },
    [
      haveIReactedTheEmoji,
      deleteMessageReaction,
      messageId,
      addMessageReaction,
      numberOfReactionsIHaveAdded,
    ]
  )

  const getUsersWhoReacted = useCallback(
    (userReactionTime: UserReactionTime[]) => {
      if (!users || users.length === 0) return []

      return userReactionTime.map(({ userId }) => users.find(({ userId: id }) => id === userId))
    },
    [users]
  )

  const reactionsModified = reactions?.filter((a) => typeof a.createdAt !== 'object')

  const combineUsersNameWhoReactedWithComma = (usersWhoReacted: (BaseUser | undefined)[]) => {
    if (usersWhoReacted.length === 0) return ''

    const usersName = usersWhoReacted.map((user) => {
      if (user === undefined) return ''
      const { firstName, lastName } = user
      return `${firstName} ${lastName}`
    })

    if (usersName.length > MAX_SHOWN_REACT_NAMES) {
      const names = usersName.slice(0, MAX_SHOWN_REACT_NAMES)
      return `${names.join(', ')} ${t('andOther')}`
    }

    return usersName.join(', ')
  }

  const getTooltipFragment = ({ count, reactionCode: unicode, userReactionTimes }: Reaction) => {
    const usersWhoReacted = getUsersWhoReacted(userReactionTimes)
    const reactionCode = unifiedToUnicode(unicode)

    if (count === 1 && usersWhoReacted[0]?.userId === meData?.userId) {
      return (
        <Text variant="smallTextRegular" color="white">
          {t('yourReactionInfo', { reactionCode })}
        </Text>
      )
    }

    const currentUserIndex = usersWhoReacted.findIndex((user) => user?.userId === meData?.userId)
    if (count > 1 && currentUserIndex !== -1) {
      const otherUsers = [...usersWhoReacted]
      otherUsers.splice(currentUserIndex, 1)

      return (
        <Container display="flex" flexDirection="column" alignItems="center" maxWidth="32rem">
          <Emoji unified={reactionCode} size={48} />
          <Text variant="smallTextRegular" color="white" textAlign="center">
            {t('reactionInfoWithYou', {
              userNames: combineUsersNameWhoReactedWithComma(otherUsers),
              reactionCode,
            })}
          </Text>
        </Container>
      )
    }

    return (
      <Container display="flex" flexDirection="column" alignItems="center" maxWidth="32rem">
        <Emoji unified={reactionCode} size={48} />
        <Text variant="smallTextRegular" color="white" textAlign="center">
          {t('reactionInfo', {
            userNames: combineUsersNameWhoReactedWithComma(usersWhoReacted),
            reactionCode,
          })}
        </Text>
      </Container>
    )
  }

  return (
    <ReactionsContainer>
      {reactionsModified &&
        reactionsModified.length > 0 &&
        reactionsModified.map((reaction, index) => {
          const { reactionCode: unicode, count, userReactionTimes, createdAt } = reaction
          const reactionCode = unifiedToUnicode(unicode)
          const haveIReacted = haveIReactedTheEmoji({
            reactionCode,
            userReactionTimes,
            createdAt,
            count,
          })
          if (count > 0) {
            return (
              <Tooltip
                key={reactionCode + index}
                aria-hidden={false}
                id="help-tooltip"
                role="tooltip"
                fragment={getTooltipFragment(reaction)}
              >
                <ReactionPill
                  haveIReacted={haveIReacted}
                  isSender={isSender}
                  onClick={() => onReactionClick(reaction)}
                >
                  <span>{reactionCode}</span>
                  <Text as="span" variant="smallTextRegular" ml="0.4rem">
                    {count}
                  </Text>
                </ReactionPill>
              </Tooltip>
            )
          }
          return null
        })}
      {!isReactionsLimit && (
        <EmojiPopover
          setMessageActionsShow={setMessageActionsShow}
          messageId={messageId}
          onEmojiClick={onEmojiClick}
        >
          <ReactionPill isSender={isSender}>
            <EmojiAddIcon width={16} height={16} />
          </ReactionPill>
        </EmojiPopover>
      )}
      {postFix && (
        <Container display="flex" marginLeft="auto">
          {postFix}
        </Container>
      )}
    </ReactionsContainer>
  )
}
