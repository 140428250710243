import { FileType } from 'src/shared/types/file-type'

const contentTypeMap: Record<string, FileType> = {
  'application/pdf': FileType.PDF,
  'image/svg+xml': FileType.DOCUMENT,
}

export const getFileType = (contentType: string): FileType => {
  // use the contentType directly for specific cases
  if (contentTypeMap[contentType]) return contentTypeMap[contentType]

  const [mainType] = contentType.split('/')

  switch (mainType) {
    case 'image':
      return FileType.IMG
    case 'video':
      return FileType.VIDEO
    case 'audio':
      return FileType.AUDIO
    case 'application':
    case 'text':
      return FileType.DOCUMENT
    default:
      return FileType.UNKNOWN
  }
}
