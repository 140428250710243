import { SidePageTypes } from '@layouts/main-layout/side-page'
import CSS from 'csstype'
import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import React from 'react'
import { useLocation } from 'react-router-dom'

export interface SidePageProviderProps {
  children: JSX.Element | JSX.Element[]
}

export interface SidePageProviderDefinition {
  open: boolean
  type: SidePageTypes | null
  sidePageUserId: string | null
  sidePageChatId: string | null
  setOpen: Dispatch<SetStateAction<boolean>>
  setType: Dispatch<SetStateAction<SidePageTypes | null>>
  bgColor: CSS.Property.Color | null
  setBgColor: Dispatch<SetStateAction<CSS.Property.Color | null>>
  setSidePageUserId: Dispatch<SetStateAction<string | null>>
  setSidePageChatId: Dispatch<SetStateAction<string | null>>
}

const initData: SidePageProviderDefinition = {
  open: false,
  type: null,
  setOpen: () => {},
  setType: () => {},
  bgColor: null,
  setBgColor: () => {},
  sidePageUserId: null,
  sidePageChatId: null,
  setSidePageChatId: () => {},
  setSidePageUserId: () => {},
}

const SidePageContext = createContext<SidePageProviderDefinition>(initData)

const SidePageProvider = ({ children }: SidePageProviderProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [type, setType] = useState<SidePageTypes | null>(null)
  const [sidePageUserId, setSidePageUserId] = useState<string | null>(null)
  const [sidePageChatId, setSidePageChatId] = useState<string | null>(null)
  const [bgColor, setBgColor] = useState<CSS.Property.Color | null>(null)
  const { pathname } = useLocation()

  useEffect(() => {
    setOpen(false)
  }, [pathname])

  useEffect(() => {
    if (!open) {
      setSidePageChatId(null)
      setSidePageUserId(null)
    }
  }, [open])

  return (
    <SidePageContext.Provider
      value={{
        open,
        type,
        setOpen,
        setType,
        bgColor,
        setBgColor,
        sidePageUserId,
        setSidePageUserId,
        sidePageChatId,
        setSidePageChatId,
      }}
    >
      {children}
    </SidePageContext.Provider>
  )
}

export { SidePageContext, SidePageProvider }
