/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptUserRequestRequest } from '../models/AcceptUserRequestRequest';
import type { ConnectionScope } from '../models/ConnectionScope';
import type { CreateUserRequestRequest } from '../models/CreateUserRequestRequest';
import type { DeclineUserRequestRequest } from '../models/DeclineUserRequestRequest';
import type { GetUserInvitationLinkResponse } from '../models/GetUserInvitationLinkResponse';
import type { UserResponse } from '../models/UserResponse';
import type { UsersResponse } from '../models/UsersResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * Accept user invitation
     * Accept user invitation using code from QR or link issued
     * @param code
     * @returns void
     * @throws ApiError
     */
    public static getApiV1UsersInviteAccept(
        code?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/invite/accept',
            query: {
                'code': code,
            },
        });
    }

    /**
     * Accept request
     * Accept user request for connection and create mutual connection
     * @param requestBody
     * @returns UsersResponse OK
     * @throws ApiError
     */
    public static patchApiV1UsersAccept(
        requestBody?: AcceptUserRequestRequest,
    ): CancelablePromise<UsersResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/users/accept',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Block user
     * Block user which is either connected or in common chat
     * @param userId
     * @returns void
     * @throws ApiError
     */
    public static postApiV1UsersBlock(
        userId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/{userId}/block',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * Create request for connection
     * Create request for connection
     * @param requestBody
     * @returns UsersResponse OK
     * @throws ApiError
     */
    public static postApiV1UsersRequest(
        requestBody?: CreateUserRequestRequest,
    ): CancelablePromise<UsersResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/request',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Decline user request for connection
     * Decline the pending user request for connection
     * @param requestBody
     * @returns UsersResponse OK
     * @throws ApiError
     */
    public static patchApiV1UsersDecline(
        requestBody?: DeclineUserRequestRequest,
    ): CancelablePromise<UsersResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/users/decline',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Get user
     * Get user details including the matching contact ID if available
     * @param userId
     * @returns UserResponse OK
     * @throws ApiError
     */
    public static getApiV1Users(
        userId: string,
    ): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * Create user invitation link
     * Create user invitation link
     * @returns GetUserInvitationLinkResponse OK
     * @throws ApiError
     */
    public static getApiV1UsersInviteLink(): CancelablePromise<GetUserInvitationLinkResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/invite/link',
        });
    }

    /**
     * Get users
     * Get all users that have any kind of connection with me
     * @param scope
     * @returns UsersResponse OK
     * @throws ApiError
     */
    public static getApiV1Users1(
        scope?: ConnectionScope,
    ): CancelablePromise<UsersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users',
            query: {
                'scope': scope,
            },
        });
    }

    /**
     * Get users in workspace
     * Get all users that exist in the workspace
     * @param workspaceid
     * @returns UsersResponse OK
     * @throws ApiError
     */
    public static getApiV1Workspaceusers(
        workspaceid?: string,
    ): CancelablePromise<UsersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workspaceusers',
            query: {
                'workspaceid': workspaceid,
            },
        });
    }

    /**
     * Mute user
     * Mute user which is either connected or in chat
     * @param userId
     * @returns void
     * @throws ApiError
     */
    public static postApiV1UsersMute(
        userId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/{userId}/mute',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * Unblock user
     * Unblock connected user or user in chat with me
     * @param userId
     * @returns void
     * @throws ApiError
     */
    public static postApiV1UsersUnblock(
        userId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/{userId}/unblock',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * Unmute user
     * Unmute connected user or user in chat with me
     * @param userId
     * @returns void
     * @throws ApiError
     */
    public static postApiV1UsersUnmute(
        userId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/{userId}/unmute',
            path: {
                'userId': userId,
            },
        });
    }

}
