import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import React, { FC, useCallback, useState } from 'react'
import { User } from 'src/entities/teammates/api/model/user-type'
import { Action, TeammateActions } from 'src/features/teammate-actions'
import styled from 'styled-components'
import { Avatar, Container, StatusVariant, Text } from 'ui'
import { TeammateInfo } from '../teammate-info'

export interface TeammateGridItemProps extends User {
  actions: Action[]
  onUserClick?: (userId: string) => void
}

export const TeammateGridItem: FC<TeammateGridItemProps> = ({
  userId,
  firstName,
  lastName,
  jobTitle,
  joiningDate,
  color,
  avatar,
  online,
  actions,
  onUserClick,
}) => {
  const [quickActionsShown, setQuickActionsShown] = useState<boolean>(false)
  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: userId,
    avatarId: avatar?.id,
  })

  const onMouseEnter = useCallback(() => {
    setQuickActionsShown(true)
  }, [setQuickActionsShown])

  const onMouseLeave = useCallback(() => {
    setQuickActionsShown(false)
  }, [setQuickActionsShown])

  return (
    <TileWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Tile onClick={() => onUserClick?.(userId)}>
        <AvatarContainer data-cy="teammateAvatar">
          <StyledAvatar
            label={firstName[0]?.toUpperCase()}
            bgColor={`#${color}`}
            name={firstName}
            size="100%"
            imgUrl={usersAvatarUrl}
            fontSize="7.6rem"
            isAvatarExists={enabled}
          />
        </AvatarContainer>
        <TeammateInfo
          fullName={`${firstName} ${lastName}`}
          status={online?.status === 1 ? StatusVariant.ONLINE : StatusVariant.OFFLINE}
          joined={joiningDate}
          jobTitle={jobTitle}
        />
      </Tile>
      {quickActionsShown && (
        <TeammateActions
          position="absolute"
          top="0.4rem"
          right="0.4rem"
          variant="iconButton"
          actions={actions}
        />
      )}
    </TileWrapper>
  )
}

const TileWrapper = styled(Container)`
  position: relative;
  cursor: pointer;
`

const Tile = styled.div`
  & ${Text} {
    max-width: 14.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

const AvatarContainer = styled.div`
  padding-top: 100%;
  position: relative;
  margin-bottom: 0.8rem;
`

const StyledAvatar = styled(Avatar)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
