export const routes = {
  home: '/',
  login: '/login',
  chats: 'chats',
  users: 'users',
  settings: 'settings',
  createNew: 'create-new',
  directMessages: 'direct-messages',
  authCallBack: '/auth-callback',
  forbidden: 'forbidden',
  credentials: 'credentials',
  resetPassword: 'reset-password',
  newPassword: 'new-password',
}
