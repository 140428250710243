/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMessageRequest } from '../models/CreateMessageRequest';
import type { ListMessagesDto } from '../models/ListMessagesDto';
import type { ListTranslationVariantsResponse } from '../models/ListTranslationVariantsResponse';
import type { MessageDetailDto } from '../models/MessageDetailDto';
import type { MessageDto } from '../models/MessageDto';
import type { TranslateMessageRequest } from '../models/TranslateMessageRequest';
import type { TranslateMessagesRequest } from '../models/TranslateMessagesRequest';
import type { TranslationDoesNotMakeSenseRequest } from '../models/TranslationDoesNotMakeSenseRequest';
import type { TranslationPreferenceRequest } from '../models/TranslationPreferenceRequest';
import type { UpdatedMessageResponse } from '../models/UpdatedMessageResponse';
import type { UpdateMessageRequest } from '../models/UpdateMessageRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MessagesService {

    /**
     * @param messageId
     * @returns MessageDetailDto OK
     * @throws ApiError
     */
    public static getApiV2Messages(
        messageId: string,
    ): CancelablePromise<MessageDetailDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/messages/{messageId}',
            path: {
                'messageId': messageId,
            },
        });
    }

    /**
     * @param messageId
     * @param requestBody
     * @returns UpdatedMessageResponse OK
     * @throws ApiError
     */
    public static patchApiV2Messages(
        messageId: string,
        requestBody?: UpdateMessageRequest,
    ): CancelablePromise<UpdatedMessageResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v2/messages/{messageId}',
            path: {
                'messageId': messageId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param messageId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteApiV2Messages(
        messageId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v2/messages/{messageId}',
            path: {
                'messageId': messageId,
            },
        });
    }

    /**
     * @param chatId
     * @param pageNumber
     * @param pageSize
     * @param order
     * @returns ListMessagesDto OK
     * @throws ApiError
     */
    public static getApiV2MessagesList(
        chatId?: string,
        pageNumber?: number,
        pageSize?: number,
        order?: string,
    ): CancelablePromise<ListMessagesDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/messages/list',
            query: {
                'ChatId': chatId,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'Order': order,
            },
        });
    }

    /**
     * @param requestBody
     * @returns MessageDto OK
     * @throws ApiError
     */
    public static postApiV2Messages(
        requestBody?: CreateMessageRequest,
    ): CancelablePromise<MessageDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/messages',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param messageId
     * @param requestBody
     * @returns MessageDetailDto OK
     * @throws ApiError
     */
    public static postApiV2MessagesTranslate(
        messageId: string,
        requestBody?: TranslateMessageRequest,
    ): CancelablePromise<MessageDetailDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/messages/{messageId}/translate',
            path: {
                'messageId': messageId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns MessageDetailDto OK
     * @throws ApiError
     */
    public static postApiV2MessagesTranslate1(
        requestBody?: TranslateMessagesRequest,
    ): CancelablePromise<Array<MessageDetailDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/messages/translate',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param messageId
     * @param requestBody
     * @returns MessageDetailDto OK
     * @throws ApiError
     */
    public static postApiV2MessagesTranslationPreference(
        messageId: string,
        requestBody?: TranslationPreferenceRequest,
    ): CancelablePromise<MessageDetailDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/messages/{messageId}/translation-preference',
            path: {
                'messageId': messageId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param messageId
     * @param requestBody
     * @returns MessageDetailDto OK
     * @throws ApiError
     */
    public static postApiV2MessagesTranslationReport(
        messageId: string,
        requestBody?: TranslationDoesNotMakeSenseRequest,
    ): CancelablePromise<MessageDetailDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/messages/{messageId}/translation-report',
            path: {
                'messageId': messageId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param messageId
     * @returns ListTranslationVariantsResponse OK
     * @throws ApiError
     */
    public static getApiV2MessagesVariants(
        messageId: string,
    ): CancelablePromise<ListTranslationVariantsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/messages/{messageId}/variants',
            path: {
                'MessageId': messageId,
            },
        });
    }

}
