import { useAppSelector } from '@app/flow/hooks'
import { GalleryModal } from '@modules/modals/gallery'
import { Attachments as AttachmentsNfc } from '@modules/native-chats/attachments'
import { isNativeChatsApp } from '@utils/is-native-chats-app'
import React, { FC, useCallback } from 'react'
import { AttachmentResponse } from 'src/entities/messages/api/types/attachment-response'
import { selectChatUsers } from 'src/features/chats/slice'
import { SpaceProps } from 'styled-system'
import { ModalVariant, useModal } from 'ui'
import { Attachments } from './attachments'

export interface AttachmentsContainerProps {
  hasIndicator: boolean
  isIncoming?: boolean
}

export interface MessageAttachmentsProps extends AttachmentsContainerProps, SpaceProps {
  conversationId: string
  attachments: AttachmentResponse[]
  hasOnlyImages: boolean
  createdByUserId: string //createdBy and createdAt BE doesn't provide in the attachment object, we pass it from the message
  createdAt?: Date
  isLoading?: boolean
}

export const MessageAttachments: FC<MessageAttachmentsProps> = ({
  conversationId,
  attachments,
  hasIndicator,
  hasOnlyImages = false,
  isIncoming = false,
  isLoading = false,
  createdAt,
  createdByUserId,
  ...spacing
}) => {
  const { openModal } = useModal()

  const chatUsers = useAppSelector(({ chats }) => selectChatUsers(chats, conversationId))

  const getSender = useCallback(
    (userId: string) => {
      return chatUsers?.find((u) => u.user.userId === userId)?.user
    },
    [chatUsers]
  )

  const handleOnClick = (index: number) => {
    const sender = getSender(createdByUserId)

    if (index >= 0 && sender) {
      openModal({
        content: (
          <GalleryModal
            initialIndex={index}
            attachments={attachments}
            conversationId={conversationId}
            sender={sender}
            createdAt={createdAt}
          />
        ),
        variant: ModalVariant.FULL,
        backdropColor: 'rgba(31, 28, 29, 0.95)',
        hasCloseButton: false,
      })
    }
  }

  return isNativeChatsApp() ? (
    <AttachmentsNfc
      attachments={attachments}
      createdAt={createdAt}
      hasIndicator={hasIndicator}
      onClick={handleOnClick}
      isIncoming={isIncoming}
      isLoading={isLoading}
      hasOnlyImages={hasOnlyImages}
      {...spacing}
    />
  ) : (
    <Attachments
      attachments={attachments}
      conversationId={conversationId}
      createdAt={createdAt}
      hasIndicator={hasIndicator}
      onClick={handleOnClick}
      isIncoming={isIncoming}
      isLoading={isLoading}
      hasOnlyImages={hasOnlyImages}
      {...spacing}
    />
  )
}
