import React, { createContext, useCallback, FC, useState } from 'react'
import { PopoverContextType, PopoverProviderProps } from './types'

export const PopoverContext = createContext<PopoverContextType | undefined>(undefined)

export const PopoverProvider: FC<PopoverProviderProps> = ({ children }) => {
  const [activePopoverId, setActivePopoverId] = useState<string | null>(null)

  const openPopover = useCallback(
    (id: string) => {
      if (id === activePopoverId) {
        setActivePopoverId(null)
        return
      }
      setActivePopoverId(id)
    },
    [activePopoverId]
  )

  const closePopover = useCallback((onClose?: VoidFunction) => {
    setActivePopoverId(null)
    onClose?.()
  }, [])

  return (
    <PopoverContext.Provider
      value={{
        openPopover,
        closePopover,
        activePopoverId,
      }}
    >
      {children}
    </PopoverContext.Provider>
  )
}
