import { useAuth } from '@hooks/chats/use-auth'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Container, Heading, Text, useModal } from 'ui'

export const SignOutModal: FC = () => {
  const { closeModal } = useModal()
  const { logout } = useAuth()
  const { t } = useTranslation('modal-sign-out')

  const onSignOut = () => {
    logout()
    closeModal()
  }
  return (
    <Container>
      <Heading as="h2" mb="0.8rem">
        {t('title')}
      </Heading>
      <Text mb="4.8rem">{t('confirmation')}</Text>
      <Container display="flex" flexGap="1.3rem">
        <Button variant="secondary" width="100%" onClick={closeModal}>
          {t('cancelButton')}
        </Button>

        <Button type="submit" variant="warning" width="100%" onClick={onSignOut}>
          {t('submitButton')}
        </Button>
      </Container>
    </Container>
  )
}
