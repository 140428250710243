import React, { FC } from 'react'
import styled from 'styled-components'
import { SpaceProps } from 'styled-system'
import { CheckSmallIcon, Container, Text } from 'ui'

export enum Rule {
  CharsCount = 'charsCount',
  Register = 'register',
  Number = 'number',
}

interface RuleItem<T> {
  rule: T
  label: string
  isChecked: boolean
}

interface CheckListProps<T> extends SpaceProps {
  rules: RuleItem<T>[]
}

export const CheckList: FC<CheckListProps<any>> = ({ rules, ...spaceProps }) => {
  return (
    <Container as="ul" {...spaceProps}>
      {rules.map((rule, index) => (
        <ListItem variant="smallTextRegular" key={index} isChecked={rule.isChecked}>
          <CheckSmallIcon />
          {rule.label}
        </ListItem>
      ))}
    </Container>
  )
}

const ListItem = styled(Text.withComponent('li'))<{ isChecked: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  list-style: none;
  text-align: left;
  color: ${({ isChecked, theme }) =>
    isChecked ? theme.colors.primary : theme.colors.greySuperDark};
  &:not(:last-of-type) {
    margin-bottom: 0.8rem;
  }
`
