import { LogoHeader } from '@components/logo-header'
import { useAuth } from '@hooks/chats/use-auth'
import { useInterfaceLanguage } from '@hooks/use-interface-language'
import { routes } from '@routes/chats/routes'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Outlet } from 'react-router-dom'
import { Container, LanguageSelect } from 'ui'

export const InitialLayout: FC = () => {
  const { isAuthorized } = useAuth()
  const { interfaceLanguage, interfaceLanguageOptions, changeInterfaceLanguage } =
    useInterfaceLanguage()
  const { t } = useTranslation('common')

  if (isAuthorized) {
    return <Navigate to={routes.home} />
  }

  return (
    <Container display="flex" flexDirection="column" height="100%">
      <LogoHeader>
        <LanguageSelect
          interfaceLanguage={interfaceLanguage}
          options={interfaceLanguageOptions}
          onClick={changeInterfaceLanguage}
          hiddenLabel={t('selectInterfaceLang')}
          hiddenLabelSelected={t('langSelected')}
        />
      </LogoHeader>
      <Container width="100%" textAlign="center" flex="1 1 auto" p="6.4rem 2rem 2rem" m="0 auto">
        <Outlet />
      </Container>
    </Container>
  )
}
