import React, { FC } from 'react'
import { Container, DotsAnimation, Text } from 'ui'

export interface DotsLoaderProps {
  text?: string
}

export const DotsLoader: FC<DotsLoaderProps> = ({ text }) => (
  <Container>
    <DotsAnimation width={120} height={30} />
    {text && (
      <Text as="h1" mt="2rem" textAlign="center">
        {text}
      </Text>
    )}
  </Container>
)
