import { AvatarField } from '@components/skeleton-items/avatar-field'
import { Button } from '@components/skeleton-items/button'
import { InputField } from '@components/skeleton-items/input-field'
import React, { FC } from 'react'
import { Container } from 'ui'

export const PersonalSettingsSkeleton: FC = () => (
  <Container display="flex" flexDirection="column" flexGap="2.4rem">
    <AvatarField />
    <Container display="flex">
      <InputField width={292} />
      <InputField width={292} ml="2.4rem" />
    </Container>
    <Container display="flex">
      <InputField width={292} />
      <InputField width={292} ml="2.4rem" />
    </Container>
    <Button />
  </Container>
)
