import { ImageMetaData, MetaData, VideoMetaData } from '@api/chats/generated'

export enum MediaType {
  IMG = 'img',
  VIDEO = 'video',
}

export type AttachmentResponse = {
  id?: string | null
  createdAt?: Date | string
  createdBy?: string | null
  uri?: string | null
  name?: string | null
  metadata?: (MetaData | ImageMetaData | VideoMetaData) | null
  type?: MediaType
}
