import React, { FC } from 'react'
import { components, ClearIndicatorProps } from 'react-select'
import { useTheme } from 'styled-components'
import { CloseIcon } from 'ui'
import { CreatableOptionType } from './types'

export const ClearIndicator: FC<ClearIndicatorProps<CreatableOptionType>> = ({ ...props }) => {
  const theme = useTheme()

  return (
    <components.ClearIndicator {...props}>
      <CloseIcon fill={theme.colors.black} />
    </components.ClearIndicator>
  )
}
