import { HandleScreenParams } from './handle-screen-params'

/*
 * handles the movement inside the screen
 * checks if edges of image and screen are colliding
 */
export const handleInsideScreen = ({
  imageHeight,
  imageWidth,
  screenWidth,
  screenHeight,
  newDy,
  newDx,
}: HandleScreenParams) => {
  const yAxisBorder = screenHeight / 2 - imageHeight
  const xAxisBorder = screenWidth / 2 - imageWidth
  const checkTop = -newDy >= yAxisBorder
  const checkLeft = -newDx >= xAxisBorder
  const checkBottom = newDy >= yAxisBorder
  const checkRight = newDx >= xAxisBorder

  return {
    checkTop,
    checkLeft,
    checkBottom,
    checkRight,
  }
}
