import { NOTIFICATIONS_BANNER_HEIGHT } from '@common/constants'
import { useBannersContext } from '@contexts/banners-provider'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Container, Text } from 'ui'

export const NotificationsBanner: FC = () => {
  const { t } = useTranslation(['common'])
  const { setNotificationsBannerShown } = useBannersContext()
  const [isNotificationsPermissionsDefault, setNotificationsPermissions] = useState(false)

  if (Notification.permission !== 'default' || isNotificationsPermissionsDefault) {
    return null
  }

  return (
    <BannerContainer>
      <Text lineHeight={2} ml="auto">
        🔔
      </Text>
      <InteractionText
        onClick={() => {
          Notification.requestPermission().then((permission) => {
            setNotificationsPermissions(permission !== 'default')
            setNotificationsBannerShown(
              Notification.permission === 'default' || isNotificationsPermissionsDefault
            )
          })
        }}
        ml="1.25rem"
        lineHeight={2}
      >
        {t('notifications.interactionText')}
      </InteractionText>
      <Text lineHeight={2}>&nbsp;{t('notifications.text')}</Text>
      <CloseNotificationBanner
        onClick={() => {
          setNotificationsPermissions(true)
          setNotificationsBannerShown(false)
        }}
        lineHeight={2}
        ml="auto"
        mr="1.25rem"
      >
        &#9587;
      </CloseNotificationBanner>
    </BannerContainer>
  )
}

const BannerContainer = styled(Container)`
  height: ${() => NOTIFICATIONS_BANNER_HEIGHT};
  display: flex;
  padding: 0.6rem;
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  justify-content: center;
  align-items: center;
  user-select: none;
`

const InteractionText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`

const CloseNotificationBanner = styled(Text)`
  cursor: pointer;
`
