import styled from 'styled-components'
import { TranslationTextProps } from '.'

export const TranslationBox = styled.button<TranslationTextProps>`
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.smoke : theme.colors.greyLighter};
  padding: 1.2rem;
  border-radius: 0 1.6rem 1.6rem 1.6rem;
  text-align: left;
  width: 100%;
  margin-bottom: 1.2rem;
  word-break: break-word;
  position: relative;
  // Enable text selection
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
`

export const IconContainer = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
`
