import { Language } from '@api/account/generated'
import { UserLanguage } from 'src/shared/types/user-language'

export function mapUserLanguages(
  languages: Language[] = [],
  primaryLanguageCode: string
): UserLanguage[] {
  return languages.map((lang) => ({
    code: lang.code ?? '',
    name: lang.name ?? '',
    isPrimary: lang.code === primaryLanguageCode,
    translatedName: lang.translatedName ?? '',
  }))
}
