import React from 'react'
import { SVGProps } from 'react'

export const TimeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 2C6.489 2 2 6.489 2 12s4.489 10 10 10 10-4.489 10-10S17.511 2 12 2Zm0 2c4.43 0 8 3.57 8 8s-3.57 8-8 8-8-3.57-8-8 3.57-8 8-8Z" />
      <path d="M12 7a1 1 0 0 0-1 1v5.5h4a1 1 0 0 0 1-1 1 1 0 0 0-1-1h-2V8a1 1 0 0 0-1-1Z" />
    </svg>
  )
}
