import { AttachmentItem } from '@modules/attachment-item'
import { Indicator } from '@modules/message-attachments/indicator'
import {
  AttachmentContainer,
  AttachmentOverlay,
  AttachmentsContainer,
  GridContainer,
} from '@modules/message-attachments/styled'
import { bytesToSize } from '@utils/bytes-to-size'
import { ATTACHMENT_SPINNER_SIZE, MAX_VISIBLE_ATTACHMENTS } from '@utils/variables'
import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { AttachmentResponse, Metadata } from 'src/entities/messages/api/types/attachment-response'
import { useFileDownload } from 'src/shared/lib/hooks/use-file-download'
import { SpaceProps } from 'styled-system'
import { SpinnerDarkAnimation, SpinnerLightAnimation, Text } from 'ui'
import { AttachmentFetcher } from '../attachment-fetcher'

export interface AttachmentsProps extends SpaceProps {
  attachments: AttachmentResponse[]
  onClick: (index: number) => void
  hasIndicator: boolean
  hasOnlyImages: boolean
  createdAt?: Date
  isIncoming?: boolean
  isLoading?: boolean
}

interface AttachmentsWithFetcherProps extends AttachmentsProps {
  conversationId: string
}

const getAttachmentContent = (
  src: string,
  name: string,
  isLoading: boolean,
  isIncoming: boolean
) => {
  return isLoading ? (
    isIncoming ? (
      <SpinnerDarkAnimation size={ATTACHMENT_SPINNER_SIZE} />
    ) : (
      <SpinnerLightAnimation size={ATTACHMENT_SPINNER_SIZE} />
    )
  ) : (
    <img src={src} alt={name ?? 'image'} />
  )
}

export const Attachments: FC<AttachmentsWithFetcherProps> = ({
  attachments,
  conversationId,
  createdAt,
  onClick,
  hasIndicator,
  hasOnlyImages,
  isIncoming = false,
  isLoading = false,
  ...spacing
}) => {
  const { t } = useTranslation('attachments')
  const totalAttachments = attachments.length
  const { downloadFile, isDownloading } = useFileDownload()

  const overlayText =
    totalAttachments > MAX_VISIBLE_ATTACHMENTS
      ? `+${totalAttachments - MAX_VISIBLE_ATTACHMENTS} ${t('more')}`
      : undefined

  const renderImageAttachment = useCallback(
    (
      src: string,
      name: string,
      index: number,
      isLoading: boolean,
      isIncoming: boolean,
      onClick: (index: number) => void,
      showOverlay: boolean,
      overlayText?: string
    ) => (
      <AttachmentContainer
        key={index}
        role="button"
        onClick={() => onClick(index)}
        isIncoming={isIncoming}
      >
        {getAttachmentContent(src, name, isLoading, isIncoming)}
        {showOverlay && overlayText && (
          <AttachmentOverlay>
            <Text color="white">{overlayText}</Text>
          </AttachmentOverlay>
        )}
      </AttachmentContainer>
    ),
    []
  )

  const renderFileAttachment = useCallback(
    (src: string, name: string, metadata: Metadata, isLoading: boolean, isIncoming: boolean) => {
      return (
        <AttachmentItem
          src={src}
          name={name}
          contentType={metadata?.contentType}
          size={bytesToSize(metadata?.size)}
          isLoading={isLoading}
          isDownloading={isDownloading(src)}
          color={isIncoming ? 'default' : 'light'}
          isDownload={true}
          onClick={() => downloadFile(src, name, t('errors.tryAgain'))} //TODO: currently all tile is clickable and triggers download in all types of files, later we will separate it so img and video click will open gallery and only download button will download
        />
      )
    },
    [isDownloading, downloadFile]
  )

  const renderAttachment = useCallback(
    (attachment: AttachmentResponse, index: number) => (
      <AttachmentFetcher conversationId={conversationId} key={attachment.id} {...attachment}>
        {({ src, name, isProcessing }) =>
          hasOnlyImages
            ? renderImageAttachment(
                src,
                name,
                index,
                isProcessing,
                isIncoming,
                onClick,
                totalAttachments > MAX_VISIBLE_ATTACHMENTS && index + 1 === MAX_VISIBLE_ATTACHMENTS,
                overlayText
              )
            : renderFileAttachment(src, name, attachment.metadata, isProcessing, isIncoming)
        }
      </AttachmentFetcher>
    ),
    [conversationId, hasOnlyImages, isIncoming, overlayText, totalAttachments, isDownloading]
  )

  const attachmentChildren =
    totalAttachments === 1
      ? renderAttachment(attachments[0], 0)
      : attachments.slice(0, MAX_VISIBLE_ATTACHMENTS).map(renderAttachment)

  return (
    <AttachmentsContainer isIncoming={isIncoming} hasIndicator={hasIndicator} {...spacing}>
      <GridContainer attachmentsCount={totalAttachments} hasOnlyImages={hasOnlyImages}>
        {attachmentChildren}
      </GridContainer>
      {hasIndicator && <Indicator isLoading={isLoading} createdAt={createdAt} />}
    </AttachmentsContainer>
  )
}
