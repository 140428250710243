import { useInterfaceLanguage } from '@hooks/use-interface-language'
import { LOCALES, Language } from '@utils/locales'
import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import React, { FC } from 'react'
import styled from 'styled-components'
import { SpinnerLightAnimation, Text } from 'ui'

interface TimestampProps {
  isLoading?: boolean
  createdAt?: Date
}

export const Timestamp: FC<TimestampProps> = ({ isLoading, createdAt }) => {
  const { interfaceLanguage } = useInterfaceLanguage()

  return (
    <TimestampContainer>
      {isLoading ? (
        <SpinnerLightAnimation size={16} />
      ) : (
        createdAt && (
          <Text color="white" variant="smallTextRegular">
            {format(createdAt, 'p', {
              locale: LOCALES[interfaceLanguage.value as Language] || enUS,
            })}
          </Text>
        )
      )}
    </TimestampContainer>
  )
}

const TimestampContainer = styled.div`
  position: absolute;
  bottom: 0.8rem;
  right: 0.8rem;
  display: inline-flex;
  align-items: center;
  padding: 0.2rem 0.8rem;
  border-radius: 10rem;
  background-color: rgba(100, 105, 95, 0.7);
  height: 2.2rem;
`
