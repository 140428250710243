export enum MetadataType {
  IMAGE = 'Image',
  VIDEO = 'Video',
  DOCUMENT = 'Document',
  AUDIO = 'Audio',
  UNKNOWN = 'Unknown',
}

export type Metadata = {
  size: number
  contentType: string
  type: MetadataType
}
