import { SyncService } from '@api/chats/generated'
import { Message } from '../../model/message'
import { MessagesRepository } from './messages-repository'

export class MessagesRepositoryImplementation implements MessagesRepository {
  async syncMessages(
    pageSize?: number,
    timeStamp?: string,
    chatId?: string,
    lastMessageId?: string
  ): Promise<{
    messages: Message[]
    lastMessageIdInBatch?: string
    lastMessageTimeStampInBatch?: string
    isSyncFinished?: boolean
  }> {
    const syncedData = await SyncService.getV3SyncMessages(
      pageSize,
      chatId,
      timeStamp,
      lastMessageId
    )
    const messages = syncedData.messages as Message[]
    return {
      messages,
      lastMessageIdInBatch: syncedData.lastMessageIdInBatch || undefined,
      lastMessageTimeStampInBatch: syncedData.lastMessageTimeStampInBatch || undefined,
      isSyncFinished: syncedData.isSyncFinished,
    }
  }
}
