import React from 'react'

export const CalendarIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.75 0A.75.75 0 0 0 3 .75v3a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-3A.75.75 0 0 0 3.75 0ZM3.75 6a.75.75 0 0 0-.75.75v.75a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-.75A.75.75 0 0 0 3.75 6ZM6 6a.75.75 0 0 0-.75.75v.75a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-.75A.75.75 0 0 0 6 6ZM8.25 6a.75.75 0 0 0-.75.75v.75a.75.75 0 0 0 .75.75A.75.75 0 0 0 9 7.5v-.75A.75.75 0 0 0 8.25 6ZM8.25 0a.75.75 0 0 0-.75.75v3a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75v-3A.75.75 0 0 0 8.25 0Z" />
      <path d="M4 0C1.797 0 0 1.797 0 4v4c0 2.203 1.797 4 4 4h4c2.203 0 4-1.797 4-4V4c0-2.203-1.797-4-4-4H4zm0 1.5h4c1.398 0 2.5 1.102 2.5 2.5v4c0 1.398-1.102 2.5-2.5 2.5H4A2.478 2.478 0 0 1 1.5 8V4c0-1.398 1.102-2.5 2.5-2.5z" />
    </svg>
  )
}
