import { NewPasswordForm } from '@modules/native-chats/forms/new-password'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Heading } from 'ui'

export const CreatePassword: FC = () => {
  const { t } = useTranslation('nc-login')

  return (
    <>
      <Heading variant="h0" mb="4rem">
        {t('createNewPswd')}
      </Heading>
      <Container maxWidth="40rem" width="100%" m="0 auto">
        <NewPasswordForm />
      </Container>
    </>
  )
}
