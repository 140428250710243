import { createEntityAdapter, EntityState } from '@reduxjs/toolkit'
import { User } from './user-type'

export const usersEntityAdapter = createEntityAdapter<User>({ selectId: ({ userId }) => userId })

export const usersInitialState = usersEntityAdapter.getInitialState

export const { selectAll: selectUsers, selectById: selectUser } = usersEntityAdapter.getSelectors(
  (state: EntityState<User>) => state
)
