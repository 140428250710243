import { useState, useEffect, useRef } from 'react'
import { handleInsideScreen } from './handle-inside-screen'
import { handleOutOfScreen } from './handle-out-of-screen'

const MAX_ZOOM = 2.0
const MIN_ZOOM = 1.0

const handleDrag = {
  inside: handleInsideScreen,
  outside: handleOutOfScreen,
}

export function useInteraction<T extends HTMLElement>() {
  const interactionRef = useRef<T>(null)
  const [{ dx, dy, scale }, setOffset] = useState({ dx: 0, dy: 0, scale: 1 })
  const [isDragging, setIsDragging] = useState(false)

  const handleMouseDown = (e: MouseEvent) => {
    e.preventDefault()
    if (scale <= 1) return

    const startX = e.pageX - dx
    const startY = e.pageY - dy

    const handleMouseMove = (e: MouseEvent) => {
      const newDx = e.pageX - startX
      const newDy = e.pageY - startY

      const { height, width } = interactionRef.current?.children[0] as HTMLImageElement
      const scaledWidth = width * scale
      const scaledHeight = height * scale

      const screenWidth = window.innerWidth
      const screenHeight = window.innerHeight

      const isScaledLargerThanScreen =
        scaledWidth > screenWidth || scaledHeight > screenHeight ? 'outside' : 'inside'

      const { checkBottom, checkRight, checkTop, checkLeft } = handleDrag[isScaledLargerThanScreen](
        {
          imageHeight: height,
          imageWidth: width,
          screenWidth,
          screenHeight,
          newDx,
          newDy,
        }
      )

      checkBorders(newDx, newDy, checkTop, checkLeft, checkBottom, checkRight)
      setIsDragging(true)
    }

    // helps to smooth the movement inside the screen
    const checkBorders = (
      newDx: number,
      newDy: number,
      checkTop: boolean,
      checkLeft: boolean,
      checkBottom: boolean,
      checkRight: boolean
    ) => {
      if (checkTop && !checkLeft && !checkRight) {
        setOffset((prevState) => ({ ...prevState, dx: newDx }))
      }
      if (checkLeft && !checkTop && !checkBottom) {
        setOffset((prevState) => ({ ...prevState, dy: newDy }))
      }
      if (checkBottom && !checkLeft && !checkRight) {
        setOffset((prevState) => ({ ...prevState, dx: newDx }))
      }
      if (checkRight && !checkTop && !checkBottom) {
        setOffset((prevState) => ({ ...prevState, dy: newDy }))
      }
      if (!checkTop && !checkLeft && !checkBottom && !checkRight) {
        setOffset((prevState) => ({ ...prevState, dx: newDx, dy: newDy }))
      }
    }

    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener(
      'mouseup',
      () => {
        setIsDragging(false)
        window.removeEventListener('mousemove', handleMouseMove)
      },
      { once: true }
    )
  }

  const handleWheel = (e: WheelEvent) => {
    e.preventDefault()

    const isPinch = Math.abs(e.deltaY) < 50

    if (isPinch || e.ctrlKey) {
      const scaleFactor = e.deltaY > 0 ? 0.9 : 1.1 // Adjust as needed
      const newScale = scale * scaleFactor
      if (newScale < MIN_ZOOM || newScale > MAX_ZOOM) {
        return
      }
      setOffset({ dx, dy, scale: newScale })
    } else if (scale > 1) {
      const newDy = dy + e.deltaY
      setOffset({ dx, dy: newDy, scale })
    }
  }

  useEffect(() => {
    const elementRef = interactionRef.current

    if (!elementRef) return

    elementRef.style.transform = `translate3d(${dx}px, ${dy}px, 0) scale(${scale})`
    elementRef.style.cursor = `${isDragging ? 'grabbing' : scale > 1 ? 'zoom-out' : 'zoom-in'}`

    elementRef.addEventListener('mousedown', handleMouseDown)
    elementRef.addEventListener('wheel', handleWheel)
    return () => {
      elementRef.removeEventListener('mousedown', handleMouseDown)
      elementRef.removeEventListener('wheel', handleWheel)
    }
  }, [dx, dy, scale, isDragging])

  return { dx, dy, scale, isDragging, setIsDragging, setOffset, interactionRef }
}
