import { LogoMicrosoft } from '@components/icons'
import { useAuth } from '@hooks/chats/use-auth'
import React from 'react'
import MicrosoftLogin from 'react-microsoft-login'
import { Button } from 'ui'

export const MicrosoftLoginButton = ({ label }: { label: string }) => {
  const { microsoftLoginCallback } = useAuth()

  return (
    <MicrosoftLogin
      clientId={process.env.MICROSOFT_CLIENT_ID!}
      authCallback={async (error, data) => {
        await microsoftLoginCallback(data.idToken)
      }}
      redirectUri={process.env.ENVIRONMENT}
    >
      <Button variant="secondary" width="100%" size="sm" preFix={<LogoMicrosoft />}>
        {label}
      </Button>
    </MicrosoftLogin>
  )
}
