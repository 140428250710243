import { CancelablePromise, OpenAPIConfig } from '@native-chats-api/accounts/generated'
import { ApiRequestOptions } from '@native-chats-api/accounts/generated/core/ApiRequestOptions'
import { getQueryString } from '@api/utils'
import { nativeChatsAccountApi } from '@native-chats-api/accounts/instance'

const getUrl = (config: OpenAPIConfig, options: ApiRequestOptions): string => {
  const encoder = config.ENCODE_PATH || encodeURI

  const path = options.url
    .replace('{api-version}', config.VERSION)
    .replace(/{(.*?)}/g, (substring: string, group: string) => {
      if (options.path?.hasOwnProperty(group)) {
        return encoder(String(options.path[group]))
      }
      return substring
    })

  const url = `${config.BASE}${path}`
  if (options.query) {
    return `${url}${getQueryString(options.query)} `
  }
  return url
}

export const request = <T>(
  config: OpenAPIConfig,
  options: ApiRequestOptions
): CancelablePromise<T> => {
  return new CancelablePromise((resolve, reject, onCancel) => {
    const url = `${getUrl(config, options)}`

    nativeChatsAccountApi
      .request({
        url,
        data: options.body,
        method: options.method,
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
