import { useMutation } from '@tanstack/react-query'
import { chatApi } from '../../../features/chats/repositories/chat-service'

interface UseReadConversationParams {
  onMutate: (chatId: string) => void
}

export const useReadConversation = ({ onMutate }: UseReadConversationParams) => {
  const { mutate } = useMutation({
    mutationFn: (conversationId) => chatApi.readConversation(conversationId),
    onMutate,
    retry: 3,
  })
  return { markAsRead: mutate }
}
