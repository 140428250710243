import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { useGetWorkspace } from '@api/workspaces/hooks/use-get-workspace'
import { useGetWorkspaceAvatar } from '@api/workspaces/hooks/use-get-workspace-avatar'
import { AddUserIcon, GearIcon, TeammatesIcon } from '@components/icons'
import { StatusLabel } from '@components/status-label'
import { useAuth } from '@hooks/use-auth'
import { useSkeleton } from '@hooks/use-skeleton'
import { useWorkspace } from '@hooks/use-workspace'
import { InviteWsMembersModal } from '@modules/modals/invite-ws-members'
import { routes } from '@routes/flow/routes'
import { getFullName } from '@utils/helpers'
import { KNOWLEDGE_BASE_LINK, PRIVACY_POLICY_LINK, TERMS_LINK } from '@utils/variables'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SkeletonComponentNames } from 'src/HOC/with-skeleton'
import {
  Avatar,
  ClipboardIcon,
  Container,
  HelpIcon,
  LogoutIcon,
  ShieldIcon,
  StatusVariant,
  useModal,
  UserIcon,
} from 'ui'
import { SidebarContent } from './content'
import { SidebarHeaderWithSkeleton } from './header'
import { SidebarOnboarding } from './onboarding'
import { SidebarPopperAction } from './popper'
import { WorkspaceName } from './popper/styled'

export const SIDEBAR_POPOVER_ID = 'sidebar-menu'

export const Sidebar: FC = () => {
  const { workspaceId, isPendingOnboarding } = useWorkspace()
  const { data: workspaceData, isLoading: isWorkspaceLoading } = useGetWorkspace({
    id: workspaceId,
  })
  const { meData, refetch: refetchMeData, isLoading: isMeDataLoading } = useGetMe(true)
  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: meData?.userId,
    avatarId: meData?.avatar?.mediaId,
  })

  const { workspaceAvatarUrl, enabled: workspaceAvatarEnabled } = useGetWorkspaceAvatar({
    workspaceId,
    //@ts-ignore
    avatarId: workspaceData?.avatar?.id,
  })

  const { openModal } = useModal()
  const { showSkeleton, hideSkeleton } = useSkeleton()
  const { logout } = useAuth()
  const { t } = useTranslation('sidebar')
  const navigate = useNavigate()

  const userName = getFullName(meData?.firstName, meData?.lastName)

  const handleRedirect = (link: string) => location.replace(link)

  useEffect(() => {
    if (!isPendingOnboarding) {
      refetchMeData()
    }
  }, [isPendingOnboarding, refetchMeData])

  useEffect(() => {
    isMeDataLoading || isWorkspaceLoading
      ? showSkeleton(SkeletonComponentNames.SIDEBAR_HEADER_1)
      : hideSkeleton(SkeletonComponentNames.SIDEBAR_HEADER_1)
  }, [isMeDataLoading, isWorkspaceLoading])

  const options: SidebarPopperAction[] = [
    {
      name: t('teammates'),
      icon: <TeammatesIcon />,
      onClick: () => {
        navigate(routes.teammates)
      },
      group: 'group2',
    },
    {
      name: t('inviteYourTeam'),
      icon: <AddUserIcon />,
      onClick: () => {
        openModal({ content: <InviteWsMembersModal />, maxWidth: '62rem' })
      },
      group: 'group2',
    },
    {
      name: t('personalSettings'),
      icon: <UserIcon />,
      onClick: () => {
        navigate(`${routes.users}/${meData?.userId}/${routes.settings}`)
      },
      group: 'group3',
    },
    {
      name: t('workspaceSettings'),
      icon: <GearIcon />,
      onClick: () => {
        navigate(routes.settings)
      },
      group: 'group3',
    },
    {
      name: t('knowledgeBase'),
      icon: <HelpIcon />,
      onClick: () => handleRedirect(KNOWLEDGE_BASE_LINK),
      group: 'group4',
    },
    {
      name: t('termsOfService'),
      icon: <ClipboardIcon />,
      onClick: () => handleRedirect(TERMS_LINK),
      group: 'group4',
    },
    {
      name: t('privacyPolicy'),
      icon: <ShieldIcon />,
      onClick: () => handleRedirect(PRIVACY_POLICY_LINK),
      group: 'group4',
    },
    {
      name: t('logoutOfNative'),
      icon: <LogoutIcon />,
      onClick: logout,
      group: 'group5',
    },
  ]

  return (
    <>
      {isPendingOnboarding || !meData?.isOnboarded ? (
        <SidebarOnboarding workspace={workspaceData} me={meData} />
      ) : (
        <>
          <SidebarHeaderWithSkeleton
            me={meData}
            onCreateNew={() => navigate(`${routes.chats}/${routes.createNew}`)}
            title={workspaceData?.name || ''}
            subtitle={
              <StatusLabel isPopup={false} label={userName} status={StatusVariant.ONLINE} />
            }
            avatarComponent={
              <Avatar
                size="4.4rem"
                fontSize="2.4rem"
                imgUrl={workspaceAvatarUrl}
                isAvatarExists={workspaceAvatarEnabled}
                bgColor={`#${workspaceData?.color}`}
                label={workspaceData?.name}
              />
            }
            popperComponent={
              <Container display="flex" alignItems="center" p="0.8rem 1.2rem">
                <Avatar
                  size="4.4rem"
                  imgUrl={usersAvatarUrl}
                  bgColor={`#${meData?.color}`}
                  isAvatarExists={enabled}
                  label={userName}
                  mr="1.2rem"
                />
                <Container>
                  <StatusLabel isPopup label={userName} status={StatusVariant.ONLINE} />
                  <WorkspaceName variant="smallTextRegular">
                    {workspaceData?.name} {t('ws')}
                  </WorkspaceName>
                </Container>
              </Container>
            }
            componentName={SkeletonComponentNames.SIDEBAR_HEADER_1}
            p="0 1.6rem"
            mb="1.2rem"
            popperItems={options}
          />
          <SidebarContent />
        </>
      )}
    </>
  )
}
