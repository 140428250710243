import {
  Reaction,
  UserReactionTime as UserReactionTimeFlow,
} from '@api/messages/types/message-detail'
import { MessageReaction, UserReactionTime } from '@native-chats-api/chats/generated'

export const mapReactions = (reactions: Array<MessageReaction> | null): Reaction[] => {
  return (reactions ?? []).map(mapReaction)
}

const mapReaction = (reaction: MessageReaction): Reaction => {
  return {
    reactionCode: reaction.reactionCode ?? '',
    count: reaction.count ?? 0,
    createdAt: reaction.createdAt ? new Date(reaction.createdAt) : new Date(),
    userReactionTimes: (reaction.userReactionTimes ?? []).map(mapUserReactionTime),
  }
}

const mapUserReactionTime = (userReactionTime: UserReactionTime): UserReactionTimeFlow => {
  return {
    userId: userReactionTime.userId ?? '',
    timestamp: userReactionTime.timestamp ? new Date(userReactionTime.timestamp) : new Date(),
  }
}
