import React, { ReactNode, FC, Children } from 'react'
import { components, ValueContainerProps } from 'react-select'
import { OptionType } from './types'

interface ValueProps extends ValueContainerProps<OptionType> {
  maxMultiChildrenAmount?: number
}

export const ValueContainer: FC<ValueProps> = ({ maxMultiChildrenAmount, ...props }) => {
  const multipleValueCount = Children.count((props.children as ReactNode[][])[0] as ReactNode[])

  // For multiple select we want to omit more than component.props.maxMultiChildrenAmount
  const allowedChildrenCount = maxMultiChildrenAmount || multipleValueCount

  const [selectedOptions, input] = props.children as ReactNode[][]

  const slicedSelectedOptions = Children.toArray(selectedOptions).slice(0, allowedChildrenCount)

  const isMultipleCountExceeded =
    maxMultiChildrenAmount && multipleValueCount > maxMultiChildrenAmount

  const moreOptionsComponent = (
    <span key="more" aria-label="More options">
      ...
    </span>
  )

  if (isMultipleCountExceeded) {
    slicedSelectedOptions.push(moreOptionsComponent)
  }

  const newChildren = [slicedSelectedOptions, input]

  return <components.ValueContainer {...props}>{newChildren}</components.ValueContainer>
}
