import { useSpacer } from '@hooks/use-spacer'
import { MessageAttachments } from '@modules/message-attachments'
import React, { RefObject, useRef, FC } from 'react'
import { SpinnerLightAnimation, Text } from 'ui'
import { GeneralMessageProps, MessageVariant } from '.'
import { MessageBody, MessageFooter, MessageText, Spacer } from './styled'

export const SendingMessage: FC<GeneralMessageProps> = ({ attachments, text }) => {
  const messageFooterRef: RefObject<HTMLDivElement> = useRef(null)
  const { spacerWidth } = useSpacer({
    refComponent: messageFooterRef,
    offset: 10,
    hostContent: text,
  })

  const hasText = text.length > 0

  return (
    <MessageBody isSender={false} variant={MessageVariant.IS_SENDING}>
      {attachments?.length > 0 && (
        <MessageAttachments
          attachments={attachments}
          hasIndicator={!hasText}
          isIncoming={false}
          isLoading={true}
          pt="0.8rem"
          pb={hasText ? '0.8rem' : '0'}
        />
      )}
      {hasText && (
        <>
          <MessageText>
            <Text>
              {text}
              <Spacer width={spacerWidth} />
            </Text>
          </MessageText>
          <MessageFooter ref={messageFooterRef}>
            <SpinnerLightAnimation size={16} />
          </MessageFooter>
        </>
      )}
    </MessageBody>
  )
}
