import styled, { FlattenSimpleInterpolation, css } from 'styled-components'
import { Container } from 'ui'
import { AttachmentsContainerProps } from '.'

type GridStylesMap = {
  default: FlattenSimpleInterpolation
  [key: number]: FlattenSimpleInterpolation
}

type BorderStylesConfig = {
  hasTimestamp: boolean
  isIncoming: boolean
  isSenderShown: boolean
}

const imageGridStylesMap: GridStylesMap = {
  1: css`
    grid-template-columns: repeat(1, 32rem);
    grid-template-rows: 32rem;
  `,
  2: css`
    grid-template-columns: repeat(2, minmax(15vw, 20rem));
    grid-template-rows: minmax(15vw, 20rem);
  `,
  3: css`
    grid-template-columns: repeat(3, minmax(15vw, 20rem));
    grid-template-rows: minmax(15vw, 20rem);
  `,
  4: css`
    grid-template-columns: repeat(2, minmax(15vw, 20rem));
    grid-template-rows: repeat(2, minmax(15vw, 20rem));
  `,
  default: css`
    grid-template-columns: repeat(6, minmax(4.65vw, 6.65rem));
    grid-template-rows: minmax(9.3vw, 13.3rem) minmax(16vw, 20rem);
    & > div {
      grid-column: span 2;
    }
    & > div:nth-child(4) {
      grid-column: span 3;
    }
    & > div:nth-child(5) {
      grid-column: span 3;
    }
  `,
}

const itemsGridStylesMap: GridStylesMap = {
  1: css`
    grid-template-columns: minmax(20.8rem, 0.5fr);
  `,
  default: css`
    grid-template-columns: repeat(auto-fit, minmax(20.8rem, 1fr));
  `,
}

const getBorderStyles = ({
  hasTimestamp,
  isIncoming,
  isSenderShown,
}: BorderStylesConfig): string => {
  const borderRadius = {
    incoming: {
      withTimestamp: {
        withSender: '0 0 1.2rem 1.2rem',
        withoutSender: '0 1.2rem 1.2rem 1.2rem',
      },
      withoutTimestamp: {
        withSender: '0',
        withoutSender: '0 1.2rem 0 0',
      },
    },
    outgoing: {
      withTimestamp: '1.2rem 1.2rem 0 1.2rem',
      withoutTimestamp: '1.2rem 1.2rem 0 0',
    },
  }

  if (isIncoming) {
    return hasTimestamp
      ? borderRadius.incoming.withTimestamp[isSenderShown ? 'withSender' : 'withoutSender']
      : borderRadius.incoming.withoutTimestamp[isSenderShown ? 'withSender' : 'withoutSender']
  }

  return hasTimestamp ? borderRadius.outgoing.withTimestamp : borderRadius.outgoing.withoutTimestamp
}

export const GridContainer = styled.div<{
  attachmentsCount: number
  hasOnlyImagesOrVideo: boolean
  open: boolean
}>`
  display: grid;
  gap: 0.4rem;
  max-width: ${({ open }) => (open ? `calc(70vw - 32rem)` : '70vw')};

  ${({ attachmentsCount, hasOnlyImagesOrVideo }) =>
    hasOnlyImagesOrVideo
      ? imageGridStylesMap[attachmentsCount] || imageGridStylesMap.default
      : itemsGridStylesMap[attachmentsCount] || itemsGridStylesMap.default}
`

export const AttachmentsContainer = styled(Container)<AttachmentsContainerProps>`
  width: ${({ width }) => (width ? `${width}` : 'auto')};
  overflow: hidden;
  position: relative;
  border-radius: ${({ hasTimestamp, isIncoming = true, isSenderShown = true }) =>
    getBorderStyles({ hasTimestamp, isIncoming, isSenderShown })};
`
