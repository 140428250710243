import { ResetPasswordForm } from '@modules/native-chats/forms/reset-password'
import { PageHeader } from '@modules/native-chats/page-header'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, ImgContainer } from 'ui'

export const ResetPassword: FC = () => {
  const { t } = useTranslation('nc-login')
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false)

  const handleSubmit = async (email: string) => {
    // handle submit here
    setIsSubmitSuccess(true)
  }
  return (
    <>
      {isSubmitSuccess ? (
        <>
          <ImgContainer maxWidth="32rem" m="0 auto">
            <img
              src="/img/reset-link.svg"
              height={320}
              width={320}
              aria-label={t('imgAlt.linkSent')}
            />
          </ImgContainer>
          <PageHeader title={t('checkEmail')} subtitle={t('checkEmailMsg')} mt="4rem" />
        </>
      ) : (
        <>
          <PageHeader title={t('resetPswd')} subtitle={t('resetPswdMsg')} mb="4rem" />
          <Container maxWidth="40rem" width="100%" m="0 auto">
            <ResetPasswordForm onSubmit={handleSubmit} />
          </Container>
        </>
      )}
    </>
  )
}
