import { PersonalProfile } from '@api/account/types/personal-profile'
import { Workspace } from '@api/workspaces/types'
import { StatusLabel } from '@components/status-label'
import { OnboardingType } from '@contexts/workspace-provider'
import { useWorkspace } from '@hooks/use-workspace'
import { SidebarContentSkeleton } from '@modules/skeleton/skeleton-variants/sidebar-content'
import { getFullName } from '@utils/helpers'
import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styled, { useTheme } from 'styled-components'
import { Avatar, ChevronDownIcon, Container, ScrollContainer, StatusVariant, Text } from 'ui'
import { WorkspaceName } from '../header'

interface SidebarOnboardingProps {
  workspace?: Workspace
  me?: PersonalProfile
}

export const SidebarOnboarding: FC<SidebarOnboardingProps> = ({ workspace, me }) => {
  const { workspaceName, onboardingType } = useWorkspace()
  const theme = useTheme()
  const color = onboardingType === OnboardingType.USER ? `#${workspace?.color}` : theme.colors.blue
  const wsName = onboardingType === OnboardingType.USER ? workspace?.name : workspaceName

  return (
    <ScrollContainer hideScrollbar>
      <HeaderContainer>
        {wsName ? (
          <Avatar size="4.4rem" name={wsName} label={wsName} bgColor={color} fontSize="2.4rem" />
        ) : (
          <Skeleton height={44} width={44} />
        )}
        <Container flex={1}>
          {wsName ? (
            <Container display="flex" alignItems="center">
              <WorkspaceName as="span" fontWeight={600} fontSize={18}>
                {wsName}
              </WorkspaceName>
              <Container as="span" mb="-0.2rem">
                <ChevronDownIcon />
              </Container>
            </Container>
          ) : (
            <Container mb="0.3rem">
              <Skeleton height={12} />
            </Container>
          )}
          {me?.firstName && me?.lastName ? (
            <StatusLabel
              isPopup={false}
              label={getFullName(me.firstName, me.lastName)}
              status={StatusVariant.ONLINE}
            />
          ) : (
            <Skeleton height={12} />
          )}
        </Container>
        <Skeleton height={36} width={36} borderRadius={12} />
      </HeaderContainer>
      <SidebarContentSkeleton />
    </ScrollContainer>
  )
}

const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0 1.6rem;
  height: 4.8rem;
  margin-bottom: 1.2rem;
`
