import { PrimaryAction } from '@components/collapsible-list'
import { PrimaryActionIconBtn, SecondaryActionIconBtn } from '@components/collapsible-list/styled'
import { Popover } from '@headlessui/react'
import React, { FC } from 'react'
import { Container, PopperAction, PopperList } from 'ui'

interface ConversationListMenuProps {
  primaryAction?: PrimaryAction
  secondaryAction?: PopperAction
}

export const ConversationListMenu: FC<ConversationListMenuProps> = ({
  primaryAction,
  secondaryAction,
}) => {
  return (
    <Container display="flex">
      {primaryAction && (
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button style={{ outline: 'none' }} aria-label={primaryAction.name}>
                <PrimaryActionIconBtn isOpen={open}>{primaryAction.icon}</PrimaryActionIconBtn>
              </Popover.Button>
              <Popover.Panel style={{ position: 'absolute', zIndex: 10 }}>
                <PopperList actions={primaryAction.popperOptions} width="21.2rem" />
              </Popover.Panel>
            </>
          )}
        </Popover>
      )}
      {secondaryAction && (
        <SecondaryActionIconBtn onClick={secondaryAction.onClick}>
          {secondaryAction.icon}
        </SecondaryActionIconBtn>
      )}
    </Container>
  )
}
