import { useGetMe } from '@api/account/hooks/use-get-me'
import { useCreateChat } from '@api/chats/hooks/use-create-chat'
import { useGetChatList } from '@api/chats/hooks/use-get-chat-list'
import { useSidePage } from '@hooks/use-side-page'
import { SidePageTypes } from '@layouts/main-layout/side-page'
import { ConnectionScope } from '@native-chats-api/accounts/generated'
import { routes } from '@routes/chats/routes'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { User } from 'src/entities/teammates/api/model/user-type'
import { FilterUsers } from 'src/features/shared/filter-users'
import { Action } from 'src/features/teammate-actions'
import { TeammatesLayoutPicker } from 'src/features/teammates-layout-picker'
import { filterByFields } from 'src/shared/lib/filter-utils/filter-by-fields'
import { Container, SendIcon, UserIcon } from 'ui'
import { useGetContacts } from '../../hooks/get-contacts'
import { ContactsListLayout } from './list-layout'
import { ContactsTileLayout } from './tile-layout'

export interface ContactsLayoutProps {
  isUsersFetching: boolean
  setIsTriggerInView: (c: boolean) => void
  users: User[]
  createActions: (userId: string) => Action[]
  onUserClick?: (userId: string) => void
}

export const ContactsLayout = () => {
  const navigate = useNavigate()
  const { data: users, isFetching: isUsersFetching } = useGetContacts(ConnectionScope._0)
  const [filteredContacts, setFilteredContacts] = useState<User[]>(users || [])
  const { chats } = useGetChatList()
  const { meData } = useGetMe()
  const { data, createChat: createChatMutation, isCreateChatSuccess } = useCreateChat()
  const [isTileLayout, setIsTileLayout] = useState(true)
  const [searchString, setSearchString] = useState('')
  const { t } = useTranslation(['teammates', 'commmon'])
  const layoutPickerLabels = { listView: t('listView'), tileView: t('tilesView') }
  const { setType, setOpen, setSidePageUserId } = useSidePage()

  useMemo(() => {
    if (users && !isUsersFetching) {
      const filteredUsersArray = filterByFields(users, searchString, ['firstName', 'lastName'])
      setFilteredContacts(filteredUsersArray as User[])
    }
  }, [users, searchString, isUsersFetching])

  const toggleLayout = () => setIsTileLayout((prev) => !prev)
  //TODO: described here NAT-2880
  const findExistingChat = (userId: string, isPrivate: boolean) => {
    if (isPrivate) {
      return chats.find(
        ({ chatUsers }) => chatUsers.length === 1 && chatUsers[0]?.user.userId === userId
      )
    }

    return chats
      .filter(({ chatUsers }) => chatUsers.length === 2)
      .find(({ chatUsers }) => {
        return chatUsers.some(({ user }) => user.userId === userId)
      })
  }

  const redirectToChat = (chatId: string) => {
    navigate(`/${routes.chats}/${chatId}`, { replace: true })
  }

  const handleSendClick = (userId: string) => {
    const existingChat = findExistingChat(userId, userId === meData?.userId)

    if (existingChat) {
      redirectToChat(existingChat.id)
    } else {
      createChatMutation({ userIds: [userId] })
    }
    redirectToChat
  }
  const handleUserClick = (userId: string) => {
    setOpen(true)
    setType(SidePageTypes.USER)
    setSidePageUserId(userId)
  }

  const setIsTriggerInView = (_c: boolean) => {}

  useEffect(() => {
    if (isCreateChatSuccess && data) {
      redirectToChat(data.id)
    }
  }, [data, isCreateChatSuccess])

  const createContactActions = useCallback(
    (userId: string) => [
      {
        label: t('teammateActions.sendMessage'),
        onActionClick: () => handleSendClick(userId),
        icon: <SendIcon />,
      },
      {
        label: t('teammateActions.viewProfile'),
        onActionClick: () => handleUserClick(userId),
        icon: <UserIcon />,
      },
    ],
    [handleSendClick, handleUserClick]
  )

  return (
    <>
      <FilterUsers
        searchString={searchString}
        setSearchString={setSearchString}
        placeholder={t('searchByName', { ns: 'common' })}
        mb="1.6rem"
      />
      <Container display="flex" mb="1.6rem" justifyContent="flex-end">
        <TeammatesLayoutPicker
          onClick={toggleLayout}
          isTile={isTileLayout}
          labels={layoutPickerLabels}
        />
      </Container>
      {isTileLayout ? (
        <ContactsTileLayout
          users={filteredContacts}
          isUsersFetching={isUsersFetching}
          setIsTriggerInView={setIsTriggerInView}
          createActions={createContactActions}
          onUserClick={handleUserClick}
        />
      ) : (
        <ContactsListLayout
          users={filteredContacts}
          isUsersFetching={isUsersFetching}
          setIsTriggerInView={setIsTriggerInView}
          createActions={createContactActions}
          onUserClick={handleUserClick}
        />
      )}
    </>
  )
}
