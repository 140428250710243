import { getBackgroundColor } from '@shared/lib/media/get-preview-settings/get-background-color'
import { getPreviewElement } from '@shared/lib/media/get-preview-settings/get-preview-element'
import { ElementType } from 'react'
import { FileType } from 'src/shared/types/file-type'
import { DefaultTheme } from 'styled-components'
import { AudioIcon, FileIcon, PdfIcon } from 'ui'

export const getPreviewSettings = (
  theme: DefaultTheme,
  type: FileType,
  src?: string,
  name?: string,
  isLoading?: boolean
) => {
  const fileTypeStyles: Record<FileType, { backgroundColor: string; elementType: ElementType }> = {
    [FileType.IMG]: { backgroundColor: 'transparent', elementType: 'img' },
    [FileType.VIDEO]: { backgroundColor: 'transparent', elementType: 'video' },
    [FileType.AUDIO]: { backgroundColor: '#ED7E0C', elementType: AudioIcon },
    [FileType.DOCUMENT]: { backgroundColor: theme.colors.yellow, elementType: FileIcon },
    [FileType.PDF]: { backgroundColor: theme.colors.green, elementType: PdfIcon },
    [FileType.UNKNOWN]: { backgroundColor: theme.colors.yellow, elementType: FileIcon },
  }

  const { backgroundColor, elementType } = fileTypeStyles[type]

  const previewElement = getPreviewElement(elementType, src, name)

  return {
    backgroundColor: getBackgroundColor({ theme, isLoading, backgroundColor, src }),
    previewElement,
  }
}
