import { CreateChatsProps } from '@api/chats/create-chat'
import { Chat } from '@api/chats/types/chat'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CONVERSATIONS_QUERY_KEY } from 'src/entities/conversations/api/use-sync-and-get-conversations'
import { chatApi } from 'src/features/chats/repositories/chat-service'

export const useCreateChannel = () => {
  const queryClient = useQueryClient()

  const {
    data,
    mutate: createChannel,
    ...other
  } = useMutation<Chat, Error, CreateChatsProps>(
    ({ chatName, description, conversationType, userIds }) =>
      chatApi.createChat({
        chatName,
        conversationType,
        description,
        userIds,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CONVERSATIONS_QUERY_KEY])
      },
    }
  )

  return { data, createChannel, ...other }
}
