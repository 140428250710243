import {
  WorkspaceUserRequest,
  WorkspaceUserRole,
  WorkspaceUserStatus,
} from '@api/workspaces/generated'
import { useInviteWorkspaceUsers } from '@api/workspaces/hooks/use-invite-workspace-users'
import { WorkspaceUser } from '@api/workspaces/types'
import { useCopyToClipboard } from '@hooks/use-copy-to-clipboard'
import { useWorkspace } from '@hooks/use-workspace'
import { SentInvitationModal } from '@modules/modals/sent-invitation'
import { getFeatureFlag } from '@utils/flags-manager'
import { Form, Formik, FormikHelpers } from 'formik'
import React, { FC, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import {
  Button,
  Container,
  CopyIcon,
  CreatableHelperText,
  CreatableSelect,
  RadioGroup,
  useModal,
} from 'ui'
import { initialValues, validationSchema } from './data'
import { InviteWsMembersValues } from './types'

interface InviteWsMembersForm {
  users: WorkspaceUser[]
}

export const InviteWsMembersForm: FC<InviteWsMembersForm> = ({ users }) => {
  const { openModal } = useModal()
  const { workspaceId } = useWorkspace()
  const {
    mutate: inviteWorkspaceUsers,
    isLoading: inviteWorkspaceUsersIsLoading,
    isSuccess: isInviteWorkspaceUsersSuccess,
    isError: isInviteWorkspaceUsersError,
    data: inviteWorkspaceUsersData,
  } = useInviteWorkspaceUsers()

  const { copyToClipboard } = useCopyToClipboard()
  const [copyLinkExecuted, setCopyLinkExecuted] = useState<boolean>(false)
  const { t } = useTranslation(['modal-invite-ws-members', 'common'])

  const memberRoleOptions = [
    { value: 'member', label: t('memberLabel'), desc: t('memberDesc') },
    { value: 'admin', label: t('adminLabel'), desc: t('adminDesc') },
  ]

  const handleSubmit = (
    values: InviteWsMembersValues,
    actions: FormikHelpers<InviteWsMembersValues>
  ) => {
    const userData: WorkspaceUserRequest[] = values.users.map((user) => {
      return {
        email: user.value,
        roles: [WorkspaceUserRole.ADMIN],
      }
    })
    actions.setSubmitting(false)
    inviteWorkspaceUsers({
      workspaceId: workspaceId,
      requestBody: { sendEmail: true, users: userData },
    })
    actions.resetForm()
    setCopyLinkExecuted(false)
  }

  const handleCopyLink = () => {
    inviteWorkspaceUsers({
      workspaceId: workspaceId,
      requestBody: { sendEmail: false },
    })
    setCopyLinkExecuted(true)
  }

  //  Trigger toast message or modal after making the request
  useEffect(() => {
    if (isInviteWorkspaceUsersSuccess && inviteWorkspaceUsersData) {
      if (copyLinkExecuted) {
        const invitationLink = inviteWorkspaceUsersData.invitationLink
        if (invitationLink) {
          copyToClipboard({
            text: invitationLink,
            successMsg: `${t('linkCopied', { ns: 'common' })}`,
            errorMsg: `${t('errors.somethingWentWrong', { ns: 'common' })}.\n${t(
              'errors.tryAgainLater',
              { ns: 'common' }
            )}`,
          })
        } else {
          toast.error(`${t('errors.somethingWentWrong', { ns: 'common' })}`)
        }
      } else {
        openModal({ content: <SentInvitationModal />, closeOnClickOutside: true })
      }
    }
    if (isInviteWorkspaceUsersError) {
      toast.error(`${t('errors.somethingWentWrong', { ns: 'common' })}`)
    }
  }, [
    isInviteWorkspaceUsersSuccess,
    inviteWorkspaceUsersData,
    copyLinkExecuted,
    openModal,
    isInviteWorkspaceUsersError,
    copyToClipboard,
    t,
  ])

  const invalidUserEmailValues = useMemo(
    () =>
      users
        .filter(
          (user) =>
            user.status === WorkspaceUserStatus.ACTIVATED ||
            user.status === WorkspaceUserStatus.DEACTIVATED
        )
        .map((user) => user.email),
    [users]
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(t)}
      validateOnBlur
    >
      {({ isSubmitting, setFieldValue, handleChange, errors, values }) => (
        <Form>
          <CreatableSelect
            inputValues={invalidUserEmailValues}
            label={t('emailLabel')}
            onChange={(val) => setFieldValue('users', val)}
            placeholder="example@email.com"
            helperText={errors.users as CreatableHelperText[] | undefined}
            values={values.users}
            mb="4rem"
          />
          {getFeatureFlag('showOnProd') && (
            <RadioGroup
              name="memberRole"
              options={memberRoleOptions}
              values={values}
              onChange={handleChange}
              border
              reverse
              mb="4.8rem"
            />
          )}
          <Container display="flex" justifyContent="space-between" alignItems="center">
            <Button
              variant="secondary"
              plain
              preFix={<CopyIcon />}
              disabled={copyLinkExecuted && inviteWorkspaceUsersIsLoading}
              onClick={handleCopyLink}
            >
              {t('copyInviteButton')}
            </Button>
            <Button
              type="submit"
              width="19rem"
              disabled={isSubmitting || Object.keys(errors).length > 0 || values.users.length === 0}
            >
              {t('submitButton')}
            </Button>
          </Container>
        </Form>
      )}
    </Formik>
  )
}
