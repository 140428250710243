import { capitalizeFirstLetter } from '@utils/helpers'
import { format } from 'date-fns'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StatusBadge, Text, CalendarIcon, Container, StatusVariant, theme, Caption } from 'ui'

interface TeammateInfoProps {
  fullName: string
  jobTitle?: string
  joined?: Date
  status?: StatusVariant
}

export const TeammateInfo: FC<TeammateInfoProps> = ({ fullName, jobTitle, joined, status }) => {
  const { t } = useTranslation(['common'])

  return (
    <Container display="flex" flexDirection="column" justifyContent="center" data-cy="teammateInfo">
      <Container display="flex" alignItems="center" mb="0.4rem">
        <Text variant="textMedium">{fullName}</Text>
        {status && <StatusBadge status={status} ml="0.8rem" />}
      </Container>
      {jobTitle && (
        <Text variant="smallTextRegular" mb="0.4rem" textAlign="left">
          {capitalizeFirstLetter(jobTitle)}
        </Text>
      )}
      {joined && (
        <Container display="flex" alignItems="center" flexGap="0.4rem">
          <CalendarIcon fill={theme.colors.greyDark} />
          <Caption variant="captionRegular" color={theme.colors.greySuperDark}>
            {t('joined')} {format(new Date(joined), 'PP')}
          </Caption>
        </Container>
      )}
    </Container>
  )
}
