import { useMutation, useQueryClient } from '@tanstack/react-query'
import { chatApi } from 'src/features/chats/repositories/chat-service'
import { LeaveChannelProps } from '../leave-channel'
import { Chat } from '../types/chat'
import { CHANNELS } from './use-get-channels'

interface UseLeaveChannelParams {
  onSuccess?: (
    data: Chat,
    variables: LeaveChannelProps,
    context: unknown
  ) => Promise<unknown> | unknown
  onError?: CallableFunction
}

export const useLeaveChannel = ({ onSuccess, onError }: UseLeaveChannelParams) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ channelId }: LeaveChannelProps) => chatApi.leaveChannel({ channelId }),
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context)
      queryClient.invalidateQueries([CHANNELS]) // TODO: remove invalidate and use dispatch instead, to do that we need to create slice for channels (different EP from the sync one)
    },
    onError: () => {
      onError?.()
    },
  })
}
