import React from 'react'

export const OpenIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.5 3.5a3.001 3.001 0 0 0-3 3v12a3.001 3.001 0 0 0 3 3h12a3.001 3.001 0 0 0 3-3v-4a1 1 0 0 0-1-1 1 1 0 0 0-1 1v4a.999.999 0 0 1-1 1h-12a.999.999 0 0 1-1-1v-12a.999.999 0 0 1 1-1h4a1 1 0 0 0 1-1 1 1 0 0 0-1-1zm9 0a1 1 0 0 0-1 1 1 1 0 0 0 1 1h2.586l-6.293 6.293a1 1 0 0 0 0 1.414 1 1 0 0 0 1.414 0L19.5 6.914V9.5a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-5a1 1 0 0 0-.076-.383 1 1 0 0 0-.217-.324A1 1 0 0 0 20.5 3.5Z" />
    </svg>
  )
}
