import { ATTACHMENT_SPINNER_SIZE } from '@utils/variables'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { FlexProps, position, PositionProps, WidthProps } from 'styled-system'
import { CloseBoldIcon, Container, DownloadIcon, SpinnerDarkAnimation, Text, Tooltip } from 'ui'
import { Preview } from './preview'

interface AttachmentProps extends WidthProps, FlexProps {
  src?: string
  name?: string
  size?: string | 0
  width?: string
  contentType?: string
  color?: ColorVariant
  isLoading?: boolean
  isDownloading?: boolean
  onRemove?: VoidFunction
  isDownload?: boolean
  onClick?: VoidFunction
}

type ColorVariant = 'default' | 'light'

interface AttachmentButtonProps extends PositionProps {
  color?: ColorVariant
}

export const AttachmentItem: FC<AttachmentProps> = ({
  size,
  src,
  name,
  color = 'default',
  isLoading = false,
  isDownloading = false,
  contentType,
  width,
  onRemove,
  isDownload = false,
  onClick,
}) => {
  const attachmentElement = onClick ? 'button' : 'div'
  const nameRef = useRef<HTMLParagraphElement | null>(null)
  const { t } = useTranslation('attachments')
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    if (nameRef.current) {
      const isContentTruncated = nameRef.current.scrollWidth > nameRef.current.clientWidth
      setIsTruncated(isContentTruncated)
    }
  }, [name])

  return (
    <Container onClick={onClick} width={width || '100%'}>
      <AttachmentContainer
        as={attachmentElement}
        type={onClick ? 'button' : undefined}
        color={color}
        p="0.8rem"
        pr={onRemove || isDownload ? '3.6rem' : '1.2rem'}
      >
        <Preview
          name={name}
          isLoading={isLoading}
          color={color}
          src={src}
          contentType={contentType}
        />
        <InnerContainer>
          {name && (
            <Tooltip text={name} placement="top" disabled={!isTruncated}>
              <Name ref={nameRef} variant="textMedium" color={color}>
                {name}
              </Name>
            </Tooltip>
          )}
          <FileSize variant="smallTextRegular" color={color}>
            {isDownloading ? `${t('downloading')}...` : size}
          </FileSize>
        </InnerContainer>
        {onRemove && (
          <AttachmentButton top="0.7rem" color={color} onClick={onRemove} aria-label={t('remove')}>
            <CloseBoldIcon />
          </AttachmentButton>
        )}
        {isDownload &&
          (isDownloading ? (
            <DownloadIndicator>
              <SpinnerDarkAnimation size={ATTACHMENT_SPINNER_SIZE} />
            </DownloadIndicator>
          ) : (
            <DownloadButton color={color} aria-label={t('download')}>
              <DownloadIcon />
            </DownloadButton>
          ))}
      </AttachmentContainer>
    </Container>
  )
}

const commonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0.7rem;
`

const AttachmentButton = styled.button<AttachmentButtonProps>`
  ${commonStyles}
  transition: color ${({ theme }) => theme.transitionTimes.short};
  color: ${({ theme }) => theme.colors.greyDark};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  ${position}
`

const DownloadButton = styled(AttachmentButton)`
  position: absolute;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity ${({ theme }) => theme.transitionTimes.short};
`

const AttachmentContainer = styled(Container)<{ color: ColorVariant }>`
  display: flex;
  width: 100%;
  text-align: left;
  overflow: hidden;
  position: relative;
  border: ${({ theme }) => `1px solid ${theme.colors.greyLight}`};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.8rem;
  ${({ color, theme }) =>
    color === 'light' &&
    `
      border-color: ${theme.colors.white};
    `}
  &:hover {
    ${DownloadButton} {
      opacity: 1;
    }
  }
`

const FileSize = styled(Text)<{ color: ColorVariant }>`
  color: ${({ theme }) => theme.colors.greySuperDark};
`

const Name = styled(Text)<{ color: ColorVariant }>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  display: block;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
  margin-left: 0.8rem;
`

const DownloadIndicator = styled.div`
  ${commonStyles}
  top: 50%;
  transform: translateY(-50%);
`
