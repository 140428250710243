import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { useToggleMute } from '@api/account/hooks/use-toggle-mute'
import { useCreateChat } from '@api/chats/hooks/use-create-chat'
import { MailIcon, PhoneIcon } from '@components/icons'
import { List } from '@components/list'
import { routes } from '@routes/flow/routes'
import { getFeatureFlag } from '@utils/flags-manager'
import { getFullName } from '@utils/helpers'
import { AVATAR_PREVIEW_WIDTH } from '@utils/variables'
import { format } from 'date-fns'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  Avatar,
  Button,
  SendIcon,
  Text,
  TimeIcon,
  ToggleButton,
  TranslationLgIcon,
  UserIcon,
} from 'ui'
import { UserDetailProps } from '.'

export const UserActive: React.FC<UserDetailProps> = ({ user }) => {
  const {
    userId,
    firstName,
    lastName,
    phoneNumber,
    email,
    jobTitle,
    avatar,
    color,
    timeZone,
    isDeleted,
    languages,
  } = user
  const [isMuted] = useToggleMute()
  const { t } = useTranslation('user-detail')
  const navigate = useNavigate()
  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId,
    avatarId: avatar?.id,
    width: AVATAR_PREVIEW_WIDTH.DETAIL,
  })
  const userLanguages = languages?.map(({ translatedName }) => translatedName).join(', ')

  const usersLocalTime = () => {
    if (!timeZone || !timeZone.id) return getFormatedTime(new Date())
    const date = new Date()
    const formattedDate = date.toLocaleString('en-US', { timeZone: timeZone.id })
    return getFormatedTime(new Date(formattedDate))
  }

  const { createChat, data, isCreateChatSuccess } = useCreateChat()

  const handleSendClick = useCallback(() => {
    createChat({ userIds: [userId] })
  }, [data, isCreateChatSuccess])

  useEffect(() => {
    if (isCreateChatSuccess) {
      navigate(`${routes.chats}/${data?.id}`)
    }
  }, [data, isCreateChatSuccess])

  const getFormatedTime = (time: Date) => {
    return `${format(time, 'p')} local time`
  }

  const fullName = getFullName(firstName, lastName)

  const info = [
    {
      icon: <UserIcon aria-label={t('positionHiddenLabel')} />,
      text: jobTitle,
    },
    {
      icon: <TranslationLgIcon aria-label={t('languagesHiddenLabel')} />,
      text: userLanguages,
    },
    {
      icon: <TimeIcon aria-label={t('localTimeHiddenLabel')} />,
      text: timeZone ? usersLocalTime() : undefined,
    },
  ]

  const contacts = [
    {
      icon: <MailIcon aria-label={t('emailHiddenLabel')} />,
      text: email ? <a href={`mailto:${email}`}>{email}</a> : undefined,
    },
    {
      icon: <PhoneIcon aria-label={t('phoneHiddenLabel')} />,
      text: phoneNumber,
    },
  ]

  return (
    <>
      <Avatar
        data-cy="userActiveAvatar"
        name={fullName}
        size="28.8rem"
        imgUrl={usersAvatarUrl ?? avatar?.uri}
        label={firstName}
        isDeleted={isDeleted}
        fontSize="8rem"
        bgColor={`#${color}`}
        iconSize={192}
        borderRadius="1.6rem"
        isAvatarExists={enabled}
      />
      <List items={info} my="1.6rem" data-cy="userActiveInfo" />
      <Button
        data-cy="userActiveMessageBtn"
        width="100%"
        variant="secondary"
        size="sm"
        preFix={isDeleted ? null : <SendIcon />}
        onClick={handleSendClick}
      >
        {isDeleted ? t('viewMsgHistoryButton') : t('msgButton')}
      </Button>
      {!isDeleted && (
        <List
          title={t('subTitleContact')}
          items={contacts}
          mt="2.8rem"
          data-cy="userActiveSubTitleContactInfo"
        />
      )}
      {getFeatureFlag('showOnProd') && (
        <>
          <Text variant="textMedium" mb="0.4rem">
            {t('subTitleNotifications')}
          </Text>
          <ToggleButton
            label={t('muteButton')}
            id="mute-user"
            checked={isMuted}
            // onClick={() => toggleMute(userId)}
          />
        </>
      )}
    </>
  )
}
