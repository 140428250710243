import { Chat } from '@api/chats/types/chat'
import { createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit'

const chatsAdapter = createEntityAdapter<Chat>()

export const { selectAll: selectChats, selectById: selectChat } = chatsAdapter.getSelectors(
  (state: EntityState<Chat>) => state
)

export const selectChatUsers = createSelector([selectChat], (chat) => chat?.chatUsers)

const chatsSlice = createSlice({
  name: 'chats',
  initialState: chatsAdapter.getInitialState(),
  reducers: {
    setChat: chatsAdapter.setOne,
    setChats: chatsAdapter.setMany,
  },
})

export const { setChats, setChat } = chatsSlice.actions
export default chatsSlice.reducer
