import camelcaseKeys from 'camelcase-keys'
import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import { refreshTokens } from '@native-chats-api/auth-api/refresh-tokens'
import {
  getAccessTokenFromCookies,
  getRefreshTokenFromCookies,
} from '@native-chats-api/auth-api/utils/get-tokens'
import { setTokens } from '@native-chats-api/auth-api/utils/set-tokens'
import { removeTokenFromCookies } from '@native-chats-api/auth-api/utils/remove-tokens'
import { TokenType } from '@native-chats-api/auth-api/utils/tokens'

export interface RetryAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean
}
export const onFulfilledChatsInterceptor = (response: AxiosResponse<any>) => {
  return camelcaseKeys(response, { deep: true }) as AxiosResponse<any>
}
export const createOnRejectedInterceptor =
  (axiosInstance: AxiosInstance) => async (error: AxiosError) => {
    const originalRequest = error.config as RetryAxiosRequestConfig
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true // Mark the request as retried to avoid infinite loops.
      try {
        const refreshToken = getRefreshTokenFromCookies() // Retrieve the stored refresh token.
        // Make a request to your auth server to refresh the token.
        if (refreshToken) {
          const response = await refreshTokens(refreshToken)
          const { accessToken, refreshToken: newRefreshToken } = response.data
          // Store the new access and refresh tokens.
          setTokens({ accessToken, refreshToken: newRefreshToken })
          // Update the authorization header with the new access token.
          axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
          if (originalRequest) {
            return axiosInstance(originalRequest) // Retry the original request with the new access token.
          }
        }
      } catch (refreshError) {
        // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
        console.error('Token refresh failed:', refreshError)
        removeTokenFromCookies(TokenType.AccessToken)
        removeTokenFromCookies(TokenType.RefreshToken)
        window.location.href = '/login'
        return Promise.reject(refreshError)
      }
    }
    return Promise.reject(error) // For all other errors, return the error as is.
  }

export const requestInterceptorSuccess = (request: InternalAxiosRequestConfig) => {
  const accessToken = getAccessTokenFromCookies()
  if (accessToken) {
    request.headers = request.headers || {}
    request.headers['Authorization'] = `Bearer ${accessToken}`
  }
  return request
}
