import { theme } from '@common/theme'
import { TeammatesIcon, TilesIcon } from '@components/icons'
import { AddChannelMembersModal } from '@modules/modals/add-channel-members'
import { EmptyResults } from '@shared/ui/modules/empty-results'
import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConversationUser } from 'src/entities/conversations/model/conversation'
import { User } from 'src/entities/teammates/api/model/user-type'
import { filterByFields } from 'src/shared/lib/filter-utils/filter-by-fields'
import {
  BasicSearch,
  Button,
  Container,
  ListIcon,
  PlusIcon,
  ScrollContainer,
  Text,
  useModal,
} from 'ui'
import { ListItem } from './list-item'
import { MembersList, MembersTiles, SwitchButton } from './styled'
import { TileItem } from './tile-item'

interface MembersProps {
  isAddMembersAllowed: boolean
  channelId: string
  conversationUsers?: ConversationUser[]
  usersToAdd: User[]
  channelName: string
  allMembersJoined?: boolean
}

export const Members: FC<MembersProps> = ({
  isAddMembersAllowed = false,
  channelName,
  channelId,
  conversationUsers,
  usersToAdd,
  allMembersJoined = false,
}) => {
  const [searchString, setSearchString] = useState<string>('')
  const [tilesView, setTilesView] = useState<boolean>(true)
  const { t } = useTranslation(['details', 'workspace', 'common'])
  const { openModal } = useModal()

  const filterMembers = useMemo(() => {
    if (!conversationUsers) return null
    return filterByFields(conversationUsers, searchString, ['user.firstName', 'user.lastName'])
  }, [searchString, conversationUsers])

  const showAddMembers = isAddMembersAllowed && !allMembersJoined

  return (
    <>
      <Container display="flex" alignItems="center">
        <BasicSearch
          id="search_member"
          name="search_member"
          hiddenLabel={t('searchMember')}
          placeholder={t('searchMember')}
          setSearchString={setSearchString}
          searchString={searchString}
          flexGrow={1}
          delay={0}
        />
        <SwitchButton
          type="button"
          onClick={() => setTilesView(!tilesView)}
          aria-label={
            tilesView ? t('listView', { ns: 'common' }) : t('tilesView', { ns: 'common' })
          }
          ml="1.2rem"
        >
          {tilesView ? <ListIcon /> : <TilesIcon />}
        </SwitchButton>
      </Container>
      {!filterMembers?.length && (
        <EmptyResults
          pb="13.2rem"
          title={t('emptyMembersTitle', { ns: 'workspace' })}
          img={{
            src: '/img/no-members-found.svg',
            alt: t('emptyMembersTitle', { ns: 'workspace' }),
            width: 240,
            height: 380,
          }}
        />
      )}
      <ScrollContainer hideScrollbar mx="-1.6rem">
        <Container m="1.6rem" mb={tilesView ? '1.6rem' : '0.8rem'}>
          {!showAddMembers && (
            <Container display="flex" alignItems="center" mb="1rem">
              <TeammatesIcon fill={theme.colors.greyDark} />
              <Text color="greySuperDark" ml="0.8rem">
                {t('allMembersJoined')}
              </Text>
            </Container>
          )}
          {showAddMembers && (
            <Button
              variant="secondary"
              plain
              width="100%"
              preFix={<PlusIcon />}
              onClick={() =>
                openModal({
                  content: (
                    <AddChannelMembersModal
                      channelName={channelName}
                      channelId={channelId}
                      usersToAdd={usersToAdd}
                    />
                  ),
                  maxWidth: '62rem',
                })
              }
            >
              {t('addMembers')}
            </Button>
          )}
        </Container>
        {!tilesView ? (
          <MembersList>
            {filterMembers?.map((user) => (
              <ListItem {...user} />
            ))}
          </MembersList>
        ) : (
          <MembersTiles>
            {filterMembers?.map((user) => (
              <TileItem {...user} />
            ))}
          </MembersTiles>
        )}
      </ScrollContainer>
    </>
  )
}
