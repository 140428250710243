import React, { ReactNode, useRef } from 'react'
import ReactSelect, {
  StylesConfig,
  components,
  ValueContainerProps,
  MultiValueRemoveProps,
  ClearIndicatorProps,
  MenuProps,
  SelectInstance,
} from 'react-select'
import { useTheme } from 'styled-components'
import { Container } from '../../container'
import { CloseIcon } from '../../icons'
import { InputLabel } from '../text-field/labels'
import { OptionType, SelectTextFieldProps } from './types'

// @TODO on arrows move along the options list
export const Select: React.FC<SelectTextFieldProps> = ({
  placeholder,
  multiple = false,
  label,
  helperText,
  disabled,
  type,
  value,
  onChange,
  options,
  maxMenuHeight,
  isSearchable,
  maxMultiChildrenAmount,
  closeMenuOnSelect,
  hideSelectedOptions,
  tooltipText,
  ...spacing
}) => {
  const appTheme = useTheme()

  if (multiple && !Array.isArray(value)) {
    throw new Error('Multiple selection allowed only when value is an array')
  }

  const selectRef = useRef<SelectInstance<OptionType> | null>(null)

  const onMenuClose = () => {
    selectRef.current?.blur()
  }

  /*Custom Components */
  const Menu = (props: MenuProps<OptionType>) => {
    return <components.Menu {...props}>{props.children}</components.Menu>
  }

  const MultiValueRemove = (props: MultiValueRemoveProps<OptionType>) => (
    <components.MultiValueRemove {...props}>
      <CloseIcon fill={appTheme.colors.black} />
    </components.MultiValueRemove>
  )

  const ClearIndicator = (props: ClearIndicatorProps<OptionType>) => (
    <components.ClearIndicator {...props}>
      <CloseIcon fill={appTheme.colors.black} />
    </components.ClearIndicator>
  )

  const ValueContainer = (props: ValueContainerProps<OptionType>) => {
    const multipleValueCount = React.Children.count(
      (props.children as ReactNode[][])[0] as ReactNode[]
    )

    // For multiple select we want to omit more than component.props.maxMultiChildrenAmount
    const allowedChildrenCount = maxMultiChildrenAmount || multipleValueCount

    // React select send ReactNode[][]
    // Where ReactNode[0] => ReactNode[] - multiple values
    // We need to take out options and input separately to save accessibility
    const [selectedOptions, input] = props.children as ReactNode[][]

    const slicedSelectedOptions = React.Children.toArray(selectedOptions).slice(
      0,
      allowedChildrenCount
    )

    const isMultipleCountExceeded =
      maxMultiChildrenAmount && multipleValueCount > maxMultiChildrenAmount

    const moreOptionsComponent = (
      <span key="more" aria-label="More options">
        ...
      </span>
    )

    if (isMultipleCountExceeded) {
      slicedSelectedOptions.push(moreOptionsComponent)
    }

    const newChildren = [slicedSelectedOptions, input]

    return <components.ValueContainer {...props}>{newChildren}</components.ValueContainer>
  }

  return (
    <Container display="flex" flexDirection="column" {...spacing}>
      <InputLabel fontSize="1.2rem" labelText={label} tooltipText={tooltipText} />
      <ReactSelect
        ref={selectRef}
        placeholder={placeholder}
        isSearchable={isSearchable}
        maxMenuHeight={maxMenuHeight}
        hideSelectedOptions={hideSelectedOptions}
        onMenuClose={onMenuClose}
        onChange={onChange}
        closeMenuOnSelect={closeMenuOnSelect}
        isDisabled={disabled}
        value={value}
        styles={SelectStyles}
        isMulti={multiple}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: appTheme.colors.primary,
            neutral10: appTheme.colors.greyLight,
            neutral20: appTheme.colors.grey,
            neutral30: appTheme.colors.greyDark,
          },
        })}
        components={{
          IndicatorSeparator: undefined,
          ValueContainer,
          MultiValueRemove,
          ClearIndicator,
          Menu,
        }}
        options={options}
      />
    </Container>
  )
}

const SelectStyles: StylesConfig<OptionType> = {
  valueContainer: (base) => ({
    ...base,
    padding: '0.4rem 1rem',
  }),
  container: (base) => ({
    ...base,
    fontSize: '1.5rem',
    letterSpacing: '-0.017em',
  }),
  control: (base, props) => ({
    ...base,
    'minHeight': '4.8rem',
    'boxShadow': 'none',
    'backgroundColor': 'white',
    'borderRadius': '0.8rem',
    'borderColor': props.isFocused ? props.theme.colors.primary : props.theme.colors.neutral20,
    'cursor': 'pointer',
    ':hover': {
      borderColor: props.isFocused ? props.theme.colors.primary : props.theme.colors.neutral20,
    },
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
  placeholder: (base, props) => ({
    ...base,
    color: props.theme.colors.neutral30,
    fontSize: '1.5rem',
    letterSpacing: '-0.017em',
  }),
  multiValue: (base, props) => ({
    ...base,
    backgroundColor: props.theme.colors.neutral10,
    borderRadius: '0.8rem',
    padding: '0.5rem 0.7rem 0.4rem 0.7rem',
    margin: '0.3rem',
    transition: `width 300ms`,
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: '#373234',
    fontWeight: 400,
    fontSize: '1.5rem',
  }),
  multiValueRemove: (base) => ({
    ...base,
    ':hover': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  }),
  indicatorsContainer: (base) => ({
    ...base,
    padding: '0.4rem',
    alignSelf: 'center',
  }),
  menu: (base, props) => ({
    ...base,
    padding: 0,
    animation: 'fade-in 300ms ease-in-out',
    overflow: 'hidden',
    boxShadow: 'none',
    border: `1px solid ${props.theme.colors.neutral20}`,
  }),
  menuList: (base, props) => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '1.2rem',
      height: '1.2rem',
    },
    '::-webkit-scrollbar-thumb': {
      border: '0.4rem solid rgba(0, 0, 0, 0)',
      backgroundClip: 'padding-box',
      borderRadius: '2rem',
      backgroundColor: props.theme.colors.neutral30,
    },
    'paddingTop': 0,
    'paddingBottom': 0,
  }),
  option: (base, props) => ({
    ...base,
    'fontSize': '1.5rem',
    'letterSpacing': '-0.017em',
    'display': 'flex',
    'alignItems': 'center',
    'height': '4rem',
    'backgroundColor': props.isFocused ? props.theme.colors.neutral10 : 'transparent',
    'color': '#373234',
    ':not(:last-child)': {
      borderBottom: `1px solid ${props.theme.colors.neutral10}`,
    },
    ':hover': {
      cursor: 'pointer',
      backgroundColor: props.theme.colors.neutral10,
    },
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    'color': '#373234',
    'transition': 'all .2s ease',
    'transform': state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
    ':hover': {
      color: '#373234',
      cursor: 'pointer',
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    ':hover': {
      cursor: 'pointer',
    },
  }),
}
