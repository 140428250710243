import { createAuthorizationParams, GrantType } from '@native-chats-api/auth-api/constants'
import { nativeChatsAuthApi } from '@native-chats-api/auth-api/instance'
import qs from 'qs'

export const logInWithGoogle = async (token: string) => {
  return nativeChatsAuthApi.post(
    '/connect/token',
    qs.stringify(
      createAuthorizationParams(GrantType.Google, {
        token,
      })
    ),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-DeviceID': 'web',
      },
    }
  )
}
