import React from 'react'
import { SVGProps } from 'react'

export const SortingUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m7.55 6.4-4 3a.75.75 0 0 0-.15 1.05.75.75 0 0 0 1.05.15L8 7.938l3.55 2.662a.75.75 0 0 0 1.05-.15.75.75 0 0 0-.15-1.05l-4-3a.75.75 0 0 0-.9 0z" />
  </svg>
)
