import { MessageDetail } from '@api/messages/types/message-detail'
import { useAppSelector } from '@app/flow/hooks'
import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react'
import { selectMessageById, selectConversationById } from 'src/features/conversation/slice'

export interface TranslationsProviderProps {
  children: JSX.Element | JSX.Element[]
}

export interface TranslationsProviderDefinition {
  sourceLanguageCode: string
  selectedLanguage: string
  conversationId: string
  clientMessageId: string
  setSourceLanguageCode: Dispatch<SetStateAction<string>>
  setSelectedLanguage: Dispatch<SetStateAction<string>>
  setConversationId: Dispatch<SetStateAction<string>>
  setClientMessageId: Dispatch<SetStateAction<string>>
  message?: MessageDetail
}

const initData: TranslationsProviderDefinition = {
  sourceLanguageCode: '',
  selectedLanguage: '',
  conversationId: '',
  clientMessageId: '',
  setSourceLanguageCode: () => {},
  setSelectedLanguage: () => {},
  setConversationId: () => {},
  setClientMessageId: () => {},
  message: undefined,
}

const TranslationsContext = createContext<TranslationsProviderDefinition>(initData)

const TranslationsProvider = ({ children }: TranslationsProviderProps) => {
  const [sourceLanguageCode, setSourceLanguageCode] = useState<string>('')
  const [selectedLanguage, setSelectedLanguage] = useState<string>('')
  const [conversationId, setConversationId] = useState<string>('')
  const [clientMessageId, setClientMessageId] = useState<string>('')

  const conversationMessaging = useAppSelector(({ conversation }) =>
    selectConversationById(conversation, conversationId)
  )

  const message = useMemo(() => {
    if (conversationMessaging) {
      return selectMessageById(conversationMessaging.messages, clientMessageId)
    }
    return undefined
  }, [conversationId, clientMessageId, conversationMessaging])

  return (
    <TranslationsContext.Provider
      value={{
        sourceLanguageCode,
        selectedLanguage,
        conversationId,
        clientMessageId,
        setSourceLanguageCode,
        setSelectedLanguage,
        setConversationId,
        setClientMessageId,
        message,
      }}
    >
      {children}
    </TranslationsContext.Provider>
  )
}

export { TranslationsContext, TranslationsProvider }
