import { TFunction } from 'next-i18next'
import * as Yup from 'yup'
import { channelNameLimits } from '../variables'

const charsLimit = 'validation.charsLimit'

export const channelNameValidationSchema = (t: TFunction, valueTag: string) =>
  Yup.object().shape({
    [valueTag]: Yup.string()
      .trim()
      .required(t(charsLimit, channelNameLimits))
      .min(channelNameLimits.min, t(charsLimit, channelNameLimits))
      .max(channelNameLimits.max, t(charsLimit, channelNameLimits)),
  })
