import { useMutation } from '@tanstack/react-query'
import { chatApi } from 'src/features/chats/repositories/chat-service'
import { DeleteMessageInput } from '../delete-message'
import { Message } from '../types/message'

interface UseDeleteMessageInput {
  onSuccessCallback?: VoidFunction
  onErrorCallback?: VoidFunction
  onMutateCallback?: VoidFunction
}

export const useDeleteMessage = ({
  onSuccessCallback,
  onErrorCallback,
  onMutateCallback,
}: UseDeleteMessageInput) => {
  return useMutation<Message, Error, DeleteMessageInput>(
    ({ messageId }) => chatApi.deleteMessage({ messageId }),
    {
      onSuccess: () => {
        onSuccessCallback?.()
      },
      onMutate: () => {
        onMutateCallback?.()
      },
      onError: () => {
        onErrorCallback?.()
      },
    }
  )
}
