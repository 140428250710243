import { CHAT_LIST } from '@api/chats/query-keys'
import { ConversationList } from '@api/chats/types/chat-list'
import { useAppDispatch, useAppSelector } from '@app/flow/hooks'
import { useInfiniteQuery } from '@tanstack/react-query'
import { flatMap } from 'lodash'
import { chatApi } from 'src/features/chats/repositories/chat-service'
import { selectChats, setChats } from '../../../features/chats/slice'

export const useGetAllChatList = (refetchIntervalValue?: number) => {
  const conversations = useAppSelector(({ chats }) => selectChats(chats))

  const dispatch = useAppDispatch()
  const {
    data,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
    ...rest
  } = useInfiniteQuery<ConversationList, Error>({
    onSuccess: (response) => {
      const chats = flatMap(response.pages, ({ chats }) => chats)
      dispatch(setChats(chats))
    },
    staleTime: Infinity,
    queryKey: [CHAT_LIST],
    queryFn: (props) => chatApi.getChatList(props),
    refetchOnWindowFocus: false,
    refetchInterval: refetchIntervalValue
      ? (data) =>
          (data?.pages ?? []).flatMap((page) => page?.chats ?? []).length > 0
            ? false
            : refetchIntervalValue
      : false,
    getNextPageParam: (lastPage) => {
      if (lastPage.currentPage < lastPage.pages) {
        return lastPage.currentPage + 1
      }
      return false
    },
  })

  // Check if data and data.pages are defined before using flatMap
  const fetchedData = conversations.filter((chat) => !chat.isDeleted) || []

  return {
    fetchedData,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
    ...rest,
  }
}
