import { theme } from '@common/theme'
import React, { FC } from 'react'
import { components, ClearIndicatorProps } from 'react-select'
import { CloseIcon } from 'ui'
import { OptionType } from './types'

export const ClearIndicator: FC<ClearIndicatorProps<OptionType>> = ({ ...props }) => (
  <components.ClearIndicator {...props}>
    <CloseIcon fill={theme.colors.black} />
  </components.ClearIndicator>
)
