import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetSupportedLanguages } from '@api/account/hooks/use-get-supported-languages'
import { useSetPersonalLanguages } from '@api/account/hooks/use-set-personal-languages'
import { UserLanguage } from '@api/account/types/language'
import { useInterfaceLanguage } from '@hooks/use-interface-language'
import { isNativeChatsApp } from '@utils/is-native-chats-app'
import { Form, Formik } from 'formik'
import React, { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, LoaderAnimation, OptionType, Select } from 'ui'

interface LanguageSettingsInitialValues {
  primaryLanguage: OptionType
  additionalLanguages: OptionType[]
  interfaceLanguage: OptionType
}

const initialPrimaryLanguage: OptionType = {
  isPrimary: true,
  label: 'English',
  value: 'en',
}

const defaultInterfaceLanguage: OptionType = {
  label: 'English',
  value: 'en',
}

export const LanguageSettingsForm: FC = () => {
  const { meData: meData, isFetching: isUserFetching } = useGetMe()
  const { interfaceLanguage, changeInterfaceLanguage, interfaceLanguageOptions } =
    useInterfaceLanguage()
  const { data: supportedLanguages, isFetching: areLanguagesFetching } = useGetSupportedLanguages({
    skip: false,
  })
  const { mutate: setPersonalLanguages, isLoading: areSettingsApplying } = useSetPersonalLanguages()
  const { t } = useTranslation(['personal'])

  const handleSubmit = useCallback(
    (values: LanguageSettingsInitialValues) => {
      const { primaryLanguage, additionalLanguages, interfaceLanguage } = values
      const languages: UserLanguage[] = [
        {
          name: primaryLanguage.label,
          isPrimary: true,
          code: primaryLanguage.value as string,
        },
        ...additionalLanguages.map((lan) => ({
          name: lan.label,
          code: lan.value as string,
        })),
      ]
      setPersonalLanguages(languages)
      if (interfaceLanguage) {
        changeInterfaceLanguage(String(interfaceLanguage.value))
      }
    },
    [setPersonalLanguages]
  )

  const initialValues: LanguageSettingsInitialValues = useMemo(() => {
    if (!meData) {
      return {
        primaryLanguage: initialPrimaryLanguage,
        additionalLanguages: [],
        interfaceLanguage: isNativeChatsApp() ? interfaceLanguage : defaultInterfaceLanguage,
      }
    }

    const primaryLanguage = meData.activeLanguages.find((lan) => lan.isPrimary)

    return {
      primaryLanguage: primaryLanguage
        ? {
            label: primaryLanguage.name,
            value: primaryLanguage.code,
          }
        : initialPrimaryLanguage,
      additionalLanguages: meData.activeLanguages
        .filter((lan) => !lan.isPrimary)
        .map((lan) => ({
          label: lan.name,
          value: lan.code,
        })),
      interfaceLanguage: isNativeChatsApp() ? interfaceLanguage : defaultInterfaceLanguage,
    }
  }, [meData])

  const languageOptions = useMemo(
    () =>
      supportedLanguages?.map((lan) => ({
        label: lan.name,
        value: lan.code,
      })) ?? [],
    [supportedLanguages]
  )

  if (isUserFetching && areLanguagesFetching) {
    return <LoaderAnimation />
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      {({ values, setFieldValue }) => {
        if (values.primaryLanguage && values.additionalLanguages) {
          return (
            <Form>
              <Select
                label={t('mainLang.label')}
                options={languageOptions}
                value={values.primaryLanguage}
                onChange={(val) => setFieldValue('primaryLanguage', val)}
                mb="2.4rem"
                tooltipText={t('mainLang.tooltip')}
              />
              <Select
                label={t('additionalLang.label')}
                options={languageOptions}
                value={values.additionalLanguages}
                onChange={(val) => setFieldValue('additionalLanguages', val)}
                multiple
                placeholder={t('additionalLang.placeholder')}
                mb="2.4rem"
                tooltipText={t('additionalLang.tooltip')}
              />
              {isNativeChatsApp() && (
                <Select
                  label={t('interfaceLang')}
                  options={interfaceLanguageOptions}
                  value={values.interfaceLanguage}
                  onChange={(val) => setFieldValue('interfaceLanguage', val)}
                />
              )}
              <Button type="submit" minWidth="16rem" mt="2.4rem" disabled={areSettingsApplying}>
                {t('saveButton')}
              </Button>
            </Form>
          )
        }
        return null
      }}
    </Formik>
  )
}
