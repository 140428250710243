import { useSidePage } from '@hooks/use-side-page'
import { AttachmentItem } from '@modules/attachment-item'
import { MediaAttachment } from '@modules/media-attachment'
import { AttachmentsContainer, GridContainer } from '@modules/message-attachments/styled'
import { isExpectedType } from '@shared/lib/media/is-expected-type'
import {
  MAX_VISIBLE_ATTACHMENTS,
  MEDIA_ONLY_PREVIEW_WIDTH,
  CHIP_PREVIEW_WIDTH,
} from '@utils/variables'
import React, { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AttachmentResponse } from 'src/entities/messages/api/types/attachment-response'
import { useFileDownload } from 'src/shared/lib/hooks/use-file-download'
import { bytesToSize } from 'src/shared/lib/media/bytes-to-size'
import { Metadata, MetadataType } from 'src/shared/types/meta-data'
import { SpaceProps, WidthProps } from 'styled-system'
import { AttachmentFetcher } from '../attachment-fetcher'
import { Timestamp } from './timestamp'

export interface AttachmentsProps extends SpaceProps {
  attachments: AttachmentResponse[]
  uploadingAttachments: AttachmentResponse[]
  onClick: (index: number) => void
  hasTimestamp: boolean
  hasOnlyImagesOrVideo: boolean
  createdAt?: Date
  isIncoming?: boolean
  isLoading?: boolean
  isSenderShown?: boolean
}

interface AttachmentsWithFetcherProps extends AttachmentsProps {
  conversationId: string
}

export const Attachments: FC<AttachmentsWithFetcherProps & WidthProps> = ({
  attachments,
  uploadingAttachments,
  conversationId,
  onClick,
  hasTimestamp,
  hasOnlyImagesOrVideo,
  createdAt,
  isIncoming = false,
  isSenderShown = true,
  isLoading = false,
  width,
  ...spacing
}) => {
  const attachmentsToRender = uploadingAttachments ? uploadingAttachments : attachments

  const { t } = useTranslation('attachments')
  const totalAttachments = uploadingAttachments ? uploadingAttachments.length : attachments.length
  const { downloadFile, isDownloading } = useFileDownload()
  const { open } = useSidePage()

  const overlayText = useMemo(
    () =>
      totalAttachments > MAX_VISIBLE_ATTACHMENTS
        ? `+${totalAttachments - MAX_VISIBLE_ATTACHMENTS} ${t('more')}`
        : undefined,
    [totalAttachments, t]
  )

  const renderMediaAttachment = (props: {
    src: string
    name: string
    metadata: Metadata
    isLoading: boolean
    index: number
    isSenderShown: boolean
  }) => (
    <MediaAttachment
      {...props}
      isVideo={props.metadata.type === MetadataType.VIDEO}
      isIncoming={isIncoming}
      onClick={() => onClick(props.index)}
      showOverlay={
        totalAttachments > MAX_VISIBLE_ATTACHMENTS && props.index + 1 === MAX_VISIBLE_ATTACHMENTS
      }
      overlayText={overlayText}
    />
  )

  const renderFileAttachment = useCallback(
    (src: string, name: string, metadata: Metadata, isLoading: boolean, index: number) => {
      const handleClick = () => {
        const isValidMedia = isExpectedType(metadata, [MetadataType.IMAGE, MetadataType.VIDEO])

        if (isValidMedia) {
          onClick(index)
        } else {
          downloadFile(attachmentsToRender[index].id, conversationId, name, t('errors.tryAgain'))
        }
      }

      return (
        <AttachmentItem
          key={index}
          src={src}
          name={name}
          size={bytesToSize(metadata?.size)}
          contentType={metadata?.contentType}
          isLoading={isLoading}
          isDownloading={isDownloading(attachmentsToRender[index].id)}
          color={isIncoming ? 'default' : 'light'}
          isDownload={true}
          onDownload={() =>
            downloadFile(attachmentsToRender[index].id, conversationId, name, t('errors.tryAgain'))
          }
          onClick={handleClick}
        />
      )
    },
    [isDownloading, onClick, t, conversationId, attachmentsToRender]
  )

  const renderAttachment = useCallback(
    (attachment: AttachmentResponse, index: number) => (
      <AttachmentFetcher
        key={attachment.id}
        conversationId={conversationId}
        skip={uploadingAttachments?.length > 0}
        width={hasOnlyImagesOrVideo ? MEDIA_ONLY_PREVIEW_WIDTH : CHIP_PREVIEW_WIDTH}
        {...attachment}
      >
        {({ src, name, isProcessing, metadata }) => {
          return hasOnlyImagesOrVideo
            ? renderMediaAttachment({
                src,
                name,
                metadata,
                isLoading: isProcessing,
                index,
              })
            : renderFileAttachment(src, name, metadata, isProcessing, index)
        }}
      </AttachmentFetcher>
    ),
    [
      conversationId,
      hasOnlyImagesOrVideo,
      isDownloading,
      onClick,
      t,
      renderFileAttachment,
      renderMediaAttachment,
    ]
  )

  const attachmentsCountToShow = hasOnlyImagesOrVideo ? MAX_VISIBLE_ATTACHMENTS : totalAttachments

  const attachmentChildren =
    totalAttachments === 1
      ? renderAttachment(attachmentsToRender[0], 0)
      : attachmentsToRender.slice(0, attachmentsCountToShow).map(renderAttachment)

  const widthToSet = useMemo(() => {
    if (hasOnlyImagesOrVideo) {
      return null
    }
    if (width) {
      return `${width}px`
    }
    return '100%'
  }, [width])

  return (
    <AttachmentsContainer
      isIncoming={isIncoming}
      isSenderShown={isSenderShown}
      hasTimestamp={hasTimestamp}
      width={widthToSet}
      {...spacing}
    >
      <GridContainer
        attachmentsCount={totalAttachments}
        hasOnlyImagesOrVideo={hasOnlyImagesOrVideo}
        open={open}
      >
        {attachmentChildren}
      </GridContainer>
      {hasTimestamp && <Timestamp isLoading={isLoading} createdAt={createdAt} />}
    </AttachmentsContainer>
  )
}
