import { ConversationsRepository } from '../repositories/conversations-repository'
import { ConversationsRepositoryImplementation } from '../repositories/conversations-repository-implementation'

class SyncConversationsService {
  constructor(private conversationsRepository: ConversationsRepository) {}

  syncConversations(pageSize = 20, timeStamp?: string, lastChatId?: string) {
    return this.conversationsRepository.syncConversations(pageSize, timeStamp, lastChatId)
  }
}

export const syncConversationsService = new SyncConversationsService(
  new ConversationsRepositoryImplementation()
)
