import { CredentialsLoginForm } from '@modules/native-chats/forms/credentials-login'
import { PageHeader } from '@modules/native-chats/page-header'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'ui'

export const CredentialsLogin: FC = () => {
  const { t } = useTranslation('nc-login')

  return (
    <>
      <PageHeader title={t('loginWithEmail')} subtitle={t('loginWitEmailMsg')} mb="4rem" />
      <Container maxWidth="40rem" width="100%" m="0 auto">
        <CredentialsLoginForm />
      </Container>
    </>
  )
}
