import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled, { keyframes, useTheme } from 'styled-components'
import { Container } from 'ui'

interface MessageSkeletonProps {
  messageWidth: number
  isIncoming?: boolean
}

export const MessageSkeleton: FC<MessageSkeletonProps> = ({ messageWidth, isIncoming = false }) => {
  const theme = useTheme()

  const messageSkeletonBorderRadius = isIncoming
    ? { borderRadius: 16, borderTopLeftRadius: 0 }
    : { borderRadius: 16, borderBottomRightRadius: 0 }

  return (
    <Container display="flex" alignSelf={isIncoming ? 'flex-start' : 'flex-end'}>
      {isIncoming && (
        <StyledSkeleton
          width={36}
          height={36}
          baseColor={theme.colors.beigeDark}
          borderRadius={6}
          style={{ marginRight: '0.8rem' }}
          fadeColor={theme.colors.beigeDark}
        />
      )}
      <StyledSkeleton
        height={48}
        width={messageWidth}
        baseColor={theme.colors.beigeDark}
        style={messageSkeletonBorderRadius}
        fadeColor={theme.colors.beigeDark}
      />
    </Container>
  )
}

const fadeColor = (color: string) => keyframes`
  0% {
    background-color: ${color};
    opacity: 0.1;
  }
  50% {
    background-color: ${color};
    opacity: 1;
  }
  100% {
    background-color: ${color};
    opacity: 0.1;
  }
`

const StyledSkeleton = styled(Skeleton)<{ fadeColor: string }>`
  animation: ${(props) => fadeColor(props.fadeColor)} 3s ease-out forwards infinite;
`
