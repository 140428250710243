import { useGetMe } from '@api/account/hooks/use-get-me'
import { User } from '@api/account/types/user'
import { getFullName } from '@utils/helpers'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SpaceProps } from 'styled-system'
import { Container, Text } from 'ui'
import { UserPreviewAvatar } from './user-preview-avatar'

interface EmptyChatMessagesProps extends SpaceProps {
  users: User[]
}

export const EmptyChatMessages: FC<EmptyChatMessagesProps> = ({ users, ...spacing }) => {
  const { t } = useTranslation(['create-new'])
  const { meData } = useGetMe()

  const firstUser = users[0]
  const isDM = users.length === 1
  const isMessageToMyself = isDM && firstUser.userId === meData?.userId

  // chatUsers excl. ME
  const chatUsers = useMemo(() => users.filter(({ userId }) => userId !== meData?.userId), [users])

  // chatUsers names excl. my name
  const chatUsersNames = useMemo(() => {
    return chatUsers.map(({ firstName }) => firstName)
  }, [chatUsers])

  // amount of the chatUsers incl. ME
  const chatUsersNumber = useMemo(() => {
    return chatUsers.length
  }, [chatUsers])

  const getEmptyText = (messageType: 'dm' | 'myspace' | 'group', userNames?: string) => {
    let content

    switch (messageType) {
      case 'dm':
        content = `${t('infoOneUser', { userNames })} ${t('accessRestricted')}`
        break
      case 'myspace':
        content = `${t('privateChatPart1')} ${t('privateChatPart2')}`
        break
      case 'group':
        if (chatUsersNumber === 2) {
          content = `${t('infoTwoUsers', {
            userNameOne: chatUsersNames[0],
            userNameTwo: chatUsersNames[1],
          })} ${t('accessRestricted')}`
        } else {
          const firstTwoNames = chatUsersNames.slice(0, 2).join(', ')
          const otherUsersCount = chatUsersNumber - 2

          const multipleUsers =
            otherUsersCount === 1 ? 'infoMultipleUsersSingular' : 'infoMultipleUsersPlural'

          content = `${t(multipleUsers, {
            userNames: firstTwoNames,
            usersCount: otherUsersCount,
          })} ${t('accessRestricted')}`
        }
        break
      default:
        return null
    }

    return <EmptyText>{content}</EmptyText>
  }

  const renderContent = (isMessageToMyself: boolean, isDM: boolean) => {
    if (isMessageToMyself) {
      return (
        <>
          <Container display="flex" flexGap="1.2rem" alignItems="center">
            <UserPreviewAvatar user={firstUser} />
            <Container>
              <Text variant="textMedium">
                {`${firstUser.firstName}`}&nbsp;{`(${t('you')})`}
              </Text>
              <Text variant="smallTextRegular">{firstUser.jobTitle}</Text>
            </Container>
          </Container>
          {getEmptyText('myspace')}
        </>
      )
    }
    if (isDM) {
      const userName = getFullName(firstUser.firstName, firstUser.lastName)
      return (
        <>
          <Container display="flex" flexDirection="row">
            <Container display="flex" flexGap="1.2rem">
              {chatUsers.map((user) => (
                <UserPreviewAvatar user={user} />
              ))}
            </Container>
            <Container display="flex" flexDirection="column" ml="1.2rem" justifyContent="center">
              <Text variant="textMedium">{userName}</Text>
              {firstUser.jobTitle && <Text>{firstUser.jobTitle}</Text>}
            </Container>
          </Container>
          {getEmptyText('dm', userName)}
        </>
      )
    }
    if (!isDM) {
      return (
        <>
          <Container display="flex" flexGap="1.2rem">
            {chatUsers.map((user) => (
              <UserPreviewAvatar user={user} />
            ))}
          </Container>
          {getEmptyText('group')}
        </>
      )
    }
    return null
  }

  return <Container {...spacing}>{renderContent(isMessageToMyself, isDM)}</Container>
}

const EmptyText = styled(Text)`
  margin-top: 1.6rem;
`
