import React from 'react'
import { SVGProps } from 'react'

export const SortingDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3.895 6.258a.75.75 0 0 0-.495.293.75.75 0 0 0 .15 1.049l4 3a.75.75 0 0 0 .9 0l4-3a.75.75 0 0 0 .15-1.05.75.75 0 0 0-1.05-.15L8 9.062 4.45 6.4a.75.75 0 0 0-.555-.142Z" />
  </svg>
)
