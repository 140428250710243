import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { SpaceProps } from 'styled-system'
import { CheckmarkIcon, TranslationLgIcon } from '../../index'
import { ArrowIndicator } from '../arrow-indicator'
import { Container } from '../container'
import { PopperButton } from '../popper/popper-button'
import { Text } from '../typography'
import { LanguageSelectProps, LanguageSelectVariant } from './types'

export const LanguageSelect: FC<LanguageSelectProps> = ({
  interfaceLanguage,
  options,
  hiddenLabel,
  hiddenLabelSelected,
  onClick,
  hideLabel,
  variant = 'primary',
  ...spacing
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleDropdown = () => setIsOpen(!isOpen)

  return (
    <DropdownContainer {...spacing}>
      <DropdownButton variant={variant} onClick={toggleDropdown}>
        <TranslationLgIcon />
        {variant === 'primary' && <Text as="span">{interfaceLanguage.label}</Text>}
        <ArrowIndicator open={isOpen} hiddenLabel={hiddenLabel} ml="0.4rem" />
      </DropdownButton>
      {isOpen && (
        <List>
          {options.map(({ value, label }) => (
            <Item
              key={value}
              onClick={() => {
                onClick(value)
                setIsOpen(false)
              }}
            >
              <PopperButton name={label} onClick={toggleDropdown} />
              {interfaceLanguage.value === value && (
                <SelectedIndicator>
                  <CheckmarkIcon aria-label={hiddenLabelSelected} />
                </SelectedIndicator>
              )}
            </Item>
          ))}
        </List>
      )}
    </DropdownContainer>
  )
}

const DropdownContainer = styled(Container)`
  position: relative;
`

const DropdownButton = styled.button<{ variant: LanguageSelectVariant }>`
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  & > svg:first-of-type {
    fill: ${({ theme }) => theme.colors.greySuperDark};
  }
  ${({ variant, theme }) =>
    variant === 'secondary' &&
    `
    border-radius: 0.8rem;
    height: 4.4rem;
    width: 7.6rem;
    padding:  1rem 0.8rem 1rem 1.2rem;
    border: 1px solid ${theme.colors.grey};
    background-color: white;
  `}
`

const Item = styled.li`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyLight};
`

const List = styled.ul<SpaceProps>`
  overflow: hidden;
  width: 24.8rem;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.8rem;
  position: absolute;
  right: 0;
  z-index: 10;
  margin-top: 0.4rem;
`

const SelectedIndicator = styled.span`
  position: absolute;
  right: 1.2rem;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 2.4rem;
  height: 2.4rem;
`
