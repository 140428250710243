import React, { SVGProps, FC } from 'react'

export const AudioIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6 3a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1 1 1 0 0 0 1-1V4a1 1 0 0 0-1-1ZM9 6a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1 1 1 0 0 0 1-1V7a1 1 0 0 0-1-1Z" />
    <path d="M15 7v10" />
    <path d="M15 6a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1 1 1 0 0 0 1-1V7a1 1 0 0 0-1-1ZM12 9a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1ZM3 8a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1 1 1 0 0 0 1-1V9a1 1 0 0 0-1-1ZM18 3a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1 1 1 0 0 0 1-1V4a1 1 0 0 0-1-1ZM21 8a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1 1 1 0 0 0 1-1V9a1 1 0 0 0-1-1Z" />
  </svg>
)
