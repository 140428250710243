import { uniq } from 'lodash'
import { theme } from 'ui'

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

const generateAvatarRandomColor = () => {
  const colors = Object.values(theme.colors)
  return colors[Math.floor(Math.random() * colors.length)]
}

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const strCompare = (a: string, b: string) => (a !== b ? (a < b ? -1 : 1) : 0)

const getFullName = (firstName?: string, lastName?: string) => `${firstName} ${lastName}`

const getArrayOfUniqueStrings = (array: string[]) => {
  return uniq(array)
}

// export function removeMeFromUsers<T extends { userId: string }>(
//   arr: Array<T>,
//   value: T | null
// ): Array<T> {
//   if (!value) {
//     return arr
//   }
//   const index = arr.findIndex(({ userId }) => userId === value.userId)
//   if (index > -1) {
//     arr.splice(index, 1)
//   }
//   return arr
// } Use this function for channel creation, when you want to remove the current user from the list of users. I wanted to use it for chats, but it was not obvious

export {
  sleep,
  generateAvatarRandomColor,
  getArrayOfUniqueStrings,
  capitalizeFirstLetter,
  strCompare,
  getFullName,
}
