import { chatsAdditionalUrl } from '@api/create-base-url'
import { protectedApiInstance } from '@api/instance'
import type { AxiosRequestConfig } from 'axios'

function get<ResponseType>(url = '', config?: AxiosRequestConfig) {
  return protectedApiInstance.get<ResponseType>(`${chatsAdditionalUrl}${url}`, config)
}

function post<ResponseType>(url = '', data?: any, config?: AxiosRequestConfig) {
  return protectedApiInstance.post<ResponseType>(`${chatsAdditionalUrl}${url}`, data, config)
}

function put<ResponseType>(url = ' ', data?: any, config?: AxiosRequestConfig) {
  return protectedApiInstance.put<ResponseType>(`${chatsAdditionalUrl}${url}`, data, config)
}

function patch<ResponseType>(url = '', data?: any, config?: AxiosRequestConfig) {
  return protectedApiInstance.patch<ResponseType>(`${chatsAdditionalUrl}${url}`, data, config)
}

function del<ResponseType>(url = '', config?: AxiosRequestConfig) {
  return protectedApiInstance.delete(`${chatsAdditionalUrl}${url}`, config)
}
export const chatsApi = {
  get,
  post,
  put,
  patch,
  delete: del,
}
