import { chatsApi } from './instance'
import { Chat } from './types/chat'

export interface JoinChannelInput {
  channelId: string
}

export const joinChannel = async ({ channelId }: JoinChannelInput) => {
  const { data } = await chatsApi.post<Chat>(`/${channelId}/join`)
  return data
}
