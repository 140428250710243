import { theme } from '@common/theme'
import React from 'react'
import { components, MultiValueRemoveProps } from 'react-select'
import { CloseIcon } from 'ui'
import { OptionType } from './types'

export const MultiValueRemove: React.FC<MultiValueRemoveProps<OptionType>> = ({ ...props }) => (
  <components.MultiValueRemove {...props}>
    <CloseIcon fill={theme.colors.black} />
  </components.MultiValueRemove>
)
