import { TeammatesRepository } from '../../repositories/teammate-repository'
import { TeammatesRepositoryImpl } from '../../repositories/teammates-repository-implementation'

class TeammatesService {
  teammatesRepository: TeammatesRepository

  constructor(teammatesRepository: TeammatesRepository) {
    this.teammatesRepository = teammatesRepository
  }

  async getAllUsers() {
    return this.teammatesRepository.getAllUsers()
  }
}

export const teammatesService = new TeammatesService(new TeammatesRepositoryImpl())
