import React, { FC } from 'react'
import { SpaceProps } from 'styled-system'
import { Container, Heading, Text } from 'ui'

interface PageHeaderProps extends SpaceProps {
  title: string
  subtitle: string
}

export const PageHeader: FC<PageHeaderProps> = ({ title, subtitle, ...spaceProps }) => {
  return (
    <Container {...spaceProps}>
      <Heading variant="h0" mb="1.2rem">
        {title}
      </Heading>
      <Text>{subtitle}</Text>
    </Container>
  )
}
