//TODO refactoring https://nativechats.atlassian.net/jira/software/c/projects/NAT/boards/44?assignee=638a2f9e77acd224b341ed08&selectedIssue=NAT-1073
import { useGetMe } from '@api/account/hooks/use-get-me'
import { NetworkMode } from '@api/enums'
import { useDeleteMessage } from '@api/messages/hooks/use-delete-message'
import { useEditMessage, EditData } from '@api/messages/hooks/use-edit-message'
import { useSendMessage } from '@api/messages/hooks/use-send-message'
import { useAppDispatch } from '@app/flow/hooks'
import { GeneralMessageProps, MessageVariant } from '@components/message'
import { MessageInputMode } from '@components/message-input'
import { StatusIcons } from '@components/message/status-icons'
import { MessageFooter, Timestamp } from '@components/message/styled'
import { useSidePage } from '@hooks/use-side-page'
import { useSpacer } from '@hooks/use-spacer'
import { useNativeTranslations } from '@hooks/use-translations'
import { SidePageTypes } from '@layouts/main-layout/side-page'
import { DeleteMessageModal } from '@modules/modals/delete-message'
import { QuickAction } from '@modules/quick-actions'
import { isNativeChatsApp } from '@utils/is-native-chats-app'
import { LOCALES, Language } from '@utils/locales'
import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { TFunction } from 'i18next'
import React, { RefObject, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { DefaultTheme, useTheme } from 'styled-components'
import {
  CopyIcon,
  EditIcon,
  EmojiAddIcon,
  SendIcon,
  TranslationLgIcon,
  TrashcanIcon,
  useModal,
} from 'ui'
import {
  changeMessageDeletedState,
  editMessage as editMessageAction,
  removeMessage,
} from '../features/conversation/slice'
import { useInterfaceLanguage } from './use-interface-language'

interface GetQuickActionsOptions {
  shouldBeTranslated: boolean
  isSender: boolean
}

type Handlers = Record<string, VoidFunction>

const filterHiddenActions = (actions: QuickAction[]) =>
  actions.filter((action) => !action.isDisabled)

const getQuickActions = (
  handlers: Handlers,
  variant: MessageVariant,
  t: TFunction,
  theme: DefaultTheme,
  options: GetQuickActionsOptions,
  hasText: boolean
) => {
  const addReactionAction = {
    actionName: t('message.addReaction'),
    actionIcon: <EmojiAddIcon width={24} height={24} />,
    isReaction: true,
    isDisabled: isNativeChatsApp(),
  }

  const showTranslationsAction = {
    actionName: t('message.showTranslations'),
    onActionClick: handlers.handleOnClickTranslateBtn,
    actionIcon: <TranslationLgIcon />,
    isDisabled: !options.shouldBeTranslated,
  }

  const copyMessageAction = {
    actionName: t('message.copyMessage'),
    onActionClick: handlers.handleCopyMessage,
    actionIcon: <CopyIcon />,
    isDisabled: !hasText,
  }

  const editMessageAction = {
    actionName: t('message.editMessage'),
    onActionClick: handlers.handleEditActionClick,
    actionIcon: <EditIcon />,
    isDisabled: options.isSender,
  }

  const deleteMessageAction = {
    actionName: t('message.deleteMessage'),
    onActionClick: handlers.handleDeleteActionClick,
    actionIcon: <TrashcanIcon />,
    isDisabled: options.isSender,
    color: 'warning',
  }

  const resendMessageAction = {
    actionName: t('message.resendMessage'),
    onActionClick: handlers.handleResendActionClick,
    actionIcon: <SendIcon />,
  }

  const defaultMessageBulbOptions = [
    addReactionAction,
    showTranslationsAction,
    copyMessageAction,
    editMessageAction,
    deleteMessageAction,
  ]

  const notSentMessageBulbOptions = [
    resendMessageAction,
    copyMessageAction,
    editMessageAction,
    deleteMessageAction,
  ]

  switch (variant) {
    case MessageVariant.NOT_SENT:
      return filterHiddenActions(notSentMessageBulbOptions)
    default:
      return filterHiddenActions(defaultMessageBulbOptions)
  }
}

//Use this hook only in message bulb components
export const useMessageBulb = (
  {
    chatId,
    isSender,
    text,
    translations,
    sourceLanguageCode,
    messageId,
    reactions,
    clientMessageId,
    editedAt,
    createdAt,
    isError,
    attachments: uploadedAttachments,
    uploadingAttachments,
  }: GeneralMessageProps,
  variant: MessageVariant
) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const { t } = useTranslation('chat')
  const { openModal, closeModal } = useModal()

  const { setOpen, setType } = useSidePage()
  const { setSourceLanguageCode, setConversationId, setClientMessageId } = useNativeTranslations()
  const [inputText, setInputText] = useState<string>(text)
  const [messageActionsShow, setMessageActionsShow] = useState<boolean>(false)
  const [messageInputMode, setMessageInputMode] = useState<MessageInputMode>(MessageInputMode.SEND)
  const [contentEditableInputText, setContentEditableInputText] = useState<string>(text)
  const [isMessageInputOpen, setIsMessageInputOpen] = useState<boolean>(false)
  const [isHoveringOverQuickActions, setIsHoveringOverQuickActions] = useState<boolean>(false)

  const messageFooterRef: RefObject<HTMLDivElement> = useRef(null)
  const { spacerWidth } = useSpacer({
    refComponent: messageFooterRef,
    offset: 8,
    hostContent: text,
  })
  const { meData } = useGetMe(false, NetworkMode.OFFLINE_FIRST)
  const { interfaceLanguage } = useInterfaceLanguage()
  const attachments = uploadedAttachments ?? uploadingAttachments

  const hasTranslations = translations.length > 0 && translations[0].translations.length > 0
  const shouldBeTranslated = isSender && hasTranslations
  const hasText = text.length > 0

  const { mutate: sendMessage } = useSendMessage()
  const { mutate: deleteMessage } = useDeleteMessage({
    onMutateCallback: () => {
      dispatch(removeMessage({ chatId, clientMessageId }))
      closeModal()
    },
    onErrorCallback: () => {
      dispatch(changeMessageDeletedState({ chatId, clientMessageId, isDeleted: false }))
      toast.error(`${t('toastMessages.changesNotSaved')}`)
    },
  })

  const { mutate: editMessage, isLoading: isEditLoading } = useEditMessage({
    onError: (_, { chatId, clientMessageId, oldText }: EditData) => {
      dispatch(
        editMessageAction({
          chatId,
          clientMessageId,
          text: oldText,
        })
      )
      toast.error(`${t('toastMessages.changesNotSaved')}.`)
    },
    onMutate: ({ chatId, clientMessageId, newText, oldText }: EditData) => {
      if (newText !== oldText) {
        const editedAt = new Date().toISOString().replace('Z', '+00:00')
        dispatch(
          editMessageAction({
            chatId,
            clientMessageId,
            editedAt,
            text: newText,
          })
        )
      }
      setIsMessageInputOpen(false)
      setMessageActionsShow(false)
      setContentEditableInputText(newText)
    },
  })

  const numberOfReactionsIHaveAdded = useMemo(() => {
    if (!reactions || !meData?.userId) return 0

    return reactions.filter((reaction) =>
      reaction.userReactionTimes.some(
        (userReactionTime) => userReactionTime.userId === meData.userId
      )
    ).length
  }, [reactions, meData])

  const reactionsCount = useMemo(() => {
    if (!reactions) return 0
    return reactions.reduce((acc, { count }) => acc + count, 0)
  }, [reactions])

  const uniqueReactionsCount = useMemo(() => {
    if (!reactions) return 0
    return reactions.reduce((acc, { reactionCode }) => {
      const usedReactions: Record<string, string> = {}
      if (!usedReactions[reactionCode]) acc
      return acc + 1
    }, 0)
  }, [reactions])

  const hasPreferredTranslation = () =>
    translations.find((t) => t.isPrimaryLanguage)?.translations?.some((t) => t.isPreferred) ?? false

  const shownText = useMemo(() => {
    if (!shouldBeTranslated) {
      return text
    }
    const primaryTranslations = translations.find((t) => t.isPrimaryLanguage)?.translations
    const primaryPreferredTranslation = primaryTranslations?.find((t) => t.isPreferred)?.translation
    const firstPrimaryTranslation = primaryTranslations?.[0]?.translation

    return primaryPreferredTranslation || firstPrimaryTranslation || text
  }, [text, shouldBeTranslated])

  const handleOnClickTranslateBtn = useCallback(() => {
    setType(SidePageTypes.TRANSLATION)
    setOpen(true)

    setSourceLanguageCode(sourceLanguageCode)
    if (clientMessageId && chatId) {
      setConversationId(chatId)
      setClientMessageId(clientMessageId)
    }
  }, [
    setType,
    setOpen,
    setSourceLanguageCode,
    setConversationId,
    setClientMessageId,
    sourceLanguageCode,
    chatId,
    clientMessageId,
  ])

  const handleDeleteActionClick = () => {
    if (messageId) {
      openModal({
        content: <DeleteMessageModal onSubmit={() => deleteMessage({ messageId })} />,
      })
    }
  }

  const onMouseEnter = useCallback(() => {
    messageActions.length > 0 && setMessageActionsShow(true)
  }, [])

  const onMouseLeave = useCallback(() => {
    setMessageActionsShow(false && !isHoveringOverQuickActions)
  }, [isHoveringOverQuickActions])

  const handleCopyMessage = useCallback(() => {
    navigator.clipboard.writeText(shownText.trim())
  }, [shownText])

  const handleEditActionClick = useCallback(() => {
    setMessageInputMode(MessageInputMode.EDIT)
    setIsMessageInputOpen(true)
  }, [])

  const handleResendActionClick = useCallback(() => {
    sendMessage({ chatId, clientMessageId, text, mediaIds: [], isResending: true })
  }, [])

  const messageActions: QuickAction[] = useMemo(() => {
    return getQuickActions(
      {
        handleOnClickTranslateBtn,
        handleCopyMessage,
        handleEditActionClick,
        handleDeleteActionClick,
        handleResendActionClick,
      },
      variant,
      t,
      theme,
      { isSender, shouldBeTranslated },
      hasText
    )
  }, [
    t,
    handleOnClickTranslateBtn,
    shouldBeTranslated,
    handleCopyMessage,
    handleEditActionClick,
    handleResendActionClick,
    isSender,
    handleDeleteActionClick,
    theme,
    hasText,
  ])

  const handleMessageEdit = () => {
    if (variant === MessageVariant.NOT_SENT || variant === MessageVariant.DEFAULT) {
      editMessage({
        chatId,
        clientMessageId,
        messageId,
        newText: inputText,
        oldText: text,
      })
    }
  }

  const getMessageFooter = ({ showTimestamp = true } = {}) => {
    const showStatusIcons = isError || !!editedAt || shouldBeTranslated
    const resetMargin = (reactions || []).length > 0 || (!shownText && attachments.length > 0)
    return (
      <MessageFooter ref={messageFooterRef} resetMargin={resetMargin}>
        {showStatusIcons && (
          <StatusIcons
            isEdited={!!editedAt}
            isError={isError}
            isSender={isSender}
            isTranslated={shouldBeTranslated}
            hasPreferredTranslation={hasPreferredTranslation()}
            isDeleted={variant === MessageVariant.DELETED}
          />
        )}
        {showTimestamp && (
          <Timestamp variant="smallTextRegular">
            {format(createdAt, 'p', {
              locale: LOCALES[interfaceLanguage.value as Language] || enUS,
            })}
          </Timestamp>
        )}
      </MessageFooter>
    )
  }

  return {
    onMouseEnter,
    onMouseLeave,
    messageActionsShow,
    setIsHoveringOverQuickActions,
    setMessageActionsShow,
    messageActions,
    messageFooterRef,
    spacerWidth,
    numberOfReactionsIHaveAdded,
    reactionsCount,
    uniqueReactionsCount,
    shownText,
    shouldBeTranslated,
    isMessageInputOpen,
    messageInputMode,
    getMessageFooter,
    shouldShowEditableBulb:
      messageInputMode === MessageInputMode.EDIT && isMessageInputOpen && !isSender,
    messageEditProps: {
      isEditLoading,
      inputText,
      contentEditableInputText,
      originalText: text,
      messageInputMode: MessageInputMode.EDIT,
      setMessageInputMode,
      setInputText,
      setContentEditableInputText,
      onClickSend: handleMessageEdit,
      setIsMessageInputOpen,
    },
  }
}
