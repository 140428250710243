import { ConversationType } from '@shared/types/conversation'
import { Form, Formik, FormikHelpers } from 'formik'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Container, RadioGroup, TextField } from 'ui'
import { initialValues, validationSchema } from './data'

interface CreateChannelFormProps {
  onSubmit: (
    values: typeof initialValues,
    helpers: FormikHelpers<typeof initialValues>
  ) => Promise<void>
  onCancel: VoidFunction
  channelTypeOptions: { value: ConversationType; label: string; desc: string }[]
}

export const CreateChannelForm: FC<CreateChannelFormProps> = ({
  onSubmit,
  onCancel,
  channelTypeOptions,
}) => {
  const { t } = useTranslation('modal-create-channel')

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema(t)}
      validateOnBlur
    >
      {({ isSubmitting, isValid, handleChange, errors, dirty, values }) => (
        <Form>
          <TextField
            id="name"
            name="name"
            label={t('nameLabel')}
            type="text"
            value={values.name}
            placeholder={t('namePlaceholder')}
            onChange={handleChange}
            warning={Boolean(errors?.name)}
            helperText={errors?.name}
            mb="2.4rem"
          />
          <TextField
            id="desc"
            name="desc"
            label={t('descLabel')}
            type="text"
            value={values.desc}
            placeholder={t('descPlaceholder')}
            onChange={handleChange}
            warning={Boolean(errors?.desc)}
            helperText={errors?.desc}
            mb="2.8rem"
          />
          <RadioGroup
            name="channelType"
            options={channelTypeOptions}
            values={values}
            onChange={handleChange}
            border
            reverse
            mb="4rem"
          />
          <Container display="flex" flexGap="1.2rem">
            <Button variant="secondary" width="100%" disabled={isSubmitting} onClick={onCancel}>
              {t('cancelButton')}
            </Button>
            <Button type="submit" width="100%" disabled={!(dirty && isValid) || isSubmitting}>
              {t('submitButton')}
            </Button>
          </Container>
        </Form>
      )}
    </Formik>
  )
}
