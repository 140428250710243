import { getAllUsers as fetchAllUsers } from '../api/get-users'
import { mapUsers } from '../api/mappers/map-users'
import { User } from '../api/model/user-type'
import { TeammatesRepository } from './teammate-repository'

export class TeammatesRepositoryImpl implements TeammatesRepository {
  async getAllUsers(): Promise<User[]> {
    const response = await fetchAllUsers()
    return mapUsers(response)
  }
}
