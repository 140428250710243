import React, { FC, ButtonHTMLAttributes, HTMLAttributes } from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { Tooltip } from 'ui'

export type ActionColor = 'light' | 'default' | 'warning'

type BaseActionProps = {
  icon: JSX.Element
  tooltipText?: string
  color?: ActionColor
  disabled?: boolean
}

type ButtonActionProps = BaseActionProps &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    component?: 'button'
  }

type SpanActionProps = BaseActionProps &
  Omit<HTMLAttributes<HTMLSpanElement>, 'disabled'> & {
    component: 'span'
  }

type ActionProps = ButtonActionProps | SpanActionProps

export const Action: FC<ActionProps> = ({
  icon,
  tooltipText,
  color = 'default',
  component = 'button',
  disabled,
  ...props
}) => {
  const content = tooltipText ? (
    <Tooltip text={tooltipText} placement="top">
      <StyledAction as={component} color={color} disabled={disabled} {...props}>
        {icon}
      </StyledAction>
    </Tooltip>
  ) : (
    <StyledAction as={component} color={color} disabled={disabled} {...props}>
      {icon}
    </StyledAction>
  )

  return content
}

const getActionStyles = ({
  theme,
  disabled,
  color = 'default',
}: {
  theme: DefaultTheme
  disabled?: boolean
  color?: ActionColor
}) => {
  const colorMap = {
    light: theme.colors.greyDark,
    default: theme.colors.greySuperDark,
    warning: theme.colors.red,
  }

  const hoverColorMap = {
    light: theme.colors.primary,
    default: theme.colors.primary,
    warning: theme.colors.redDark,
  }

  const baseColor = disabled ? theme.colors.grey : colorMap[color]
  const hoverColor = disabled ? theme.colors.grey : hoverColorMap[color]
  const cursor = disabled ? 'auto' : 'pointer'

  return {
    color: baseColor,
    cursor,
    hoverColor,
  }
}

const StyledAction = styled.button<{ disabled?: boolean; color?: ActionColor }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  transition: ${({ theme }) => theme.transitionTimes.short};
  ${(props) => {
    const { color, cursor, hoverColor } = getActionStyles(props)
    return `
      color: ${color};
      cursor: ${cursor};
      &:hover {
        color: ${hoverColor};
      }
    `
  }}
`
