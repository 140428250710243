import { InitialLayout } from '@layouts/native-chats/initial-layout'
import { MainLayout } from '@layouts/native-chats/main-layout'
import { AuthCallBack } from '@pages/auth-call-back'
import { Conversation } from '@pages/conversation'
import { DirectMessages } from '@pages/direct-messages'
import { Forbidden } from '@pages/forbidden'
import { NativeChatsCreateNew } from '@pages/native-chats/create-new'
import { CreatePassword } from '@pages/native-chats/create-password'
import { CredentialsLogin } from '@pages/native-chats/credentials-login'
import { Login } from '@pages/native-chats/login'
import { ResetPassword } from '@pages/native-chats/reset-password'
import { NotFound } from '@pages/not-found'
import { Personal } from '@pages/personal'
import { UsersNfc } from '@pages/users_nfc'
import { Welcome } from '@pages/welcome'
import { ProtectedRoutes } from '@routes/chats/protected-routes'
import { routes } from '@routes/chats/routes'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

export const NativeChatsNavigationContainer = () => {
  return (
    <Routes>
      <Route path="/login/*" element={<InitialLayout />}>
        <Route index element={<Login />} />
        <Route path={routes.credentials} element={<CredentialsLogin />} />
        <Route path={routes.resetPassword} element={<ResetPassword />} />
        <Route path={routes.newPassword} element={<CreatePassword />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path={routes.home} element={<MainLayout />}>
          <Route index element={<Welcome />} />
          <Route path={routes.directMessages} element={<DirectMessages />} />
          <Route path={routes.chats}>
            <Route path=":chatId" element={<Conversation />} />
            <Route path={routes.createNew} element={<NativeChatsCreateNew />} />
          </Route>
          <Route path={routes.users} element={<UsersNfc />} />
          <Route path={routes.settings} element={<Personal />} />
        </Route>
      </Route>
      <Route path={routes.authCallBack} element={<AuthCallBack />} />
      <Route element={<InitialLayout />}>
        <Route path="/*" element={<NotFound />} />
        <Route path={routes.forbidden} element={<Forbidden />} />
      </Route>
    </Routes>
  )
}
