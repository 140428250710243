import { IconButton } from '@components/icon-button'
import styled from 'styled-components'
import { Container } from 'ui'
import { StyledSidePageProps } from './index'

export const SidePageContainer = styled(Container)`
  padding: 2rem 2rem 2rem 0;
  height: 100%;
`

export const StyledSidePage = styled(Container)<StyledSidePageProps>`
  border-radius: 1.6rem 1.6rem 1.6rem 0;
  position: relative;
  padding: 2rem 1.6rem 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props.bgColor || props.theme.colors.white};
  overflow: hidden;
  width: 32rem;
  &::after {
    content: '';
    z-index: 3;
    position: absolute;
    background: linear-gradient(0deg, rgba(254, 254, 254, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
    bottom: 0;
    left: 0;
    right: 0;
    height: 2rem;
    pointer-events: none;
  }
`

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 2.4rem;
  right: 1.6rem;
  height: 2.4rem;
  width: 2.4rem;
  color: ${(props) => props.theme.colors.greySuperDark};
  transition: color ${(props) => props.theme.transitionTimes.short};
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`
