import { PersonalProfile } from '@api/account/types/personal-profile'
import { PersonalProfileResponse } from '@native-chats-api/accounts/generated'
import { getUserInfo } from '@native-chats-api/auth-api/get-user-info'

export const convertPersonalProfile = async (
  user: PersonalProfileResponse
): Promise<PersonalProfile> => {
  const userInfo = await getUserInfo()
  return {
    firstName: user.firstName!,
    lastName: user.lastName!,
    phoneNumber: user.phoneNumber as string | undefined,
    email: user.email!,
    color: user.color,
    avatar: {
      mediaId: user.avatar?.mediaId as string | undefined,
      mediaUri: user.avatar?.mediaUri as string | undefined,
      mediaUriExpiresAt: user.avatar?.mediaUriExpiresAt,
    },
    isOnboarded: user.isOnboarded,
    activeLanguages:
      user.activeLanguages?.map((language: any) => ({
        code: language.code,
        name: language.name!,
        isPrimary: language.isPrimary,
        translatedName: language.translatedName as string | undefined,
      })) ?? [],
    userId: userInfo.sub,
    online: {
      status: 1,
    },
  }
}
