import React from 'react'

export const MoreIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M13.556 17.444a1.555 1.555 0 1 1-3.11 0 1.555 1.555 0 0 1 3.11 0Zm0-5.444a1.556 1.556 0 1 1-3.11 0 1.556 1.556 0 0 1 3.11 0Zm-1.555-3.889a1.556 1.556 0 1 0 0-3.11 1.556 1.556 0 0 0 0 3.11Z"
      />
    </svg>
  )
}
