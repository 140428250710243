import React from 'react'
import { Text } from '../typography'
import { TabButton } from './styled'
import { TabTitleProps } from './types'

export const TabTitle = (props: TabTitleProps) => {
  const { title, setSelectedTab, index, isSelected, variant } = props
  return (
    <li>
      <TabButton
        type="button"
        onClick={() => setSelectedTab(index)}
        variant={variant}
        isSelected={isSelected}
      >
        <Text as="span" fontWeight={500}>
          {title}
        </Text>
      </TabButton>
    </li>
  )
}
