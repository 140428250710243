import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { SpaceProps } from 'styled-system'
import { CloseIcon, IndicatorEditedIcon, TranslationIcon } from 'ui'
import { IconsContainer, IconWrapper } from './styled'

interface StatusIconsProps extends SpaceProps {
  isEdited?: boolean
  isError?: boolean
  isSender?: boolean
  isTranslated?: boolean
  isDeleted?: boolean
  hasPreferredTranslation?: boolean
}

export const StatusIcons: React.FC<StatusIconsProps> = ({
  isEdited,
  isError,
  isSender,
  isTranslated,
  isDeleted,
  hasPreferredTranslation,
  ...spacing
}) => {
  const theme = useTheme()
  const { t } = useTranslation('chat')

  return (
    <IconsContainer {...spacing}>
      {isEdited && !isDeleted && (
        <IconWrapper>
          <IndicatorEditedIcon aria-label={t('message.edited')} />
        </IconWrapper>
      )}
      {isTranslated && (
        <IconWrapper>
          <TranslationIcon
            aria-label={t('message.translated')}
            fill={hasPreferredTranslation ? theme.colors.primary : theme.colors.greyDark}
            width={16}
            height={16}
          />
        </IconWrapper>
      )}
      {isError && !isSender && (
        <IconWrapper>
          <CloseIcon
            aria-label={t('message.notSent')}
            fill={theme.colors.white}
            width={14}
            height={14}
          />
        </IconWrapper>
      )}
    </IconsContainer>
  )
}
