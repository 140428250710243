import {
  createOnRejectedInterceptor,
  onFulfilledChatsInterceptor,
  requestInterceptorSuccess,
} from '@native-chats-api/interceptos'
import { createBaseURL } from '@native-chats-api/utils'
import axios from 'axios'

const url = process.env.NATIVE_CHATS_MEDIA_API_URL as string

const baseURL = createBaseURL(url)

export const nativeChatsMediaApi = axios.create({
  baseURL,
})

nativeChatsMediaApi.interceptors.response.use(
  onFulfilledChatsInterceptor,
  createOnRejectedInterceptor(nativeChatsMediaApi)
)

nativeChatsMediaApi.interceptors.request.use(requestInterceptorSuccess)
