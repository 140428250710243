import { GeneralMessageProps } from '@components/message'
import { MessageListWithDateDivider } from '@modules/conversation-body/message-list-with-date-divider'
import { Skeleton } from '@modules/skeleton'
import { cloneDeep } from 'lodash'
import React, { FC } from 'react'
import { InViewTrigger } from 'src/components/in-view-trigger'
import { Container } from 'ui'
import { generateMessagesWithDate } from './utils'

export interface MessageContentProps {
  messages: GeneralMessageProps[]
  setIsInView: (c: boolean) => void
  setTriggerIsInView: (c: boolean) => void
  isLoading?: boolean
  showAvatar?: boolean
  lastPageFetched?: boolean
}

export const MessageList: FC<MessageContentProps> = ({
  messages,
  setIsInView,
  setTriggerIsInView,
  isLoading,
  showAvatar,
  lastPageFetched,
}) => {
  const messageList = cloneDeep(messages)
  const filteredList = generateMessagesWithDate(messageList)

  const triggerScrollToBottomHandler = (inView: boolean) => {
    setIsInView(inView)
  }

  const triggerFetchHandler = (inView: boolean) => {
    if (!setTriggerIsInView) return
    setTriggerIsInView(inView)
  }

  return (
    <Container position="relative">
      {!isLoading && <InViewTrigger position="absolute" onChange={triggerFetchHandler} top={0} />}
      {isLoading && !lastPageFetched && <Skeleton type="conversationBody" />}
      <MessageListWithDateDivider list={filteredList} showAvatar={showAvatar} />
      <InViewTrigger position="absolute" onChange={triggerScrollToBottomHandler} bottom={0} />
    </Container>
  )
}
