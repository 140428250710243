import { useAuth } from '@hooks/chats/use-auth'
import { useSignalR } from '@hooks/use-signalr'
import { useSignalREvents } from '@hooks/use-signalr-events'
import { routes } from '@routes/chats/routes'
import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

export const ProtectedRoutes = () => {
  const { isAuthorized } = useAuth()

  const { startConnection, connection, stopConnection } = useSignalR()
  const { startEvents, stopEvents } = useSignalREvents()

  useEffect(() => {
    if (!isAuthorized) {
      stopConnection()
      return
    }
    startConnection()
    return () => {
      stopConnection()
    }
  }, [isAuthorized])

  useEffect(() => {
    startEvents()
    return () => {
      stopEvents()
    }
  }, [connection, isAuthorized])

  return !isAuthorized ? <Navigate to={routes.login} /> : <Outlet />
}
