import { routes } from '@routes/flow/routes'

interface OauthConfiguration {
  authority: string
  clientId: string
  redirectUri: string
  scope: string
  responseType: string
  postLogoutRedirectUrl: string
}

const authConfig: OauthConfiguration = {
  authority: `${process.env.IDENTITY_SERVER_URL}`,
  clientId: 'native-flow',
  redirectUri: `${process.env.ENVIRONMENT}${routes.authCallBack}`,
  responseType: 'code',
  scope: 'openid email offline_access workspaces.api',
  postLogoutRedirectUrl: `${process.env.ENVIRONMENT}`,
}

export { authConfig }
export type { OauthConfiguration }
