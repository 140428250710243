import { CreateChatIcon } from '@components/icons/create-chat'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SpaceProps } from 'styled-system'
import { HiddenLabel } from 'ui'
import { ButtonContainer } from './styled'

interface CreateNewButtonProps {
  onClick: () => void
}

type GeneralCreateNewButtonProps = SpaceProps & CreateNewButtonProps

export const CreateNewChatButton: FC<GeneralCreateNewButtonProps> = ({
  onClick,
  ...spaceProps
}) => {
  const { t } = useTranslation('sidebar')

  return (
    <ButtonContainer onClick={onClick} {...spaceProps} data-cy="newChatBtn">
      <CreateChatIcon />
      <HiddenLabel>{t('createNewChat')}</HiddenLabel>
    </ButtonContainer>
  )
}

export default { CreateNewChatButton }
