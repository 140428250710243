import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { Container } from 'ui'

interface LogoHeaderProps extends SpaceProps {
  children?: ReactNode
}

export const LogoHeader: FC<LogoHeaderProps> = ({ children, ...spacing }) => {
  return (
    <LogoHeaderContainer {...spacing}>
      <FlexContainer />
      <FlexContainer textAlign="center">
        <LogoContainer>
          <img src="/img/logo-native.svg" alt="Logo Native" width={106} height={20} />
        </LogoContainer>
      </FlexContainer>
      <FlexContainer textAlign="right">{children}</FlexContainer>
    </LogoHeaderContainer>
  )
}

const LogoHeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  padding: 2.4rem 2rem;
  ${space}
`

const FlexContainer = styled(Container)`
  flex: 1 1 0;
`

const LogoContainer = styled.div`
  & img {
    display: block;
    margin: 0 auto;
  }
`
