import {
  ConnectionScope,
  UserDetailResponse,
  UsersService,
} from '@native-chats-api/accounts/generated'
import { User } from 'src/entities/teammates/api/model/user-type'

export interface ContactsRepository {
  getContacts: (scope: ConnectionScope) => Promise<User[]>
  getContact: (userId: string) => Promise<User>
}

export class ContactsRepositoryImpl implements ContactsRepository {
  async getContacts(scope: ConnectionScope) {
    const { users } = await UsersService.getApiV1Users1(scope)
    return users?.map(mapUserDetailResponseToUser) ?? []
  }

  async getContact(userId: string) {
    const { user } = await UsersService.getApiV1Users(userId)
    return user as User
  }
}

const mapUserDetailResponseToUser = (userDetailResponse: UserDetailResponse): User => {
  //this mapping is not done. Currently covers everything that is needed for the contacts page
  const output: User = {
    avatar: {
      id: userDetailResponse.avatar?.mediaId as string,
      uri: userDetailResponse.avatar?.mediaUri as string,
    },
    userId: userDetailResponse.userId!,
    firstName: userDetailResponse.firstName!,
    lastName: userDetailResponse.lastName!,
    email: userDetailResponse.email!,
    phoneNumber: userDetailResponse.phoneNumber!,
    color: userDetailResponse.color!,
    isDeleted: userDetailResponse.isDeleted!,
    primaryLanguageCode: '',
    languages: [],
    joiningDate: new Date(),
    timeZone: { displayName: '', id: '' },
    doNotDisturbFrom: '',
    jobTitle: '',
    online: {
      status: userDetailResponse.online?.status!,
      lastOnline: new Date(userDetailResponse.online?.lastOnline!),
    },
  }

  return output
}
