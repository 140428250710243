import flags from 'flags.json'

const isProdOrDevEnv =
  process.env.APP_ENVIRONMENT === 'prod' || process.env.APP_ENVIRONMENT === 'development'

interface flagsMap {
  [key: string]: boolean | undefined
}

const FLAGS: flagsMap = { ...flags, hideOnDevAndProd: !isProdOrDevEnv }

export const getFeatureFlag = (flagName: string) => {
  return (FLAGS as flagsMap)[flagName] ?? true
}
