import React from 'react'

export const EditIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.848 3.293 3.293 14.848a1 1 0 0 0-.293.707V20a1 1 0 0 0 1 1h4.445a1 1 0 0 0 .707-.293L20.707 9.152a1 1 0 0 0 0-1.414l-4.443-4.445a1 1 0 0 0-1.416 0zm.707 2.121 3.03 3.031L8.03 19H5v-3.03z" />
    </svg>
  )
}
