import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NewMessageIndicator } from 'src/features/chats/components/conversation-list/conversation-item'
import styled from 'styled-components'
import { Container, Text } from 'ui'

interface MessageInfoProps {
  name: string
  lastModifiedAt: string
  createdBy: string
  lastMessage?: string
  isUnread: boolean
}

export const MessageInfo: FC<MessageInfoProps> = ({
  name,
  lastModifiedAt,
  lastMessage,
  createdBy,
  isUnread,
}) => {
  const { t } = useTranslation('sidebar')
  return (
    <Container display="flex" justifyContent="space-between" width="100%">
      <Container display="flex" flexDirection="column" ml="1.2rem">
        <Text variant="textMedium">{name}</Text>
        <LastMessage variant="smallTextRegular" mt="0.4rem">
          {createdBy}: {lastMessage}
        </LastMessage>
      </Container>
      <Container
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Text variant="smallTextRegular" color="greyDark">
          {lastModifiedAt}
        </Text>
        {isUnread && <NewMessageIndicator aria-label={t('unreadMessage')} />}
      </Container>
    </Container>
  )
}

const LastMessage = styled(Text)`
  white-space: nowrap;
  max-width: 60vw;
  overflow: hidden;
  text-overflow: ellipsis;
  &::first-letter {
    text-transform: capitalize;
  }
`