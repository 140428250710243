import React from 'react'

export const SendIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.37689 10.9992L5.30652 5.6495L18.0111 11.9992L5.30652 18.349L6.3769 12.9992H11C11.5523 12.9992 12 12.5515 12 11.9992C12 11.4469 11.5523 10.9992 11 10.9992H6.37689ZM3.02451 4.43813C2.82861 3.459 3.84449 2.68289 4.73767 3.1293L20.337 10.9258C21.2217 11.368 21.2217 12.6305 20.337 13.0726L4.73767 20.8692C3.84448 21.3156 2.82861 20.5395 3.02451 19.5603L4.49023 12.2347C4.52133 12.0792 4.52133 11.9192 4.49023 11.7638L3.02451 4.43813Z"
      />
    </svg>
  )
}
