import { useGetMe } from '@api/account/hooks/use-get-me'
import { useSkeleton } from '@hooks/use-skeleton'
import { PersonalLanguages } from '@modules/personal-languages'
import { PersonalPreferences } from '@modules/personal-preferences'
import { getFeatureFlag } from '@utils/flags-manager'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SkeletonComponentNames } from 'src/HOC/with-skeleton'
import { PersonalSettingsWithSkeleton } from 'src/features/user/components/personal-settings'
import { Heading, RoundedContainer, Tab, Tabs, TabsVariant, ScrollableTab } from 'ui'

export const Personal = () => {
  const { t } = useTranslation(['personal'])

  const { isLoading: isGeneralDataLoading } = useGetMe()
  const { showSkeleton, hideSkeleton } = useSkeleton()

  useEffect(() => {
    isGeneralDataLoading
      ? showSkeleton(SkeletonComponentNames.SETTINGS_PERSONAL_GENERAL_1)
      : hideSkeleton(SkeletonComponentNames.SETTINGS_PERSONAL_GENERAL_1)
  }, [isGeneralDataLoading, showSkeleton, hideSkeleton])

  const tabs = [
    <ScrollableTab title={t('tabGeneralLabel')} key={t('tabGeneralLabel')} pb="6rem">
      <PersonalSettingsWithSkeleton
        componentName={SkeletonComponentNames.SETTINGS_PERSONAL_GENERAL_1}
      />
    </ScrollableTab>,
    <ScrollableTab title={t('tabLanguagesLabel')} key={t('tabLanguagesLabel')} pb="6rem">
      <PersonalLanguages />
    </ScrollableTab>,
  ]

  if (getFeatureFlag('showOnProd')) {
    tabs.push(
      <Tab title={t('tabPreferencesLabel')} key={t('tabPreferencesLabel')}>
        <PersonalPreferences />
      </Tab>
    )
  }

  return (
    <RoundedContainer
      display="flex"
      flexDirection="column"
      p="2rem 2rem 0"
      height="100%"
      flex="1 1 auto"
      overflow="hidden"
    >
      <Heading mb="0.8rem">{t('pageTitle')}</Heading>
      <Tabs variant={TabsVariant.UNDERLINE}>{tabs}</Tabs>
    </RoundedContainer>
  )
}
