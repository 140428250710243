import { useConversationId } from '@hooks/use-conversation-id'
import { AttachmentFetcher } from '@modules/attachment-fetcher'
import { AttachmentItem } from '@modules/attachment-item'
import { bytesToSize } from '@shared/lib/media/bytes-to-size'
import { MIN_WIDTH_ATTACHMENT_TILE } from '@utils/variables'
import React, { FC } from 'react'
import { AttachmentResponse } from 'src/entities/messages/api/types/attachment-response'

interface AttachmentItemWithFetcherProps {
  attachment: AttachmentResponse
}

const AttachmentItemWithFetcher: FC<AttachmentItemWithFetcherProps> = ({ attachment }) => {
  const conversationId = useConversationId()

  return (
    <AttachmentFetcher
      key={attachment.id}
      conversationId={conversationId as string}
      {...attachment}
    >
      {({ src, isProcessing, ...restAttachment }) => (
        <AttachmentItem
          key={attachment.id}
          name={restAttachment.name as string}
          src={src as string}
          size={restAttachment.metadata?.size && bytesToSize(restAttachment.metadata.size)}
          contentType={restAttachment.metadata?.contentType}
          width={`${MIN_WIDTH_ATTACHMENT_TILE}rem`}
        />
      )}
    </AttachmentFetcher>
  )
}

export default AttachmentItemWithFetcher
