import { store } from '@app/flow/store'
import { theme } from '@common/theme'
import { ToastContainer } from '@components/toast-container'
import { AuthProvider } from '@contexts/chats/auth-provider'
import { InterfaceLanguageProvider } from '@contexts/interface-language-provider'
import { SidePageProvider } from '@contexts/side-page-provider'
import { SignalRProvider } from '@contexts/signalr-provider'
import { SkeletonProvider } from '@contexts/skeleton-provider'
import { ThemeProvider } from '@contexts/theme-provider'
import { TranslationsProvider } from '@contexts/translations-provider'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { GlobalStyles } from '@styles/global-styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React, { StrictMode, Suspense } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Provider } from 'react-redux/es/exports'
import { BrowserRouter as Router } from 'react-router-dom'
import { ModalProvider } from 'ui'
import { NativeChatsNavigationContainer } from './navigation/native-chats-navigation'

const queryClient = new QueryClient()

const NativeChatsApp = () => {
  return (
    <StrictMode>
      <Provider store={store}>
        <Suspense fallback={null}>
          <Router>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider>
                <ToastContainer />
                <GlobalStyles />
                <InterfaceLanguageProvider>
                  <AuthProvider>
                    <TranslationsProvider>
                      <SidePageProvider>
                        <SignalRProvider>
                          <SkeletonTheme
                            baseColor={theme.colors.greyLight}
                            enableAnimation={false}
                            borderRadius={8}
                          >
                            <SkeletonProvider>
                              <ModalProvider>
                                <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID!}>
                                  <NativeChatsNavigationContainer />
                                </GoogleOAuthProvider>
                              </ModalProvider>
                            </SkeletonProvider>
                          </SkeletonTheme>
                        </SignalRProvider>
                      </SidePageProvider>
                    </TranslationsProvider>
                  </AuthProvider>
                </InterfaceLanguageProvider>
              </ThemeProvider>
            </QueryClientProvider>
          </Router>
        </Suspense>
      </Provider>
    </StrictMode>
  )
}

export default NativeChatsApp
