import { MessagesRepository } from '../repositories/messages-repository'
import { MessagesRepositoryImplementation } from '../repositories/messages-repository-implementation'

class SyncMessagesService {
  constructor(private conversationsRepository: MessagesRepository) {}

  syncMessages(pageSize = 20, timeStamp?: string, chatId?: string, lastMessageId?: string) {
    return this.conversationsRepository.syncMessages(pageSize, timeStamp, chatId, lastMessageId)
  }
}

export const syncMessagesService = new SyncMessagesService(new MessagesRepositoryImplementation())
