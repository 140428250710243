import React from 'react'

export const SearchIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11 3c-4.406 0-8 3.594-8 8 0 4.406 3.594 8 8 8 1.844 0 3.545-.63 4.9-1.686l3.393 3.393a1 1 0 0 0 1.414 0 1 1 0 0 0 0-1.414L17.314 15.9A7.955 7.955 0 0 0 19 11c0-4.406-3.594-8-8-8zm0 2c3.326 0 6 2.674 6 6s-2.674 6-6 6-6-2.674-6-6 2.674-6 6-6z" />
    </svg>
  )
}
