import React from 'react'

export const CheckSmallIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="currentColor"
      {...props}
    >
      <path d="M9.725 2.5a1 1 0 0 0-.7.31L4.98 7.067 3.207 5.293a1 1 0 0 0-1.414 0 1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 1.432-.018l4.75-5a1 1 0 0 0-.036-1.414 1 1 0 0 0-.714-.275Z" />
    </svg>
  )
}
