import { useMutation } from '@tanstack/react-query'
import { chatApi } from 'src/features/chats/repositories/chat-service'
import { UpdateChatInfoRequest } from '../generated'
import { Chat } from '../types/chat'

interface UseUpdateChatInput {
  onSuccessCallback?: (data: Chat) => void
  onErrorCallback?: CallableFunction
}

export const useUpdateChat = ({ onSuccessCallback, onErrorCallback }: UseUpdateChatInput = {}) => {
  return useMutation({
    mutationFn: ({ chatId, requestBody }: { chatId: string; requestBody: UpdateChatInfoRequest }) =>
      chatApi.updateChat({ chatId, requestBody }),
    onSuccess: (data) => {
      onSuccessCallback?.(data)
    },
    onError: () => {
      onErrorCallback?.()
    },
  })
}
