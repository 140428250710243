import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CONVERSATIONS_QUERY_KEY } from 'src/entities/conversations/api/use-sync-and-get-conversations'
import { joinChannel } from '../join-channel'
import { CHANNELS } from './use-get-channels'

interface UseJoinChannelInput {
  onSuccessCallback: CallableFunction
  onErrorCallback: CallableFunction
}

export const useJoinChannel = ({ onSuccessCallback, onErrorCallback }: UseJoinChannelInput) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (channelId: string) => joinChannel({ channelId }),
    onSuccess: () => {
      queryClient.invalidateQueries([CONVERSATIONS_QUERY_KEY]) //TODO: remove and use dispatch when we will have slice for channels
      queryClient.invalidateQueries([CHANNELS])
      onSuccessCallback?.()
    },
    onError: () => {
      onErrorCallback?.()
    },
  })
}
