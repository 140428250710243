import React, { FC } from 'react'
import { LoadingIndicatorProps } from 'react-select'
import { SpinnerLightAnimation } from 'ui'
import { CreatableOptionType } from './types'

interface ExtendedLoadingIndicatorProps extends LoadingIndicatorProps<CreatableOptionType> {
  isLoading?: boolean
}

export const LoadingIndicator: FC<ExtendedLoadingIndicatorProps> = ({ ...props }) => {
  return <SpinnerLightAnimation width={24} height={24} />
}
