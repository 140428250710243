/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddChatUsersRequest } from '../models/AddChatUsersRequest';
import type { AvatarResponse } from '../models/AvatarResponse';
import type { ChannelResponse } from '../models/ChannelResponse';
import type { ChatFilesResponse } from '../models/ChatFilesResponse';
import type { ChatResponse } from '../models/ChatResponse';
import type { ChatsListResponse } from '../models/ChatsListResponse';
import type { ChatUserResponse } from '../models/ChatUserResponse';
import type { ConversationType } from '../models/ConversationType';
import type { CreateAttachmentResponse } from '../models/CreateAttachmentResponse';
import type { CreateChatRequest } from '../models/CreateChatRequest';
import type { CreateFileDto } from '../models/CreateFileDto';
import type { Operation } from '../models/Operation';
import type { Operation_1 } from '../models/Operation_1';
import type { SetChatAvatarRequest } from '../models/SetChatAvatarRequest';
import type { TransformChatRequest } from '../models/TransformChatRequest';
import type { UpdateChatInfoRequest } from '../models/UpdateChatInfoRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChatsService {

    /**
     * Gets chat details.
     * @param chatId
     * @returns ChatResponse Chat details
     * @throws ApiError
     */
    public static getV3Chats(
        chatId: string,
    ): CancelablePromise<ChatResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/chats/{chatId}',
            path: {
                'chatId': chatId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Chat not found`,
            },
        });
    }

    /**
     * Update channel properties (AvatarMediaId, Color, Description, IsMuted, Name)
     * @param conversationId
     * @param requestBody
     * @returns ChatResponse Chat details
     * @throws ApiError
     */
    public static patchV3Chats(
        conversationId: string,
        requestBody?: Array<(Operation | Operation_1)>,
    ): CancelablePromise<ChatResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v3/chats/{conversationId}',
            path: {
                'conversationId': conversationId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Chat not found`,
            },
        });
    }

    /**
     * Redirect to avatar uri
     * @param conversationId
     * @param height
     * @param width
     * @returns void
     * @throws ApiError
     */
    public static getV3ChatsAvatar(
        conversationId: string,
        height?: number,
        width?: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/chats/{conversationId}/avatar',
            path: {
                'conversationId': conversationId,
            },
            query: {
                'Height': height,
                'Width': width,
            },
            errors: {
                302: `Found`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Create avatar upload uri
     * @param conversationId
     * @returns AvatarResponse Upload uri
     * @throws ApiError
     */
    public static postV3ChatsAvatar(
        conversationId: string,
    ): CancelablePromise<AvatarResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/chats/{conversationId}/avatar',
            path: {
                'conversationId': conversationId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Chat users
     * @param chatId
     * @returns ChatUserResponse OK
     * @throws ApiError
     */
    public static getV3ChatsUsers(
        chatId: string,
    ): CancelablePromise<Array<ChatUserResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/chats/{chatId}/users',
            path: {
                'chatId': chatId,
            },
        });
    }

    /**
     * Add users to the channel
     * @param chatId
     * @param requestBody
     * @returns ChatResponse Conversation detail
     * @throws ApiError
     */
    public static postV3ChatsAddUsers(
        chatId: string,
        requestBody?: AddChatUsersRequest,
    ): CancelablePromise<ChatResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/chats/{chatId}/add-users',
            path: {
                'chatId': chatId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                404: `Conversation not found`,
            },
        });
    }

    /**
     * Chat user
     * @param chatId
     * @param userId
     * @returns ChatUserResponse OK
     * @throws ApiError
     */
    public static getV3ChatsUsers1(
        chatId: string,
        userId: string,
    ): CancelablePromise<ChatUserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/chats/{chatId}/users/{userId}',
            path: {
                'chatId': chatId,
                'userId': userId,
            },
        });
    }

    /**
     * List chats
     * @param pageNumber
     * @param pageSize
     * @param type
     * @param search
     * @param order
     * @returns ChatsListResponse OK
     * @throws ApiError
     */
    public static getV3ChatsList(
        pageNumber?: number,
        pageSize?: number,
        type?: Array<ConversationType>,
        search: string = '',
        order: string = 'DESC',
    ): CancelablePromise<ChatsListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/chats/list',
            query: {
                'pageNumber': pageNumber,
                'pageSize': pageSize,
                'type': type,
                'search': search,
                'order': order,
            },
        });
    }

    /**
     * Search for channels
     * @param search
     * @returns ChannelResponse OK
     * @throws ApiError
     */
    public static getV3ChatsChannels(
        search?: string,
    ): CancelablePromise<Array<ChannelResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/chats/channels',
            query: {
                'search': search,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Create chat
     * @param requestBody
     * @returns ChatResponse Chat details
     * @throws ApiError
     */
    public static postV3Chats(
        requestBody?: CreateChatRequest,
    ): CancelablePromise<ChatResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/chats',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Delete channel
     * @param channelId
     * @returns void
     * @throws ApiError
     */
    public static deleteV3Chats(
        channelId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v3/chats/{channelId}',
            path: {
                'channelId': channelId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Update chat
     * @param chatId
     * @param requestBody
     * @returns ChatResponse OK
     * @throws ApiError
     */
    public static patchV3ChatsUpdateInfo(
        chatId: string,
        requestBody?: UpdateChatInfoRequest,
    ): CancelablePromise<ChatResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v3/chats/{chatId}/update-info',
            path: {
                'chatId': chatId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Set chat avatar
     * @param chatId
     * @param requestBody
     * @returns ChatResponse OK
     * @throws ApiError
     */
    public static patchV3ChatsAvatar(
        chatId: string,
        requestBody?: SetChatAvatarRequest,
    ): CancelablePromise<ChatResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v3/chats/{chatId}/avatar',
            path: {
                'chatId': chatId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Remove chat avatar
     * @param chatId
     * @returns ChatResponse OK
     * @throws ApiError
     */
    public static patchV3ChatsAvatarRemove(
        chatId: string,
    ): CancelablePromise<ChatResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v3/chats/{chatId}/avatar-remove',
            path: {
                'chatId': chatId,
            },
        });
    }

    /**
     * Transform chat to channel
     * @param chatId
     * @param requestBody
     * @returns ChatResponse OK
     * @throws ApiError
     */
    public static postV3ChatsTransform(
        chatId: string,
        requestBody?: TransformChatRequest,
    ): CancelablePromise<ChatResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/chats/{chatId}/transform',
            path: {
                'chatId': chatId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param chatId
     * @returns any OK
     * @throws ApiError
     */
    public static postV3ChatsMute(
        chatId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/chats/{chatId}/mute',
            path: {
                'chatId': chatId,
            },
        });
    }

    /**
     * @param chatId
     * @returns any OK
     * @throws ApiError
     */
    public static postV3ChatsUnmute(
        chatId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/chats/{chatId}/unmute',
            path: {
                'chatId': chatId,
            },
        });
    }

    /**
     * @param chatId
     * @returns any OK
     * @throws ApiError
     */
    public static postV3ChatsRead(
        chatId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/chats/{chatId}/read',
            path: {
                'chatId': chatId,
            },
        });
    }

    /**
     * @param chatId
     * @returns any OK
     * @throws ApiError
     */
    public static postV3ChatsLeave(
        chatId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/chats/{chatId}/leave',
            path: {
                'chatId': chatId,
            },
        });
    }

    /**
     * @param publicChannelId
     * @returns any OK
     * @throws ApiError
     */
    public static postV3ChatsJoin(
        publicChannelId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/chats/{publicChannelId}/join',
            path: {
                'publicChannelId': publicChannelId,
            },
        });
    }

    /**
     * Get all attachments for 1:1 chat with an user
     * @param sort
     * @param order
     * @param pageNumber
     * @param pageSize
     * @param userId
     * @returns ChatFilesResponse OK
     * @throws ApiError
     */
    public static getV3ChatsAttachments(
        sort?: string,
        order?: string,
        pageNumber?: number,
        pageSize?: number,
        userId?: string,
    ): CancelablePromise<ChatFilesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/chats/attachments',
            query: {
                'Sort': sort,
                'Order': order,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'UserId': userId,
            },
        });
    }

    /**
     * Get all attachments in chat
     * @param conversationId
     * @param sort
     * @param order
     * @param pageNumber
     * @param pageSize
     * @param userId
     * @returns ChatFilesResponse OK
     * @throws ApiError
     */
    public static getV3ChatsAttachments1(
        conversationId: string,
        sort?: string,
        order?: string,
        pageNumber?: number,
        pageSize?: number,
        userId?: string,
    ): CancelablePromise<ChatFilesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/chats/{conversationId}/attachments',
            path: {
                'conversationId': conversationId,
            },
            query: {
                'Sort': sort,
                'Order': order,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'UserId': userId,
            },
        });
    }

    /**
     * Create attachments
     * @param conversationId
     * @param requestBody
     * @returns CreateAttachmentResponse OK
     * @throws ApiError
     */
    public static postV3ChatsAttachments(
        conversationId: string,
        requestBody?: Array<CreateFileDto>,
    ): CancelablePromise<Record<string, CreateAttachmentResponse>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v3/chats/{conversationId}/attachments',
            path: {
                'conversationId': conversationId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get attachment uri
     * @param conversationId
     * @param attachmentId
     * @param height
     * @param width
     * @returns any OK
     * @throws ApiError
     */
    public static getV3ChatsAttachments2(
        conversationId: string,
        attachmentId: string,
        height?: number,
        width?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/chats/{conversationId}/attachments/{attachmentId}',
            path: {
                'conversationId': conversationId,
                'attachmentId': attachmentId,
            },
            query: {
                'Height': height,
                'Width': width,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Redirect to attachment uri
     * @param conversationId
     * @param attachmentId
     * @param height
     * @param width
     * @returns any OK
     * @throws ApiError
     */
    public static getV3ChatsAttachmentsRedirect(
        conversationId: string,
        attachmentId: string,
        height?: number,
        width?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/chats/{conversationId}/attachments/{attachmentId}/redirect',
            path: {
                'conversationId': conversationId,
                'attachmentId': attachmentId,
            },
            query: {
                'Height': height,
                'Width': width,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
