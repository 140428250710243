const KNOWLEDGE_BASE_LINK = 'https://intercom.help/native1/en/'
const TERMS_LINK = 'https://native.tech/terms/'
const PRIVACY_POLICY_LINK = 'https://native.tech/privacy/'
const MAX_VISIBLE_ATTACHMENTS = 5
const IMG_ZOOMED_SCALE = 2
const ATTACHMENT_SPINNER_SIZE = 24
const MAX_FILES = 10
const MIN_WIDTH_ATTACHMENT_TILE = 20.8
const INT_32 = 2147483647
const CHAR_LIMIT = 5000
const DEACTIVATE_DAYS = 30
const MAX_USER_REACTIONS = 20
const MAX_UNIQUE_REACTIONS = 40

export {
  KNOWLEDGE_BASE_LINK,
  TERMS_LINK,
  PRIVACY_POLICY_LINK,
  MAX_VISIBLE_ATTACHMENTS,
  IMG_ZOOMED_SCALE,
  ATTACHMENT_SPINNER_SIZE,
  MAX_FILES,
  MIN_WIDTH_ATTACHMENT_TILE,
  INT_32,
  CHAR_LIMIT,
  DEACTIVATE_DAYS,
  MAX_UNIQUE_REACTIONS,
  MAX_USER_REACTIONS,
}
