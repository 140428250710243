import { useAppSelector } from '@app/flow/hooks'
import { GalleryModal } from '@modules/modals/gallery'
import { Attachments as AttachmentsNfc } from '@modules/native-chats/attachments'
import { isExpectedType } from '@shared/lib/media/is-expected-type'
import { MetadataType } from '@shared/types/meta-data'
import { isNativeChatsApp } from '@utils/is-native-chats-app'
import React, { FC, useCallback } from 'react'
import { AttachmentResponse } from 'src/entities/messages/api/types/attachment-response'
import { selectChatUsers } from 'src/features/chats/slice'
import { SpaceProps, WidthProps } from 'styled-system'
import { ModalVariant, useModal } from 'ui'
import { Attachments } from './attachments'

export interface AttachmentsContainerProps {
  hasTimestamp: boolean
  isIncoming?: boolean
}

export interface MessageAttachmentsProps extends AttachmentsContainerProps, SpaceProps, WidthProps {
  conversationId: string
  attachments: AttachmentResponse[]
  uploadingAttachments: AttachmentResponse[]
  hasOnlyImagesOrVideo: boolean
  createdByUserId: string //createdBy and createdAt BE doesn't provide in the attachment object, we pass it from the message
  createdAt?: Date
  isLoading?: boolean
}

export const MessageAttachments: FC<MessageAttachmentsProps> = ({
  conversationId,
  attachments,
  uploadingAttachments,
  hasTimestamp,
  hasOnlyImagesOrVideo = false,
  isIncoming = false,
  isLoading = false,
  createdAt,
  createdByUserId,
  width,
  ...spacing
}) => {
  const { openModal } = useModal()

  const chatUsers = useAppSelector(({ chats }) => selectChatUsers(chats, conversationId))

  const getSender = useCallback(
    (userId: string) => {
      return chatUsers?.find((u) => u.user.userId === userId)?.user
    },
    [chatUsers]
  )
  const mapValidAttachmentsWithIndexes = (
    attachments: AttachmentResponse[]
  ): { validAttachments: AttachmentResponse[]; originalIndexes: number[] } => {
    const validAttachments: AttachmentResponse[] = []
    const originalIndexes: number[] = []

    attachments.forEach((attachment, index) => {
      if (isExpectedType(attachment.metadata, [MetadataType.IMAGE, MetadataType.VIDEO])) {
        validAttachments.push(attachment)
        originalIndexes.push(index)
      }
    })

    return { validAttachments, originalIndexes }
  }

  const handleOnClick = (index: number) => {
    const sender = getSender(createdByUserId)
    const { validAttachments, originalIndexes } = mapValidAttachmentsWithIndexes(attachments)
    const adjustedIndex = originalIndexes.indexOf(index)

    if (index >= 0 && sender) {
      openModal({
        content: (
          <GalleryModal
            initialIndex={adjustedIndex}
            attachments={validAttachments}
            conversationId={conversationId}
            sender={sender}
            createdAt={createdAt}
          />
        ),
        variant: ModalVariant.FULL,
        backdropColor: 'rgba(31, 28, 29, 0.95)',
        hasCloseButton: false,
      })
    }
  }

  return isNativeChatsApp() ? (
    <AttachmentsNfc
      uploadingAttachments={uploadingAttachments}
      attachments={attachments}
      createdAt={createdAt}
      hasTimestamp={hasTimestamp}
      onClick={handleOnClick}
      isIncoming={isIncoming}
      isLoading={isLoading}
      hasOnlyImagesOrVideo={hasOnlyImagesOrVideo}
      {...spacing}
    />
  ) : (
    <Attachments
      uploadingAttachments={uploadingAttachments}
      attachments={attachments}
      conversationId={conversationId}
      createdAt={createdAt}
      hasTimestamp={hasTimestamp}
      onClick={handleOnClick}
      isIncoming={isIncoming}
      isLoading={isLoading}
      hasOnlyImagesOrVideo={hasOnlyImagesOrVideo}
      width={width}
      {...spacing}
    />
  )
}
