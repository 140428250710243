/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvatarResponse } from '../models/AvatarResponse';
import type { CreateProfile } from '../models/CreateProfile';
import type { GetDevicesResponse } from '../models/GetDevicesResponse';
import type { Operation } from '../models/Operation';
import type { Operation_1 } from '../models/Operation_1';
import type { PersonalProfileResponse } from '../models/PersonalProfileResponse';
import type { SetBrowserDetailRequest } from '../models/SetBrowserDetailRequest';
import type { SetDeviceDetailRequest } from '../models/SetDeviceDetailRequest';
import type { SetLanguageRequest } from '../models/SetLanguageRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MeService {

    /**
     * Create profile
     * Create profile for current user
     * @param requestBody
     * @returns PersonalProfileResponse OK
     * @throws ApiError
     */
    public static postV2Me(
        requestBody?: CreateProfile,
    ): CancelablePromise<PersonalProfileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/me',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Get profile
     * Get the profile information of the current user
     * @returns PersonalProfileResponse OK
     * @throws ApiError
     */
    public static getV2Me(): CancelablePromise<PersonalProfileResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/me',
        });
    }

    /**
     * Update profile
     * Update current user profile
     * @param requestBody
     * @returns PersonalProfileResponse OK
     * @throws ApiError
     */
    public static patchV2Me(
        requestBody?: Array<(Operation | Operation_1)>,
    ): CancelablePromise<PersonalProfileResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v2/me',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Create avatar
     * Create avatar
     * @returns AvatarResponse OK
     * @throws ApiError
     */
    public static postV2MeAvatar(): CancelablePromise<AvatarResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/me/avatar',
        });
    }

    /**
     * Set device detail
     * Set the current device settings to be uses by BE
     * @returns GetDevicesResponse OK
     * @throws ApiError
     */
    public static getApiV2MeDevices(): CancelablePromise<GetDevicesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/me/devices',
        });
    }

    /**
     * Set device detail
     * Set the current device settings to be uses by BE
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static postApiV2MeDevices(
        requestBody?: SetDeviceDetailRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/me/devices',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Set browser detail
     * Set the current browser detail for push notification
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static postV2MeBrowsers(
        requestBody?: SetBrowserDetailRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/me/browsers',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Set language
     * Set current user languages
     * @param requestBody
     * @returns PersonalProfileResponse OK
     * @throws ApiError
     */
    public static postV2MeLanguage(
        requestBody?: SetLanguageRequest,
    ): CancelablePromise<PersonalProfileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v2/me/language',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
