import React from 'react'

export const IndicatorEditedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 10.25a.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75h10a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75Z" />
      <path d="M6.898.541 1.25 6.189V9.25h3.06L9.96 3.602a.863.863 0 0 0 0-1.204L8.102.541a.863.863 0 0 0-1.204 0zm.602 1.52L8.44 3 3.69 7.75h-.94v-.94Z" />
    </svg>
  )
}
