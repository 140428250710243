import React, { FC } from 'react'
import styled from 'styled-components'
import { PlayIcon } from 'ui'

//TODO: Add variant for small thumbnail
export const VideoIndicator: FC = () => {
  return (
    <VideoIndicatorContainer>
      <PlayIcon />
    </VideoIndicatorContainer>
  )
}

const VideoIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`
