import { useQuery } from '@tanstack/react-query'
import { userService } from '../../../features/user/repositories/user-service'

interface useGetSupportedLanguagesProps {
  skip?: boolean
}

export const useGetSupportedLanguages = ({ skip }: useGetSupportedLanguagesProps) => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery(
    ['getSupportedLanguages'],
    () => userService.getSupportedLanguages(),
    { refetchOnWindowFocus: false, enabled: !skip, staleTime: Infinity }
  )

  return { data: data?.languages, isLoading, isError, isFetching, refetch }
}
