import React, { FC } from 'react'
import { components, MultiValueRemoveProps } from 'react-select'
import { useTheme } from 'styled-components'
import { CloseIcon } from 'ui'
import { CreatableOptionType } from './types'

export const MultiValueRemove: FC<MultiValueRemoveProps<CreatableOptionType>> = ({ ...props }) => {
  const theme = useTheme()

  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon fill={theme.colors.black} />
    </components.MultiValueRemove>
  )
}
