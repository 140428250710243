/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChatSyncResponse } from '../models/ChatSyncResponse';
import type { IncrementalMessagesUpdateResponse } from '../models/IncrementalMessagesUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SyncService {

    /**
     * @param pageSize
     * @param timeStamp
     * @param lastChatId
     * @param order
     * @returns ChatSyncResponse OK
     * @throws ApiError
     */
    public static getV3SyncChats(
        pageSize?: number,
        timeStamp?: string,
        lastChatId?: string,
        order: string = 'DESC',
    ): CancelablePromise<ChatSyncResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/sync/chats',
            query: {
                'pageSize': pageSize,
                'timeStamp': timeStamp,
                'lastChatId': lastChatId,
                'order': order,
            },
        });
    }

    /**
     * @param pageSize
     * @param chatId
     * @param timeStamp
     * @param lastMessageId
     * @returns IncrementalMessagesUpdateResponse OK
     * @throws ApiError
     */
    public static getV3SyncMessages(
        pageSize?: number,
        chatId?: string,
        timeStamp?: string,
        lastMessageId?: string,
    ): CancelablePromise<IncrementalMessagesUpdateResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v3/sync/messages',
            query: {
                'pageSize': pageSize,
                'chatId': chatId,
                'timeStamp': timeStamp,
                'lastMessageId': lastMessageId,
            },
        });
    }

}
