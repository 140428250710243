import React, { FC } from 'react'
import styled from 'styled-components'
import { SpaceProps, space } from 'styled-system'
import { Container, Text } from 'ui'

export interface ListItem {
  icon?: JSX.Element
  text?: string | JSX.Element
}

export interface ListProps extends SpaceProps {
  title?: string
  items: ListItem[]
}

export const List: FC<ListProps> = ({ title, items, ...stylesProps }) => (
  <Container {...stylesProps}>
    {title && (
      <Text variant="textMedium" mb="0.8rem">
        {title}
      </Text>
    )}
    <ul>
      {items.map(({ icon, text }, index) => {
        if (!text) {
          return null
        }

        return (
          <StyledItem key={index} variant="smallTextRegular">
            {icon && <StyledIcon>{icon}</StyledIcon>}
            {text}
          </StyledItem>
        )
      })}
    </ul>
  </Container>
)

const StyledItem = styled(Text.withComponent('li'))`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  & a {
    color: inherit;
  }
`

const StyledIcon = styled.span<SpaceProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  flex: 0;
  margin: -0.2rem 0.8rem 0 0;
  color: ${({ theme }) => theme.colors.greyDark};
  ${space}
`
