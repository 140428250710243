import styled, { FlattenSimpleInterpolation, css } from 'styled-components'
import { Container } from 'ui'
import { AttachmentsContainerProps } from '.'

type GridStylesMap = {
  default: FlattenSimpleInterpolation
  [key: number]: FlattenSimpleInterpolation
}

export const AttachmentContainer = styled(Container)<{ isIncoming: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  cursor: pointer;
  background: ${({ isIncoming, theme }) => (isIncoming ? theme.colors.greyLight : '#4875C3')};

  & > img,
  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const imageGridStylesMap: GridStylesMap = {
  1: css`
    grid-template-columns: repeat(1, 32rem);
    grid-template-rows: 32rem;
  `,
  2: css`
    grid-template-columns: repeat(2, minmax(15vw, 20rem));
    grid-template-rows: minmax(15vw, 20rem);
  `,
  3: css`
    grid-template-columns: repeat(3, minmax(15vw, 20rem));
    grid-template-rows: minmax(15vw, 20rem);
  `,
  4: css`
    grid-template-columns: repeat(2, minmax(15vw, 20rem));
    grid-template-rows: repeat(2, minmax(15vw, 20rem));
  `,
  default: css`
    grid-template-columns: repeat(6, minmax(4.65vw, 6.65rem));
    grid-template-rows: minmax(9.3vw, 13.3rem) minmax(16vw, 20rem);

    & > div {
      grid-column: span 2;
    }
    & > div:nth-child(4) {
      grid-column: span 3;
    }
    & > div:nth-child(5) {
      grid-column: span 3;
    }
  `,
}

const itemsGridStylesMap: GridStylesMap = {
  1: css`
    grid-template-columns: minmax(20.8rem, 0.5fr);
  `,
  2: css`
    grid-template-columns: repeat(2, 1fr);
  `,
  default: css`
    grid-template-columns: repeat(auto-fit, minmax(20.8rem, 1fr));
  `,
}

const getBorderStyles = (hasIndicator: boolean, isIncoming: boolean) => {
  if (hasIndicator) {
    if (isIncoming) {
      return '0 0 1.2rem 1.2rem'
    }
    return '1.2rem 1.2rem 0 1.2rem'
  } else {
    if (isIncoming) {
      return '0'
    }
    return '1.2rem 1.2rem 0 0'
  }
}

export const GridContainer = styled.div<{ attachmentsCount: number; hasOnlyImages: boolean }>`
  display: grid;
  gap: 0.4rem;

  ${({ attachmentsCount, hasOnlyImages }) =>
    hasOnlyImages
      ? imageGridStylesMap[attachmentsCount] || imageGridStylesMap.default
      : itemsGridStylesMap[attachmentsCount] || itemsGridStylesMap.default}
`

export const IndicatorContainer = styled.div`
  position: absolute;
  bottom: 0.8rem;
  right: 0.8rem;
  display: inline-flex;
  align-items: center;
  padding: 0.2rem 0.8rem;
  border-radius: 10rem;
  background-color: rgba(100, 105, 95, 0.7);
  height: 2.2rem;
`

export const AttachmentsContainer = styled(Container)<AttachmentsContainerProps>`
  overflow: hidden;
  position: relative;
  border-radius: ${({ hasIndicator, isIncoming = false }) =>
    getBorderStyles(hasIndicator, isIncoming)};
`

export const AttachmentOverlay = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
`
