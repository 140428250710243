import styled from 'styled-components'
import { Container } from 'ui'

export const ActionsContainer = styled(Container)`
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  outline: ${({ theme }) => `1px solid ${theme.colors.grey}`};
  background-color: ${({ theme }) => theme.colors.white};
`

interface ActionProps {
  isReactionsLimit?: boolean
}
export const Action = styled.button<ActionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  color: ${({ theme }) => theme.colors.greySuperDark};
  transition: ${({ theme }) => theme.transitionTimes.short};
  &:hover {
    color: ${({ isReactionsLimit, theme }) =>
      !isReactionsLimit ? theme.colors.primary : theme.colors.greySuperDark};
  }
`
