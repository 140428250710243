import { Conversation } from '../model/conversation'
import { syncConversationsService } from './services/sync-conversations-service'

type SyncData = {
  conversations: Conversation[]
  lastChatIdInBatch?: string
  lastChatTimestampInBatch?: string
  isSyncFinished?: boolean
}

export const syncConversations = async (
  pageSize: number,
  timeStamp?: string,
  lastChatId?: string,
  syncData: SyncData = {
    conversations: [],
    lastChatIdInBatch: '',
    lastChatTimestampInBatch: '',
    isSyncFinished: false,
  }
): Promise<{
  conversations: Conversation[]
  lastChatId?: string
  lastChatTimestamp?: string
  isSyncFinished?: boolean
}> => {
  const syncedData = await syncConversationsService.syncConversations(
    pageSize,
    timeStamp,
    lastChatId
  )

  const newSyncData: SyncData = {
    conversations: [...syncData.conversations, ...syncedData.conversations],
    lastChatIdInBatch: syncedData.lastChatIdInBatch,
    lastChatTimestampInBatch: syncedData.lastChatTimestampInBatch,
    isSyncFinished: syncedData.isSyncFinished,
  }

  if (newSyncData.isSyncFinished) {
    return {
      conversations: newSyncData.conversations,
      lastChatId: newSyncData.lastChatIdInBatch,
      lastChatTimestamp: newSyncData.lastChatTimestampInBatch,
      isSyncFinished: newSyncData.isSyncFinished,
    }
  }

  return syncConversations(
    pageSize,
    newSyncData.lastChatTimestampInBatch,
    newSyncData.lastChatIdInBatch,
    newSyncData
  )
}
