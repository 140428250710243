import { createBaseURL } from '@native-chats-api/utils'
import axios, { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import {
  getAccessTokenFromCookies,
  getRefreshTokenFromCookies,
} from '@native-chats-api/auth-api/utils/get-tokens'
import { refreshTokens } from '@native-chats-api/auth-api/refresh-tokens'
import { setTokens } from '@native-chats-api/auth-api/utils/set-tokens'
import { removeTokenFromCookies } from '@native-chats-api/auth-api/utils/remove-tokens'
import { TokenType } from '@native-chats-api/auth-api/utils/tokens'
import { RetryAxiosRequestConfig } from '@native-chats-api/interceptos'

const url = process.env.NATIVE_CHATS_AUTH_API_URL as string

const baseURL = createBaseURL(url)

export const nativeChatsAuthApi = axios.create({ baseURL })

nativeChatsAuthApi.interceptors.response.use(
  (response) => {
    return camelcaseKeys(response, { deep: true }) as AxiosResponse
  },
  async (error) => {
    {
      const originalRequest = error.config as RetryAxiosRequestConfig
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true // Mark the request as retried to avoid infinite loops.
        try {
          const refreshToken = getRefreshTokenFromCookies() // Retrieve the stored refresh token.
          // Make a request to your auth server to refresh the token.
          if (refreshToken) {
            const response = await refreshTokens(refreshToken)
            const { accessToken, refreshToken: newRefreshToken } = response.data
            // Store the new access and refresh tokens.
            setTokens({ accessToken, refreshToken: newRefreshToken })
            // Update the authorization header with the new access token.
            nativeChatsAuthApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
            if (originalRequest) {
              return nativeChatsAuthApi(originalRequest) // Retry the original request with the new access token.
            }
          }
        } catch (refreshError) {
          // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
          console.error('Token refresh failed:', refreshError)
          removeTokenFromCookies(TokenType.AccessToken)
          removeTokenFromCookies(TokenType.RefreshToken)
          window.location.href = '/login'
          return Promise.reject(refreshError)
        }
      }
      return Promise.reject(error) // For all other errors, return the error as is.
    }
  }
)

nativeChatsAuthApi.interceptors.request.use((request) => {
  const accessToken = getAccessTokenFromCookies()
  if (accessToken) {
    request.headers = request.headers || {}
    request.headers['Authorization'] = `Bearer ${accessToken}`
  }
  return request
})
