import { PatchMeArgs } from '@api/account/types/patch-me-args'
import { NativeChatsRepository } from './native-chats-repository'
import { NativeFlowRepository } from './native-flow-repository'
import { UserRepository } from './user-repository'
import { LanguageParam } from '@api/account/set-personal-languages'

class UserService {
  userRepository: UserRepository

  constructor(userRepository: UserRepository) {
    this.userRepository = userRepository
  }

  async getMyProfile() {
    return this.userRepository.getMyProfile()
  }
  async updateMyProfile(args: PatchMeArgs) {
    return this.userRepository.updateMyProfile(args)
  }

  async getSupportedLanguages() {
    return this.userRepository.getSupportedLanguages()
  }

  async setPersonalLanguages(languages: LanguageParam[]) {
    return this.userRepository.setPersonalLanguages(languages)
  }
}

export const userService = new UserService(
  process.env.APP_ENV === 'chats' ? new NativeChatsRepository() : new NativeFlowRepository()
)
