import { MessageInput } from '@components/message-input'
import { useMessageBulb } from '@hooks/use-message-bulb'
import { MessageAttachments, MessageAttachmentsProps } from '@modules/message-attachments'
import { QuickActions } from '@modules/quick-actions'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { WidthProps } from 'styled-system'
import { Container, Text } from 'ui'
import { GeneralMessageProps, MessageVariant } from '.'
import { MessageReactions } from './reactions'
import { MessageBody, MessageHeader, MessageText, Spacer } from './styled'

export interface MessageHeaderProps {
  shouldBeTranslated?: boolean
}

export interface MessageFooterProps {
  resetMargin?: boolean
}

export const MessageLink = styled.a<{ isSender: boolean }>`
  color: ${(props) => (props.isSender ? props.theme.colors.primary : props.theme.colors.white)};
  cursor: pointer;
  text-decoration: underline;

  &:visited {
    color: ${(props) => props.theme.colors.primaryLoading};
  }
`

export type SpacerProps = WidthProps

export const DefaultMessage: React.FC<GeneralMessageProps> = (props) => {
  const {
    isSender,
    createdByFullName,
    messageId,
    reactions,
    clientMessageId,
    createdAt,
    createdByUserId,
    attachments,
    chatId,
    isUserDeleted,
  } = props
  const {
    shouldShowEditableBulb,
    messageEditProps,
    onMouseEnter,
    onMouseLeave,
    getMessageFooter,
    messageActionsShow,
    messageActions,
    noOfReactionsInMessage,
    shouldBeTranslated,
    setIsHoveringOverQuickActions,
    setMessageActionsShow,
    spacerWidth,
    shownText,
  } = useMessageBulb(props, MessageVariant.DEFAULT)
  const { t } = useTranslation('user-detail')

  // For now we will format text which is started from http://, https:// or ftp:// to links
  // Other cases are problematically to cover using only regex
  // TODO: In future, if we will change approach of text input
  // (for example support html markup in input area) we will be able to parse user's input,
  // modify it to markup (in this particular case to HTML links) ans send to BE as a markup
  const urlRegEx = /((ftp|https?):\/\/[^\s]+)/g

  const hasAttachments = attachments?.length > 0
  const hasOnlyImages =
    hasAttachments &&
    attachments.every((attachment) => attachment.metadata?.contentType?.startsWith('image/'))
  const showMessageFooter = shownText || (!shownText && !hasOnlyImages)
  const hasReactions = noOfReactionsInMessage > 0
  const hasIndicator = !shownText && !hasReactions && hasOnlyImages

  const commonMessageAttachmentsProps: MessageAttachmentsProps = {
    conversationId: chatId,
    attachments: attachments,
    hasIndicator: hasIndicator,
    hasOnlyImages: hasOnlyImages,
    createdAt,
    createdByUserId,
    pb: shownText || hasReactions || !hasOnlyImages ? '0.4rem' : 0,
    pt: isSender ? '0.8rem' : 0,
  }

  if (shouldShowEditableBulb) {
    return (
      <Container width="70%">
        <MessageInput {...messageEditProps} />
      </Container>
    )
  }

  const formatText = (message: string) => {
    // Checking wether message contains url
    if (urlRegEx.test(message)) {
      // All further steps allows us to avoid usage of dangerouslySetInnerHTML

      // Wrap all urls with clientMessageId for correct string splitting
      const stringWithWrappedUrls = message.replace(
        urlRegEx,
        (url) => `${clientMessageId}${url}${clientMessageId}`
      )
      // Message parts is an array of plain strings and urls
      const messageParts = stringWithWrappedUrls.split(clientMessageId)

      return messageParts.map((part: string, index) =>
        urlRegEx.test(part) ? (
          <MessageLink isSender={isSender} rel="noreferrer" target="_blank" key={index} href={part}>
            {part}
          </MessageLink>
        ) : (
          part
        )
      )
    } else {
      return message
    }
  }
  const name = isUserDeleted ? t('deactivatedAccount') : createdByFullName

  return (
    <MessageBody
      isSender={isSender}
      variant={MessageVariant.DEFAULT}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      hasOnlyImages={hasOnlyImages}
    >
      {messageActionsShow && (
        <QuickActions
          top="-3.2rem"
          right="0rem"
          borderRadius="1.6rem"
          height="4rem"
          padding="0.8rem 1.2rem"
          reactions={reactions}
          isSender={isSender}
          actions={messageActions}
          messageId={messageId}
          clientMessageId={clientMessageId}
          reactionCount={noOfReactionsInMessage}
          setMessageActionsShow={setMessageActionsShow}
          setIsHoveringOverQuickActions={setIsHoveringOverQuickActions}
        />
      )}
      {isSender && (
        <>
          <MessageHeader shouldBeTranslated={shouldBeTranslated}>
            <Text variant="textMedium">{name}</Text>
          </MessageHeader>
          {hasAttachments && (
            <MessageAttachments {...commonMessageAttachmentsProps} isIncoming={true} />
          )}
        </>
      )}
      {!isSender && hasAttachments && <MessageAttachments {...commonMessageAttachmentsProps} />}
      {shownText && (
        <MessageText>
          <Text color={isSender ? 'black' : 'white'}>
            {formatText(shownText.trimEnd())}
            <Spacer width={spacerWidth} />
          </Text>
        </MessageText>
      )}
      {messageId && noOfReactionsInMessage && noOfReactionsInMessage > 0 ? (
        <MessageReactions
          setMessageActionsShow={setMessageActionsShow}
          reactions={reactions}
          postFix={getMessageFooter()}
          messageId={messageId}
          clientMessageId={clientMessageId}
          reactionCount={noOfReactionsInMessage}
          isSender={isSender}
        />
      ) : (
        <>{showMessageFooter && getMessageFooter()}</>
      )}
    </MessageBody>
  )
}
