import { Metadata, MetadataType } from '@shared/types/meta-data'

export const isExpectedType = (
  metadata: Metadata,
  expectedTypes: MetadataType | MetadataType[]
): boolean => {
  const { type, contentType } = metadata || {}
  const typesArray = Array.isArray(expectedTypes) ? expectedTypes : [expectedTypes]

  return typesArray.some((expectedType) => {
    if (expectedType === MetadataType.IMAGE) {
      return type === MetadataType.IMAGE && !contentType?.includes('svg')
    }
    return type === expectedType
  })
}
