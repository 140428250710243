import { FilesService } from '@native-chats-api/media/generated'
import { useQuery } from '@tanstack/react-query'
import { convertFileToAttachmentResponse } from 'src/features/chats/repositories/helpers/convertMedia'

const NATIVE_CHATS_ATTACHMENTS = 'native-chats-attachments'

export const useGetAttachments = (attachmentIds: string[], enabled: boolean) => {
  return useQuery(
    [NATIVE_CHATS_ATTACHMENTS, attachmentIds],
    async () => {
      const response = await FilesService.getApiV2Files(attachmentIds)
      return response?.files?.map(convertFileToAttachmentResponse)
    },
    {
      enabled: Boolean(enabled && attachmentIds.length > 0),
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  )
}
