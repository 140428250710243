import { nativeChatsAuthApi } from '@native-chats-api/auth-api/instance'

interface UserInfo {
  userId: string
  email: string
  email_verified: boolean
  name: string
  phone_number: string
  phone_number_verified: boolean
  preferred_username: string
  sub: string
}

export const getUserInfo = async () => {
  const { data } = await nativeChatsAuthApi.get<UserInfo>('/connect/userInfo')
  return data
}
