import { InViewTrigger } from '@components/in-view-trigger'
import { Skeleton, SkeletonType } from '@modules/skeleton'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { HeightProps, SpaceProps } from 'styled-system'
import { Container, ScrollContainer } from 'ui'

interface ListProps<T> extends SpaceProps, HeightProps {
  renderItem: (item: T) => ReactNode
  skeletonType?: SkeletonType
  setIsTriggerInView?: (c: boolean) => void
  isFetching?: boolean
  itemPadding?: string
  hasHover?: boolean
  items?: T[]
}

interface ListItemProps {
  isLast?: boolean
  itemPadding?: string
  hasHover?: boolean
}

/* ScrollableList component is a reusable list component that can render any array of items.
 It displays the items using a custom render function and can show a skeleton loader when fetching data.
 It accepts various styling props for padding, hover effects, and custom item rendering.*/

export const ScrollableList = <T,>({
  items = [],
  renderItem,
  isFetching = false,
  skeletonType,
  setIsTriggerInView,
  hasHover = false,
  itemPadding = '1.2rem 2rem',
  ...stylesProps
}: ListProps<T>) => (
  <ScrollContainer hideScrollbar {...stylesProps}>
    {items?.map((item, index) => (
      <ListItem key={index} p={itemPadding} hasHover={hasHover} isLast={items.length - 1 === index}>
        {renderItem(item)}
      </ListItem>
    ))}
    {isFetching && skeletonType && <Skeleton type={skeletonType} length={12} />}
    {setIsTriggerInView && (
      <InViewTrigger onChange={setIsTriggerInView} bottom={0} position="absolute" />
    )}
  </ScrollContainer>
)

export const ListItem = styled(Container)<ListItemProps>`
  padding: ${({ itemPadding }) => itemPadding};
  position: relative;
  ${({ isLast, theme }) =>
    !isLast &&
    `
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 2rem;
      right: 2rem;
      height: 0.1rem;
      background: ${theme.colors.greyLight};
    }
  `}

  ${({ hasHover, theme }) =>
    hasHover &&
    `
    &:hover {
      background-color: ${theme.colors.greyLight};
    }
  `}
`
