import React, { SVGProps, FC } from 'react'

export const PdfIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10.158 9.037a.811.811 0 0 0-.478.41.96.96 0 0 0-.07.532c.038.325.17.634.35.982.077.146.241.322.335.477-.014.545-.01 1.075-.106 1.697-.113.744-.3 1.376-.486 1.924-.958.535-1.572 1.137-.871 1.724a.872.872 0 0 0 .572.21.89.89 0 0 0 .534-.218c.266-.223.419-.512.568-.86.02-.047.026-.126.045-.175a18.409 18.409 0 0 1 2.836-.722c.046.035.097.106.142.138.314.225.599.398.938.46.17.03.371.03.564-.063a.863.863 0 0 0 .41-.46c.338-.872-.536-1.093-1.675-1.087-.455-.38-.954-.83-1.448-1.412-.42-.497-.71-.97-1.013-1.442 0-.19.05-.437.037-.609-.028-.387-.076-.715-.215-1.01a.937.937 0 0 0-.344-.412.808.808 0 0 0-.625-.084zm1.053 3.71c.132.167.204.326.344.491.3.355.606.58.916.889a8.932 8.932 0 0 0-1.62.435c.109-.436.254-.8.327-1.275.03-.193.01-.349.033-.54z" />
    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
    <path d="M7 2a3.001 3.001 0 0 0-3 3v14a3.001 3.001 0 0 0 3 3h10a3.001 3.001 0 0 0 3-3V8a1 1 0 0 0-.293-.707l-5-5A1 1 0 0 0 14 2Zm0 2h6.586L18 8.414V19a.999.999 0 0 1-1 1H7a.999.999 0 0 1-1-1V5a.999.999 0 0 1 1-1Z" />
  </svg>
)
