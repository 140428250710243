import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  overflow: hidden;
`

export const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  padding: 2rem;
`

export const SidebarContainer = styled.div`
  border-radius: 0 1.6rem 1.6rem 0;
  display: flex;
  width: 28rem;
  flex-direction: column;
  flex: 0 0 28rem;
  height: 100%;
  padding-top: 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  &::after {
    content: '';
    z-index: 3;
    position: absolute;
    background: linear-gradient(0deg, rgba(254, 254, 254, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
    bottom: 0;
    left: 0;
    right: 0;
    height: 5rem;
    pointer-events: none;
    border-radius: 0 1.6rem 1.6rem 0;
  }
`
