import React, { FC } from 'react'
import { TeammatesList } from 'src/entities/teammates/ui/modules/teammates-list'
import { ContactsLayoutProps } from './index'

export const ContactsListLayout: FC<ContactsLayoutProps> = ({
  users,
  createActions,
  isUsersFetching,
  setIsTriggerInView,
  onUserClick,
}) => {
  return (
    <TeammatesList
      users={users ?? []}
      createActions={createActions}
      isUsersFetching={isUsersFetching}
      setIsTriggerInView={setIsTriggerInView}
      onUserClick={onUserClick}
    />
  )
}
