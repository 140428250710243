export const filterByFields = <T extends Record<string, any>>(
  items: T[],
  searchString: string,
  keys: string[]
) => {
  return (
    items.filter((item) => {
      const combinedFields = keys
        .map((key) => {
          const keyParts = key.split('.')
          // Reduce the key parts to access the nested value
          const value = keyParts.reduce((obj: any, k) => (obj ? obj[k] : undefined), item)
          return value !== undefined ? String(value) : ''
        })
        .join(' ')
        .toLowerCase()

      return combinedFields.includes(searchString.toLowerCase())
    }) ?? []
  )
}
