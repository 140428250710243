import axios from 'axios'
import { protectedBaseUrl, workspaceBaseUrl } from './create-base-url'
import { onFulfilled, onRejected, setAuthHeaders, setWorkspaceAuthHeaders } from './interceptors'

export const workspaceApiInstance = axios.create({ baseURL: workspaceBaseUrl })

workspaceApiInstance.interceptors.response.use(onFulfilled, onRejected)
workspaceApiInstance.interceptors.request.use(setWorkspaceAuthHeaders)

export const protectedApiInstance = axios.create({ baseURL: protectedBaseUrl })

protectedApiInstance.interceptors.request.use(setAuthHeaders)
