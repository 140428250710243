/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateFileDto } from '../models/CreateFileDto';
import type { FileResponse } from '../models/FileResponse';
import type { FilesResponse } from '../models/FilesResponse';
import type { HttpResponseMessage } from '../models/HttpResponseMessage';
import type { JsonPatchDocument } from '../models/JsonPatchDocument';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FilesService {

    /**
     * Get media files metadata
     * @param fileIds
     * @returns FilesResponse Media files metadata
     * @throws ApiError
     */
    public static getApiV2Files(
        fileIds?: Array<string>,
    ): CancelablePromise<FilesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/files',
            query: {
                'FileIds': fileIds,
            },
            errors: {
                401: `Unauthorized access`,
            },
        });
    }

    /**
     * Create media file
     * @param requestBody
     * @returns FileResponse Media file metadata
     * @throws ApiError
     */
    public static postApiV2Files(
        requestBody?: CreateFileDto,
    ): CancelablePromise<FileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/files',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized access`,
            },
        });
    }

    /**
     * Redirect to media file URI
     * @param fileId
     * @returns void
     * @throws ApiError
     */
    public static getApiV2Files1(
        fileId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/files/{fileId}',
            path: {
                'fileId': fileId,
            },
            errors: {
                302: `Redirect to media file URI`,
                401: `Unauthorized access`,
                404: `Media file not found`,
            },
        });
    }

    /**
     * Patch file size for a given media file
     * @param fileId
     * @param requestBody
     * @returns HttpResponseMessage OK
     * @throws ApiError
     */
    public static patchApiV2Files(
        fileId: string,
        requestBody?: JsonPatchDocument,
    ): CancelablePromise<HttpResponseMessage> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v2/files/{fileId}',
            path: {
                'fileId': fileId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
