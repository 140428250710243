import React, { createContext, FC, PropsWithChildren, useContext } from 'react'
import ImageUploading, { ImageUploadingPropsType } from 'react-images-uploading'
import { ExportInterface } from 'react-images-uploading/dist/typings'

interface WithError {
  lengthError?: boolean
}

const AttachmentsUploadingContext = createContext<ExportInterface & WithError>()

export const useAttachmentsUploadingContext = () => useContext(AttachmentsUploadingContext)

type AttachmentsUploadingProviderProps = Omit<ImageUploadingPropsType, 'children'>

const MAX_FILES = 10

export const AttachmentsUploadingProvider: FC<
  PropsWithChildren<AttachmentsUploadingProviderProps>
> = ({ children, ...props }) => {
  return (
    <ImageUploading {...props} allowNonImageType>
      {(props) => (
        <AttachmentsUploadingContext.Provider
          value={{
            ...props,
            imageList: props.imageList.slice(0, Math.min(props.imageList.length, MAX_FILES)),
            lengthError: props.imageList.length > 10,
          }}
        >
          {children}
        </AttachmentsUploadingContext.Provider>
      )}
    </ImageUploading>
  )
}
