import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import React, { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui'
import { chatsPersonalValidationSchema } from './data'
import { ChatsPersonalValues, FlowPersonalValues } from './types'

interface PersonalSettingsForm {
  initialValues: ChatsPersonalValues | FlowPersonalValues
  onSubmit: (values: any) => void
  children: ReactNode
  isLoading?: boolean
}

export const PersonalSettingsForm: FC<PersonalSettingsForm> = ({
  initialValues,
  onSubmit,
  children,
  isLoading = false,
}) => {
  const { t } = useTranslation(['personal'])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={chatsPersonalValidationSchema(t)}
      onSubmit={onSubmit}
      validateOnBlur
    >
      {({ errors }) => {
        const isDisabled = !isEmpty(errors) || isLoading
        return (
          <Form>
            {children}
            <Button type="submit" minWidth="16rem" disabled={isDisabled}>
              {t('saveButton')}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}
