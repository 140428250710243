import React, { FC } from 'react'
import { User } from 'src/entities/teammates/api/model/user-type'
import { ScrollContainer } from 'ui'
import { UserActive } from './user-active'

export interface UserDetailProps {
  user: User
}

//TODO: implement UserDeleted when will be made functionality to delete users
export const UserDetail: FC<UserDetailProps> = ({ user }) => (
  <ScrollContainer hideScrollbar pb="4rem">
    <UserActive user={user} />
  </ScrollContainer>
)
