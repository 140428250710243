import React, { Dispatch, FC, SetStateAction } from 'react'
import { SpaceProps } from 'styled-system'
import { BasicSearch } from 'ui'

interface FilterUsersProps extends SpaceProps {
  searchString: string
  setSearchString: Dispatch<SetStateAction<string>>
  placeholder: string
}

export const FilterUsers: FC<FilterUsersProps> = ({
  searchString,
  setSearchString,
  placeholder,
  ...spacing
}) => {
  return (
    <BasicSearch
      searchString={searchString}
      setSearchString={setSearchString}
      id="searchUser"
      name="searchUser"
      placeholder={placeholder}
      {...spacing}
    />
  )
}
