export type MediaModel = {
  mediaId?: string | null
  mediaUri?: string | null
  mediaUriExpiresAt?: string
}

export enum ChatType {
  '_0' = 0,
  '_1' = 1,
  '_-1' = -1,
}

export type ChatUserMediaResponse = {
  mediaId?: string | null
  mediaUri?: string | null
  mediaUriExpiresAt?: string
}

export enum UserConnectionStateResponse {
  '_1' = 1,
  '_2' = 2,
  '_3' = 3,
  '_4' = 4,
  '_5' = 5,
  '_6' = 6,
  '_7' = 7,
  '_8' = 8,
}

export type ConnectionStateResponse = {
  state?: UserConnectionStateResponse
  establishedAt?: string | null
}

export type OnlineResponse = {
  status?: ConnectionStatusResponse
  lastOnline?: string | null
}

export enum ConnectionStatusResponse {
  '_0' = 0,
  '_1' = 1,
}

export type UserResponse = {
  avatar?: ChatUserMediaResponse
  color?: string | null
  connection?: ConnectionStateResponse
  contactId?: string | null
  email?: string | null
  firstName?: string | null
  isBlocked?: boolean
  isBlockingMe?: boolean
  isDeleted?: boolean
  isMuted?: boolean
  isMutingMe?: boolean
  lastName?: string | null
  online?: OnlineResponse
  phoneNumber?: string | null
  userId?: string
}

export type ChatUserResponse = {
  user?: UserResponse
  lastMessageReadAt?: string | null
  idOfLastMessageRead?: string | null
  isKnown?: boolean
  isKnowsMe?: boolean
  isOwner?: boolean
  leftChat?: boolean
}

export enum TranslateProvider {
  '_0' = 0,
  '_1' = 1,
  '_2' = 2,
  '_3' = 3,
}

export type MessageTranslationItemDto = {
  provider?: TranslateProvider
  translation?: string | null
  isPreferred?: boolean
}

export type MessageTranslationDto = {
  translationId?: string | null
  languageCode?: string | null
  isPrimaryLanguage?: boolean
  translations?: Array<MessageTranslationItemDto> | null
  hasBeenReportedAsIncorrect?: boolean
}

export type UserReactionTime = {
  userId?: string
  timestamp?: string
}

export type MessageReaction = {
  reactionCode?: string | null
  readonly count?: number
  createdAt?: string
  userReactionTimes?: Array<UserReactionTime> | null
}

export type MessageDetailDto = {
  messageId?: string
  chatId?: string
  isDeleted?: boolean
  createdBy?: string | null
  createdAt?: string
  lastModifiedAt?: string | null
  editedAt?: string | null
  originalMessage?: string | null
  sourceLanguageCode?: string | null
  clientMessageId?: string | null
  translations?: Array<MessageTranslationDto> | null
  media?: Array<MediaModel> | null
  reactions?: Array<MessageReaction> | null
}

export type ChatResponse = {
  id?: string
  name?: string | null
  color?: string | null
  chatType?: ChatType
  isMuted?: boolean
  isBlocked?: boolean
  avatar?: MediaModel
  createdAt?: string
  lastModifiedAt?: string | null
  chatUsers?: Array<ChatUserResponse> | null
  lastMessage?: MessageDetailDto
  messagesDeletedToMessageId?: string | null
  unreadMessagesCount?: number
}
