import React from 'react'
import { SVGProps } from 'react'

export const EmojiIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 2C6.489 2 2 6.489 2 12s4.489 10 10 10 10-4.489 10-10S17.511 2 12 2Zm0 2c4.43 0 8 3.57 8 8s-3.57 8-8 8-8-3.57-8-8 3.57-8 8-8Z" />
      <circle cx="8.5" cy="9.5" r="1.5" />
      <circle cx="15.5" cy="9.5" r="1.5" />
      <path d="M8 15c2 2.5 5.6 2.667 8 0z" />
      <path d="M8 14.5a.5.5 0 0 0-.39.813c2.175 2.72 6.188 2.88 8.761.021A.5.5 0 0 0 16 14.5Zm1.379 1h5.142c-1.706 1.162-3.626 1.115-5.142 0z" />
    </svg>
  )
}
