import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { useGetChatDetails } from '@api/chats/hooks/use-get-chat-details'
import { useSidePage } from '@hooks/use-side-page'
import { SidePageTypes } from '@layouts/main-layout/side-page'
import { CHANNEL_TYPES, isChat } from '@utils/get-chat-type'
import { getFullName } from '@utils/helpers'
import React, { FC, useMemo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ConversationUser } from 'src/entities/conversations/model/conversation'
import { SpaceProps } from 'styled-system'
import { Avatar, AvatarsIcon, ChevronDownIcon, Container, Heading, StatusVariant } from 'ui'
import { withSkeleton } from '../../../HOC/with-skeleton'
import { ConversationHeaderSkeleton } from '../conversation-body/ui/modules/conversation-skeleton/conversation-header-skeleton'
import { ConversationHeaderButton, IconContainer } from './ui/styles/styled'

interface ConversationHeaderProps extends SpaceProps {
  conversationName: string
}

export const ConversationHeader: FC<ConversationHeaderProps> = ({
  conversationName,
  ...spacing
}) => {
  const { setOpen, setType, setSidePageUserId, setSidePageChatId } = useSidePage()
  const { t } = useTranslation(['chat'])
  const { meData } = useGetMe()
  const { chatId, channelId } = useParams<{ chatId?: string; channelId?: string }>()
  const { data: chatDetails } = useGetChatDetails(chatId || channelId)
  const [chatUserId, setChatUserID] = useState('')
  const [avatarId, setAvatarId] = useState<string>()
  const { usersAvatarUrl, enabled } = useGetUsersAvatar({ userId: chatUserId, avatarId })

  // Get chat users amount
  const chatUsersNumber = useMemo(() => {
    return chatDetails?.chatUsers.length || 0
  }, [chatDetails])

  // Filter all chat users excluding ME
  const usersSendingToMe = useMemo(() => {
    if (!meData || !chatDetails) {
      return []
    }

    return chatDetails.chatUsers.filter((chatUser) => chatUser.user.userId !== meData.userId)
  }, [chatDetails, meData])

  const isMyPrivateChat = useMemo(() => {
    if (!meData || !chatDetails) {
      return false
    }

    return chatUsersNumber === 1 && usersSendingToMe.length === 0 && isChat(chatDetails)
  }, [chatDetails, chatUsersNumber, meData, usersSendingToMe.length])

  useEffect(() => {
    if (isMyPrivateChat && meData) {
      setChatUserID(meData.userId)
      setAvatarId(meData.avatar?.mediaId)
    }
    if (usersSendingToMe.length === 1) {
      setChatUserID(usersSendingToMe[0].user.userId)
      //@ts-ignore
      setAvatarId(usersSendingToMe[0].user.avatar?.id)
    }
  }, [usersSendingToMe, meData])

  const isChannel = chatDetails && CHANNEL_TYPES.includes(chatDetails.conversationType)

  // Generate chat header title
  const generateChatAvatar = (usersSendingToMe: ConversationUser[]) => {
    if (isChannel) {
      return null
    }
    if (isMyPrivateChat && meData) {
      const { firstName, lastName, online, color } = meData
      const fullName = getFullName(firstName, lastName)
      return (
        <Avatar
          imgUrl={usersAvatarUrl}
          name={fullName}
          status={online.status === 0 ? StatusVariant.OFFLINE : StatusVariant.ONLINE}
          label={firstName.charAt(0).toUpperCase()}
          borderRadius="0.6rem"
          size="3.6rem"
          fontSize="2.4rem"
          bgColor={`#${color}`}
          isAvatarExists={enabled}
          hasBorder={false}
        />
      )
    }

    if (chatUsersNumber === 2) {
      const { firstName, lastName, color, online, avatar } = usersSendingToMe[0].user
      const fullName = getFullName(firstName, lastName)
      return (
        <Avatar
          imgUrl={usersAvatarUrl}
          name={fullName}
          label={firstName}
          status={online.status === 0 ? StatusVariant.OFFLINE : StatusVariant.ONLINE}
          borderRadius="0.6rem"
          size="3.6rem"
          fontSize="2.4rem"
          bgColor={`#${color}`}
          hasBorder={false}
          isAvatarExists={enabled}
        />
      )
    }
    if (chatUsersNumber > 2) {
      return (
        <Avatar
          size="3.6rem"
          borderRadius="0.6rem"
          bgColor={`#${chatDetails?.color}`}
          icon={<AvatarsIcon />}
        />
      )
    }
    return null
  }

  const handleClick = useCallback(() => {
    let sidePageType
    let sidePageUserId

    if (!chatDetails) {
      return
    }

    setSidePageChatId(chatDetails.id)

    if (isChannel || usersSendingToMe.length > 1) {
      sidePageType = SidePageTypes.DETAILS
      sidePageUserId = null
    } else {
      sidePageUserId = isMyPrivateChat && meData ? meData.userId : usersSendingToMe[0]?.user.userId
      sidePageType = SidePageTypes.USER
    }

    setSidePageUserId(sidePageUserId)
    setType(sidePageType)
    setOpen(true)
  }, [
    chatDetails,
    isMyPrivateChat,
    meData,
    usersSendingToMe,
    setType,
    setOpen,
    setSidePageChatId,
    setSidePageUserId,
  ])

  if (!chatDetails) {
    return null
  }

  return (
    <Container as="header" {...spacing}>
      <ConversationHeaderButton
        type="button"
        onClick={handleClick}
        aria-label={t('chatHeader.ariaLabelViewDetails')}
        aria-hidden={true}
      >
        {generateChatAvatar(usersSendingToMe)}
        <Heading as="p" lineClamp={1}>
          {conversationName}
        </Heading>
        <IconContainer>
          <ChevronDownIcon />
        </IconContainer>
      </ConversationHeaderButton>
    </Container>
  )
}

export const ConversationHeaderWithSkeleton = withSkeleton(
  ConversationHeader,
  <ConversationHeaderSkeleton />
)
