import React from 'react'

export const DeleteIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.778 5.208A.722.722 0 0 1 10.286 5h3.428c.192 0 .375.076.508.208s.207.31.207.492V7H9.57V5.7c0-.183.074-.36.207-.492ZM7.57 7V5.7c0-.719.288-1.406.798-1.912S9.57 3 10.286 3h3.428c.717 0 1.407.282 1.916.788.51.506.798 1.193.798 1.912V7H20a1 1 0 1 1 0 2h-1v10.3c0 .719-.288 1.406-.798 1.912s-1.2.788-1.916.788H7.714a2.722 2.722 0 0 1-1.916-.788A2.693 2.693 0 0 1 5 19.3V9H4a1 1 0 0 1 0-2H7.571ZM7 9v10.3c0 .183.073.36.206.492a.722.722 0 0 0 .508.208h8.572a.722.722 0 0 0 .508-.208A.693.693 0 0 0 17 19.3V9H7Z" />
    </svg>
  )
}
