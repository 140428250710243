import { Conversation } from '../conversation'

export const parseConversationUserLeft = (conversation: Conversation, userId: string) => {
  const chatUsers = conversation.chatUsers.map((chatUser) => {
    const { user, leftChat } = chatUser
    if (user.userId === userId) {
      return { ...chatUser, leftChat: !leftChat }
    }
    return chatUser
  })
  const chatUserIds = conversation.chatUserIds.filter((chatUserId) => chatUserId !== userId)

  return {
    chatUsers,
    chatUserIds,
  }
}
