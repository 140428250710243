import React, { ElementType } from 'react'
import styled from 'styled-components'
import { PlayIcon } from 'ui'

export const getPreviewElement = (elementType: ElementType, src?: string, name?: string) => {
  switch (elementType) {
    case 'img':
      return <img src={src} alt={name} />
    case 'video':
      return (
        <>
          <img src={src} alt={name} />
          <PlayIconPreview />
        </>
      )
    default:
      return React.createElement(elementType)
  }
}

const PlayIconPreview = styled(PlayIcon)`
  position: absolute;
`
