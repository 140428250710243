import React from 'react'

export const ShieldIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m11.633 2.145-7 2.755A1 1 0 0 0 4 5.83v6.67c0 3.15 1.959 5.738 4 7.613a21.723 21.723 0 0 0 3.488 2.594 1 1 0 0 0 1.024 0A21.722 21.722 0 0 0 16 20.113c2.041-1.875 4-4.463 4-7.613V5.83a1 1 0 0 0-.633-.93l-7-2.755a1 1 0 0 0-.734 0zM12 4.15l6 2.362V12.5c0 2.297-1.525 4.461-3.354 6.14a19.841 19.841 0 0 1-2.494 1.934v-.002c-.06.04-.096.06-.152.096-.056-.036-.092-.056-.152-.096v.002a19.842 19.842 0 0 1-2.494-1.933C7.525 16.96 6 14.797 6 12.5V6.512Z" />
    </svg>
  )
}
