import { Language, localeTh, localeUS } from '@utils/locales'
import { formatRelative } from 'date-fns'

const LOCALES: Record<Language, Locale> = {
  en: localeUS,
  th: localeTh,
}

export const getLocaleDate = (dateToHandle: Date | string, interfaceLanguage: Language) => {
  const date = new Date(dateToHandle)
  const now = new Date()
  return formatRelative(date, now, {
    locale: LOCALES[interfaceLanguage as Language] || localeUS,
  })
}
