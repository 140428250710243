import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { SpaceProps } from 'styled-system'
import { Container } from 'ui'
import { InputLabel } from './input-label'

interface InputFieldProps extends SpaceProps {
  width?: number
  height?: number
}

const DEFAULT_WIDTH = 600
const DEFAULT_HEIGHT = 48

export const InputField = ({ width, height, ...spaceProps }: InputFieldProps) => {
  return (
    <Container display="flex" flexDirection="column" {...spaceProps}>
      <InputLabel />
      <Container mt={12}>
        <Skeleton width={width ?? DEFAULT_WIDTH} height={height || DEFAULT_HEIGHT} />
      </Container>
    </Container>
  )
}
