import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

export const useFileDownload = () => {
  const [downloading, setDownloading] = useState<{ [key: string]: boolean }>({})

  const downloadFile = useCallback(async (uri: string, filename: string, errorMsg: string) => {
    // use the uri as the key for tracking the download state
    setDownloading((prev) => ({ ...prev, [uri]: true }))

    try {
      const response = await fetch(uri)
      if (!response.ok) throw new Error('Failed to download file')

      const blob = await response.blob()
      const blobUrl = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = blobUrl
      link.download = filename
      link.click()

      // clean up the blob URL after download
      URL.revokeObjectURL(blobUrl)
    } catch (_err) {
      toast.error(errorMsg)
    } finally {
      setDownloading((prev) => {
        const newState = { ...prev }
        delete newState[uri]
        return newState
      })
    }
  }, [])

  const isDownloading = (uri: string) => downloading[uri] || false

  return { downloadFile, isDownloading }
}
