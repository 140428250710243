import React from 'react'
import { createPortal } from 'react-dom'
import { PortalProps } from './types'

export const Portal: React.FC<PortalProps> = ({ children, selectorId }) => {
  const [mounted, setMounted] = React.useState<boolean>(false)

  React.useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [])

  return mounted ? createPortal(children, document.getElementById(selectorId) as HTMLElement) : null
}
