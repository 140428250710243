import { useAuth } from '@hooks/chats/use-auth'
import { useInterfaceLanguage } from '@hooks/use-interface-language'
import { CredentialResponse, GoogleLogin as GoogleButton } from '@react-oauth/google'
import React from 'react'

export const GoogleLogin = () => {
  const { ssoLoginCallback } = useAuth()
  const { interfaceLanguage } = useInterfaceLanguage()

  const handleSuccess = async (credentials: CredentialResponse) => {
    if (credentials.credential) {
      await ssoLoginCallback(credentials.credential)
    }
  }

  return (
    <GoogleButton
      onSuccess={handleSuccess}
      shape="rectangular"
      size="medium"
      width="5000"
      text="continue_with"
      locale={interfaceLanguage.value}
      logo_alignment="center"
    />
  )
}
