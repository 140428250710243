import React from 'react'

export const EmailIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 5C4.801 5 3 6.801 3 9v6c0 2.199 1.801 4 4 4h10c2.199 0 4-1.801 4-4V9c0-2.199-1.801-4-4-4H7zm0 2h10c.144 0 .282.014.416.041l-3.463 2.969a2.991 2.991 0 0 1-3.906 0L6.584 7.04C6.718 7.014 6.856 7 7 7zM5.09 8.395l3.656 3.132a5.01 5.01 0 0 0 6.508 0l3.656-3.132c.058.19.09.392.09.605v6c0 1.125-.875 2-2 2H7c-1.125 0-2-.875-2-2V9c0-.213.032-.415.09-.605z" />
    </svg>
  )
}
