import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Container, Heading, ImgContainer } from 'ui'

const IMG_SRC = '/img/group.svg'

export const Welcome = () => {
  const { t } = useTranslation(['common'])

  return (
    <PageContainer>
      <ImgContainer maxWidth="55rem">
        <img src={IMG_SRC} alt={t('imgAlt.welcome')} width={550} height={304} />
      </ImgContainer>
      <Heading variant="h2" as="h1" mt="3.2rem">
        {t('welcomeToNative')}
      </Heading>
    </PageContainer>
  )
}

const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`
