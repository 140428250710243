import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

// PaginationParams are common params which are used in most of api hooks with pagination. If we need to add extra params we have to define them on a place where the hook is used. PaginationParams are extended by AdditionalParams
interface PaginationParams {
  search?: string
  sortDescending?: boolean
  sortBy?: string
}

// Define a generic type parameter AdditionalParams for HookConfig
export interface HookConfig<AdditionalParams> {
  hook: (params: PaginationParams & AdditionalParams) => any
  params?: PaginationParams & AdditionalParams
}

// Define HookProps with a generic type parameter AdditionalParams
export interface HookProps<AdditionalParams> {
  deleteTrigger?: boolean
  setDeleteTrigger?: (v: boolean) => void
  hookConfig: HookConfig<AdditionalParams>
}

export const usePagination = <AdditionalParams>({
  deleteTrigger,
  setDeleteTrigger,
  hookConfig,
}: HookProps<AdditionalParams>) => {
  const { hook, params = {} as PaginationParams & AdditionalParams } = hookConfig

  const {
    fetchedData,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isFetching,
    refetch,
    error,
    isLoading,
    resetInfiniteQueryPagination,
    ...rest
  } = hook(params)

  const { ref, inView } = useInView()

  /*NOTE: I've added these fetching functions to
          dependency array to avoid double fetch that appeared
          after I created this hook
        */

  useEffect(() => {
    if (deleteTrigger && setDeleteTrigger) {
      refetch()
      setDeleteTrigger(false)
    }
  }, [deleteTrigger, refetch, setDeleteTrigger])

  useEffect(() => {
    resetInfiniteQueryPagination?.()
    refetch()
  }, [params.search, params.sortBy, params.sortDescending, refetch])

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [inView, fetchedData.length, fetchNextPage])

  return {
    fetchedData,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isFetching,
    refetch,
    error,
    isLoading,
    ref,
    ...rest,
  }
}
