import { MessageTranslationDto, MessageTranslationItemDto } from '@native-chats-api/chats/generated'
import { ProviderTranslation, Translation } from '../messages-repository'

export const mapTranslations = (
  translations: Array<MessageTranslationDto> | null
): Translation[] => {
  if (!translations) return []
  return translations.map((translation) => ({
    translationId: translation.translationId || '',
    languageCode: translation.languageCode || '',
    isPrimaryLanguage: translation.isPrimaryLanguage || false,
    translations: (translation.translations || []).map((trans) => ({
      provider: trans.provider || 0,
      translation: trans.translation || '',
      isPreferred: trans.isPreferred || false,
    })),
    hasBeenReportedAsIncorrect: translation.hasBeenReportedAsIncorrect || false,
  }))
}

const mapProviderTranslation = (
  translationProvider: MessageTranslationItemDto
): ProviderTranslation => {
  const outputProviderTranslation: ProviderTranslation = {
    provider: translationProvider.provider!,
    translation: translationProvider.translation!,
    isPreferred: translationProvider.isPreferred!,
  }
  return outputProviderTranslation
}

export const mapTranslation = (translation: MessageTranslationDto): Translation => {
  const outputTranslation: Translation = {
    translationId: translation.translationId!,
    languageCode: translation.languageCode!,
    isPrimaryLanguage: translation.isPrimaryLanguage!,
    translations: translation.translations?.map(mapProviderTranslation) ?? [],
    hasBeenReportedAsIncorrect: translation.hasBeenReportedAsIncorrect!,
  }
  return outputTranslation
}
