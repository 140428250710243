export enum GrantType {
  Refresh = 'refresh_token',
  Password = 'password',
  Google = 'google_auth',
  Apple = 'apple_auth',
  Microsoft = 'ms_auth',
}

const baseAuthorizationParams = {
  client_id: process.env.NATIVE_CHATS_AUTH_CLIENT_ID,
  client_secret: process.env.NATIVE_CHATS_AUTH_CLIENT_SECRET,
}

export const createAuthorizationParams = (
  grantType: GrantType,
  additionalParams: Record<string, string> = {}
) => {
  return {
    grant_type: grantType,
    ...baseAuthorizationParams,
    ...additionalParams,
  }
}
