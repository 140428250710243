import { ThemeTypeEnum, useTheme } from '@contexts/theme-provider'
import { useWorkspace } from '@hooks/use-workspace'
import { routes } from '@routes/flow/routes'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Heading, Text } from 'ui'

export const NotFound = () => {
  const { setCurrentTheme } = useTheme()
  const { t } = useTranslation(['not-found'])
  const navigate = useNavigate()
  const { workspaceId, isPendingOnboarding, onboardingType } = useWorkspace()
  const { workspaces, teammates } = routes

  useEffect(() => {
    setCurrentTheme(ThemeTypeEnum.DARK)
  }, [])

  const navigateToFlow = useCallback(() => {
    setCurrentTheme(ThemeTypeEnum.LIGHT)

    if (workspaceId && !onboardingType) {
      navigate(`${workspaces}/${workspaceId}/${teammates}`, { replace: true })
    } else {
      navigate(`${workspaces}`, { replace: true })
    }
  }, [isPendingOnboarding, workspaceId])

  return (
    <StyledContainer>
      <Heading variant="h0" mb="0.8rem">
        {t('title')}
      </Heading>
      <Text variant="textMedium" mb="2.6rem">
        {t('subtitle')}
      </Text>
      <Button onClick={navigateToFlow} minWidth="20rem">
        {t('goBack')}
      </Button>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 12rem;
  height: 100%;
  background: url('/img/not-found.svg') no-repeat;
  background-size: cover;
  background-position: center -7rem;
  text-align: center;
`
