import { createAuthorizationParams, GrantType } from '@native-chats-api/auth-api/constants'
import { nativeChatsAuthApi } from '@native-chats-api/auth-api/instance'
import qs from 'qs'

export const refreshTokens = async (refreshToken: string) => {
  return nativeChatsAuthApi.post(
    '/connect/token',
    qs.stringify(
      createAuthorizationParams(GrantType.Refresh, {
        refresh_token: refreshToken,
      })
    ),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  )
}
