import { useEffect, RefObject } from 'react'

type Event = MouseEvent | KeyboardEvent | TouchEvent

export const useClickOutside = <T extends HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current
      if (!el || el.contains((event?.target as Node) || null)) {
        return
      }
      handler(event) // Call the handler only if the click is outside of the element passed.
    }
    const escapeListener = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        handler(event)
      }
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener) // For touch-based devices (smartphones, tablets)
    document.addEventListener('keydown', escapeListener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
      document.removeEventListener('keydown', escapeListener)
    }
  }, [ref, handler]) // Reload only if ref or handler changes
}
