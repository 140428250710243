import { getChatDetails } from '@api/chats/get-chat-details'
import { CHANNEL_LIST } from '@api/chats/query-keys'
import { ConversationList } from '@api/chats/types/chat-list'
import { QueryClient } from '@tanstack/react-query'

interface ChannelListCacheData {
  pageParams: any[]
  pages: ConversationList[]
}

interface SignalRChat {
  chatId: string
  createdAt: string
  createdBy: string
  lastModifiedAt: string
  chatType: number
  chatName: string
  chatAvatarUrl: string | null
  isGroupChat: boolean
  joiningUsers: string[]
}

export const handleNewUserJoinedChat = (queryClient: QueryClient) => async (chat: SignalRChat) => {
  try {
    const newChat = await getChatDetails({ chatId: chat.chatId })

    queryClient.setQueryData([CHANNEL_LIST], (oldData: ChannelListCacheData | undefined | null) => {
      if (!oldData) return null

      const lastPageIndex = oldData.pages.length - 1
      const lastPage = oldData.pages[lastPageIndex]

      if (!lastPage) return oldData

      const updatedLastPage = {
        ...lastPage,
        chats: [...lastPage.chats, newChat],
        totalChatsCount: lastPage.totalChatsCount + 1,
      }

      const updatedPages = [...oldData.pages.slice(0, lastPageIndex), updatedLastPage]

      return { ...oldData, pages: updatedPages }
    })

    queryClient.invalidateQueries([CHANNEL_LIST])
  } catch (error) {
    // Handle error (e.g., log to Sentry)
    console.error(error)
  }
}
