import { ATTACHMENT_SPINNER_SIZE } from '@utils/variables'
import React, { FC } from 'react'
import { getFileType } from 'src/shared/lib/media/get-file-type'
import { getPreviewSettings } from 'src/shared/lib/media/get-preview-settings'
import styled, { useTheme } from 'styled-components'
import { Container, SpinnerDarkAnimation, SpinnerLightAnimation } from 'ui'

interface PreviewContainerProps {
  isLoading: boolean
  color: string
}

interface PreviewProps extends PreviewContainerProps {
  name?: string
  src?: string
  contentType?: string
}

export const Preview: FC<PreviewProps> = ({
  color,
  isLoading,
  src,
  name,
  contentType = 'text/plain',
}) => {
  const type = getFileType(contentType)
  const theme = useTheme()
  const { backgroundColor, previewElement } = getPreviewSettings(theme, type, src, name, isLoading)

  return (
    <PreviewContainer backgroundColor={backgroundColor} color={color} isLoading={isLoading}>
      {isLoading ? (
        color === 'light' ? (
          <SpinnerLightAnimation size={ATTACHMENT_SPINNER_SIZE} />
        ) : (
          <SpinnerDarkAnimation size={ATTACHMENT_SPINNER_SIZE} />
        )
      ) : (
        previewElement
      )}
    </PreviewContainer>
  )
}

const PreviewContainer = styled(Container)<PreviewContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 0.6rem;
  overflow: hidden;
  flex: 0 0 auto;
  border: ${({ isLoading, theme }) =>
    `1px solid ${isLoading ? theme.colors.greyLight : theme.colors.transparent}`};
  & svg {
    fill: ${({ theme }) => theme.colors.white};
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
