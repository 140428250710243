import { useAppSelector } from '@app/flow/hooks'
import { useSidePage } from '@hooks/use-side-page'
import { Files } from '@modules/files'
import { SidepageHeader } from '@modules/side-page/header'
import { UserDetail } from '@modules/user-detail'
import { getFeatureFlag } from '@utils/flags-manager'
import { getStatusVariant } from '@utils/get-status'
import { capitalizeFirstLetter, getFullName } from '@utils/helpers'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { selectUser } from 'src/entities/teammates/api/model/entity-adapter'
import { StatusBadge, Tab, Tabs, TabsVariant } from 'ui'

export const User: FC = () => {
  const { t } = useTranslation('common')
  const { t: tUser } = useTranslation('user-detail')
  const { sidePageUserId } = useSidePage()
  const user = useAppSelector(({ users }) =>
    sidePageUserId ? selectUser(users, sidePageUserId) : null
  )

  if (!user) {
    return null
  }

  const userFullName = user.isDeleted
    ? tUser('deactivatedAccount')
    : getFullName(user.firstName, user.lastName)

  const tabs = [
    <Tab title={capitalizeFirstLetter(t('about'))}>
      <UserDetail user={user} />
    </Tab>,
    <Tab title={capitalizeFirstLetter(t('files'))}>
      <Files />
    </Tab>,
  ]

  return (
    <>
      {user.isDeleted ? (
        <SidepageHeader title={userFullName} mb="1.2rem" />
      ) : (
        <SidepageHeader
          title={userFullName}
          mb="1.4rem"
          postFix={<StatusBadge status={getStatusVariant(user.online)} sizeLg />}
        />
      )}
      {getFeatureFlag('showOnProd') ? (
        <Tabs variant={TabsVariant.UNDERLINE} listMarginBottom="1.6rem">
          {tabs}
        </Tabs>
      ) : (
        <UserDetail user={user} />
      )}
    </>
  )
}
