/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AttachmentMetadataType {
    UNKNOWN = 'Unknown',
    IMAGE = 'Image',
    VIDEO = 'Video',
    DOCUMENT = 'Document',
    AUDIO = 'Audio',
}
