import { accountAdditionalUrl } from '@api/create-base-url'
import { protectedApiInstance } from '@api/instance'
import type { AxiosRequestConfig } from 'axios'

interface GetArgs {
  url: string
  sortBy?: string
  sortDesc?: boolean
  config?: AxiosRequestConfig
  pageNumber?: number
  pageSize?: number
}
function get<ResponseType>(url = '', config?: AxiosRequestConfig) {
  return protectedApiInstance.get<ResponseType>(`${accountAdditionalUrl}${url}`, config)
}

function post<ResponseType>(url = '', data?: any, config?: AxiosRequestConfig) {
  return protectedApiInstance.post<ResponseType>(`${accountAdditionalUrl}${url}`, data, config)
}

function put<ResponseType>(url = '', data?: any, config?: AxiosRequestConfig) {
  return protectedApiInstance.put<ResponseType>(`${accountAdditionalUrl}${url}`, data, config)
}
function patch<ResponseType>(url = '', data?: any, config?: AxiosRequestConfig) {
  return protectedApiInstance.patch<ResponseType>(`${accountAdditionalUrl}${url}`, data, config)
}
function del<ResponseType>(url = '', config?: AxiosRequestConfig) {
  return protectedApiInstance.delete<ResponseType>(`${accountAdditionalUrl}${url}`, config)
}

export const accountApi = {
  get,
  post,
  put,
  patch,
  delete: del,
}
