import { enUS, th } from 'date-fns/locale'

export type Language = 'en' | 'th'

export const LOCALES = {
  en: enUS,
  th,
}

export const formatRelativeLocaleUs = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: 'MMM dd, yyyy',
}

export const formatRelativeLocaleTh = {
  lastWeek: "'เมื่อสัปดาห์ที่แล้ว' eeee",
  yesterday: "'เมื่อวานนี้'",
  today: "'วันนี้'",
  tomorrow: "'วันพรุ่งนี้'",
  nextWeek: "'สัปดาห์หน้า' eeee",
  other: 'MMM dd, yyyy',
}

export const localeUS = {
  ...enUS,
  formatRelative: (token: string) => (formatRelativeLocaleUs as any)[token],
}

export const localeTh = {
  ...th,
  formatRelative: (token: string) => (formatRelativeLocaleTh as any)[token],
}
