export interface ConversationUser {
  userId: string
  firstName: string
  lastName: string
  isDeleted: boolean
}

export const getConversationUserNames = <T extends { user: ConversationUser }>(
  deactivatedAccountStr: string,
  users: T[],
  myUserId?: string
) => {
  if (!users || !myUserId) return ''
  return users
    .filter(({ user: { userId } }) => userId !== myUserId)
    .map(({ user: { isDeleted, firstName, lastName } }) =>
      isDeleted ? deactivatedAccountStr : `${firstName} ${lastName}`
    )
    .join(', ')
}
