import { PersonalProfile } from '@api/account/types/personal-profile'
import { ConversationUser } from '../conversation'

export const parsePersonalProfile = (newUser: PersonalProfile): ConversationUser => {
  return {
    user: {
      firstName: newUser.firstName,
      lastName: newUser.lastName,
      userId: newUser.userId,
      color: newUser.color || '',
      avatar: {
        id: newUser.avatar?.mediaId || '',
        uri: newUser.avatar?.mediaUri || '',
      },
      email: newUser.email,
      isDeleted: false,
      languages: newUser.activeLanguages,
      primaryLanguageCode: newUser.activeLanguages.find((l) => l.isPrimary)?.code || '',
      timeZone: {
        id: newUser.timezone?.id || '',
        displayName: newUser.timezone?.displayName || '',
      },
      online: {
        status: newUser.online.status,
        lastOnline: newUser.online.lastOnline || new Date(),
      },
      doNotDisturbFrom: '',
    },
    lastMessageReadAt: new Date(),
    idOfLastMessageRead: '',
    isOwner: false,
    leftChat: false,
  }
}
