import styled, { css } from 'styled-components'
import { DefaultTheme } from 'styled-components'
import { Container, Text } from 'ui'
import { ReactionStylesProps } from '.'

const getMessageReactionStyles = (
  iHaveReacted: boolean,
  isSender: boolean,
  theme: DefaultTheme
) => {
  if (iHaveReacted && isSender) {
    return {
      backgroundColor: theme.colors.smoke,
      borderColor: theme.colors.primaryLight,
      color: theme.colors.primary,
    }
  } else if (!iHaveReacted && isSender) {
    return {
      backgroundColor: theme.colors.greyLight,
      borderColor: theme.colors.transparent,
      color: theme.colors.greySuperDark,
      hoverBorderColor: theme.colors.greyDark,
    }
  } else if (iHaveReacted && !isSender) {
    return {
      backgroundColor: theme.colors.smoke,
      borderColor: theme.colors.primaryLight,
      color: theme.colors.primary,
    }
  } else {
    return {
      backgroundColor: theme.colors.primaryLoading,
      borderColor: theme.colors.transparent,
      color: theme.colors.primaryLighter,
      hoverBorderColor: theme.colors.primaryLight,
    }
  }
}

export const ReactionsContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 0.6rem;
  padding: 0.4rem 0 0 0.8rem;
`

// Message reaction pill generic styles
export const ReactionPill = styled.button<ReactionStylesProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  min-width: 4rem;
  padding: 0 0.7rem;
  border-radius: 10rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.transparent};
  transition: border-color ${({ theme }) => theme.transitionTimes.short};
  cursor: pointer;
  ${({ theme, isSender, haveIReacted }) => {
    const { borderColor, backgroundColor, color, hoverBorderColor } = getMessageReactionStyles(
      haveIReacted ?? false,
      isSender,
      theme
    )

    // Check if hoverBorderColor is defined, add hover styles accordingly
    let hoverStyles = ''
    if (hoverBorderColor) {
      hoverStyles = `
        &:hover {
          border-color: ${hoverBorderColor};
        }
      `
    }

    return css`
      border-color: ${borderColor};
      background-color: ${backgroundColor};
      ${Text} {
        color: ${color};
      }
      & svg {
        color: ${isSender ? theme.colors.greyDark : theme.colors.primaryLighter};
      }
      ${hoverStyles}
    `
  }}
`
