import { DeleteMessageInput } from '@api/messages/delete-message'
import { EditMessageParams } from '@api/messages/edit-message'
import { SendMessageProps } from '@api/messages/send-message'
import {
  ChatApiService,
  AddUsersToChannelProps,
  CreateChatsProps,
  GetChatDetailsProps,
  LeaveChannelProps,
  UpdateChatProps,
  GetChatListProps,
  GetChannelListProps,
  ReportTranslationProps,
  UpdateTranslationPreferenceProps,
  UpdateTranslationLanguageProps,
  DeleteChatProps,
} from './chat-repository'
import { NativeChatsChatService } from './native-chats-repository'
import { NativeFlowChatService } from './native-flow-repository'

class ChatsHttpService {
  private chatsService: ChatApiService

  constructor(chatsService: ChatApiService) {
    this.chatsService = chatsService
  }

  addUsersToChat(props: AddUsersToChannelProps) {
    return this.chatsService.addUsersToChat(props)
  }

  createChat(props: CreateChatsProps) {
    return this.chatsService.createChat(props)
  }

  //   deleteChannel(props: DeleteChannelProps) {
  //     return this.chatsService.deleteChannel(props)
  //   }

  //   getAttachment(conversationId: string, attachmentId: string) {
  //     return this.chatsService.getAttachment(conversationId, attachmentId)
  //   }

  //   getChannelList(props: GetChannelListProps) {
  //     return this.chatsService.getChannelList(props)
  //   }

  //   getChannels(props: GetChannelsParams) {
  //     return this.chatsService.getChannels(props)
  //   }

  getChatDetails(props: GetChatDetailsProps) {
    return this.chatsService.getChatDetails(props)
  }

  getChatList(props: GetChatListProps) {
    return this.chatsService.getChatList(props)
  }

  getChannelList(props: GetChannelListProps) {
    return this.chatsService.getChannelList(props)
  }

  //   joinChannel(props: JoinChannelInput) {
  //     return this.chatsService.joinChannel(props)
  //   }

  leaveChannel(props: LeaveChannelProps) {
    return this.chatsService.leaveChannel(props)
  }

  //   readConversation(conversationId: string) {
  //     return this.chatsService.readConversation(conversationId)
  //   }

  //   toggleMuteChat(props: ToggleMuteChatProps) {
  //     return this.chatsService.toggleMuteChat(props)
  //   }

  updateChat(props: UpdateChatProps) {
    return this.chatsService.updateChat(props)
  }

  //   getAttachment(attachmentId: string, conversationId: string) {
  //     return this.chatsService.getAttachment(attachmentId, conversationId)
  //   }

  sendMessage(props: SendMessageProps) {
    return this.chatsService.sendMessage(props)
  }

  deleteMessage(props: DeleteMessageInput) {
    return this.chatsService.deleteMessage(props)
  }

  deleteChat(props: DeleteChatProps) {
    return this.chatsService.deleteChat(props)
  }

  editMessage(props: EditMessageParams) {
    return this.chatsService.editMessage(props)
  }

  getMessage(props: { id: string }) {
    return this.chatsService.getMessage(props)
  }

  reportTranslation(data: ReportTranslationProps) {
    return this.chatsService.reportTranslation(data)
  }

  updateTranslationPreference(data: UpdateTranslationPreferenceProps) {
    return this.chatsService.updateTranslationPreference(data)
  }

  updateTranslationLanguage(data: UpdateTranslationLanguageProps) {
    return this.chatsService.updateTranslationLanguage(data)
  }

  readConversation(conversationId: string) {
    return this.chatsService.readConversation(conversationId)
  }
}

export const chatApi = new ChatsHttpService(
  process.env.APP_ENV === 'chats' ? new NativeChatsChatService() : new NativeFlowChatService()
)
