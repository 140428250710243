import { MessageDetail } from '@api/messages/types/message-detail'
import { AppDispatch, store } from '@app/flow/store'
import { QueryClient } from '@tanstack/react-query'
import { notificationsManager } from '@utils/notifications'
import { CONVERSATIONS_QUERY_KEY } from 'src/entities/conversations/api/use-sync-and-get-conversations'
import { markConversationAsUnread } from 'src/entities/conversations/model/slice'
import { chatApi } from 'src/features/chats/repositories/chat-service'
import { receiveMessage } from '../../features/conversation/slice'

// TODO:  After back end changes socket payload to be same DTO as response from getMessageById, we can get rid of this query in socket payload this field is mandatory

export const handleNewMessage =
  (dispatch: AppDispatch, queryClient: QueryClient) => async (message: MessageDetail) => {
    try {
      const state = store.getState()
      const isNewConversation = !state.chats.ids.includes(message.chatId)

      const newMessage = await chatApi.getMessage({ id: message.messageId! })
      dispatch(receiveMessage(newMessage))
      dispatch(markConversationAsUnread({ id: message.chatId }))
      const newMessageTranslation =
        newMessage.translations[0]?.translations[0]?.translation || undefined

      if (isNewConversation) {
        queryClient.invalidateQueries([CONVERSATIONS_QUERY_KEY])
      }

      notificationsManager.tryToSendNotification(message, newMessageTranslation)
    } catch (error) {
      //todo: handle error in sentry
      console.error(error)
    }
  }
