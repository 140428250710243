import { useAppDispatch } from '@app/flow/hooks'
import { HubConnectionState } from '@microsoft/signalr'
import { useQueryClient } from '@tanstack/react-query'
import { isNativeChatsApp } from '@utils/is-native-chats-app'
import {
  NEW_MESSAGE_HAS_BEEN_SENT,
  CHAT_HAS_BEEN_CREATED,
  USERS_HAVE_JOINED_CHAT,
  CONNECTION_STATUS_CHANGED,
  MESSAGE_REACTION,
  USER_IS_TYPING,
  USER_IS_TYPING_IN_CHAT,
} from 'src/signalr/events'
import { handleMessageReaction } from 'src/signalr/handlers/handle-message-reaction'
import { handleNewMessage } from 'src/signalr/handlers/handle-new-message-set'
import { handleNewUserJoinedChat } from 'src/signalr/handlers/handle-new-user-join-chat'
import {
  handleConnectionStatusChanged,
  nativeChatsHandleConnectionStatusChanged,
} from 'src/signalr/handlers/handle-user-connection-status-changed'
import { useSignalR } from './use-signalr'

export const useSignalREvents = () => {
  const { connection, stopConnection, subscribeToEvent, unsubscribeFromEvent } = useSignalR()
  const queryClient = useQueryClient()

  const dispatch = useAppDispatch()

  const startEvents = async () => {
    if (connection && connection.state === HubConnectionState.Connected) {
      subscribeToEvent(NEW_MESSAGE_HAS_BEEN_SENT, handleNewMessage(dispatch, queryClient))
      subscribeToEvent(CHAT_HAS_BEEN_CREATED, handleNewUserJoinedChat(queryClient))
      subscribeToEvent(USERS_HAVE_JOINED_CHAT, handleNewUserJoinedChat(queryClient))
      subscribeToEvent(
        CONNECTION_STATUS_CHANGED,
        isNativeChatsApp()
          ? nativeChatsHandleConnectionStatusChanged(queryClient, dispatch)
          : handleConnectionStatusChanged(queryClient, dispatch)
      )
      subscribeToEvent(MESSAGE_REACTION, handleMessageReaction(dispatch))
    }
  }

  const stopEvents = () => {
    if (connection) {
      unsubscribeFromEvent(NEW_MESSAGE_HAS_BEEN_SENT)
      unsubscribeFromEvent(CHAT_HAS_BEEN_CREATED)
      unsubscribeFromEvent(USERS_HAVE_JOINED_CHAT)
      unsubscribeFromEvent(USER_IS_TYPING)
      unsubscribeFromEvent(USER_IS_TYPING_IN_CHAT)
      unsubscribeFromEvent(MESSAGE_REACTION)
      stopConnection()
    }
  }

  return { startEvents, stopEvents }
}
