import { PersonalProfile } from '@api/account/types/personal-profile'
import { ChatUser } from '@api/chats/types/chat-user'
import { MessageDetail } from '@api/messages/types/message-detail'
import { MessageVariant } from '@components/message'
import { getFullName } from './helpers'

export interface FormatMessageProps {
  messages: MessageDetail[]
  users?: Record<string, ChatUser>
  meData?: PersonalProfile
}

export const formatMessages = ({ messages, users, meData }: FormatMessageProps) => {
  return messages.map((message) => ({
    clientMessageId: message.clientMessageId,
    // todo: should update when the actual data is available
    avatarUrl: '',
    chatId: message.chatId,
    createdAt: new Date(message.createdAt),
    // todo: should update when the actual data is available
    isEdited: false,
    isDeleted: message.isDeleted,
    messageAuthorColor: users && users[message.createdBy]?.user.color,
    createdByFullName:
      (users &&
        getFullName(
          users[message.createdBy]?.user.firstName,
          users[message.createdBy]?.user.lastName
        )) ||
      'unknown user',
    createdByUserId: (users && users[message.createdBy]?.user.userId) || null,
    isUserDeleted: (users && users[message.createdBy]?.user.isDeleted) || false,
    isSender: meData?.userId !== message.createdBy,
    // todo: should update when the actual data is available
    isSending: message.isSending,
    isError: message.isError,
    // todo: should update when the actual data is available
    isTranslated: false,
    // todo: should update when the actual data is available
    isWatched: false,
    editedAt: message.editedAt ? new Date(message.editedAt) : null,
    messageId: message.messageId,
    text: message.originalMessage,
    variant: message.isDeleted ? MessageVariant.DELETED : MessageVariant.DEFAULT,
    sourceLanguageCode: message.sourceLanguageCode,
    translations: message.translations,
    reactions: message.reactions,
    lastModifiedAt: message.lastModifiedAt ? new Date(message.lastModifiedAt) : undefined,
    attachments: message.attachments,
    uploadingAttachments: message.uploadingAttachments,
  }))
}
