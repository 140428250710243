import React, { FC, forwardRef, RefAttributes } from 'react'
import styled from 'styled-components'
import { Container } from 'ui'

export interface MediaPreviewProps {
  src: string
  onZoom: VoidFunction
  currentIndex: number
  alt: string
  isVideo?: boolean
}

export const MediaPreview: FC<MediaPreviewProps & RefAttributes<HTMLDivElement>> = forwardRef(
  ({ src, onZoom, alt, isVideo = false }, ref) => {
    return isVideo ? (
      <MediaContainer>
        <video src={src} controls autoPlay />
      </MediaContainer>
    ) : (
      <ImgContainer onMouseUp={onZoom} ref={ref}>
        <img src={src} alt={alt} />
      </ImgContainer>
    )
  }
)

MediaPreview.displayName = 'MediaPreview'

export const MediaContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80vw;
  border-radius: 0.8rem;
  overflow: hidden;
  & img,
  & video {
    width: 100%;
    height: 100%;
    max-height: 80vh;
    object-fit: cover;
  }
`

export const ImgContainer = styled(MediaContainer)`
  cursor: zoom-in;
`
