import React, { ElementType } from 'react'
import { FileType } from 'src/shared/types/file-type'
import { DefaultTheme } from 'styled-components'
import { AudioIcon, FileIcon, PdfIcon, PlayIcon } from 'ui'

export const getPreviewSettings = (
  theme: DefaultTheme,
  type: FileType,
  src?: string,
  name?: string,
  isLoading?: boolean
) => {
  const fileTypeStyles: Record<FileType, { backgroundColor: string; elementType: ElementType }> = {
    [FileType.IMG]: { backgroundColor: 'transparent', elementType: 'img' },
    [FileType.VIDEO]: { backgroundColor: '#ED7E0C', elementType: PlayIcon }, // TODO: fix the preview to have img and PlayIcon overlay
    [FileType.AUDIO]: { backgroundColor: '#ED7E0C', elementType: AudioIcon },
    [FileType.DOCUMENT]: { backgroundColor: theme.colors.yellow, elementType: FileIcon },
    [FileType.PDF]: { backgroundColor: theme.colors.green, elementType: PdfIcon },
    [FileType.UNKNOWN]: { backgroundColor: theme.colors.yellow, elementType: FileIcon },
  }

  const { backgroundColor, elementType } = fileTypeStyles[type]
  const previewElement =
    elementType === 'img' ? <img src={src} alt={name} /> : React.createElement(elementType)

  return {
    backgroundColor: isLoading ? theme.colors.white : backgroundColor,
    previewElement,
  }
}
