import React from 'react'

export const CheckIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      {...props}
    >
      <path d="M12.975 3.666a1 1 0 0 0-.7.313L6.648 9.9 4.041 7.293a1 1 0 0 0-1.414 0 1 1 0 0 0 0 1.414l3.334 3.334a1 1 0 0 0 1.432-.02l6.334-6.666a1 1 0 0 0-.038-1.414 1 1 0 0 0-.714-.275Z" />
    </svg>
  )
}
