import { useDeleteChannel } from '@api/chats/hooks/use-delete-channel'
import { useLeaveChannel } from '@api/chats/hooks/use-leave-channel'
import { useAppDispatch } from '@app/flow/hooks'
import { useSidePage } from '@hooks/use-side-page'
import { ButtonsContainer } from '@modules/about/styled'
import React, { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Conversation } from 'src/entities/conversations/model/conversation'
import { ConversationType } from 'src/entities/conversations/model/conversation-type'
import { deleteConversation, leaveConversation } from 'src/entities/conversations/model/slice'
import { DeleteChannelModal } from 'src/entities/conversations/ui/modules/delete-channel'
import { LeaveChannelModal } from 'src/entities/conversations/ui/modules/leave-channel'
import { Button, LogoutIcon, TrashcanIcon, useModal } from 'ui'

interface ToastMessage {
  success: string
  error: string
}

interface LeaveAndDeleteChannelProps {
  myUserId: string
  redirectUrl: string
  conversation: Conversation
  leaveModalTexts: {
    title: string
    confirmation: string
    buttonLabels: string[]
    toastMessages: ToastMessage
  }
  deleteModalTexts: {
    title: string
    confirmation: string
    name: string
    membersCount: string
    buttonLabels: string[]
    toastMessages: ToastMessage
  }
  buttonLabels: { leave: string; delete: string }
}

export const LeaveAndDeleteChannel: FC<LeaveAndDeleteChannelProps> = ({
  myUserId,
  conversation,
  redirectUrl,
  deleteModalTexts,
  leaveModalTexts,
  buttonLabels,
}) => {
  const { conversationType, chatUsers, id, createdBy } = conversation
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { setOpen } = useSidePage()
  const membersNumber = chatUsers.length
  const isChat = conversationType === ConversationType.CHAT
  const isPrivateChannel = conversationType === ConversationType.PRIVATE_CHANNEL
  const isGeneralChannel = conversationType === ConversationType.GENERAL_CHANNEL

  const toBeDeleted = isPrivateChannel && membersNumber === 1

  const canBeEdited = useMemo(() => {
    const users = conversation?.chatUsers
    const myUser = users?.find(({ user }) => user.userId === myUserId)
    const isMyUserInChat = !!myUser
    const isMyUserActiveInChat = !myUser?.leftChat

    return isMyUserInChat && isMyUserActiveInChat
  }, [conversation, myUserId])

  const canBeDeleted = createdBy === myUserId && !isGeneralChannel
  const { openModal, closeModal } = useModal()

  const { mutate: deleteChannel, isLoading: isDeleteChannelLoading } = useDeleteChannel({
    onSuccess: (_, variables) => {
      dispatch(deleteConversation({ id: variables.channelId }))
      toast.success(`${deleteModalTexts.toastMessages.success}`)
      navigate(redirectUrl)
      closeModal()
      setOpen(false)
    },
    onError: () => toast.error(deleteModalTexts.toastMessages.error),
  })

  const { mutate: leaveChannel, isLoading: isLeaveChannelLoading } = useLeaveChannel({
    onSuccess: (_x, variables) => {
      dispatch(leaveConversation({ id: variables.channelId, myUserId }))
      toast.success(leaveModalTexts.toastMessages.success)
      closeModal()
      if (toBeDeleted || isPrivateChannel) {
        navigate(redirectUrl)
      }
    },
    onError: () => toast.error(leaveModalTexts.toastMessages.error),
  })

  // Leave channel (leaving private channel as the last member is deleting the channel at the same time)
  const onLeaveChannelSubmit = () => {
    leaveChannel({ channelId: id })
  }

  const onLeaveChannelClick = () => {
    openModal({
      content: (
        <LeaveChannelModal
          title={leaveModalTexts.title}
          confirmation={leaveModalTexts.confirmation}
          buttonLabels={leaveModalTexts.buttonLabels}
          onSubmit={onLeaveChannelSubmit}
          isDisabled={isLeaveChannelLoading}
          variant={toBeDeleted ? 'warning' : undefined}
        />
      ),
    })
  }

  // Delete channel
  const onDeleteChannelSubmit = () => {
    deleteChannel({ channelId: id })
  }

  const onDeleteChannelClick = () => {
    openModal({
      content: (
        <DeleteChannelModal
          title={deleteModalTexts.title}
          confirmation={deleteModalTexts.confirmation}
          name={deleteModalTexts.name}
          membersCount={deleteModalTexts.membersCount}
          buttonLabels={deleteModalTexts.buttonLabels}
          onSubmit={onDeleteChannelSubmit}
          onCancel={closeModal}
          isDisabled={isDeleteChannelLoading}
        />
      ),
    })
  }

  return (
    <>
      {!isChat && !isGeneralChannel && (
        <ButtonsContainer>
          {canBeEdited && (
            <Button
              display="block"
              onClick={onLeaveChannelClick}
              plain
              variant="warning"
              preFix={<LogoutIcon />}
            >
              {buttonLabels.leave}
            </Button>
          )}
          {canBeDeleted && (
            <Button
              display="block"
              onClick={onDeleteChannelClick}
              plain
              variant="warning"
              preFix={<TrashcanIcon />}
            >
              {buttonLabels.delete}
            </Button>
          )}
        </ButtonsContainer>
      )}
    </>
  )
}
