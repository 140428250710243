import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { GetChannelsParams, getChannels } from '../get-channels'
import { Channel } from '../types/channel'

export const CHANNELS = 'channels'

export const useGetChannels = ({ search }: GetChannelsParams) => {
  const { data, refetch, isLoading, isFetching } = useQuery<Channel[], Error>([CHANNELS], () =>
    getChannels({ search })
  )

  useEffect(() => {
    refetch()
  }, [search])

  return { data, refetch, isLoading, isFetching }
}
