import { PersonalProfile } from '@api/account/types/personal-profile'
import { CreateNewChatButton } from '@components/create-new-chat-btn'
import { Popover } from '@headlessui/react'
import { SidebarHeaderSkeleton } from '@modules/skeleton/skeleton-variants/sidebar-header'
import React, { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { withSkeleton } from 'src/HOC/with-skeleton'
import styled from 'styled-components'
import { SpaceProps } from 'styled-system'
import { Text, Container, ArrowIndicator, ImgContainer } from 'ui'
import { SidebarPopper, SidebarPopperAction } from './popper'

export interface SidebarHeaderProps extends SpaceProps {
  title: string
  subtitle: string | ReactNode
  avatarComponent: ReactNode
  popperItems: SidebarPopperAction[]
  me?: PersonalProfile
  onCreateNew?: VoidFunction
  popperComponent?: ReactNode
}

export interface ArrowIndicatorStylesProps {
  isCardOpen: boolean
}

export const SidebarHeader: FC<SidebarHeaderProps> = ({
  me,
  onCreateNew,
  title,
  subtitle,
  avatarComponent,
  popperItems,
  popperComponent,
  ...spaceProps
}) => {
  const { t } = useTranslation(['sidebar', 'common'])

  return (
    <Container {...spaceProps}>
      <Container display="flex">
        {avatarComponent}
        <Container mx="1rem" flex="1 1 auto" maxWidth="15rem">
          <Container position="relative" zIndex={1}>
            <Popover>
              {({ open }) => (
                <>
                  <Container>
                    <Popover.Button style={{ outline: 'none' }}>
                      <PopperButton>
                        <Text as="span" fontWeight={600} fontSize={18} data-cy="workspaceMenuBtn">
                          {title}
                        </Text>
                        <ArrowIndicator
                          open={open}
                          hiddenLabel={t('hiddenLabel.mainMenu', { ns: 'common' })}
                        />
                      </PopperButton>
                    </Popover.Button>
                  </Container>
                  <Container position="absolute">
                    <Popover.Panel>
                      {({ close }) => (
                        <SidebarPopper
                          onClose={close}
                          items={popperItems}
                          headingComponent={popperComponent}
                        />
                      )}
                    </Popover.Panel>
                  </Container>
                </>
              )}
            </Popover>
          </Container>
          {subtitle}
        </Container>
        {onCreateNew && <CreateNewChatButton onClick={onCreateNew} />}
      </Container>
    </Container>
  )
}

export const SidebarHeaderWithSkeleton = withSkeleton(SidebarHeader, <SidebarHeaderSkeleton />)

export const PopperButton = styled.span`
  display: inline-flex;
  align-items: center;
  ${Text} {
    flex-grow: 1;
    max-width: 12rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const Logo = styled(ImgContainer)`
  flex: 0 0 auto;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 0.8rem;
  overflow: hidden;
`
