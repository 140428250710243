import { Status } from '../../../signalr/handlers/handle-user-connection-status-changed'
import { UserResponse } from '../generated'
export const updateUserOnlineStatus = (cache: UserResponse, status: Status): UserResponse => {
  return {
    ...cache,
    online: {
      ...cache.online,
      status: status ? 1 : 0,
    },
  }
}
