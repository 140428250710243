import { EmojiPopover } from '@components/emoji-popover'
import { setCursorPosition } from '@utils/set-cursor-position'
import { EmojiClickData } from 'emoji-picker-react'
import React, { useRef, useState, forwardRef, useEffect, MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { Button, CheckmarkIcon, CloseIcon, EmojiIcon, Text, useClickOutside } from 'ui'
import { MessageInputMode } from '.'
import {
  ExpandingInput,
  InputButton,
  InputScrollContainer,
  InputWrapper,
  InputBottom,
  EditActions,
} from './styled'

export interface EditGridProps {
  contentEditableInputText: string

  cursorPosition: number
  handleOnChangeText: (target: HTMLDivElement) => void
  onClickSend: VoidFunction
  onClickClose?: VoidFunction
  isDisabled?: boolean
  hasReachedLimit?: boolean
  charsLeftText?: string
  onEmojiClick: ({ emoji }: EmojiClickData, event: MouseEvent, onClose: any) => void
  isEditLoading: boolean
}

export const EditGrid = forwardRef<HTMLDivElement | null, EditGridProps>(
  (
    {
      contentEditableInputText,
      cursorPosition,
      handleOnChangeText,
      onClickSend,
      onClickClose,
      isDisabled,
      hasReachedLimit,
      charsLeftText,
      onEmojiClick,
      isEditLoading,
    },
    ref
  ) => {
    const { t } = useTranslation(['chat'])
    const theme = useTheme()
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const inputRef = useRef<null | HTMLDivElement>(null)
    useClickOutside(inputRef, () => setIsFocused(false))

    const onFocusInputWrapper = () => {
      setIsFocused(true)
    }

    useEffect(() => {
      const target = ref as MutableRefObject<HTMLDivElement>
      if (target.current) setCursorPosition(cursorPosition, target.current)
    }, [contentEditableInputText])

    return (
      <InputWrapper onFocus={onFocusInputWrapper} ref={inputRef} isFocused={isFocused}>
        <InputScrollContainer messageInputMode={MessageInputMode.EDIT}>
          <ExpandingInput
            // https://www.jsdocs.io/package/@types/react#HTMLAttributes.contentEditable
            // @ts-ignore
            contentEditable="plaintext-only"
            placeholder={t('messageInput.placeholder')}
            onInput={(e) => {
              handleOnChangeText(e.currentTarget)
            }}
            ref={ref}
            suppressContentEditableWarning={true}
          >
            {contentEditableInputText}
          </ExpandingInput>
        </InputScrollContainer>
        <InputBottom>
          <EmojiPopover
            messageId="edit-grid"
            onEmojiClick={onEmojiClick}
            floatProps={{ placement: 'top-start', offset: { crossAxis: -15 } }}
            pb="4.5rem"
          >
            <InputButton
              type="button"
              disabled={isEditLoading}
              aria-label={t('messageInput.addEmoji')}
            >
              <EmojiIcon />
            </InputButton>
          </EmojiPopover>
          <EditActions>
            {charsLeftText && (
              <Text
                color={hasReachedLimit ? theme.colors.red : theme.colors.greyDark}
                variant="smallTextRegular"
              >
                {charsLeftText}
              </Text>
            )}
            <InputButton
              type="button"
              onClick={onClickClose}
              disabled={isEditLoading}
              aria-label={t('messageInput.closeInput')}
            >
              <CloseIcon />
            </InputButton>
            <Button
              variant="primary"
              circle
              onClick={onClickSend}
              hiddenLabel={t('messageEdited')}
              disabled={isDisabled}
              icon={<CheckmarkIcon />}
            />
          </EditActions>
        </InputBottom>
      </InputWrapper>
    )
  }
)

EditGrid.displayName = 'EditGrid'
