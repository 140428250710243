import { createAuthorizationParams, GrantType } from '@native-chats-api/auth-api/constants'
import { nativeChatsAuthApi } from '@native-chats-api/auth-api/instance'
import qs from 'qs'

export interface Credentials {
  email: string
  password: string
}

export const logInWithCredentials = async (credentials: Credentials) => {
  return nativeChatsAuthApi.post(
    '/connect/token',
    qs.stringify(
      createAuthorizationParams(GrantType.Password, {
        username: credentials.email,
        password: credentials.password,
      })
    ),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-DeviceID': 'web',
      },
    }
  )
}
