import { UsersResponse } from '@api/account/generated'
import { accountApi } from '@api/account/instance'

export async function getUsers(
  pageNumber: number,
  sortBy: string = 'name',
  sortDesc: boolean = false
): Promise<UsersResponse> {
  const { data } = await accountApi.get<UsersResponse>('/users', {
    params: {
      sortBy,
      sortDesc,
      pageNumber: pageNumber,
    },
  })
  return data
}

export const getAllUsers = async (): Promise<UsersResponse[]> => {
  let allUsers: UsersResponse[] = []
  let currentPage = 1
  let totalPages = 1

  while (currentPage <= totalPages) {
    const response = await getUsers(currentPage)
    if (response) {
      allUsers.push(response)
      totalPages = response.pages || 1
    }
    currentPage += 1
  }

  return allUsers
}
