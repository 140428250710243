import { useGetMe } from '@api/account/hooks/use-get-me'
import { NetworkMode } from '@api/enums'
import { useAddMessageReaction } from '@api/messages/hooks/use-add-message-reaction'
import { Reaction } from '@api/messages/types/message-detail'
import { useHasUserReacted } from '@hooks/use-has-user-reacted'
import { EmojiClickData } from 'emoji-picker-react'
import { Dispatch, SetStateAction } from 'react'

interface OnEmojiClickArgs {
  messageId?: string
  reactions?: Reaction[]
  setMessageActionsShow?: Dispatch<SetStateAction<boolean>>
}

export const useEmojiClickReaction = ({
  messageId,
  reactions,
  setMessageActionsShow,
}: OnEmojiClickArgs) => {
  const { meData } = useGetMe(false, NetworkMode.OFFLINE_FIRST)
  const haveIReactedTheEmoji = useHasUserReacted(meData)
  const { mutate: addMessageReaction } = useAddMessageReaction()

  const onEmojiClick = (emojiClickData: EmojiClickData, _: MouseEvent, onClose?: VoidFunction) => {
    const { emoji } = emojiClickData
    const findIfWasAdded = reactions?.find((reaction) => reaction.reactionCode === emoji)
    if (findIfWasAdded && haveIReactedTheEmoji(findIfWasAdded)) {
      onClose && onClose()
      return
    }

    if (messageId) addMessageReaction({ reactionCode: emoji, messageId })
    if (onClose) {
      onClose()
    }

    if (setMessageActionsShow) setMessageActionsShow(false)
  }

  return { onEmojiClick, haveIReactedTheEmoji, addMessageReaction, meData }
}
