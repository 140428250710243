import { UpdateTranslationLanguageInput } from '@api/messages/update-translation-language-flow'
import { useAppDispatch } from '@app/flow/hooks'
import { useMutation } from '@tanstack/react-query'
import { chatApi } from 'src/features/chats/repositories/chat-service'
import { updateMessageTranslation } from 'src/features/conversation/slice'
import { MessageDetail } from '../types/message-detail'

export type ExtendedUpdateTranslationLanguageInput = UpdateTranslationLanguageInput & {
  clientMessageId: string
  chatId: string
}

export const useUpdateTranslationLanguage = () => {
  const dispatch = useAppDispatch()

  return useMutation<MessageDetail, Error, ExtendedUpdateTranslationLanguageInput>(
    ({ messageId, languageCode }) => chatApi.updateTranslationLanguage({ messageId, languageCode }),
    {
      onSuccess: ({ clientMessageId, messageId, chatId, translations }) => {
        dispatch(
          updateMessageTranslation({
            clientMessageId,
            messageId: messageId || '',
            chatId,
            translations,
          })
        )
      },
    }
  )
}
