import { MessageDetailDto } from '@api/chats/generated'
import { useMutation } from '@tanstack/react-query'
import { chatApi } from 'src/features/chats/repositories/chat-service'
import { EditMessageParams } from '../edit-message'

export interface EditData extends EditMessageParams {
  clientMessageId: string
  newText: string
  oldText: string
  chatId: string
}

interface UseEditMessageArgs {
  onSuccess?: (data: MessageDetailDto, payload: EditData) => void
  onError?: (error: unknown, data: EditData) => void
  onMutate?: (data: EditData) => void
}

export const useEditMessage = ({ onSuccess, onError, onMutate }: UseEditMessageArgs) => {
  const { mutate, isSuccess, isLoading } = useMutation<MessageDetailDto, Error, EditData>({
    mutationFn: ({ messageId, newText }) => chatApi.editMessage({ messageId, newText }),
    onMutate,
    onSuccess,
    onError,
  })

  return { mutate, isSuccess, isLoading }
}
