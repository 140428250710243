import { debounce } from 'lodash'
import React, { KeyboardEventHandler, useState, FC, useRef, useMemo, useCallback } from 'react'
import { InputActionMeta, StylesConfig } from 'react-select'
import Creatable from 'react-select/async-creatable'
import { useTheme } from 'styled-components'
import { Container, InfoIcon, Text } from 'ui'
import { InputLabel } from 'ui/src/components/input/text-field/labels'
import { ClearIndicator } from './clear-indicator'
import { ErrorMessage } from './error-message'
import { LoadingIndicator } from './loading-indicator'
import { MultiValueRemove } from './multivalue-remove'
import { CreatableSelectProps, CreatableOptionType } from './types'
import { ValueContainer } from './value-container'

const createOptionItem = (label: string, isNewValueDuplicated = false) => ({
  label,
  value: label,
  isDuplicated: isNewValueDuplicated,
})

export const CreatableSelect: FC<CreatableSelectProps> = ({
  label,
  onChange,
  placeholder,
  helperText,
  onInputChangeCallback,
  inputValues = [],
  disabled,
  values,
  isLoading = false,
  minHeight,
  ...spacing
}) => {
  const [inputValue, setInputValue] = useState<string>('')
  const appTheme = useTheme()

  const isNewValueDuplicated = useMemo(
    () => inputValues.includes(inputValue),
    [inputValues, inputValue]
  )

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  const isEmail = (value: string) => emailRegex.test(value)
  const creatableRef = useRef<any>(null)

  const createOption = useCallback(() => {
    if (!inputValue) return
    const option = createOptionItem(inputValue, isNewValueDuplicated)
    const isArray = Array.isArray(values)
    const isDuplicated = isArray
      ? values.some((value) => value.value === option.value)
      : values === option

    if (isDuplicated) return

    const newValue = isArray ? [...values, option] : option
    onChange(newValue)
    setInputValue('')
    onInputChangeCallback?.('')

    if (creatableRef.current) {
      const menuElement = creatableRef.current.controlRef
      menuElement.scrollTop = menuElement.scrollHeight
    }
  }, [inputValue, isNewValueDuplicated, values])

  // Add icon in label if the value is duplicated (is already existing)
  function formatOptionLabel(option: CreatableOptionType) {
    if (option.isDuplicated || !isEmail(option.value)) {
      return (
        <Container display="flex" alignItems="center">
          <InfoIcon fill={appTheme.colors.red} />
          <Text ml="0.8rem">{option.label}</Text>
        </Container>
      )
    }
    return <Text>{option.label}</Text>
  }

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return

    switch (event.code) {
      case 'Space':
      case 'Enter':
      case 'Tab':
      case 'Comma':
        event.preventDefault()
        if (!isLoading) {
          createOption()
        }
        break

      default:
        break
    }
  }

  const debouncedInputChange = useCallback(
    debounce((value: string) => {
      onInputChangeCallback?.(value)
    }, 500),
    []
  )

  const handleInputChange = (inputValue: string, action: InputActionMeta) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      setInputValue(inputValue)
      debouncedInputChange(inputValue)
    }
  }

  const creatableSelectStyles: StylesConfig<CreatableOptionType, false> = {
    multiValue: (base, { data }) => ({
      ...base,
      backgroundColor:
        data.isDuplicated || !isEmail(data.value)
          ? appTheme.colors.redLighter
          : appTheme.colors.background,
      borderRadius: '0.8rem',
      margin: `0.4rem 0.8rem 0.4rem 0`,
      padding: '0.4rem 1.2rem',
      height: '3.2rem',
      display: 'flex',
      alignItems: 'center',
    }),
    multiValueLabel: (base) => ({
      ...base,
      fontSize: '1.5rem',
    }),
    multiValueRemove: (base) => ({
      ...base,
      ':hover': {
        cursor: 'pointer',
        backgroundColor: 'transparent',
      },
    }),
    indicatorsContainer: (base) => ({
      ...base,
      padding: 0,
      width: '4rem',
      height: '4rem',
      alignSelf: 'flex-start',
    }),
    clearIndicator: (base) => ({
      ...base,
      ':hover': {
        cursor: 'pointer',
      },
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0.8rem 1.2rem',
    }),
    container: (base) => ({
      ...base,
      fontSize: '1.5rem',
    }),
    placeholder: (base) => ({
      ...base,
      color: appTheme.colors.greyDark,
    }),
    control: (base) => ({
      ...base,
      'minHeight': minHeight || '8.8rem',
      'alignItems': 'flex-start',
      'maxHeight': '17.6rem',
      'boxShadow': 'none',
      'backgroundColor': 'transparent',
      'borderColor': appTheme.colors.grey,
      'borderRadius': '0.8rem',
      'overflowY': 'auto',
      'paddingBottom': '4rem',
      ':hover': {
        borderColor: appTheme.colors.grey,
      },
      ':focused': {
        borderColor: appTheme.colors.primary,
      },
      '::-webkit-scrollbar': {
        display: 'none',
      },
    }),
  }

  return (
    <Container display="flex" flexDirection="column" position="relative" {...spacing}>
      <InputLabel fontSize="1.2rem" labelText={label} />
      <Creatable
        ref={creatableRef}
        inputValue={inputValue}
        styles={creatableSelectStyles}
        isClearable
        isMulti={true}
        menuIsOpen={false}
        isLoading={isLoading}
        onChange={(newValue) => {
          onChange(newValue as CreatableOptionType)
        }}
        onInputChange={(newValue, action) => handleInputChange(newValue, action)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        isDisabled={disabled}
        value={values}
        components={{
          Menu: undefined,
          IndicatorSeparator: undefined,
          DropdownIndicator: undefined,
          MultiValueRemove,
          ValueContainer,
          ClearIndicator,
          LoadingIndicator,
        }}
        formatOptionLabel={formatOptionLabel}
      />
      <ErrorMessage helperText={helperText} />
    </Container>
  )
}
