import React, { FC } from 'react'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { Container } from 'ui'
import { Item } from './item'

export const ChatsSkeleton: FC<SpaceProps> = ({ ...spacing }) => {
  const items = new Array(7).fill(null)
  return (
    <Container p="0.4rem 1.6rem">
      <ListContainer {...spacing}>
        {items.length > 0 && (
          <Container pb="1.6rem">
            {items.map((_, index) => (
              <Item key={index} />
            ))}
          </Container>
        )}
      </ListContainer>
    </Container>
  )
}

const ListContainer = styled.div`
  &:not(:last-of-type) {
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.greyLight};
  }
  ${space}
`
