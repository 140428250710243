import React from 'react'

export const VerticalMenuIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14 19a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm0-7a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-2-5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
