import React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, RoundedContainer } from 'ui'
import { ContactsLayout } from '../features/chats-contacts/components/contacts-layout'

export const UsersNfc = () => {
  const { t } = useTranslation('teammates')

  return (
    <RoundedContainer
      display="flex"
      flexDirection="column"
      p="2rem 2rem 0"
      height="100%"
      flex="1 1 auto"
      overflow="hidden"
    >
      <Heading mb="1.6rem">{t('pageTitle')}</Heading>
      <ContactsLayout />
    </RoundedContainer>
  )
}
