import React from 'react'
import { SVGProps } from 'react'

export const DashIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="12"
      height="2"
      viewBox="0 0 12 2"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 .25A.75.75 0 0 0 .25 1a.75.75 0 0 0 .75.75h10a.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75Z" />
    </svg>
  )
}
