import { useGetMe } from '@api/account/hooks/use-get-me'
import { User } from '@api/account/types/user'
import { useAppSelector } from '@app/flow/hooks'
import { EmptyChannelMembers } from '@modules/empty-channel-members'
import { EmptyChannelMessages } from '@modules/empty-channel-messages'
import { EmptyChatMessages } from '@modules/empty-chat-messages'
import { CHANNEL_TYPES } from '@utils/get-chat-type'
import React from 'react'
import { FC } from 'react'
import { ScrollContainer } from 'ui'
import { ConversationType } from '../../../../../conversations/model/conversation-type'
import { selectUsers } from '../../../../../teammates/api/model/entity-adapter'

export interface ConversationDetails {
  type: ConversationType
  name?: string
  id?: string
}

interface EmptyConversationBodyProps {
  users: User[]
  conversation: ConversationDetails
}

export const EmptyConversationBody: FC<EmptyConversationBodyProps> = ({ conversation, users }) => {
  const { id, name, type } = conversation
  const { meData } = useGetMe()
  const usersToAdd = useAppSelector(({ users }) =>
    selectUsers(users).filter((user) => user.userId !== meData?.userId)
  )
  const renderEmptyContent = (users: User[], type: ConversationType) => {
    // Chat with members and 0 messages
    if (type === ConversationType.CHAT && users.length > 0) {
      return <EmptyChatMessages users={users} mt="auto" />
    }
    // Channels with members and 0 messages
    if (CHANNEL_TYPES.includes(type)) {
      if (users.length > 1) {
        return <EmptyChannelMessages name={name} mt="auto" />
      }
      // Channels with no members (except me) and 0 messages
      return (
        <EmptyChannelMembers
          name={name}
          id={id}
          m="auto"
          maxWidth="44rem"
          usersToAdd={usersToAdd}
        />
      )
    }

    return null
  }

  return (
    <ScrollContainer display="flex" hideScrollbar>
      {renderEmptyContent(users, type)}
    </ScrollContainer>
  )
}
