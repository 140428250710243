import React, { SVGProps, FC } from 'react'

export const FileIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
    <path d="M7 2a3.001 3.001 0 0 0-3 3v14a3.001 3.001 0 0 0 3 3h10a3.001 3.001 0 0 0 3-3V8a1 1 0 0 0-.293-.707l-5-5A1 1 0 0 0 14 2Zm0 2h6.586L18 8.414V19a.999.999 0 0 1-1 1H7a.999.999 0 0 1-1-1V5a.999.999 0 0 1 1-1Z" />
  </svg>
)
