import { chatsApi } from './instance'
import { Channel } from './types/channel'

export interface GetChannelsParams {
  search?: string
}

export const getChannels = async ({ search }: GetChannelsParams) => {
  const { data } = await chatsApi.get<Channel[]>('/channels', {
    params: {
      search,
    },
  })
  return data
}
