import React, { FC, useMemo } from 'react'
import { Container, ErrorMessageProps, Text } from 'ui'

export const ErrorMessage: FC<ErrorMessageProps> = ({ helperText }) => {
  // In case of multiple same error, it shows the error only once
  const showOnlyUnique = (
    value: string | undefined,
    index: number,
    self: (string | undefined)[]
  ) => {
    return self.indexOf(value) === index
  }

  const generatedHelperText = useMemo(() => {
    const uniqueErrors = Array.isArray(helperText)
      ? helperText
          .map((errorMessage) => errorMessage?.isDuplicated || errorMessage?.value)
          .filter(showOnlyUnique)
          .map((text, index) => (
            <Text key={index} fontSize="1.2rem" color="red">
              {text}
            </Text>
          ))
      : null

    return uniqueErrors
  }, [helperText])

  return (
    <Container
      position="absolute"
      backgroundColor="white"
      bottom="0.1rem"
      left="1.2rem"
      right="1.2rem"
      pb="0.7rem"
    >
      {generatedHelperText}
    </Container>
  )
}
