import { useAppDispatch } from '@app/flow/hooks'
import { useInfiniteQuery } from '@tanstack/react-query'
import { INT_32 } from '@utils/variables'
import { messagesService } from 'src/features/conversation/repository/messages-service'
import { setMessagesToConversation } from '../../../entities/messages/model/slice'
import { GetMessageListProps, GetMessageListResponse } from '../get-message-list'

export const MESSAGE_LIST = 'messageList'

export const useGetMessageList = ({ chatId, lastFetchedPage }: GetMessageListProps) => {
  const dispatch = useAppDispatch()
  const {
    data,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    isFetching,
  } = useInfiniteQuery<GetMessageListResponse, Error>({
    queryKey: [MESSAGE_LIST, chatId],
    queryFn: ({ pageParam = lastFetchedPage ? lastFetchedPage : INT_32 }) =>
      messagesService.getMessagesList({ chatId, pageNumber: pageParam }),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!chatId,
    onSuccess: (res) => {
      const messages = res.pages.flatMap(({ messages }) => messages)
      dispatch(setMessagesToConversation({ chatId: chatId!, messages })) //marking chatId with ! because ts complains that chatId can be undefined, but in fact, if !chatId query will not be fired at all
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.currentPage > 1) return lastPage.currentPage - 1
      return
    },
  })
  const messages = (data?.pages || []).flatMap((page) => page.messages)
  //in case if the last page becomes larger
  const pages = (data?.pages || []).flatMap((page) => page.pages).sort()

  return {
    messages,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
    pages: pages[pages.length - 1],
  }
}
