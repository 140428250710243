import { ConversationType } from '@utils/get-chat-type'
import { TFunction } from 'next-i18next'
import * as Yup from 'yup'
import { CreateChannelValues } from '.'
import { channelDescLimits, channelNameLimits } from '../variables'

export const initialValues: CreateChannelValues = {
  name: '',
  desc: '',
  channelType: ConversationType.PUBLIC_CHANNEL,
}

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string()
      .required(
        t('errors.emptyField', { minLimit: channelNameLimits.min, maxLimit: channelNameLimits.max })
      )
      .max(channelNameLimits.max, t('errors.maxNameChars', { maxLimit: channelNameLimits.max })),
    desc: Yup.string().max(
      channelDescLimits.max,
      t('errors.maxDescChars', { maxLimit: channelDescLimits.max })
    ),
    channelType: Yup.string(),
  })
