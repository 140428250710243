import { HandleScreenParams } from './handle-screen-params'

// this coefficient is used to give more space to image to move
const FREEDOM_COEFFICIENT = 1.5

/*
 * handles the movement outside the screen
 * checks if edges of image are within the threshold
 */
export const handleOutOfScreen = ({
  imageHeight,
  imageWidth,
  screenWidth,
  screenHeight,
  newDy,
  newDx,
}: HandleScreenParams) => {
  const yThreshold = (imageHeight - screenHeight / 2) * FREEDOM_COEFFICIENT
  const xThreshold = (imageWidth - screenWidth / 2) * FREEDOM_COEFFICIENT
  const checkTop = newDy > yThreshold
  const checkLeft = newDx > xThreshold
  const checkBottom = newDy < -yThreshold
  const checkRight = newDx < -xThreshold

  return {
    checkTop,
    checkLeft,
    checkBottom,
    checkRight,
  }
}
