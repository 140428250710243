import React from 'react'

export const LogoMicrosoft: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg width="25" height="24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path d="M2.667 2h20v20h-20V2Z" fill="#F3F3F3" />
        <path d="M3.536 2.87h8.696v8.695H3.536V2.87Z" fill="#F35325" />
        <path d="M13.101 2.87h8.696v8.695h-8.696V2.87Z" fill="#81BC06" />
        <path d="M3.536 12.435h8.696v8.696H3.536v-8.696Z" fill="#05A6F0" />
        <path d="M13.101 12.435h8.696v8.696h-8.696v-8.696Z" fill="#FFBA08" />
      </g>
      <defs>
        <clipPath>
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
