import { GoogleLogin } from '@components/google-login'
import { MicrosoftLoginButton } from '@components/microsoft-login'
import { useAuth } from '@hooks/chats/use-auth'
import React, { FC } from 'react'
import AppleLogin from 'react-apple-login'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button, LogoApple } from 'ui'

export const SocialButtons: FC = () => {
  const { t } = useTranslation()

  const { appleLoginCallback } = useAuth()

  return (
    <ul>
      <ButtonItem>
        <GoogleLogin />
      </ButtonItem>
      <ButtonItem>
        <MicrosoftLoginButton label={t('socialButtons.continueWithMicrosoft')} />
      </ButtonItem>
      {process.env.APPLE_CLIENT_ID && (
        <ButtonItem>
          <AppleLogin
            clientId={process.env.APPLE_CLIENT_ID!}
            redirectURI={process.env.ENVIRONMENT!}
            usePopup={true}
            scope="openid email name"
            callback={async (data) => {
              await appleLoginCallback(data.authorization.id_token)
            }}
            render={(props) => {
              return (
                <Button
                  id="apple-link"
                  variant="secondary"
                  preFix={<LogoApple />}
                  onClick={props.onClick}
                  width="100%"
                  size="sm"
                >
                  {t('socialButtons.continueWithApple')}
                </Button>
              )
            }}
          />
        </ButtonItem>
      )}
    </ul>
  )
}

const ButtonItem = styled.li`
  &:not(:last-of-type) {
    margin-bottom: 1.2rem;
  }
`
