import React, { FC } from 'react'
import { SpaceProps } from 'styled-system'
import { Text } from 'ui'
import { DateDividerContainer } from './styled'

interface DateDiverProps extends SpaceProps {
  date: string
}

export const DateDivider: FC<DateDiverProps> = ({ date, ...spacing }) => {
  return (
    <DateDividerContainer {...spacing}>
      <Text variant="smallTextMedium">{date}</Text>
    </DateDividerContainer>
  )
}
