import { MessageDetail } from '@api/messages/types/message-detail'
import { MessageDetailDto } from '@native-chats-api/chats/generated'
import { mapMedia } from 'src/features/chats/repositories/helpers/convertChatsListResponseToConversationList'
import { mapReactions } from './map-reactions'
import { mapTranslations } from './map-translation'

export const mapMessageDetail = (messageDetail: MessageDetailDto): MessageDetail => ({
  clientMessageId: messageDetail.clientMessageId || '',
  chatId: messageDetail.chatId || '',
  isDeleted: messageDetail.isDeleted || false,
  createdBy: messageDetail.createdBy || '',
  createdAt: new Date(messageDetail.createdAt || ''),
  originalMessage: messageDetail.originalMessage || '',
  sourceLanguageCode: messageDetail.sourceLanguageCode || '',
  translations: mapTranslations(messageDetail.translations ?? []),
  reactions: mapReactions(messageDetail.reactions ?? []),
  messageId: messageDetail.messageId,
  editedAt: messageDetail.editedAt ? new Date(messageDetail.editedAt) : null,
  lastModifiedAt: messageDetail.lastModifiedAt ? new Date(messageDetail.lastModifiedAt) : undefined,
  isSending: false,
  isError: false,
  attachments: messageDetail.media ? messageDetail.media.map(mapMedia) : [],
})
