import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'ui'
import { ConfirmationModal } from '../confirmation'

interface DeleteMessageModalProps {
  onSubmit: VoidFunction
}

export const DeleteMessageModal: FC<DeleteMessageModalProps> = ({ onSubmit }) => {
  const { t } = useTranslation('chat')

  return (
    <ConfirmationModal
      title={t('modalDeleteMessage.title')}
      cancelButton={t('modalDeleteMessage.cancel')}
      submitButton={t('modalDeleteMessage.submit')}
      onSubmit={onSubmit}
    >
      <Text mb="4rem">{t('modalDeleteMessage.confirmation')}</Text>
    </ConfirmationModal>
  )
}
