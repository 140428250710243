export enum TokenType {
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',
}

export const accessTokenExpiresIn = 60 * 55 * 1000 // 55 minutes
export const refreshTokenExpiresIn = 30 * 24 * 60 * 60 * 1000 // 30 days

export const getExpiresDate = (expiresIn: number) => {
  return new Date(Date.now() + expiresIn)
}
