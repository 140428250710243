import { User } from '@api/account/types/user'
import { GeneralMessageProps } from '@components/message'
import React, { FC, MutableRefObject } from 'react'
import { ConversationDetails, EmptyConversationBody } from './ui/modules/empty-conversation-body'
import { MessageList } from './ui/modules/message-list'
import { ChatBodyContainer } from './ui/styles/styled'

export interface ConversationBodyProps {
  messages: GeneralMessageProps[]
  conversation: ConversationDetails
  users: User[]
  setIsInView: (c: boolean) => void
  setTriggerIsInView: (c: boolean) => void
  messageListRef?: MutableRefObject<HTMLDivElement | null>
  isLoading?: boolean
  isSenderShown?: boolean
  lastPageFetched?: boolean
}

export const ConversationBody: FC<ConversationBodyProps> = ({
  messages,
  conversation,
  users,
  setIsInView,
  messageListRef,
  setTriggerIsInView,
  isLoading,
  isSenderShown,
  lastPageFetched,
}) => {
  if (messages.length === 0 && !isLoading) {
    return <EmptyConversationBody conversation={conversation} users={users} />
  }

  return (
    <ChatBodyContainer ref={messageListRef} hideScrollbar>
      <MessageList
        lastPageFetched={lastPageFetched}
        isLoading={isLoading}
        setTriggerIsInView={setTriggerIsInView}
        setIsInView={setIsInView}
        messages={messages}
        isSenderShown={isSenderShown}
      />
    </ChatBodyContainer>
  )
}
