import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { Text } from 'ui'

export const MembersList = styled.ul`
  margin: 0 2rem;
  padding-bottom: 10rem;
`

export const MemberListItem = styled.li`
  position: relative;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.background};
  }
  &::after {
    content: '';
    position: absolute;
    left: -3.5rem;
    right: -3.5em;
    top: -0.1rem;
    bottom: -0.1rem;
    transition: background-color ${({ theme }) => theme.transitionTimes.short};
  }
  &:hover {
    &::after {
      background-color: ${({ theme }) => theme.colors.greyLight};
    }
  }
`

export const MembersTiles = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.6rem 0.9rem;
  margin: 0 1.6rem;
  padding-bottom: 10rem;
`

export const MemberTileItem = styled.a`
  display: flex;
  flex-direction: column;
`

export const InnerContainer = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
  height: 6rem;
`

export const MemberResultItem = styled.a`
  display: flex;
  padding: 0.8rem 1.2rem;
  transition: background ${({ theme }) => theme.transitionTimes.short};
  &:hover {
    background: ${({ theme }) => theme.colors.background};
  }
`
export const SwitchButton = styled.button<SpaceProps>`
  color: ${({ theme }) => theme.colors.greySuperDark};
  transition: color ${({ theme }) => theme.transitionTimes.short};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  ${space}
`
