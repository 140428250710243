import { Chat } from '@api/chats/types/chat'
import {
  ArrowBtn,
  ListContainer,
  ListHeaderContainer,
  ListHeaderIcon,
  ListHeaderText,
  ListItemButton,
  ListItemsContainer,
} from '@components/collapsible-list/styled'
import { ChatsSkeleton } from '@components/skeleton-items/chats-skeleton'
import { Disclosure } from '@headlessui/react'
import { useLeftSideBar } from '@hooks/use-left-side-bar'
import { routes } from '@routes/chats/routes'
import React, { forwardRef, ReactNode, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { SpaceProps } from 'styled-system'
import { ChevronUpIcon, Container } from 'ui'
import { ConversationItem } from './conversation-item'

interface ConversationListProps extends SpaceProps {
  title: string
  icon: JSX.Element
  myUserId: string
  conversations: Chat[]
  isNextPageLoading?: boolean
  isAllChats?: boolean
  children?: ReactNode
  isOpen?: boolean
}

export const ConversationList = forwardRef<HTMLDivElement, ConversationListProps>(
  (
    {
      title,
      icon,
      myUserId,
      conversations,
      children,
      isOpen = false,
      isNextPageLoading,
      isAllChats,
      ...spaceProps
    },
    ref
  ) => {
    const { setSelectedOption, selectedOption } = useLeftSideBar()
    const { chatId } = useParams()
    const navigate = useNavigate()
    const [hover, setHover] = useState(false)
    const theme = useTheme()

    const onConversationClick = (id: string) => navigate(`${routes.chats}/${id}`)

    useEffect(() => {
      setSelectedOption(chatId ?? null)
    }, [chatId, setSelectedOption])

    return (
      <ListContainer {...spaceProps}>
        <Disclosure defaultOpen={isOpen}>
          {({ open }) => (
            <>
              <Container
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mr="1.6rem"
                minHeight="2.8rem"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <Disclosure.Button>
                  <ListHeaderContainer>
                    <ListHeaderIcon>
                      {hover ? (
                        <ArrowBtn open={open}>
                          <ChevronUpIcon fill={theme.colors.greyDark} />
                        </ArrowBtn>
                      ) : (
                        <Container as="span" display="flex">
                          {icon}
                        </Container>
                      )}
                    </ListHeaderIcon>
                    <ListHeaderText variant="captionMedium">{title.toUpperCase()}</ListHeaderText>
                  </ListHeaderContainer>
                </Disclosure.Button>
                {hover && children && <>{children}</>}
              </Container>
              {conversations.length > 0 && (
                <ListItemsContainer open={open}>
                  {conversations.map((item, i) => (
                    <Disclosure.Panel
                      key={item.id}
                      ref={conversations.length === i + 1 && isAllChats ? ref : null}
                    >
                      <ListItemButton
                        isSelected={selectedOption === item.id}
                        onClick={() => onConversationClick(item.id)}
                        id={item.id}
                      >
                        <ConversationItem item={item} myUserId={myUserId} />
                      </ListItemButton>
                    </Disclosure.Panel>
                  ))}
                </ListItemsContainer>
              )}
              {isNextPageLoading && isAllChats && <ChatsSkeleton />}
            </>
          )}
        </Disclosure>
      </ListContainer>
    )
  }
)

ConversationList.displayName = 'ConversationList'
