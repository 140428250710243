import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Button, Container, TextField } from 'ui'
import { initialValues, ValidationMessages, validationSchema } from './data'

interface ResetPaswordFormProps {
  onSubmit: (value: string) => void
}

export const ResetPasswordForm: FC<ResetPaswordFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation('nc-login')

  const validationMessages: ValidationMessages = {
    enterEmail: t('errorMessages.enterEmail'),
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(validationMessages)}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        try {
          await onSubmit(values.email)
        } catch (error) {
          toast.error(`${t('toastMessages.somethingWentWrong')}`)
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => {
        const isDisabled = !isEmpty(errors) || isSubmitting || !values.email
        return (
          <Form>
            <Container width="100%">
              <TextField
                id="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                warning={Boolean(errors.email && touched.email)}
                helperText={errors.email}
                label={t('credentialsForm.labels.email')}
                placeholder={t('credentialsForm.placeholders.email')}
              />
              <Button
                type="submit"
                width="100%"
                variant="primary"
                mt="2.4rem"
                disabled={isDisabled}
              >
                {t('resetPswdForm.submit')}
              </Button>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}
