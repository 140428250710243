import { CHAT_LIST } from '@api/chats/query-keys'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { chatApi } from 'src/features/chats/repositories/chat-service'

export const useAddUsersToChannel = (chatId: string) => {
  const queryClient = useQueryClient()
  const { data, mutate, mutateAsync, isLoading, isSuccess } = useMutation({
    mutationFn: async (userIds: string[]) => chatApi.addUsersToChat({ chatId, userIds }),
    onSuccess: () => {
      return queryClient.invalidateQueries([CHAT_LIST, chatId])
    },
  })

  return { data, mutate, mutateAsync, isLoading, isSuccess }
}
