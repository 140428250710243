import { numberRegexp, upperLowerCaseRegexp } from '@modules/forms/variables'
import { CheckList, Rule } from '@modules/native-chats/check-list'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import React, { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Button, TextField } from 'ui'
import { initialValues, validationSchema } from './data'

const minCharsAmout = 8

export const NewPasswordForm: FC = () => {
  const { t } = useTranslation('nc-login')

  const initialRules = [
    {
      rule: Rule.CharsCount,
      label: t('newPswdForm.validationRules.charsNumber', { amount: minCharsAmout }),
      isChecked: false,
    },
    {
      rule: Rule.Register,
      label: t('newPswdForm.validationRules.upperLowerCase'),
      isChecked: false,
    },
    { rule: Rule.Number, label: t('newPswdForm.validationRules.number'), isChecked: false },
  ]

  const [rules, setRules] = useState(initialRules)

  const validateRules = (password: string) => {
    const updatedRules = initialRules.map((rule) => {
      switch (rule.rule) {
        case Rule.CharsCount:
          return { ...rule, isChecked: password.length >= 8 }
        case Rule.Register:
          return { ...rule, isChecked: upperLowerCaseRegexp.test(password) }
        case Rule.Number:
          return { ...rule, isChecked: numberRegexp.test(password) }
        default:
          return rule
      }
    })
    setRules(updatedRules)
  }

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    validateRules(value)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        try {
          // add submit logic here
        } catch (error) {
          toast.error(`${t('toastMessages.somethingWentWrong')}`)
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ values, errors, handleChange, handleBlur, isSubmitting }) => {
        const isDisabled = !isEmpty(errors) || isSubmitting || !values.password

        return (
          <Form>
            <TextField
              id="password"
              name="password"
              value={values.password}
              toggleVisibility
              onChange={(e) => {
                handleChange(e)
                handlePasswordChange(e)
              }}
              onBlur={handleBlur}
              label={t('newPswdForm.label')}
              placeholder={t('newPswdForm.placeholder')}
              mb="1.2rem"
            />
            <CheckList rules={rules} />
            <Button type="submit" width="100%" variant="primary" mt="2.4rem" disabled={isDisabled}>
              {t('newPswdForm.submit')}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}
