import { LanguageParam } from '@api/account/set-personal-languages'
import { UserLanguage } from '@api/account/types/language'
import { PatchMeArgs } from '@api/account/types/patch-me-args'
import { PersonalProfile } from '@api/account/types/personal-profile'
import { LanguagesService } from '@native-chats-api/accounts-v2/generated'
import { MeService } from '@native-chats-api/accounts/generated'
import { convertPersonalProfile } from './helpers/convert-personal-profile'
import { UserRepository } from './user-repository'

export class NativeChatsRepository implements UserRepository {
  async getMyProfile(): Promise<PersonalProfile> {
    const user = await MeService.getApiV1Me()
    return convertPersonalProfile(user)
  }

  async updateMyProfile(args: PatchMeArgs): Promise<PersonalProfile> {
    const response = await MeService.patchApiV1Me(args)
    return convertPersonalProfile(response)
  }

  async getSupportedLanguages() {
    return LanguagesService.getV2Languages() as Promise<{ languages: UserLanguage[] }>
  }

  async setPersonalLanguages(languages: LanguageParam[]) {
    MeService.postApiV1MeLanguage({ languages })
  }
}
