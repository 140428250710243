import { useGetMe } from '@api/account/hooks/use-get-me'
import { NetworkMode } from '@api/enums'
import { useAddMessageReaction } from '@api/messages/hooks/use-add-message-reaction'
import { Reaction } from '@api/messages/types/message-detail'
import { useHasUserReacted } from '@hooks/use-has-user-reacted'
import { LimitModal } from '@modules/modals/limit'
import { EmojiClickData } from 'emoji-picker-react'
import { TFunction } from 'i18next'
import React, { Dispatch, SetStateAction } from 'react'
import { useModal } from 'ui'

interface OnEmojiClickArgs {
  t: TFunction
  messageId?: string
  reactions?: Reaction[]
  reactionCount?: number
  setMessageActionsShow?: Dispatch<SetStateAction<boolean>>
}

export const useEmojiClickReaction = ({
  t,
  messageId,
  reactions,
  reactionCount,
  setMessageActionsShow,
}: OnEmojiClickArgs) => {
  const { meData } = useGetMe(false, NetworkMode.OFFLINE_FIRST)
  const haveIReactedTheEmoji = useHasUserReacted(meData)
  const { openModal } = useModal()
  const { mutate: addMessageReaction } = useAddMessageReaction()

  const onEmojiClick = (emojiClickData: EmojiClickData, _: MouseEvent, onClose?: VoidFunction) => {
    const { emoji } = emojiClickData
    const findIfWasAdded = reactions?.find((reaction) => reaction.reactionCode === emoji)
    if (findIfWasAdded && haveIReactedTheEmoji(findIfWasAdded)) {
      onClose && onClose()
      return
    }

    if (reactionCount && reactionCount >= 20) {
      openModal({
        content: (
          <LimitModal
            title={t('modalReactions.title')}
            text={t('modalReactions.subtitle', { reactionsNumber: reactionCount })}
            buttonLabel={t('modalReactions.submitButton')}
          />
        ),
      })
      return
    }
    if (messageId) addMessageReaction({ reactionCode: emoji, messageId })
    if (onClose) {
      onClose()
    }

    if (setMessageActionsShow) setMessageActionsShow(false)
  }

  return { onEmojiClick, haveIReactedTheEmoji, addMessageReaction, meData }
}
