import { UpdateTranslationPreferenceInput } from '@api/messages/update-translation-preference-flow'
import { useAppDispatch } from '@app/flow/hooks'
import { useMutation } from '@tanstack/react-query'
import { chatApi } from 'src/features/chats/repositories/chat-service'
import { updateTranslationPreferences } from 'src/features/conversation/slice'
import { Message } from '../types/message'

export type ExtendedUpdateTranslationPreferenceInput = UpdateTranslationPreferenceInput & {
  clientMessageId: string
  chatId: string
}

export const useUpdateTranslationPreference = () => {
  const dispatch = useAppDispatch()

  return useMutation<Message, Error, ExtendedUpdateTranslationPreferenceInput>(
    ({ languageCode, provider, messageId }) =>
      chatApi.updateTranslationPreference({ messageId, languageCode, provider }),
    {
      onMutate: async ({ clientMessageId, chatId, languageCode, provider }) => {
        dispatch(
          updateTranslationPreferences({
            clientMessageId,
            chatId,
            languageCode,
            provider,
          })
        )
      },
    }
  )
}
