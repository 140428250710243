import React, { FC } from 'react'
import styled from 'styled-components'
import { LayoutProps, SpaceProps } from 'styled-system'
import { Container, Heading } from 'ui'

export interface SidepageHeaderProps {
  title: string
  preFix?: React.ReactNode
  postFix?: React.ReactNode
}

export type GeneralSidepageHeaderProps = LayoutProps & SpaceProps & SidepageHeaderProps

export const SidepageHeader: FC<GeneralSidepageHeaderProps> = ({
  title,
  preFix,
  postFix,
  ...spacing
}) => {
  return (
    <SidepageHeaderContainer {...spacing}>
      {preFix && (
        <IconContainer as="span" mr="0.4rem">
          {preFix}
        </IconContainer>
      )}
      <Heading as="h2" variant="h2">
        {title}
      </Heading>
      {postFix && (
        <IconContainer as="span" ml="0.4rem">
          {postFix}
        </IconContainer>
      )}
    </SidepageHeaderContainer>
  )
}

const IconContainer = styled(Container)`
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SidepageHeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  max-width: 26rem;
`
