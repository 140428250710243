import styled from 'styled-components'

export const ConversationHeaderButton = styled.button`
  display: flex;
  align-items: center;
  text-align: left;
  & > * + * {
    margin-left: 1.2rem;
  }
`

export const IconContainer = styled.span`
  display: block;
  width: 2.4rem;
  height: 2.4rem;
`
