import { useInterfaceLanguage } from '@hooks/use-interface-language'
import { Language } from '@utils/locales'
import React, { FC } from 'react'
import styled from 'styled-components'
import { Container, Text } from 'ui'
import { getLocaleDate } from '../../shared/lib/get-locale-date'

interface MessageInfoProps {
  name: string
  lastModifiedAt: Date | string
  createdBy: string
  lastMessage?: string
}

export const MessageInfo: FC<MessageInfoProps> = ({
  name,
  lastModifiedAt,
  lastMessage,
  createdBy,
}) => {
  const { interfaceLanguage } = useInterfaceLanguage()

  const lastModifiedDate = getLocaleDate(lastModifiedAt, interfaceLanguage.value as Language)

  return (
    <Container display="flex" justifyContent="space-between" width="100%">
      <Container display="flex" flexDirection="column" ml="1.2rem">
        <Text variant="textMedium">{name}</Text>
        <LastMessage variant="smallTextRegular" mt="0.4rem">
          {createdBy}: {lastMessage}
        </LastMessage>
      </Container>
      <Text variant="smallTextRegular" color="greyDark">
        {lastModifiedDate}
      </Text>
    </Container>
  )
}

const LastMessage = styled(Text)`
  white-space: nowrap;
  max-width: 60vw;
  overflow: hidden;
  text-overflow: ellipsis;
  &::first-letter {
    text-transform: capitalize;
  }
`
