import { FileType } from '@shared/types/file-type'
import { useEffect, useState } from 'react'

export const useVideoThumbnail = (file: File | undefined) => {
  const [thumbnail, setThumbnail] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!file || !file.type.startsWith(FileType.VIDEO)) {
      setThumbnail(null)
      setLoading(false)
      return
    }

    const generateThumbnail = (videoFile: File): Promise<string> => {
      const video = document.createElement('video')
      const canvas = document.createElement('canvas')

      return new Promise<string>((resolve, reject) => {
        video.src = URL.createObjectURL(videoFile)
        video.currentTime = 1

        video.onloadeddata = () => {
          const context = canvas.getContext('2d')
          if (context) {
            canvas.width = video.videoWidth
            canvas.height = video.videoHeight
            context.drawImage(video, 0, 0, canvas.width, canvas.height)
            const thumbnailData = canvas.toDataURL('image/jpeg')
            resolve(thumbnailData)
          } else {
            reject('Failed to generate thumbnail')
          }
        }

        video.onerror = () => {
          reject('Video loading error')
        }
      })
    }

    setLoading(true)
    generateThumbnail(file)
      .then((thumbnailSrc) => setThumbnail(thumbnailSrc))
      .catch(() => setThumbnail(null))
      .finally(() => setLoading(false))
  }, [file])

  return { thumbnail, loading }
}
