import { CreateChatsProps } from '@api/chats/create-chat'
import { Chat } from '@api/chats/types/chat'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ConversationType } from '@utils/get-chat-type'
import { CONVERSATIONS_QUERY_KEY } from 'src/entities/conversations/api/use-sync-and-get-conversations'
import { chatApi } from 'src/features/chats/repositories/chat-service'

interface UseCreateChatInput {
  onSuccessCallback?: CallableFunction
  onErrorCallback?: CallableFunction
}

export const useCreateChat = ({ onErrorCallback, onSuccessCallback }: UseCreateChatInput = {}) => {
  const queryClient = useQueryClient()
  const {
    data,
    isLoading: isCreateChatLoading,
    isError: isCreateChatError,
    isSuccess: isCreateChatSuccess,
    isIdle: isCreateChatIdle,
    mutateAsync: createChatAsync,
    mutate: createChat,
  } = useMutation<Chat, Error, CreateChatsProps>(
    ({ chatName, userIds }) =>
      chatApi.createChat({ chatName, conversationType: ConversationType.CHAT, userIds }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CONVERSATIONS_QUERY_KEY])
        onSuccessCallback?.()
      },
      onError: () => {
        onErrorCallback?.()
      },
    }
  )

  return {
    data,
    createChat,
    createChatAsync,
    isCreateChatLoading,
    isCreateChatSuccess,
    isCreateChatError,
    isCreateChatIdle,
  }
}
