import { useGetWorkspace } from '@api/workspaces/hooks/use-get-workspace'
import { useWorkspace } from '@hooks/use-workspace'
import { InviteWsMembersForm } from '@modules/forms/invite-ws-members'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Text } from 'ui'

export const InviteWsMembersModal: FC = () => {
  const { workspaceId } = useWorkspace()
  const { data: workspaceData } = useGetWorkspace({ id: workspaceId })
  const { t } = useTranslation('modal-invite-ws-members')

  if (!workspaceData) {
    return null
  }

  return (
    <>
      <Heading as="h2" mb="0.8rem">
        {t('title', { workspaceName: workspaceData.name })}
      </Heading>
      <Text mb="2.8rem">{t('inviteInfo')}</Text>
      <InviteWsMembersForm users={workspaceData.users} />
    </>
  )
}
