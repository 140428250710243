type AsyncOperation<T> = (item: T) => Promise<any>

export async function processArray<T>(
  array: T[],
  asyncOperation: AsyncOperation<T>
): Promise<any[]> {
  return await Promise.all(
    array.map(async (item) => {
      return await asyncOperation(item)
    })
  )
}
