import { useAddUsersToChannel } from '@api/chats/hooks/use-add-users'
import { useGetChatDetails } from '@api/chats/hooks/use-get-chat-details'
import { InviteToChannelSelect, InviteToChannelTextContent } from '@components/invite-select'
import { initialValues } from '@components/invite-select/data'
import { InviteChannelMembersValues } from '@components/invite-select/types'
import { InviteToChannelSkeleton } from '@modules/skeleton/skeleton-variants/invite-to-channel'
import { ConnectionScope } from '@native-chats-api/accounts/generated'
import { Form, Formik, FormikHelpers } from 'formik'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useGetContacts } from 'src/features/chats-contacts/hooks/get-contacts'
import { Button, Container, useModal } from 'ui'

interface AddChannelMembersFormProps {
  channelId: string
}

export const AddChannelMembersForm: FC<AddChannelMembersFormProps> = ({ channelId }) => {
  const { data: users, isLoading: isGetContactsLoading } = useGetContacts(ConnectionScope._0)
  const { mutateAsync, isLoading } = useAddUsersToChannel(channelId)
  const { data } = useGetChatDetails(channelId)
  const { closeModal } = useModal()
  const { t } = useTranslation(['modal-add-channel-members', 'common'])
  const formRef = React.useRef<HTMLFormElement>(null)

  const formSelectTexts: InviteToChannelTextContent = {
    loadingMsg: t('loadingMsg'),
    noResultsMsg: t('noResultsMsg'),
    inChannel: t('inChannel'),
  }

  const usersToAdd = useMemo(() => {
    const channelUsers = data?.chatUsers ?? []
    const usersIds = channelUsers.map(({ user }) => user.userId ?? '')
    return (
      users?.map((user) => ({
        id: user.userId,
        value: {
          firstName: user.firstName,
          lastName: user.lastName,
          fullName: `${user.firstName} ${user.lastName}`,
        },
        online: user.online,
        label: `${user.firstName} ${user.lastName}`,
        inChannel: usersIds.includes(user.userId),
        avatar: user.avatar ?? { mediaUri: '', mediaType: '', mediaSize: 0 },
        color: user.color ?? '',
      })) || []
    )
  }, [data, users])

  const handleSubmit = async (
    values: InviteChannelMembersValues,
    formikHelpers: FormikHelpers<InviteChannelMembersValues>
  ) => {
    const userIds = values.users.map(({ id }) => id)

    try {
      await mutateAsync(userIds)
      closeModal()
      formikHelpers.resetForm()
      toast.success(`${t('changesSaved', { ns: 'common' })}`)
    } catch (_error) {
      toast.error(`${t('errors.somethingWentWrong', { ns: 'common' })}`)
    }
  }

  return isGetContactsLoading ? (
    <InviteToChannelSkeleton />
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => handleSubmit(values, formikHelpers)}
      validateOnBlur
    >
      {({ setFieldValue, errors, values, isValid }) => (
        <Form ref={formRef}>
          <InviteToChannelSelect
            label={t('memberLabel')}
            loadOptions={usersToAdd}
            value={values.users}
            onChange={(val) => setFieldValue('users', val)}
            placeholder={t('memberPlaceholder')}
            mb="4.8rem"
            helperText={errors?.users as string}
            disabled={isLoading}
            texts={formSelectTexts}
          />
          <Container display="flex" flexGap="1.2rem">
            <Button
              variant="secondary"
              width="100%"
              onClick={closeModal}
              disabled={isLoading} // isSubmitting is not valid with mutate function in onSubmit handler
            >
              {t('cancelButton')}
            </Button>
            <Button
              type="submit"
              width="100%"
              disabled={isLoading || !isValid}
              loading={isLoading}
              loadText={t('submitButton')}
              animationSize={24}
            >
              {t('submitButton')}
            </Button>
          </Container>
        </Form>
      )}
    </Formik>
  )
}
