import React from 'react'
import styled from 'styled-components'
import { Container } from 'ui'
import { ConversationBodySkeleton } from './conversation-body-skeleton'
import { ConversationHeaderSkeleton } from './conversation-header-skeleton'
import { MessageInputSkeleton } from './message-input-skeleton'

export const ConversationSkeleton = () => {
  return (
    <Container position="relative" flex="1 1 auto" height="100%">
      <PageContainer>
        <ConversationHeaderSkeleton />
        <ConversationBodySkeleton />
        <MessageInputSkeleton style={{ marginTop: '3.2rem' }} />
      </PageContainer>
    </Container>
  )
}
const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`
