import React from 'react'
import { SVGProps } from 'react'

export const AttachIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.6 2a7.294 7.294 0 0 0-5.258 2.23 1 1 0 0 0-.006.008L2.287 12.66c-1.86 1.946-1.768 5.391.35 7.608 2.117 2.216 5.534 2.313 7.394.367l8.05-8.424c1.256-1.315 1.514-3.46.175-4.861-.67-.7-1.59-1.04-2.463-.973-.873.068-1.679.5-2.307 1.156L5.787 15.59a1 1 0 0 0 .031 1.414 1 1 0 0 0 1.414-.031l7.7-8.057c.338-.354.709-.521 1.015-.545.307-.024.567.05.864.361.592.62.5 1.389-.176 2.096a1 1 0 0 0 0 .002l-8.05 8.422c-.938.983-3.12 1.08-4.501-.365a1 1 0 0 0-.002 0c-1.382-1.447-1.29-3.86-.35-4.844l8.043-8.418C12.802 4.57 14.171 3.993 15.592 4c1.42.007 2.783.597 3.8 1.662C20.41 6.727 20.994 8.184 21 9.707a5.903 5.903 0 0 1-1.572 4.059l-4.543 4.754a1 1 0 0 0 .033 1.414 1 1 0 0 0 1.412-.034l4.55-4.76a1 1 0 0 0 .007-.005A7.896 7.896 0 0 0 23 9.697a7.888 7.888 0 0 0-2.162-5.416C19.457 2.836 17.57 2.01 15.6 2Z" />
    </svg>
  )
}
