type SortingOrder = 'asc' | 'desc'

export const sortByDate = <T,>(items: T[], key: string, order: SortingOrder) => {
  const isNestedPath = key.includes('.')
  const getTimeValue = (item: T): number => {
    const value = isNestedPath ? getNestedValue(item, key) : item[key as keyof T]
    return new Date(value as unknown as string).getTime()
  }

  return [...items].sort((a, b) => {
    const timeA = getTimeValue(a)
    const timeB = getTimeValue(b)

    return order === 'asc' ? timeA - timeB : timeB - timeA
  })
}

const getNestedValue = (obj: unknown, path: string): Date => {
  const value = path
    .split('.')
    .reduce((value, key) => (value ? value[key as keyof typeof value] : undefined), obj) as Date

  return new Date(value)
}
