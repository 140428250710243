import styled from 'styled-components'
import { css } from 'styled-components'
import { Button, Container, Text } from 'ui'
import { ListItemsProps } from '.'

export const PopperContainer = styled(Container)`
  width: 27rem;
  border: 0.1rem solid ${(props) => props.theme.colors.grey};
  border-radius: 0.8rem;
  background-color: ${(props) => props.theme.colors.white};
  overflow: hidden;
`

export const ListItem = styled.li<ListItemsProps>`
  /* Apply padding and border-top based on whether it's the first or last in the group */
  ${({ isFirstInGroup, isLastInGroup, theme }) => {
    const firstInGroupStyles = css`
      position: relative;
      padding-top: 0.3rem;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;
        background-color: ${theme.colors.greyLight};
      }
    `
    const lastInGroupStyles = css`
      padding-bottom: 0.3rem;
    `

    return css`
      ${isFirstInGroup && firstInGroupStyles}
      ${isLastInGroup && lastInGroupStyles}
    `
  }}
`

export const StyledButton = styled(Button)`
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
`

export const WorkspaceName = styled(Text)`
  color: ${({ theme }) => theme.colors.greyDark};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
