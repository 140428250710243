import { getImageDimensions } from './get-image-dimensions'
import { getVideoMeta } from './get-video-meta'

export const getMetaData = async (file: File) => {
  const basicMeta = {
    name: file.name,
    size: file.size,
    contentType: file.type,
  }
  if (file.type.includes('image')) {
    const { width, height } = await getImageDimensions(file)
    return { ...basicMeta, width, height: height }
  }
  if (file.type.includes('video')) {
    const { duration, width, height } = await getVideoMeta(file)
    return { ...basicMeta, durationInSeconds: duration, width, height }
  }
  return basicMeta
}
