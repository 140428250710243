import { Reaction } from '@api/messages/types/message-detail'
import { Action, ActionColor } from '@components/action'
import { EmojiPopover } from '@components/emoji-popover'
import { useEmojiClickReaction } from '@hooks/use-emoji-click-reaction'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { BorderRadiusProps, HeightProps, PositionProps, SpaceProps } from 'styled-system'
import { Container } from 'ui'

export interface QuickAction {
  actionName: string
  actionIcon: JSX.Element
  onActionClick?: VoidFunction
  isDisabled?: boolean
  isReaction?: boolean
  color?: ActionColor
}

interface QuickActionsProps extends PositionProps, SpaceProps, BorderRadiusProps, HeightProps {
  actions: QuickAction[]
  isSender?: boolean
  messageId?: string
  setIsHoveringOverQuickActions: Dispatch<SetStateAction<boolean>>
  reactions?: Reaction[]
  clientMessageId?: string
  setMessageActionsShow?: Dispatch<SetStateAction<boolean>>
  isReactionsLimit?: boolean
}

export const QuickActions: FC<QuickActionsProps> = ({
  actions,
  messageId,
  setIsHoveringOverQuickActions,
  reactions,
  isSender,
  clientMessageId,
  setMessageActionsShow,
  isReactionsLimit,
  ...stylesProps
}) => {
  const { t } = useTranslation('chat')
  const { onEmojiClick } = useEmojiClickReaction({
    messageId,
    reactions,
    setMessageActionsShow,
  })

  return (
    <ActionsContainer
      {...stylesProps}
      onMouseOver={() => setIsHoveringOverQuickActions(true)}
      onMouseOut={() => setIsHoveringOverQuickActions(false)}
    >
      {actions.map(({ actionIcon, actionName, color, onActionClick, isDisabled, isReaction }) => {
        if (isDisabled) return null

        if (!isReaction) {
          return (
            <Action
              icon={actionIcon}
              key={actionName}
              name={actionName}
              color={color}
              onClick={onActionClick}
            />
          )
        }
        return (
          <EmojiPopover
            setMessageActionsShow={setMessageActionsShow}
            messageId={clientMessageId}
            key={actionName}
            isReactionsLimit={isReactionsLimit}
            onEmojiClick={onEmojiClick}
          >
            <Action
              aria-label={actionName}
              icon={actionIcon}
              disabled={isReactionsLimit}
              color={color}
              tooltipText={isReactionsLimit ? t('tooltip.reactionsLimit') : undefined}
              component="span"
            />
          </EmojiPopover>
        )
      })}
    </ActionsContainer>
  )
}

const ActionsContainer = styled(Container)`
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  outline: ${({ theme }) => `1px solid ${theme.colors.grey}`};
  background-color: ${({ theme }) => theme.colors.white};
`
