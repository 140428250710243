import { ME } from '@api/account/tags'
import { User } from '@api/account/types/user'
import { useAppDispatch } from '@app/flow/hooks'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { handleReaction } from '../../../features/conversation/slice'
import { deleteMessageReaction, DeleteMessageReactionArgs } from '../delete-reaction'
import { Message } from '../types/message'
import { MESSAGE_LIST } from './use-get-message-list'

export const useDeleteMessageReaction = () => {
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const { chatId } = useParams()
  const { data, mutate, isSuccess, isLoading } = useMutation<
    Message,
    Error,
    DeleteMessageReactionArgs
  >(({ messageId, reactionCode }) => deleteMessageReaction({ messageId, reactionCode }), {
    onMutate: async ({ reactionCode, messageId }) => {
      await queryClient.cancelQueries([MESSAGE_LIST])
      const me = queryClient.getQueryData<User>([ME])
      if (!me || !chatId) return
      dispatch(
        handleReaction({
          messageId,
          chatId,
          reactionCode,
          meId: me.userId,
          added: false,
        })
      )
    },

    onSettled: () => {
      queryClient.invalidateQueries([MESSAGE_LIST])
    },
  })
  return { data, mutate, isSuccess, isLoading }
}
