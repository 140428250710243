import * as Yup from 'yup'

export const initialValues = {
  email: '',
  password: '',
}

export interface ValidationMessages {
  enterEmail: string
  incorrectEmailOrPassword: string
}

export const validationSchema: (validationMessages: ValidationMessages) => Yup.ObjectSchema<any> = (
  validationMessages
) =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .required(validationMessages.enterEmail)
      .email(validationMessages.enterEmail),
    password: Yup.string().trim().required(''), // No error should be shown
  })
