import React, { SVGProps, FC } from 'react'

export const DirectMessageIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7.5 8C3.922 8 1 10.922 1 14.5V21h10.5c3.578 0 6.5-2.922 6.5-6.5S15.078 8 11.5 8Zm0 2h4c2.497 0 4.5 2.003 4.5 4.5S13.997 19 11.5 19H3v-4.5C3 12.003 5.003 10 7.5 10Z" />
    <path d="M11.992 2a6.504 6.504 0 0 0-5.674 3.33l-.191.34a1 1 0 0 0 .385 1.361 1 1 0 0 0 1.361-.385l.19-.341A4.498 4.498 0 0 1 11.992 4H16.5C18.997 4 21 6.003 21 8.5V12h-.766a1 1 0 0 0-1 1 1 1 0 0 0 1 1H23V8.5C23 4.922 20.078 2 16.5 2Z" />
  </svg>
)
