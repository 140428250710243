interface ImageDimensions {
  width: number
  height: number
}

export const getImageDimensions = (fileBlob: Blob): Promise<ImageDimensions> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const image = new Image()
      image.onload = () => {
        resolve({ width: image.width, height: image.height })
      }
      image.onerror = () => {
        reject(new Error('Failed to load image'))
      }
      image.src = e.target?.result as string
    }
    reader.readAsDataURL(fileBlob)
  })
}
