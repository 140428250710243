import { useGetMe } from '@api/account/hooks/use-get-me'
import { useToggleMute } from '@api/chats/hooks/use-toggle-mute-chat'
import { useAppSelector } from '@app/flow/hooks'
import { IconButton } from '@components/icon-button'
import { useFormattedChatLanguages } from '@hooks/use-chat-languages'
import { useWorkspace } from '@hooks/use-workspace'
import { EditType } from '@modules/side-page/details'
import { routes } from '@routes/flow/routes'
import { getFeatureFlag } from '@utils/flags-manager'
import { isNativeChatsApp } from '@utils/is-native-chats-app'
import { format } from 'date-fns'
import React, { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Conversation } from 'src/entities/conversations/model/conversation'
import { ConversationType } from 'src/entities/conversations/model/conversation-type'
import { selectAllConversations } from 'src/entities/conversations/model/entity-adapter'
import { useGetUsers } from 'src/entities/teammates/api/hooks/use-get-users'
import { LeaveAndDeleteChannel } from 'src/features/leave-delete-channel'
import { Text, Container, EditIcon, ToggleButton, ScrollContainer } from 'ui'
import { ItemContainer } from './styled'

interface AboutProps {
  setEditType: Dispatch<SetStateAction<EditType | null>>
  conversation: Conversation
}

export const About: FC<AboutProps> = ({ setEditType, conversation }) => {
  const { conversationType, name, isMuted, description, chatUsers } = conversation
  const { workspaceId } = useWorkspace()
  const { users } = useGetUsers()
  const { meData } = useGetMe()
  const membersNumber = chatUsers.length
  const isChat = conversationType === ConversationType.CHAT
  const generalChannel = useAppSelector(({ conversations }) =>
    selectAllConversations(conversations).find(
      (c) => c.conversationType === ConversationType.GENERAL_CHANNEL
    )
  )
  const generalChannelUrl = `${routes.workspaces}/${workspaceId}/${routes.channels}/${generalChannel?.id}`

  const channelToBeDeleted =
    membersNumber === 1 && conversationType === ConversationType.PRIVATE_CHANNEL

  const canBeEdited = useMemo(() => {
    if (!meData) return
    const users = conversation?.chatUsers
    const myUser = users?.find(({ user }) => user.userId === meData.userId)
    const isMyUserInChat = !!myUser
    const isMyUserActiveInChat = !myUser?.leftChat

    return (
      isMyUserInChat &&
      isMyUserActiveInChat &&
      conversationType !== ConversationType.GENERAL_CHANNEL
    )
  }, [meData, conversation])

  const { t } = useTranslation([
    'details',
    'modal-delete-channel',
    'modal-leave-channel',
    'modal-leave-delete-channel',
  ])
  const toggleMute = useToggleMute()

  // TODO: EP seems to not work, see more https://nativechats.atlassian.net/browse/NAT-3163
  const conversationLanguages = useFormattedChatLanguages(conversation)

  const creator = useMemo(() => {
    return users?.find((user) => user.userId === conversation?.createdBy)
  }, [conversation])

  const createdStr = useMemo(() => {
    const creatorName = `${creator?.firstName} ${creator?.lastName}`
    const creationDate = format(new Date(conversation?.createdAt || new Date()), 'MMMM d, yyyy')

    if (creatorName) {
      return t('creationInfo', { userName: creatorName, date: creationDate })
    }
    return t('created', { date: creationDate })
  }, [conversation])

  // Modal texts
  const leaveChannelModalTexts = {
    title: t('title', { ns: 'modal-leave-channel' }),
    confirmation:
      conversation.conversationType === ConversationType.PRIVATE_CHANNEL
        ? t('confirmation-private', { ns: 'modal-leave-channel', name })
        : t('confirmation', { ns: 'modal-leave-channel', name }),
    buttonLabels: [
      t('cancelButton', { ns: 'modal-leave-channel' }),
      t('submitButton', { ns: 'modal-leave-channel' }),
    ],
    toastMessages: {
      success: t('toastMessages.yourChangesWereSaved', { ns: 'modal-leave-channel' }),
      error: t('toastMessages.yourChangesWerentSaved', { ns: 'modal-leave-channel' }),
    },
  }

  const deleteChannelModalTexts = {
    title: t('title', { ns: 'modal-delete-channel' }),
    confirmation: t('confirmation', { ns: 'modal-delete-channel', name }),
    name: conversation.name,
    membersCount: t('members', { ns: 'modal-delete-channel', count: membersNumber }),
    buttonLabels: [
      t('cancelButton', { ns: 'modal-delete-channel' }),
      t('submitButton', { ns: 'modal-delete-channel' }),
    ],
    toastMessages: {
      success: t('toastMessages.yourChangesWereSaved', { ns: 'modal-delete-channel' }),
      error: t('toastMessages.yourChangesWerentSaved', { ns: 'modal-delete-channel' }),
    },
  }

  const leaveAndDeleteChannelModalTexts = {
    title: t('title', { ns: 'modal-leave-delete-channel' }),
    confirmation: `${t('confirmation', { ns: 'modal-leave-delete-channel', name })}`,
    buttonLabels: [
      t('cancelButton', { ns: 'modal-leave-delete-channel' }),
      t('submitButton', { ns: 'modal-leave-delete-channel' }),
    ],
    toastMessages: {
      success: t('toastMessages.yourChangesWereSaved', { ns: 'modal-leave-delete-channel' }),
      error: t('toastMessages.yourChangesWerentSaved', { ns: 'modal-leave-delete-channel' }),
    },
  }

  return (
    <ScrollContainer
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      hideScrollbar
    >
      <Container>
        {!isChat && (
          <>
            <ItemContainer>
              <Text variant="textMedium" mb="0.4rem">
                {t('name')}
              </Text>
              <Container display="flex" justifyContent="space-between" alignItems="flex-end">
                <Text>{name}</Text>
                {canBeEdited && (
                  <IconButton onClick={() => setEditType(EditType.channelName)}>
                    <EditIcon aria-label={t('editName')} />
                  </IconButton>
                )}
              </Container>
            </ItemContainer>
            {description && (
              <ItemContainer>
                <Text variant="textMedium" mb="0.4rem">
                  {t('description')}
                </Text>
                <Container display="flex" justifyContent="space-between" alignItems="flex-end">
                  <Text color={!description ? 'greyDark' : 'default'}>
                    {description || 'Add description'}
                  </Text>
                  {getFeatureFlag('showOnProd') && (
                    <IconButton onClick={() => setEditType(EditType.channelDescription)} ml="auto">
                      <EditIcon aria-label={t('editDescription')} />
                    </IconButton>
                  )}
                </Container>
              </ItemContainer>
            )}
          </>
        )}
        {getFeatureFlag('showOnProd') && (
          <ItemContainer>
            <Text variant="textMedium" mb="0.4rem">
              {t('notifications')}
            </Text>
            <ToggleButton
              label={isChat ? t('muteChatToggle') : t('muteChannelToggle')}
              id="mute"
              checked={isMuted}
              onClick={toggleMute}
            />
          </ItemContainer>
        )}
        {!isNativeChatsApp() && (
          <ItemContainer>
            <Text variant="textMedium" mb="0.4rem">
              {t('teammatesLanguages')}
            </Text>
            <Text pr="2rem">{conversationLanguages}</Text>
          </ItemContainer>
        )}
        <ItemContainer>
          <Text variant="textMedium" mb="0.4rem">
            {t('created')}
          </Text>
          <Text pr="2rem">{createdStr}</Text>
        </ItemContainer>
      </Container>
      <LeaveAndDeleteChannel
        myUserId={meData?.userId!}
        conversation={conversation}
        redirectUrl={generalChannelUrl}
        deleteModalTexts={deleteChannelModalTexts}
        leaveModalTexts={
          channelToBeDeleted ? leaveAndDeleteChannelModalTexts : leaveChannelModalTexts
        }
        buttonLabels={{
          leave: t('leaveChannel', { ns: 'details' }),
          delete: t('deleteChannel', { ns: 'details' }),
        }}
      />
    </ScrollContainer>
  )
}
