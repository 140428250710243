import { MetadataType } from '@shared/types/meta-data'

export interface MimeType {
  readonly value: string
}

const MimeType = {
  // Images
  ImagePng: { value: 'image/png' } as MimeType,
  ImageJpeg: { value: 'image/jpeg' } as MimeType,
  ImageBmp: { value: 'image/bmp' } as MimeType,
  ImageGif: { value: 'image/gif' } as MimeType,

  // Documents
  ApplicationPdf: { value: 'application/pdf' } as MimeType,
  ApplicationMsWord: { value: 'application/msword' } as MimeType,
  ApplicationMsExcel: { value: 'application/msexcel' } as MimeType,
  ApplicationMsPowerPoint: { value: 'application/mspowerpoint' } as MimeType,
  TextPlain: { value: 'text/plain' } as MimeType,
  TextHtml: { value: 'text/html' } as MimeType,
  TextXml: { value: 'text/xml' } as MimeType,
  TextXhtml: { value: 'application/xhtml+xml' } as MimeType,
  ApplicationJson: { value: 'application/json' } as MimeType,

  // Video
  VideoMpeg: { value: 'video/mpeg' } as MimeType,
  VideoMp4: { value: 'video/mp4' } as MimeType,
  VideoQuicktime: { value: 'video/quicktime' } as MimeType,

  // Audio
  AudioMpeg: { value: 'audio/mpeg' } as MimeType,
  AudioWave: { value: 'audio/wav' } as MimeType,
  AudioFlac: { value: 'audio/flac' } as MimeType,
  AudioAac: { value: 'audio/aac' } as MimeType,
  AudioWma: { value: 'audio/wma' } as MimeType,
}

type MimeTypeValue = typeof MimeType[keyof typeof MimeType]['value']

export const processContentType = (contentType: MimeTypeValue) => {
  switch (contentType) {
    case MimeType.ImagePng.value:
    case MimeType.ImageJpeg.value:
    case MimeType.ImageBmp.value:
    case MimeType.ImageGif.value:
      // Handle image files
      return MetadataType.IMAGE
    case MimeType.ApplicationPdf.value:
    case MimeType.ApplicationMsWord.value:
    case MimeType.ApplicationMsExcel.value:
    case MimeType.ApplicationMsPowerPoint.value:
    case MimeType.TextPlain.value:
    case MimeType.TextHtml.value:
    case MimeType.TextXml.value:
    case MimeType.TextXhtml.value:
    case MimeType.ApplicationJson.value:
      // Handle document files
      return MetadataType.DOCUMENT
    case MimeType.VideoMpeg.value:
    case MimeType.VideoMp4.value:
    case MimeType.VideoQuicktime.value:
      // Handle video files
      return MetadataType.VIDEO
    case MimeType.AudioMpeg.value:
    case MimeType.AudioWave.value:
    case MimeType.AudioFlac.value:
    case MimeType.AudioAac.value:
    case MimeType.AudioWma.value:
      // Handle audio files
      return MetadataType.AUDIO
    default:
      return MetadataType.UNKNOWN
  }
}
