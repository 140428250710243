import { UserResponse } from '@api/account/generated'
import { USER_DETAIL } from '@api/account/hooks/use-get-user'
import { updateUserOnlineStatus } from '@api/account/signal-r-handler-helpers/update-user-online-status'
import { CHAT_LIST } from '@api/chats/query-keys'
import { Chat } from '@api/chats/types/chat'
import { ConversationList } from '@api/chats/types/chat-list'
import { ChatUser } from '@api/chats/types/chat-user'
import { AppDispatch, store } from '@app/flow/store'
import { QueryClient } from '@tanstack/react-query'
import { cloneDeep } from 'lodash'
import { USERS } from 'src/entities/teammates/api/hooks/use-get-users'
import { selectUser } from 'src/entities/teammates/api/model/entity-adapter'
import { setUser } from 'src/entities/teammates/api/model/slice'
import { User } from 'src/entities/teammates/api/model/user-type'
import { NATIVE_CHATS_CONTACTS_GET_CONTACTS } from '../../features/chats-contacts/hooks/get-contacts'
import { setChats } from '../../features/chats/slice'

export type Status = 1 | 0
type ActivityType = Status
type Online = {
  status: Status
  activityType: ActivityType
  lastOnline: Date
}

interface UserConnectionStatus {
  userId: string
  online: Online
}

export type PaginatedUsersCacheData = {
  pages: {
    currentPage: number
    pages: number
    totalTeammatesCount: number
    users: UserResponse[]
  }[]
  pageParams: any[]
}

export const handleConnectionStatusChanged =
  (_queryClient: QueryClient, dispatch: AppDispatch) => (signal: UserConnectionStatus) => {
    if (signal) {
      const { userId, online } = signal

      try {
        const state = store.getState().users
        const user = selectUser(state, userId)
        if (!user) return

        const updatedUser: User = {
          ...user,
          online: {
            ...user.online,
            status: online.status,
          },
        }

        dispatch(setUser(updatedUser))
      } catch (error) {
        // todo: add error sentry
        console.error('Failed to update user status:', error)
      }
    }
  }

export const nativeChatsHandleConnectionStatusChanged =
  (queryClient: QueryClient, dispatch: AppDispatch) =>
  (signal: { status: Status; userId: string }) => {
    if (signal) {
      try {
        queryClient.setQueryData(
          [USERS, USER_DETAIL, signal.userId],
          (oldData: UserResponse | undefined | null) => {
            if (!oldData) return
            return updateUserOnlineStatus(cloneDeep(oldData), signal.status)
          }
        )

        queryClient.setQueryData(
          [CHAT_LIST],
          (oldData: { pages: ConversationList[] } | undefined | null) => {
            if (!oldData) return
            // Function to update the user within a chat
            const updateUserInChat = (chatUser: ChatUser) => {
              const user = chatUser.user
              if (user.userId !== signal.userId) {
                return chatUser
              }
              return {
                ...chatUser,
                user: {
                  ...user,
                  online: {
                    status: signal.status,
                  },
                },
              }
            }

            const updateChatUsers = (chat: Chat) => ({
              ...chat,
              chatUsers: chat.chatUsers.map(updateUserInChat),
            })

            const updateChatsInPage = (page: ConversationList) => ({
              ...page,
              chats: page.chats.map(updateChatUsers),
            })

            const updatedPages = oldData.pages.map(updateChatsInPage)

            const chats: Chat[] = updatedPages.flatMap((page) => page.chats)

            dispatch(setChats(chats))

            return {
              ...oldData,
              pages: updatedPages,
            }
          }
        )

        queryClient.setQueryData(
          [NATIVE_CHATS_CONTACTS_GET_CONTACTS],
          (oldData: User[] | undefined | null) => {
            if (!oldData) return

            return oldData.map((user: User) => {
              if (user.userId !== signal.userId) {
                return user
              }
              return {
                ...user,
                online: {
                  ...user.online,
                  status: signal.status,
                },
              }
            })
          }
        )
      } catch (error) {
        // todo: add error sentry
        console.error(error)
      }
    }
  }
