import { Skeleton } from '@modules/skeleton'
import React from 'react'
import { Container } from 'ui'
import { ChatBodyContainer } from '../../styles/styled'

export const ConversationBodySkeleton = () => {
  return (
    <ChatBodyContainer>
      <Container position="relative">
        <Skeleton type="conversationBody" />
      </Container>
    </ChatBodyContainer>
  )
}
