import { LostConnectionBanner } from '@components/lost-connection-banner'
import { NotificationsBanner } from '@components/notifications-banner'
import { BannersProvider } from '@contexts/banners-provider'
import { FiltersProvider, teammatesInitialFilters } from '@contexts/filters-provider'
import { LeftSideBarProvider } from '@contexts/left-side-bar-provider'
import { SidePage } from '@layouts/main-layout/side-page'
import { MainContainer, ScreenContainer, SidebarContainer } from '@layouts/main-layout/styled'
import { Sidebar } from '@modules/native-chats/sidebar'
import React from 'react'
import { Outlet } from 'react-router-dom'

export const MainLayout = () => (
  <BannersProvider>
    <LostConnectionBanner />
    <NotificationsBanner />
    <MainContainer>
      <LeftSideBarProvider>
        <SidebarContainer>
          <Sidebar />
        </SidebarContainer>
      </LeftSideBarProvider>
      <FiltersProvider initialValues={teammatesInitialFilters}>
        <ScreenContainer>
          <Outlet />
        </ScreenContainer>
        <SidePage />
      </FiltersProvider>
    </MainContainer>
  </BannersProvider>
)
