import { DirectMessageIcon, UnreadIcon } from '@components/icons'
import { List } from '@components/skeleton-items/list'
import React from 'react'
import { Container } from 'ui'

export const SidebarChatsContentSkeleton = () => (
  <Container px="1.6rem">
    <List icon={<UnreadIcon />} itemsNumber={0} />
    <List icon={<DirectMessageIcon />} />
  </Container>
)
