import { NetworkMode } from '@api/enums'
import { useAppSelector } from '@app/flow/hooks'
import { useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { selectUsers } from '../model/entity-adapter'
import { setUsers } from '../model/slice'
import { User } from '../model/user-type'
import { teammatesService } from '../services/teammates-service'

export const USERS = 'users'
export const USER_LIST = 'usersList'

const useGetUsers = ({ networkMode = NetworkMode.ONLINE } = {}) => {
  const dispatch = useDispatch()
  const usersFromStore = useAppSelector(({ users }) => selectUsers(users))

  const { isLoading, isError } = useQuery<User[], Error>({
    queryKey: [USERS, USER_LIST],
    staleTime: Infinity,
    queryFn: () => teammatesService.getAllUsers(),
    refetchOnWindowFocus: false,
    networkMode: networkMode,
    onSuccess: (response) => {
      dispatch(setUsers(response))
    },
  })

  return {
    users: usersFromStore,
    isLoading,
    isError,
  }
}

export { useGetUsers }
