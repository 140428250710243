import { PageHeader } from '@modules/native-chats/page-header'
import { SocialButtons } from '@modules/native-chats/social-buttons'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ButtonLink, Container, Divider, Inner, Text } from 'ui'

export const Login: FC = () => {
  const { t } = useTranslation('nc-login')

  return (
    <Container display="flex" flexDirection="column" justifyContent="space-between" height="100%">
      <Container>
        <PageHeader title={t('welcome')} subtitle={t('welcomeMsg')} mb="4rem" />
        <Container maxWidth="40rem" width="100%" m="0 auto">
          <SocialButtons />
          <Divider label={t('or')} py="3.6rem" />
          <ButtonLink variant="tertiary" width="100%" href="login/credentials">
            {t('withEmail')}
          </ButtonLink>
        </Container>
      </Container>
      <Container as="footer" textAlign="center" mt="auto" pt="2rem">
        <GdrpText variant="smallTextRegular" maxWidth="36rem" m="0 auto">
          {t('gdpr.termsAndpolicy')}{' '}
          <ButtonLink href="https://native.tech/terms/" plain variant="secondary" size="sm" newTab>
            {t('gdpr.termsOfService')}
          </ButtonLink>{' '}
          {t('and')}{' '}
          <ButtonLink
            href="https://native.tech/privacy-policy/"
            plain
            variant="secondary"
            size="sm"
            newTab
          >
            {t('gdpr.privacyPolicy')}
          </ButtonLink>
        </GdrpText>
      </Container>
    </Container>
  )
}

const GdrpText = styled(Text)`
  ${Inner} {
    height: 1.8rem;
  }
`
