import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
    *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  #root {
    display:flex;
    flex-direction:column;
    height: 100%;
    white-space: pre-line;
  }

  html {
    scroll-behavior: smooth;
  }

  html,
  body {
    font-size: 62.5%;
    font-family: ${({ theme }) => theme.fonts.default};
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    height: 100%;
  }


  a {
    text-decoration: none;
    cursor: pointer;
  }

  ul,
  ul li {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style: none;
    list-style-type: none;

    &:last-child {
      margin-bottom: 0;
    }
  }

  button {
    border: none;
    background-color: inherit;
    cursor: pointer;
  }

  fieldset {
    border: none;
  }

  ::selection {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.yellow};
  }

  /* Change Chrome autocomplete bg color in inputs */
input:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
}

  /* Remove styles of input of type search */
  /* clears the ‘X’ from Internet Explorer */
  input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
  input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }

  /* clears the ‘X’ from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }
`
