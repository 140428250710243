import React, { FC } from 'react'
import { User } from 'src/entities/teammates/api/model/user-type'
import { Action } from 'src/features/teammate-actions'
import { ScrollableList } from 'src/shared/ui/modules/scrollable-list'
import { TeammatesListItem } from './teammates-list-item'

export interface TeammatesListProps {
  users: User[]
  createActions: (userId: string) => Action[]
  isUsersFetching?: boolean
  setIsTriggerInView?: (c: boolean) => void
  onUserClick?: (userId: string) => void
}

export const TeammatesList: FC<TeammatesListProps> = ({
  users,
  createActions,
  isUsersFetching = false,
  setIsTriggerInView,
  onUserClick,
}) => (
  <ScrollableList<User>
    items={users}
    renderItem={(user) => (
      <TeammatesListItem
        actions={createActions(user.userId)}
        onTeammateClick={onUserClick}
        {...user}
      />
    )}
    isFetching={isUsersFetching}
    skeletonType="teammatesList"
    setIsTriggerInView={setIsTriggerInView}
    hasHover
    mx="-2rem"
  />
)
