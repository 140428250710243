import styled from 'styled-components'
import { Container, Text } from 'ui'

export const DateDividerContainer = styled(Container)`
  display: flex;
  padding: 0.4rem 1.2rem;
  background: ${(props) => props.theme.colors.pinkLight};
  width: fit-content;
  border-radius: 2rem;
`
