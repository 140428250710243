import React from 'react'

export const ListIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM4 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM4 18a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM20.06 11H7.94a.94.94 0 0 0-.94.94v.12c0 .52.42.94.94.94h12.12c.52 0 .94-.42.94-.94v-.12a.94.94 0 0 0-.94-.94ZM20.06 16H7.94a.94.94 0 0 0-.94.94v.12c0 .52.42.94.94.94h12.12c.52 0 .94-.42.94-.94v-.12a.94.94 0 0 0-.94-.94ZM20.06 6H7.94a.94.94 0 0 0-.94.94v.12c0 .52.42.94.94.94h12.12c.52 0 .94-.42.94-.94v-.12a.94.94 0 0 0-.94-.94Z" />
    </svg>
  )
}
