import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Container } from 'ui'

export const SidebarHeaderSkeleton = () => (
  <Container display="flex" alignItems="center" flexGap="0.8rem" mb="1.6rem" px="1.6rem">
    <Skeleton height={44} width={44} />
    <Container flex={1}>
      <Container mb="0.3rem">
        <Skeleton height={12} />
      </Container>
      <Skeleton height={12} />
    </Container>
    <Skeleton height={36} width={36} borderRadius={12} />
  </Container>
)
