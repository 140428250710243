import { useContext } from 'react'
import { PopoverContext } from '../contexts/popover-provider'

interface UsePopoverReturn {
  isOpen: boolean
  openPopover: () => void
  closePopover: (onClose?: () => void) => void
}

export const usePopover = (popoverId: string): UsePopoverReturn => {
  const context = useContext(PopoverContext)
  if (!context) {
    throw new Error('usePopover must be used within a PopoverProvider')
  }

  const { activePopoverId, openPopover: contextOpenPopover, closePopover } = context

  const isOpen = activePopoverId === popoverId
  const openPopover = () => contextOpenPopover(popoverId)

  return {
    isOpen,
    openPopover,
    closePopover,
  }
}
