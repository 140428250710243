import { useGetMe } from '@api/account/hooks/use-get-me'
import { useDeleteChat } from '@api/chats/hooks/use-delete-chat'
import { useAppDispatch } from '@app/flow/hooks'
import { useSidePage } from '@hooks/use-side-page'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { leaveConversation } from 'src/entities/conversations/model/slice'
import { Text, useModal } from 'ui'
import { ConfirmationModal } from '../confirmation'

interface DeleteModalProps {
  chatId: string
  navigateFn: VoidFunction
}

export const DeleteChatModal: FC<DeleteModalProps> = ({ chatId, navigateFn }) => {
  const { closeModal } = useModal()
  const { setOpen } = useSidePage()
  const { t } = useTranslation('modal-delete-chat')
  const dispatch = useAppDispatch()
  const { meData } = useGetMe()

  const { mutate: deleteChat, isLoading: isDeleteChatLoading } = useDeleteChat({
    onSuccess: (_x, variables) => {
      dispatch(leaveConversation({ id: variables.chatId, myUserId: meData?.userId! }))
      onSuccessCallback()
    },
    onError: () => toast.error(`${t('toastMessages.yourChangesWerentSaved')}`),
  })

  const onSuccessCallback = () => {
    toast.success(`${t('toastMessages.yourChangesWereSaved')}`)
    closeModal()
    setOpen(false)
    navigateFn()
  }

  const handleOnSubmit = () => {
    deleteChat({ chatId })
  }

  return (
    <ConfirmationModal
      title={t('title')}
      cancelButton={t('cancelButton')}
      submitButton={t('submitButton')}
      isDisabled={isDeleteChatLoading}
      onSubmit={handleOnSubmit}
    >
      <Text mb="4.8rem">{t('confirmation')}</Text>
    </ConfirmationModal>
  )
}
