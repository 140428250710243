import { useCreateChannel } from '@api/chats/hooks/use-create-channel'
import { useWorkspace } from '@hooks/use-workspace'
import { routes } from '@routes/flow/routes'
import { ConversationType } from '@utils/get-chat-type'
import { Form, Formik, FormikHelpers } from 'formik'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Container, RadioGroup, TextField, useModal } from 'ui'
import { initialValues, validationSchema } from './data'

export interface CreateChannelValues {
  name: string
  desc: string
  channelType: ConversationType
}

export const CreateChannelForm: FC = () => {
  const { isError, mutateAsync } = useCreateChannel()
  const { closeModal } = useModal()
  const { t } = useTranslation('modal-create-channel')
  const navigate = useNavigate()
  const { workspaceId } = useWorkspace()

  //TODO: in Chats should not be option to chose type of channel
  const channelTypeOptions = [
    { value: ConversationType.PUBLIC_CHANNEL, label: t('publicLabel'), desc: t('publicDesc') },
    { value: ConversationType.PRIVATE_CHANNEL, label: t('privateLabel'), desc: t('privateDesc') },
  ]

  useEffect(() => {
    if (isError) {
      toast.error(`${t('createError')}`)
    }
  }, [isError, t])

  const handleSubmit = async (
    values: CreateChannelValues,
    actions: FormikHelpers<CreateChannelValues>
  ) => {
    const result = await mutateAsync({
      chatName: values.name,
      description: values.desc,
      conversationType: values.channelType,
    })
    actions.setSubmitting(false)
    actions.resetForm()
    navigate(`${routes.workspaces}/${workspaceId}/${routes.channels}/${result.id}`)
    closeModal()
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(t)}
      validateOnBlur
    >
      {({ isSubmitting, isValid, handleChange, errors, dirty, values }) => (
        <Form>
          <TextField
            id="name"
            name="name"
            label={t('nameLabel')}
            type="text"
            value={values.name}
            placeholder={t('namePlaceholder')}
            onChange={handleChange}
            warning={Boolean(errors?.name)}
            helperText={errors?.name}
            mb="1.6rem"
          />
          <TextField
            id="desc"
            name="desc"
            label={t('descLabel')}
            type="text"
            value={values.desc}
            placeholder={t('descPlaceholder')}
            onChange={handleChange}
            warning={Boolean(errors?.desc)}
            helperText={errors?.desc}
            mb="2.8rem"
          />
          <RadioGroup
            name="channelType"
            options={channelTypeOptions}
            values={values}
            onChange={handleChange}
            border
            reverse
            mb="4.8rem"
          />
          <Container display="flex" flexGap="1.2rem">
            <Button variant="secondary" width="100%" disabled={isSubmitting} onClick={closeModal}>
              {t('cancelButton')}
            </Button>
            <Button type="submit" width="100%" disabled={!(dirty && isValid) || isSubmitting}>
              {t('submitButton')}
            </Button>
          </Container>
        </Form>
      )}
    </Formik>
  )
}
