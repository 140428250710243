import { ReportTranslationInput } from '@api/messages/report-translation-flow'
import { useAppDispatch } from '@app/flow/hooks'
import { useMutation } from '@tanstack/react-query'
import { chatApi } from 'src/features/chats/repositories/chat-service'
import { reportIncorrectTranslation } from 'src/features/conversation/slice'
import { Message } from '../types/message'

export type ExtendedReportTranslationInput = ReportTranslationInput & {
  clientMessageId: string
  chatId: string
}

export const useReportTranslation = () => {
  const dispatch = useAppDispatch()

  return useMutation<Message, Error, ExtendedReportTranslationInput>(
    ({ messageId, languageCode }) => chatApi.reportTranslation({ messageId, languageCode }),
    {
      onMutate: async ({ clientMessageId, messageId, chatId, languageCode }) => {
        dispatch(
          reportIncorrectTranslation({
            clientMessageId,
            messageId,
            chatId,
            languageCode,
          })
        )
      },
    }
  )
}
