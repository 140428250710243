import { useGetUser } from '@api/account/hooks/use-get-user'
import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { Translation } from '@api/chats/types/translation'
import { Reaction } from '@api/messages/types/message-detail'
import { SendingMessage } from '@components/message/sending-message'
import { useSidePage } from '@hooks/use-side-page'
import { SidePageTypes } from '@layouts/main-layout/side-page'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { AttachmentResponse } from 'src/entities/messages/api/types/attachment-response'
import { Avatar, StatusVariant } from 'ui'
import { DefaultMessage } from './default-message'
import { NotSentMessage } from './not-sent-message'
import { MessageContainer } from './styled'

export enum MessageVariant {
  DELETED = 'isDeleted',
  NOT_SENT = 'notSent',
  DEFAULT = 'default',
  IS_SENDING = 'isSending',
}

export interface MessageProps {
  clientMessageId: string
  chatId: string
  variant: MessageVariant
  isTranslated: boolean
  isEdited: boolean
  isDeleted: boolean
  createdAt: Date
  createdByFullName: string
  createdByUserId: string
  avatarUrl: string
  text: string
  attachments: AttachmentResponse[]
  messageId?: string
  isSending?: boolean
  isError?: boolean
  lastModifiedAt?: Date
  editedAt?: Date | null
  status?: StatusVariant
  isUserDeleted?: boolean
  type?: string
  date?: string
  sourceLanguageCode: string
  translations: Translation[]
  reactions?: Reaction[]
}

export interface MessageStylesProps {
  variant: MessageVariant
  isSender: boolean
  hasOnlyImages?: boolean
  hasAvatar?: boolean
}

export type GeneralMessageProps = MessageProps & MessageStylesProps

const getMessageByVariant = (variant: MessageVariant, props: GeneralMessageProps) => {
  switch (variant) {
    case MessageVariant.IS_SENDING:
      return <SendingMessage {...props} />
    case MessageVariant.NOT_SENT:
      return <NotSentMessage {...props} />
    default:
      return <DefaultMessage {...props} />
  }
}

export const Message: FC<GeneralMessageProps> = (props) => {
  const [message, setMessage] = useState<ReactElement>()
  const {
    isSender,
    createdByFullName,
    variant,
    isUserDeleted,
    createdByUserId,
    hasAvatar = true,
  } = props

  const { setOpen, setType, setSidePageUserId } = useSidePage()

  const { user } = useGetUser(createdByUserId)

  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: props.createdByUserId,
    //@ts-ignore
    //TODO resolve response type not always correct
    avatarId: user?.avatar?.id,
  })

  useEffect(() => {
    setMessage(getMessageByVariant(variant, props))
  }, [props, variant])

  const handleClick = () => {
    setOpen(true)
    setType(SidePageTypes.USER)
    setSidePageUserId(createdByUserId)
  }

  return (
    <MessageContainer justifyContent={isSender ? 'flex-start' : 'flex-end'}>
      {isSender && user && hasAvatar && (
        <Avatar
          imgUrl={usersAvatarUrl || user?.avatar?.uri}
          label={createdByFullName}
          name={createdByFullName}
          size="3.6rem"
          isDeleted={isUserDeleted}
          bgColor={`#${user.color}`}
          isAvatarExists={enabled}
          flex="0 0 3.6rem"
          onClick={handleClick}
        />
      )}
      {message}
    </MessageContainer>
  )
}
