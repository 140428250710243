import { useGetMe } from '@api/account/hooks/use-get-me'
import { useCreateChannel } from '@api/chats/hooks/use-create-channel'
import { InviteToChannelSelect, InviteToChannelTextContent } from '@components/invite-select'
import { OptionType } from '@components/invite-select/types'
import { CreateChannelSkeleton } from '@modules/skeleton/skeleton-variants/create-channel'
import { ConnectionScope } from '@native-chats-api/accounts/generated'
import { routes } from '@routes/flow/routes'
import { ConversationType } from '@utils/get-chat-type'
import { Form, Formik, FormikHelpers } from 'formik'
import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useGetContacts } from 'src/features/chats-contacts/hooks/get-contacts'
import { Button, Container, TextField, useModal } from 'ui'
import { initialValues, validationSchema } from './data'

export interface CreateChannelValues {
  name: string
  users: OptionType[] | []
}

export const CreateChannelForm: FC = () => {
  const { isError, mutateAsync, isLoading } = useCreateChannel()
  const { data: contacts, isLoading: isGetContactsLoading } = useGetContacts(ConnectionScope._0)
  const { meData } = useGetMe()
  const { closeModal } = useModal()
  const { t } = useTranslation(['modal-create-channel', 'modal-add-channel-members'])
  const navigate = useNavigate()

  const formSelectTexts: InviteToChannelTextContent = {
    loadingMsg: t('loadingMsg', { ns: 'modal-add-channel-members' }),
    noResultsMsg: t('noResultsMsg', { ns: 'modal-add-channel-members' }),
    inChannel: t('inChannel', { ns: 'modal-add-channel-members' }),
  }

  useEffect(() => {
    if (isError) {
      toast.error(`${t('createError')}`)
    }
  }, [isError, t])

  const handleSubmit = async (
    values: CreateChannelValues,
    actions: FormikHelpers<CreateChannelValues>
  ) => {
    if (!meData) return
    const formData = {
      chatName: values.name,
      conversationType: ConversationType.PUBLIC_CHANNEL,
      userIds: values.users.map((user) => user.id),
    }
    const result = await mutateAsync(formData)
    actions.setSubmitting(false)
    actions.resetForm()
    navigate(`${routes.chats}/${result.id}`)
    closeModal()
  }

  const usersToAdd = useMemo(() => {
    return (
      contacts?.map((user) => ({
        id: user.userId,
        value: {
          firstName: user.firstName,
          lastName: user.lastName,
          fullName: `${user.firstName} ${user.lastName}`,
        },
        online: user.online,
        label: `${user.firstName} ${user.lastName}`,
        inChannel: false,
        avatar: user.avatar ?? { mediaUri: '', mediaType: '', mediaSize: 0 },
        color: user.color ?? '',
      })) || []
    )
  }, [contacts])

  return isGetContactsLoading ? (
    <CreateChannelSkeleton />
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(t)}
      validateOnBlur
    >
      {({ isSubmitting, isValid, handleChange, errors, dirty, values, setFieldValue }) => {
        const isDisabled = !(dirty && isValid) || isSubmitting || isLoading
        return (
          <Form>
            <TextField
              id="name"
              name="name"
              label={t('nameLabel')}
              type="text"
              value={values.name}
              placeholder={t('namePlaceholder')}
              onChange={handleChange}
              warning={Boolean(errors?.name)}
              helperText={errors?.name}
              mb="1.6rem"
            />
            <InviteToChannelSelect
              label={t('membersLabel')}
              loadOptions={usersToAdd}
              value={values.users}
              onChange={(val) => setFieldValue('users', val)}
              placeholder="Ex. Darlene Robertson"
              mb="4.8rem"
              helperText={errors?.users as string}
              disabled={isLoading}
              texts={formSelectTexts}
            />
            <Container display="flex" flexGap="1.2rem">
              <Button variant="secondary" width="100%" disabled={isSubmitting} onClick={closeModal}>
                {t('cancelButton')}
              </Button>
              <Button type="submit" width="100%" disabled={isDisabled}>
                {t('submitButton')}
              </Button>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}
