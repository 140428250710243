import { useAuth } from '@hooks/chats/use-auth'
import axios from 'axios'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Button, ButtonLink, TextField } from 'ui'
import { initialValues, ValidationMessages, validationSchema } from './data'

export const CredentialsLoginForm: FC = () => {
  const { t } = useTranslation('nc-login')
  const { login } = useAuth()

  const validationMessages: ValidationMessages = {
    enterEmail: t('errorMessages.enterEmail'),
    incorrectEmailOrPassword: t('errorMessages.incorrectEmailOrPassword'),
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(validationMessages)}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        setSubmitting(true)
        try {
          await login(values)
        } catch (error) {
          if (axios.isAxiosError(error) && error.response) {
            const { data } = error.response
            if (data.code === 'UsernamePasswordCombinationNotFound') {
              setFieldError('password', validationMessages.incorrectEmailOrPassword)
            } else toast.error(`${t('toastMessages.somethingWentWrong')}`)
          }
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ values, errors, touched, setFieldValue, handleBlur, isSubmitting }) => {
        const isDisabled = !isEmpty(errors) || isSubmitting || !values.email || !values.password
        return (
          <Form>
            <TextField
              id="email"
              name="email"
              value={values.email}
              onChange={(e) => setFieldValue('email', e.target.value)}
              onBlur={handleBlur}
              warning={Boolean(errors.email && touched.email && errors)}
              helperText={touched.email && errors ? errors?.email : null}
              label={t('credentialsForm.labels.email')}
              placeholder={t('credentialsForm.placeholders.email')}
              mb="2rem"
            />
            <TextField
              id="password"
              name="password"
              value={values.password}
              toggleVisibility
              onChange={(e) => setFieldValue('password', e.target.value)}
              onBlur={handleBlur}
              warning={Boolean(errors.password && touched.password && errors)}
              helperText={touched.password && errors ? errors.password : null}
              label={t('credentialsForm.labels.password')}
              placeholder={t('credentialsForm.placeholders.password')}
            />
            <ButtonLink plain variant="secondary" width="100%" href="reset-password" mt="1.2rem">
              {t('forgotPswd')}
            </ButtonLink>
            <Button type="submit" width="100%" variant="primary" mt="3.2rem" disabled={isDisabled}>
              {t('credentialsForm.submit')}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}
