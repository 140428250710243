interface VideoMeta {
  duration: number
  width: number
  height: number
}

export const getVideoMeta = (file: File): Promise<VideoMeta> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    const objectURL = URL.createObjectURL(file)

    video.src = objectURL

    const cleanup = () => {
      URL.revokeObjectURL(objectURL)
      video.remove()
      video.removeEventListener('loadedmetadata', onLoadedMetadata)
      video.removeEventListener('error', onError)
    }

    const onLoadedMetadata = () => {
      resolve({
        duration: video.duration,
        width: video.videoWidth,
        height: video.videoHeight,
      })
      cleanup()
    }

    const onError = () => {
      cleanup()
      reject(new Error(`Failed to load video`))
    }

    video.addEventListener('loadedmetadata', onLoadedMetadata)
    video.addEventListener('error', onError)
  })
}
