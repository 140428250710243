import { theme } from '@common/theme'
import { TilesIcon } from '@components/icons'
import { AddChannelMembersModal } from '@modules/modals/add-channel-members'
import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConversationUser } from 'src/entities/conversations/model/conversation'
import { User } from 'src/entities/teammates/api/model/user-type'
import { filterByFields } from 'src/shared/lib/filter-utils/filter-by-fields'
import {
  BasicSearch,
  Button,
  Container,
  HiddenLabel,
  ListIcon,
  PlusIcon,
  ScrollContainer,
  useModal,
} from 'ui'
import { ListItem } from './list-item'
import { MembersList, MembersTiles, SwitchButton } from './styled'
import { TileItem } from './tile-item'

interface MembersProps {
  isAddMembersAllowed: boolean
  channelName: string
  channelId: string
  conversationUsers?: ConversationUser[]
  usersToAdd: User[]
}

export const Members: FC<MembersProps> = ({
  isAddMembersAllowed = false,
  channelName,
  channelId,
  conversationUsers,
  usersToAdd,
}) => {
  const [searchString, setSearchString] = useState<string>('')
  const [tilesView, setTilesView] = useState<boolean>(true)
  const { t } = useTranslation(['details', 'common'])
  const { openModal } = useModal()

  const filterMembers = useMemo(() => {
    if (!conversationUsers) return null
    return filterByFields(conversationUsers, searchString, ['user.firstName', 'user.lastName'])
  }, [searchString, conversationUsers])

  return (
    <>
      <Container mb="1.6rem" display="flex" alignItems="center">
        <BasicSearch
          id="search_member"
          name="search_member"
          hiddenLabel={t('searchMember')}
          placeholder={t('searchMember')}
          setSearchString={setSearchString}
          searchString={searchString}
          flexGrow={1}
        />
        <SwitchButton type="button" onClick={() => setTilesView(!tilesView)} ml="0.5rem">
          <HiddenLabel>
            {tilesView ? t('listView', { ns: 'common' }) : t('tilesView', { ns: 'common' })}
          </HiddenLabel>
          {tilesView ? (
            <ListIcon fill={theme.colors.black} />
          ) : (
            <TilesIcon fill={theme.colors.black} />
          )}
        </SwitchButton>
      </Container>
      <ScrollContainer hideScrollbar mx="-2rem">
        {isAddMembersAllowed && (
          <Container m="0.3rem 2rem 1.9rem">
            <Button
              variant="secondary"
              plain
              width="100%"
              preFix={<PlusIcon />}
              onClick={() =>
                openModal({
                  content: (
                    <AddChannelMembersModal
                      channelName={channelName}
                      channelId={channelId}
                      usersToAdd={usersToAdd}
                    />
                  ),
                  maxWidth: '62rem',
                })
              }
            >
              {t('addMembers')}
            </Button>
          </Container>
        )}
        {!tilesView ? (
          <MembersList>
            {filterMembers?.map((user) => (
              <ListItem {...user} />
            ))}
          </MembersList>
        ) : (
          <MembersTiles>
            {filterMembers?.map((user) => (
              <TileItem {...user} />
            ))}
          </MembersTiles>
        )}
      </ScrollContainer>
    </>
  )
}
