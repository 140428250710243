type SortingOrder = 'asc' | 'desc'

export const sortByAlphabet = <T,>(items: T[], key: keyof T, order: SortingOrder) => {
  return [...items].sort((a, b) => {
    const aValue = String(a[key])
    const bValue = String(b[key])

    if (order === 'asc') {
      return aValue.localeCompare(bValue)
    } else {
      return bValue.localeCompare(aValue)
    }
  })
}
