import React, { FC } from 'react'
import styled from 'styled-components'
import { Button, Container, Heading, Text } from 'ui'

interface JoinChannelCtaProps {
  id: string
  title: string
  subtitle: string
  isDisabled: boolean
  onClick: (id: string) => void
  onGoBack: VoidFunction
  buttonLabels: string[]
}

// TODO: Move to features
export const JoinChannelCta: FC<JoinChannelCtaProps> = ({
  id,
  title,
  subtitle,
  isDisabled = false,
  onClick,
  onGoBack,
  buttonLabels,
}) => (
  <JoinCtaContainer>
    <Container mb="1.2rem">
      <Heading variant="h2">{title}</Heading>
      {subtitle && <Text>{subtitle}</Text>}
    </Container>
    <Container display="flex" justifyContent="center" flexGap="1.2rem">
      <Button
        variant="secondary"
        size="sm"
        minWidth="12rem"
        disabled={isDisabled}
        onClick={onGoBack}
      >
        {buttonLabels[0]}
      </Button>
      <Button size="sm" minWidth="12rem" disabled={isDisabled} onClick={() => onClick(id)}>
        {buttonLabels[1]}
      </Button>
    </Container>
  </JoinCtaContainer>
)

const JoinCtaContainer = styled.div`
  padding: 1.6rem;
  text-align: center;
  border-radius: 1.6rem 1.6rem 1.6rem 0;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  background-color: ${({ theme }) => theme.colors.white};
`
