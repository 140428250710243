import { useInterfaceLanguage } from '@hooks/use-interface-language'
import { logInWithApple } from '@native-chats-api/auth-api/auth-with-apple'
import {
  Credentials,
  logInWithCredentials,
} from '@native-chats-api/auth-api/log-in-with-credentials'
import { logInWithGoogle } from '@native-chats-api/auth-api/log-in-with-google'
import { logInWithMicrosoft } from '@native-chats-api/auth-api/log-in-with-microsoft'
import { logOut } from '@native-chats-api/auth-api/log-out'
import { getAccessTokenFromCookies } from '@native-chats-api/auth-api/utils/get-tokens'
import { removeTokenFromCookies } from '@native-chats-api/auth-api/utils/remove-tokens'
import { setTokens } from '@native-chats-api/auth-api/utils/set-tokens'
import { TokenType } from '@native-chats-api/auth-api/utils/tokens'
import React, { createContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

interface AuthProvideProps {
  children: JSX.Element | JSX.Element[]
}

export interface AuthStateDefinition {
  isAuthorized: boolean
  logout: () => void
  login: (credentials: Credentials) => Promise<void>
  ssoLoginCallback: (token: string) => Promise<void>
  microsoftLoginCallback: (token: string) => Promise<void>
  appleLoginCallback: (token: string) => Promise<void>
}

export const initData: AuthStateDefinition = {
  isAuthorized: false,
  logout: () => {},
  login: async () => {},
  ssoLoginCallback: async () => {},
  microsoftLoginCallback: async () => {},
  appleLoginCallback: async () => {},
}

const AuthContext = createContext<AuthStateDefinition>(initData)

const AuthProvider = ({ children }: AuthProvideProps) => {
  const { t } = useTranslation('common')

  const { removeInterfaceLanguage } = useInterfaceLanguage()
  const [isAuthorized, setIsAuthorized] = useState(Boolean(getAccessTokenFromCookies()))

  const logout = async () => {
    try {
      await logOut()
      removeTokenFromCookies(TokenType.AccessToken)
      removeTokenFromCookies(TokenType.RefreshToken)
      setIsAuthorized(false)
      removeInterfaceLanguage()
    } catch (_error) {
      toast.error(`${t('errors.somethingWentWrong')}`)
    }
  }

  const login = async (credentials: Credentials) => {
    try {
      const { data } = await logInWithCredentials(credentials)

      setIsAuthorized(true)
      setTokens({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      })
    } catch (_error) {
      toast.error(`${t('errors.somethingWentWrong')}`)
    }
  }

  const ssoLoginCallback = async (token: string) => {
    try {
      const { data } = await logInWithGoogle(token)

      setIsAuthorized(true)
      setTokens({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      })
    } catch (error) {
      toast.error(`${t('errors.somethingWentWrong')}`)
    }
  }

  const microsoftLoginCallback = async (token: string) => {
    try {
      const { data } = await logInWithMicrosoft(token)

      setIsAuthorized(true)
      setTokens({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      })
    } catch (error) {
      toast.error(`${t('errors.somethingWentWrong')}`)
    }
  }

  const appleLoginCallback = async (token: string) => {
    try {
      console.log('apple callback')
      const { data } = await logInWithApple(token)
      console.log(data)
      setIsAuthorized(true)
      setTokens({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      })
    } catch (error) {
      toast.error(`${t('errors.somethingWentWrong')}`)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthorized,
        logout,
        login,
        ssoLoginCallback,
        microsoftLoginCallback,
        appleLoginCallback,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext }
