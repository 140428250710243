import { ATTACHMENT_SPINNER_SIZE } from '@utils/variables'
import React, { FC } from 'react'
import { SpinnerDarkAnimation, SpinnerLightAnimation } from 'ui'

interface MediaContentProps {
  src: string
  name: string
  isVideo: boolean
  isLoading: boolean
  isIncoming: boolean
}

export const MediaContent: FC<MediaContentProps> = ({
  src,
  name,
  isVideo,
  isLoading,
  isIncoming,
}) => {
  if (isLoading) {
    return isIncoming ? (
      <SpinnerDarkAnimation size={ATTACHMENT_SPINNER_SIZE} />
    ) : (
      <SpinnerLightAnimation size={ATTACHMENT_SPINNER_SIZE} />
    )
  }

  if (isVideo) {
    return (
      <video>
        <source src={src} />
      </video>
    )
  }

  return <img src={src} alt={name || 'attachment'} />
}
