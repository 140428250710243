import { User } from 'src/entities/teammates/api/model/user-type'
import { ConversationUser } from '../conversation'

export function parseUser(user: User): ConversationUser {
  return {
    user: {
      ...user,
      doNotDisturbFrom: '',
    },
    lastMessageReadAt: new Date(),
    idOfLastMessageRead: '',
    isOwner: false,
    leftChat: false,
  }
}
