import { DefaultTheme } from 'styled-components'

interface GetBackgroundColorProps {
  theme: DefaultTheme
  backgroundColor: string
  isLoading?: boolean
  src?: string
}

export const getBackgroundColor = ({
  theme,
  backgroundColor,
  isLoading,
  src,
}: GetBackgroundColorProps) => {
  if (isLoading) {
    return theme.colors.white
  }
  if (!src) {
    return theme.colors.yellow
  }
  return backgroundColor
}
