import { GeneralMessageProps } from '@components/message'
import { formatISO9075 } from 'date-fns'
import { MessageDate } from '../types/types'

interface GroupedDays {
  [date: string]: GeneralMessageProps[]
}

export function groupedDays(messages: GeneralMessageProps[]) {
  return messages.reduce((acc: GroupedDays, el: GeneralMessageProps) => {
    if (el.isDeleted) return acc
    const messageDay = formatISO9075(el.createdAt, { representation: 'date' })
    if (acc[messageDay as keyof typeof acc]) {
      return { ...acc, [messageDay]: acc[messageDay as keyof typeof acc].concat([el]) }
    }
    return { ...acc, [messageDay]: [el] }
  }, {})
}

export function generateMessagesWithDate(messages: GeneralMessageProps[]) {
  const days = groupedDays(messages)
  const sortedDays = Object.keys(days).sort(
    (x: string, y: string) => new Date(y).valueOf() - new Date(x).valueOf()
  )
  return sortedDays
    .reduce((acc: (GeneralMessageProps | MessageDate)[], date: string) => {
      const sortedMessages: GeneralMessageProps[] = days[date as keyof typeof days].sort(
        (a, b) => b.createdAt.valueOf() - a.createdAt.valueOf()
      )
      const withoutDeleted = sortedMessages.filter((message) => !message.isDeleted)
      return acc.concat([...withoutDeleted, { type: 'day', date, id: date }])
    }, [])
    .reverse()
}
