import { CookieStorage } from 'cookie-storage'
import { TokenType } from '@native-chats-api/auth-api/utils/tokens'

const cookieStorage = new CookieStorage()

export const getRefreshTokenFromCookies = () => {
  return cookieStorage.getItem(TokenType.RefreshToken)
}

export const getAccessTokenFromCookies = () => {
  return cookieStorage.getItem(TokenType.AccessToken)
}
