import { InterfaceLanguageContext } from '@contexts/chats/interface-language-provider'
import { useContext } from 'react'

const useInterfaceLanguage = () => {
  const context = useContext(InterfaceLanguageContext)

  if (!context) {
    throw new Error('useInterfaceLanguage must be used within InterfaceLanguageProvider')
  }
  return useContext(InterfaceLanguageContext)
}

export { useInterfaceLanguage }
