import { GetMessageListProps, GetMessageListResponse } from '@api/messages/get-message-list'
import { MessagesService } from '@native-chats-api/chats/generated'
import { mapMessageDetail } from './helpers/map-message-detail'
import { MessagesRepository } from './messages-repository'

export class NativeChatsRepository implements MessagesRepository {
  async getMessagesList(params: GetMessageListProps): Promise<GetMessageListResponse> {
    const messageList = await MessagesService.getApiV2MessagesList(
      params.chatId,
      params.pageNumber ?? 1,
      params.pageSize ?? 10
    )

    const mappedMessages = messageList.messages?.map(mapMessageDetail) ?? []

    const getMessageListResponse: GetMessageListResponse = {
      chatId: messageList.chatId!,
      messages: mappedMessages,
      pages: messageList.pages!,
      messagesCount: messageList.messagesCount!,
      currentPage: messageList.currentPage!,
    }

    return getMessageListResponse
  }
}
