import React from 'react'

export const PlayIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.6912 10.2353C19.1029 10.9882 19.1029 13.0118 17.6912 13.7647L8.94118 18.4314C7.60896 19.1419 6 18.1765 6 16.6667L6 7.33333C6 5.82349 7.60896 4.85811 8.94118 5.56863L17.6912 10.2353Z" />
    </svg>
  )
}
