import {
  AttachmentResponse,
  MediaType as AttachmentMediaType,
} from '@api/messages/types/attachment-response'
import { FileResponse, MediaType } from '@native-chats-api/media/generated'

export function convertFileToAttachmentResponse(file: FileResponse): AttachmentResponse {
  return {
    id: file.id || '',
    uri: file.uri || '',
    type: file.mediaType === MediaType._0 ? AttachmentMediaType.IMG : AttachmentMediaType.VIDEO,
  }
}
