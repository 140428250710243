import { createBaseURL } from '@native-chats-api/utils'
import axios from 'axios'
import {
  createOnRejectedInterceptor,
  onFulfilledChatsInterceptor,
  requestInterceptorSuccess,
} from '@native-chats-api/interceptos'

const url = process.env.NATIVE_CHATS_ACCOUNTS_API_URL as string

const baseURL = createBaseURL(url)

export const nativeChatsAccountApi = axios.create({
  baseURL,
})

nativeChatsAccountApi.interceptors.response.use(
  onFulfilledChatsInterceptor,
  createOnRejectedInterceptor(nativeChatsAccountApi)
)

nativeChatsAccountApi.interceptors.request.use(requestInterceptorSuccess)
