import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateChannel } from 'src/features/create-channel'
import { Heading } from 'ui'

export const CreateChannelModal: FC = () => {
  const { t } = useTranslation('modal-create-channel')
  return (
    <>
      <Heading as="h2" mb="2.8rem">
        {t('title')}
      </Heading>
      <CreateChannel />
    </>
  )
}
