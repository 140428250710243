import React, { FC } from 'react'
import styled from 'styled-components'
import { Button, Container, Heading, Text } from 'ui'

interface DeleteChannelModalProps {
  title: string
  name: string
  confirmation: string
  membersCount: string
  onSubmit: VoidFunction
  onCancel: VoidFunction
  buttonLabels: string[]
  isDisabled: boolean
}

export const DeleteChannelModal: FC<DeleteChannelModalProps> = ({
  title,
  name,
  confirmation,
  membersCount,
  buttonLabels,
  onSubmit,
  onCancel,
  isDisabled,
}) => {
  return (
    <Container>
      <Heading as="h2" mb="0.8rem">
        {title}
      </Heading>
      <Text>{confirmation}</Text>
      <InfoBox m="2.8rem 0 0">
        <Text mr="1rem" variant="textMedium">
          {name}
        </Text>
        <Text color="greyDark" variant="smallTextRegular">
          {membersCount}
        </Text>
      </InfoBox>
      <ButtonsContainer>
        <Button variant="secondary" width="100%" onClick={onCancel} disabled={isDisabled}>
          {buttonLabels[0]}
        </Button>
        <Button type="submit" width="100%" variant="warning" onClick={onSubmit}>
          {buttonLabels[1]}
        </Button>
      </ButtonsContainer>
    </Container>
  )
}

const InfoBox = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 1.6rem;
  border-radius: 1.6rem 1.6rem 1.6rem 0;
  border: 1px solid ${({ theme }) => theme.colors.grey};
`

const ButtonsContainer = styled(Container)`
  display: flex;
  gap: 1.2rem;
  margin-top: 4rem;
`
