import { useGetAttachment } from '@api/chats/hooks/use-get-attachments'
import React, { FC, ReactElement } from 'react'
import { useInView } from 'react-intersection-observer'
import { AttachmentResponse } from 'src/entities/messages/api/types/attachment-response'
import { Container } from 'ui'

interface AttachmentFetcherProps extends AttachmentResponse {
  children: (
    options: AttachmentResponse & {
      src: string
      isProcessing: boolean
    }
  ) => ReactElement
  conversationId: string
}
export const AttachmentFetcher: FC<AttachmentFetcherProps> = ({
  children,
  conversationId,
  ...attachment
}) => {
  const { ref, inView } = useInView({ triggerOnce: true })
  const {
    data: src,
    isLoading,
    isFetching,
  } = useGetAttachment(attachment.id!, conversationId, inView)

  //TODO: handle errors, show fallback
  if (!src && !isLoading && !isFetching) {
    return null
  }

  return (
    <Container height="100%" ref={ref}>
      {children({
        src,
        isProcessing: isFetching || isLoading,
        ...attachment,
      })}
    </Container>
  )
}
