import React from 'react'

export const SortingArrowsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.996 1.5a1 1 0 0 0-.709.299L2.793 6.293a1 1 0 0 0 0 1.414 1 1 0 0 0 1.414 0l2.79-2.789V15.5a1 1 0 0 0 1 1 1 1 0 0 0 1-1V4.91l2.796 2.797a1 1 0 0 0 1.414 0 1 1 0 0 0 0-1.414l-4.5-4.5a1 1 0 0 0-.004 0 1 1 0 0 0-.707-.293Zm8.5 6a1 1 0 0 0-1 1v10.582l-2.789-2.789a1 1 0 0 0-1.414 0 1 1 0 0 0 0 1.414l4.494 4.494a1 1 0 0 0 .71.299 1 1 0 0 0 .706-.293 1 1 0 0 0 .004 0l4.5-4.5a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0l-2.797 2.797V8.5a1 1 0 0 0-1-1z" />
    </svg>
  )
}
