import { SyncService } from '@api/chats/generated'
import { Conversation } from '../../model/conversation'
import { ConversationsRepository } from './conversations-repository'

export class ConversationsRepositoryImplementation implements ConversationsRepository {
  async syncConversations(
    pageSize?: number,
    timeStamp?: string,
    lastChatId?: string
  ): Promise<{
    conversations: Conversation[]
    lastChatIdInBatch?: string
    lastChatTimestampInBatch?: string
    isSyncFinished?: boolean
  }> {
    const syncedData = await SyncService.getV3SyncChats(pageSize, timeStamp, lastChatId)
    const conversations = syncedData.chats as Conversation[]
    return {
      conversations: conversations,
      lastChatIdInBatch: syncedData.lastChatIdInBatch || undefined,
      lastChatTimestampInBatch: syncedData.lastChatTimeStampInBatch || undefined,
      isSyncFinished: syncedData.isSyncFinished,
    }
  }
}
