import React from 'react'

export const TrashcanIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.285 3a2.72 2.72 0 0 0-1.916.787A2.694 2.694 0 0 0 7.572 5.7V7H6a1 1 0 0 0-1 1v11.3c0 .719.29 1.408.799 1.913A2.72 2.72 0 0 0 7.715 22h8.57a2.72 2.72 0 0 0 1.916-.787c.51-.505.799-1.194.799-1.912V8a1 1 0 0 0-1-1h-1.572V5.7c0-.719-.288-1.408-.797-1.913A2.72 2.72 0 0 0 13.715 3Zm0 2h3.43a.72.72 0 0 1 .508.207.69.69 0 0 1 .205.492V7H9.572V5.7a.69.69 0 0 1 .205-.493.72.72 0 0 1 .508-.207ZM7 9h10v10.3c0 .185-.074.36-.207.493a.72.72 0 0 1-.508.207h-8.57a.72.72 0 0 1-.508-.207.691.691 0 0 1-.207-.492Z" />
    </svg>
  )
}
