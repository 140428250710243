import { AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { Authenticator, cookieStorage } from './auth/oauth/auth-service'

export const getWorkspaceTokenFromStorage = () => {
  return cookieStorage.getItem(`oidc.user:${process.env.IDENTITY_SERVER_URL}:native-flow`)
}

export const getToken = () => {
  return sessionStorage.getItem(`workspace.token`)
}

export const onFulfilled = (response: never) => {
  return camelcaseKeys(response, { deep: true })
}

// Flag to prevent multiple token refresh requests
let isRefreshing = false

export const onRejected = async (error: AxiosError) => {
  try {
    if (error instanceof AxiosError && error.response?.status === 401 && !isRefreshing) {
      isRefreshing = true
      await Authenticator.userManager.signinSilent()
      isRefreshing = false
    }
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 401) {
      await Authenticator.userManager.signinRedirect()
    }
  }
  throw camelcaseKeys(error, { deep: true })
}

export const setWorkspaceAuthHeaders = (config: InternalAxiosRequestConfig) => {
  const workspaceToken = getWorkspaceTokenFromStorage()
  if (workspaceToken && config?.headers) {
    config.headers['Authorization'] = `Bearer ${JSON.parse(workspaceToken).access_token}`
    return config
  }
  return config
}

export const setAuthHeaders = (config: InternalAxiosRequestConfig) => {
  if (getWorkspaceTokenFromStorage() && config?.headers) {
    config.headers['Authorization'] = `Bearer ${getToken()}`
    return config
  }
  return config
}

export const setWorkspaceAccessToken = (config: AxiosRequestConfig) => {
  if (getWorkspaceTokenFromStorage() && config?.headers) {
    config.headers['Authorization'] = `Bearer ${getToken()}`
    config.headers['X-DeviceId'] = 'web_client'
    return config
  }
  return config
}
