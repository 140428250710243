import React, { FC } from 'react'
import { Container } from 'ui'
import { TabProps } from './types'

export const Tab: FC<TabProps> = ({ children, ...spaceProps }) => {
  return (
    <Container flex="1 1 auto" minHeight="0" {...spaceProps}>
      {children}
    </Container>
  )
}
