import React, { FC } from 'react'
import { ConfirmationModal, ConfirmationVariant, Text } from 'ui'

interface LeaveChannelModalProps {
  title: string
  confirmation: string
  buttonLabels: string[]
  onSubmit: VoidFunction
  isDisabled?: boolean
  variant?: ConfirmationVariant
}

export const LeaveChannelModal: FC<LeaveChannelModalProps> = ({
  title,
  confirmation,
  onSubmit,
  buttonLabels,
  variant,
  isDisabled = false,
}) => (
  <ConfirmationModal
    title={title}
    cancelLabel={buttonLabels[0]}
    submitLabel={buttonLabels[1]}
    onSubmit={onSubmit}
    isDisabled={isDisabled}
    variant={variant}
  >
    <Text mb="4rem">{confirmation}</Text>
  </ConfirmationModal>
)
