import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { Container } from 'ui'
import { Item } from './item'

interface ListProps extends SpaceProps {
  icon: JSX.Element
  itemsNumber?: number
}

export const List: FC<ListProps> = ({ icon, itemsNumber = 7, ...spacing }) => {
  const items = new Array(itemsNumber).fill(null)

  return (
    <ListContainer {...spacing}>
      <Container display="flex" alignItems="center" flexGap="0.8rem" py="1.2rem">
        <Icon>{icon}</Icon>
        <Skeleton height={12} width={94} />
      </Container>
      {items.length > 0 && (
        <Container pb="1.6rem">
          {items.map((_, index) => (
            <Item key={index} />
          ))}
        </Container>
      )}
    </ListContainer>
  )
}

const ListContainer = styled.div`
  &:not(:last-of-type) {
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.greyLight};
  }
  ${space}
`

const Icon = styled.span`
  color: ${({ theme }) => theme.colors.greyDark};
`
