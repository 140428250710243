import { InViewTrigger } from '@components/in-view-trigger'
import { Skeleton } from '@modules/skeleton'
import React, { FC } from 'react'
import { User } from 'src/entities/teammates/api/model/user-type'
import { Action } from 'src/features/teammate-actions'
import styled from 'styled-components'
import { ScrollContainer } from 'ui'
import { TeammateGridItem } from './teammates-grid-item'

export interface TeammatesGridProps {
  users: User[]
  createActions: (userId: string) => Action[]
  isUsersFetching?: boolean
  setIsTriggerInView?: (c: boolean) => void
  onUserClick?: (userId: string) => void
}

export const TeammatesGrid: FC<TeammatesGridProps> = ({
  users,
  isUsersFetching = false,
  setIsTriggerInView,
  createActions,
  onUserClick,
}) => {
  return (
    <GridScrollContainer hideScrollbar>
      <GridContainer>
        {users.map((user) => (
          <TeammateGridItem
            key={user.userId}
            actions={createActions(user.userId)}
            onUserClick={onUserClick}
            {...user}
          />
        ))}
        {isUsersFetching && <Skeleton type="teammatesTile" length={12} />}
        {setIsTriggerInView && (
          <InViewTrigger onChange={setIsTriggerInView} bottom={0} position="absolute" />
        )}
      </GridContainer>
    </GridScrollContainer>
  )
}

const GridScrollContainer = styled(ScrollContainer)`
  height: calc(100% - 13rem);
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(16rem, 1fr)
  ); /* min 16rem is set to not wrap the text in the tile */
  gap: 1.2rem;
  justify-content: center;
  height: auto;
  padding-bottom: 3rem;
  position: relative;
`
