import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { NetworkMode } from '@api/enums'
import { getChatAvatar } from '@utils/get-chat-avatar'
import { MAX_VISIBLE_ATTACHMENTS } from '@utils/variables'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatConversationName } from 'src/entities/conversations/lib/format-conversation-name'
import { Conversation, ConversationUser } from 'src/entities/conversations/model/conversation'
import { useGetUsers } from 'src/entities/teammates/api/hooks/use-get-users'
import styled from 'styled-components'
import { Container } from 'ui'
import { MessageInfo } from './message-info'

export interface MessageItemProps {
  chat: Conversation
}

export const MessageItem: FC<MessageItemProps> = ({ chat }) => {
  const { chatUsers, lastMessage, avatar, id } = chat
  const { meData } = useGetMe(false, NetworkMode.OFFLINE_FIRST)
  const { users } = useGetUsers({ networkMode: NetworkMode.OFFLINE_FIRST })
  const [chatUser, setChatUser] = useState<ConversationUser>()
  const { t } = useTranslation('chat')

  const chatName = useMemo(() => {
    if (!chat || !meData) return ''
    return formatConversationName(chat, meData.userId, t)
  }, [chat, t])

  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: chatUser?.user.userId,
    //TODO: review Avatar types
    //@ts-ignore
    avatarId: chatUser?.user.avatar?.id,
  })

  useEffect(() => {
    if (chatUsers.length === 1) {
      setChatUser(chat.chatUsers[0])
    }
    if (chatUsers.length === 2) {
      const uniqUser = chat.chatUsers.find((chatUser) => chatUser.user.userId !== meData?.userId)
      if (uniqUser) {
        setChatUser(uniqUser)
      }
    }
  }, [chatUsers])

  const lastMessageCreatedBy = () => {
    if (!lastMessage) return ''
    const { createdBy } = lastMessage

    if (createdBy === meData?.userId) {
      return t('chatName.you')
    }
    const creator = chatUsers.find((chatUser) => chatUser.user.userId === createdBy)
    if (creator) return creator.user.firstName
    return ''
  }

  const getLastMessageContent = () => {
    if (!lastMessage) return ''

    const { originalMessage, translations, attachments, createdBy } = lastMessage

    if (createdBy === meData?.userId && originalMessage) {
      return originalMessage
    }
    if (translations.length > 0) {
      const translation = translations.find(
        ({ hasBeenReportedAsIncorrect }) => !hasBeenReportedAsIncorrect
      )
      const preferred = translation?.translations.find(({ isPreferred }) => isPreferred)
      if (preferred) {
        return preferred.translation
      }
      return translation?.translations[0].translation
    }

    if (!originalMessage && attachments.length > 0) {
      if (attachments.length > MAX_VISIBLE_ATTACHMENTS) {
        return `\u{1F39E} +${MAX_VISIBLE_ATTACHMENTS} ${t('message.more')} ${t('media')}`
      }
      return `\u{1F39E} ${attachments.length} ${t('media')}`
    }

    return originalMessage
  }

  return (
    <MessageContainer>
      {getChatAvatar({
        ...chat,
        avatar: avatar,
        label: chatName?.slice(0, 1),
        size: '3.6rem',
        borderRadius: '0.6rem',
        iconSize: 24,
        id: id,
        workspaceUsers: users,
        me: meData,
        chatUsers,
        imgURL: usersAvatarUrl,
        isAvatarExists: enabled,
      })}
      <MessageInfo
        name={chatName}
        createdBy={lastMessageCreatedBy()}
        lastMessage={getLastMessageContent()}
        lastModifiedAt={lastMessage.updatedAt}
      />
    </MessageContainer>
  )
}

export const MessageContainer = styled(Container)`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`
