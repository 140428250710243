import { InputField } from '@components/skeleton-items/input-field'
import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Container } from 'ui'

export const CreateChannelSkeleton: FC = () => (
  <>
    <InputField width={570} mb="1.6rem" />
    <InputField width={570} height={86} />
    <Container display="flex" flexGap="1.2rem" pt="4.8rem">
      <Skeleton width={280} height={48} />
      <Skeleton width={280} height={48} />
    </Container>
  </>
)
