import { TilesIcon } from '@components/icons'
import React, { FC } from 'react'
import styled from 'styled-components'
import { SpaceProps } from 'styled-system'
import { Container, ListIcon } from 'ui'

interface TeammatesLayoutPickerProps extends SpaceProps {
  onClick: VoidFunction
  isTile: boolean
  labels: { listView: string; tileView: string }
}

interface IconContainerProps {
  active?: boolean
}

export const TeammatesLayoutPicker: FC<TeammatesLayoutPickerProps> = ({
  onClick,
  isTile,
  labels,
  ...spacing
}) => (
  <PickerContainer {...spacing}>
    <IconButton type="button" aria-label={labels.tileView} active={isTile} onClick={onClick}>
      <TilesIcon />
    </IconButton>
    <IconButton type="button" aria-label={labels.listView} active={!isTile} onClick={onClick}>
      <ListIcon />
    </IconButton>
  </PickerContainer>
)

const PickerContainer = styled(Container)`
  display: flex;
  gap: 0.4rem;
  border: ${({ theme }) => `1px solid ${theme.colors.grey}`};
  border-radius: 0.8rem;
  padding: 0.4rem;
`

const IconButton = styled('button')<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  background-color: ${({ active, theme }) => active && theme.colors.greyLight};
  border-radius: 0.6rem;
  & svg {
    fill: ${({ active, theme }) => (active ? theme.colors.black : theme.colors.greyDark)};
  }
`
