import { SendMessageProps } from '@api/messages/send-message'
import { Message } from '@api/messages/types/message'
import { MessageDetail } from '@api/messages/types/message-detail'
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import {
  findReactionIndex,
  generateReaction,
  getEntities,
  getMessageById,
  hasMessageReaction,
  mutateReaction,
} from '@utils/redux-reaction-handlers'
import {
  markIncorrectTranslation,
  updatePreferredTranslation,
} from '@utils/redux-translations-handlers'
import {
  ChangeMessageDeletedStatePayload,
  ConversationMessaging,
  EditMessagePayload,
  RemoveMessagePayload,
  ReportIncorrectTranslationPayload,
  SetMessagesToConversationPayload,
  UpdateMessageTranslationPayload,
  UpdateTranslationPreferencesPayload,
} from './models'

const conversationAdapter = createEntityAdapter<ConversationMessaging>({
  selectId: ({ id }) => id,
})

const messagesAdapter = createEntityAdapter<MessageDetail>({
  selectId: (message) => message.clientMessageId,
})

type ReactionPayload = {
  reactionCode: string
  meId: string
  messageId: string
  chatId: string
  added: boolean
}

export const { selectById: selectConversationById } = conversationAdapter.getSelectors(
  (state: EntityState<ConversationMessaging>) => state
)

export const { selectAll: selectMessages, selectById: selectMessageById } =
  messagesAdapter.getSelectors((state: EntityState<MessageDetail>) => state)

const conversationSlice = createSlice({
  name: 'conversation',
  initialState: conversationAdapter.getInitialState(),
  reducers: {
    setMessagesToConversation: (state, action: PayloadAction<SetMessagesToConversationPayload>) => {
      const chatId = action.payload.chatId
      const chatEntity = state.entities[chatId]
      if (chatEntity) {
        messagesAdapter.setMany(chatEntity.messages, action.payload.messages)
      } else {
        conversationAdapter.addOne(state, {
          id: chatId,
          messages: messagesAdapter.addMany(
            messagesAdapter.getInitialState(),
            action.payload.messages
          ),
        })
      }
    },
    sendMessage: (state, action: PayloadAction<MessageDetail>) => {
      const { chatId } = action.payload
      const chatEntity = state.entities[chatId]
      if (chatEntity) {
        messagesAdapter.addOne(chatEntity.messages, { ...action.payload, isSending: true })
      }
    },
    resendMessage: (state, action: PayloadAction<MessageDetail>) => {
      const { chatId, clientMessageId } = action.payload
      const chat = selectConversationById(state, chatId)
      if (chat) {
        messagesAdapter.updateOne(chat.messages, {
          id: clientMessageId,
          changes: {
            isError: false,
            isSending: true,
          },
        })
      }
    },
    receiveMessage: (state, action: PayloadAction<MessageDetail>) => {
      const chatId = action.payload.chatId
      const chatEntity = state.entities[chatId]
      if (chatEntity) {
        messagesAdapter.addOne(chatEntity.messages, action.payload)
      }
    },
    editMessage: (state, action: PayloadAction<EditMessagePayload>) => {
      const { chatId, clientMessageId, text, editedAt } = action.payload
      const chatEntity = state.entities[chatId]
      if (chatEntity) {
        messagesAdapter.updateOne(chatEntity.messages, {
          id: clientMessageId,
          changes: {
            originalMessage: text,
            editedAt,
          },
        })
      }
    },
    removeMessage: (state, action: PayloadAction<RemoveMessagePayload>) => {
      const { chatId, clientMessageId } = action.payload
      const chatEntity = state.entities[chatId]
      if (chatEntity) {
        messagesAdapter.updateOne(chatEntity.messages, {
          id: clientMessageId,
          changes: {
            isDeleted: true,
          },
        })
      }
    },
    changeMessageDeletedState: (state, action: PayloadAction<ChangeMessageDeletedStatePayload>) => {
      const { chatId, clientMessageId, isDeleted } = action.payload
      const chatEntity = state.entities[chatId]
      if (chatEntity) {
        messagesAdapter.updateOne(chatEntity.messages, {
          id: clientMessageId,
          changes: {
            isDeleted,
          },
        })
      }
    },
    handleReaction: (state, action: PayloadAction<ReactionPayload>) => {
      const { chatId, reactionCode, meId, added, messageId } = action.payload

      // getting needed entities
      const { chatEntity, messageEntities } = getEntities(state, chatId)
      if (!chatEntity || !messageEntities) return

      // getting message which had emoji action
      const neededMessage = getMessageById(messageEntities, messageId)
      if (!neededMessage) return

      // since we have redux store we are getting client message id to provide mutations
      const clientMessageId = neededMessage.clientMessageId

      const hasThisReaction = hasMessageReaction(neededMessage, reactionCode)

      const oldReactions = neededMessage.reactions

      if (hasThisReaction) {
        const indexToMutate = findReactionIndex(oldReactions, reactionCode)

        const reactionToMutate = oldReactions[indexToMutate]

        mutateReaction(reactionToMutate, meId, added)
      } else {
        const reaction = generateReaction(reactionCode, meId)

        oldReactions.push(reaction)

        messagesAdapter.updateOne(chatEntity.messages, {
          id: clientMessageId,
          changes: {
            reactions: oldReactions,
          },
        })
      }
    },
    setMessageSent: (state, action: PayloadAction<Message & SendMessageProps>) => {
      const { chatId, id, clientMessageId, createdAt } = action.payload
      const chatEntity = state.entities[chatId]
      if (chatEntity) {
        messagesAdapter.updateOne(chatEntity.messages, {
          id: clientMessageId,
          changes: {
            createdAt: new Date(createdAt),
            isSending: false,
            isError: false,
            messageId: id,
          },
        })
      }
    },
    updateTranslationPreferences: (
      state,
      action: PayloadAction<UpdateTranslationPreferencesPayload>
    ) => {
      const { chatId, languageCode, provider, clientMessageId } = action.payload
      const chatEntity = state.entities[chatId]
      const conversation = selectConversationById(state, chatId)

      if (!conversation) return

      const selectedMessage = selectMessageById(conversation.messages, clientMessageId)

      if (!selectedMessage?.translations || selectedMessage.translations.length === 0) {
        return
      }

      const updatedTranslations = updatePreferredTranslation(
        languageCode,
        provider,
        selectedMessage.translations
      )

      if (chatEntity) {
        messagesAdapter.updateOne(chatEntity.messages, {
          id: clientMessageId,
          changes: {
            translations: updatedTranslations,
          },
        })
      }
    },
    setMessageError: (state, action: PayloadAction<SendMessageProps>) => {
      const chatId = action.payload.chatId
      const chatEntity = state.entities[chatId]
      if (chatEntity) {
        messagesAdapter.updateOne(chatEntity.messages, {
          id: action.payload.clientMessageId,
          changes: {
            isError: true,
          },
        })
      }
    },
    reportIncorrectTranslation: (
      state,
      action: PayloadAction<ReportIncorrectTranslationPayload>
    ) => {
      const { chatId, clientMessageId, languageCode } = action.payload
      const chatEntity = state.entities[chatId]
      const conversation = selectConversationById(state, chatId)

      if (!conversation) return

      const selectedMessage = selectMessageById(conversation.messages, clientMessageId)

      if (!selectedMessage?.translations || selectedMessage.translations.length === 0) {
        return
      }

      const updatedTranslations = markIncorrectTranslation(
        languageCode,
        selectedMessage.translations
      )

      if (chatEntity) {
        messagesAdapter.updateOne(chatEntity.messages, {
          id: clientMessageId,
          changes: { translations: updatedTranslations },
        })
      }
    },
    updateMessageTranslation: (state, action: PayloadAction<UpdateMessageTranslationPayload>) => {
      const { chatId, clientMessageId, translations } = action.payload
      const chatEntity = state.entities[chatId]

      if (chatEntity) {
        messagesAdapter.updateOne(chatEntity.messages, {
          id: clientMessageId,
          changes: { translations },
        })
      }
    },
  },
})

export const {
  setMessagesToConversation,
  sendMessage,
  setMessageSent,
  setMessageError,
  receiveMessage,
  handleReaction,
  removeMessage,
  resendMessage,
  editMessage,
  updateTranslationPreferences,
  reportIncorrectTranslation,
  updateMessageTranslation,
  changeMessageDeletedState,
} = conversationSlice.actions

export default conversationSlice.reducer
