import React, { FC } from 'react'
import styled from 'styled-components'
import { PositionProps } from 'styled-system'
import { Button, Container, HiddenLabel } from 'ui'

export interface Action {
  label: string
  icon: JSX.Element
  onActionClick?: () => void
  isDisabled?: boolean
}

export type TeammateActionsVariant = 'button' | 'iconButton'

interface TeammateActionsProps extends PositionProps {
  actions: Action[]
  variant?: TeammateActionsVariant
}

export const TeammateActions: FC<TeammateActionsProps> = ({
  actions,
  variant = 'button',
  ...stylesProps
}) => (
  <ActionsContainer variant={variant} {...stylesProps}>
    {actions.map(({ icon, label, onActionClick, isDisabled }) =>
      variant === 'button' ? (
        <Button
          key={label}
          variant="secondary"
          size="sm"
          onClick={onActionClick}
          preFix={icon}
          minWidth="15.4rem"
          disabled={isDisabled}
        >
          {label}
        </Button>
      ) : (
        <ActionButton key={label} onClick={onActionClick} disabled={isDisabled}>
          {icon}
          <HiddenLabel>{label}</HiddenLabel>
        </ActionButton>
      )
    )}
  </ActionsContainer>
)

const ActionsContainer = styled(Container)<{ variant: TeammateActionsVariant }>`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;

  ${({ variant, theme }) =>
    variant === 'iconButton' &&
    `
        outline: 1px solid ${theme.colors.grey};
        background-color: ${theme.colors.white};
        border-radius: 0.8rem;
        padding: 0.4rem 0.8rem;
      `}
`

const ActionButton = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  color: ${({ theme }) => theme.colors.greySuperDark};
  transition: ${({ theme }) => theme.transitionTimes.short};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`
