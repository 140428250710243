import { AVATAR_STALE_TIME } from '@hooks/constants'
import { useQuery } from '@tanstack/react-query'
import { getAttachment } from '../get-attachment'

export const ATTACHMENT = 'sending-attachment-fetcher'

export const useGetAttachment = (
  attachmentId: string,
  conversationId?: string,
  enabled?: boolean
) => {
  const isEnabled = Boolean(attachmentId && conversationId) && enabled !== false

  return useQuery([ATTACHMENT, attachmentId], () => getAttachment(conversationId!, attachmentId), {
    staleTime: AVATAR_STALE_TIME,
    enabled: isEnabled,
  })
}
