import { useAppDispatch, useAppSelector } from '@app/flow/hooks'
import { useQuery } from '@tanstack/react-query'
import { selectAllConversations } from '../model/entity-adapter'
import { syncConversations as syncAction } from '../model/slice'
import { syncConversations } from './sync-conversations'

export const CONVERSATIONS_QUERY_KEY = 'Conversations'

export const useSyncAndGetConversations = () => {
  const conversations = useAppSelector((state) => state.conversations)

  const dispatch = useAppDispatch()

  const query = useQuery(
    [
      CONVERSATIONS_QUERY_KEY,
      conversations.lastConversationTimestamp,
      conversations.lastConversationId,
    ],
    () => {
      return syncConversations(
        20,
        conversations.lastConversationTimestamp,
        conversations.lastConversationId
      )
    },
    {
      onSuccess: (data) => {
        dispatch(syncAction(data))
      },
    }
  )

  return { conversations: selectAllConversations(conversations), ...query }
}
