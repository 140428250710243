import { useGetMe } from '@api/account/hooks/use-get-me'
import { useJoinChannel } from '@api/chats/hooks/use-join-channel'
import { useReadConversation } from '@api/chats/hooks/use-read-conversation'
import { useAppDispatch } from '@app/flow/hooks'
import { MessageInput, MessageInputMode } from '@components/message-input'
import { TypingIndicator } from '@components/typing-indicator'
import { AttachmentsUploadingProvider } from '@contexts/attachments-uploading-provider'
import { useAttachments } from '@hooks/use-attachments'
import { useConversationAndTrackTyping } from '@hooks/use-conversation-and-track-typing'
import { useSkeleton } from '@hooks/use-skeleton'
import { ChannelDenied } from '@modules/channel-denied'
import { JoinChannelCta } from '@modules/join-channel-cta'
import { ConversationType } from '@shared/types/conversation'
import React, { FC, Suspense, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SkeletonComponentNames } from 'src/HOC/with-skeleton'
import { ConversationHeaderWithSkeleton } from 'src/entities/conversation/conversation-header'
import DragAndDropPreview from 'src/entities/conversation/drag-and-drop-preview'
import { formatConversationName } from 'src/entities/conversations/lib/format-conversation-name'
import { Conversation as ConversationModel } from 'src/entities/conversations/model/conversation'
import { joinConversation, markConversationAsRead } from 'src/entities/conversations/model/slice'
import { useDragAndDrop } from 'src/features/chats/hooks/use-drag-and-drop'
import styled from 'styled-components'
import { Container } from 'ui'
import { ConversationBody } from '../entities/conversation/conversation-body'
import { ConversationSkeleton } from '../entities/conversation/conversation-body/ui/modules/conversation-skeleton/conversation-skeleton'
import { MessageInputSkeleton } from '../entities/conversation/conversation-body/ui/modules/conversation-skeleton/message-input-skeleton'

export const Conversation: FC = () => {
  const [attachments, onAttachmentsChange] = useAttachments()

  return (
    <Suspense fallback={<ConversationSkeleton />}>
      <AttachmentsUploadingProvider value={attachments} onChange={onAttachmentsChange} multiple>
        <ConversationContent />
      </AttachmentsUploadingProvider>
    </Suspense>
  )
}

export const ConversationContent: FC = () => {
  const { t } = useTranslation(['chat'])
  const { meData, isLoading: isMeDataLoading } = useGetMe()
  const {
    formattedMessages,
    isLoading: isConversationLoading,
    users,
    messageInputProps,
    typingUsers,
    typingChat,
    typingUsersString,
    fetchNextPageMessageList,
    messageListRef,
    scrollDownHandle,
    pages,
    lastFetchedPage,
    conversationDetails,
    typingTrackingData: { conversationId },
    isChatDetailsError,
  } = useConversationAndTrackTyping()
  const { showSkeleton, hideSkeleton } = useSkeleton()
  const [inView, setIsInView] = useState(false)
  const [triggerInView, setTriggerIsInView] = useState(false)
  const dispatch = useAppDispatch()
  const { markAsRead } = useReadConversation({
    onMutate: (chatId) => dispatch(markConversationAsRead({ id: chatId })),
  })

  const conversationName = useMemo(() => {
    if (!conversationDetails || !meData) return ''
    return formatConversationName(conversationDetails, meData.userId, t)
  }, [conversationDetails, t])

  const { isDragging, dragHandlers } = useDragAndDrop()

  const navigate = useNavigate()
  const handleOnGoBack = () => navigate(-1)

  const {
    mutate: joinChannel,
    isLoading: isJoinChannelLoading,
    isSuccess: isJoinChannelSuccess,
  } = useJoinChannel({
    onSuccessCallback: () => {
      toast.success(`${t('changesSaved', { ns: 'common' })}`)
      dispatch(
        joinConversation({
          conversation: conversationDetails as ConversationModel,
          newUser: meData,
        })
      )
    },
    onErrorCallback: () => toast.error(`${t('errors.somethingWentWrong', { ns: 'common' })}`),
  })

  const handleOnJoinChannel = (id: string) => {
    joinChannel(id)
  }

  const isMeJoined = useMemo(() => {
    if (!meData || !conversationDetails) {
      return false
    }
    const foundUser = conversationDetails.chatUsers.find(
      (user) => user.user.userId === meData.userId
    )

    return foundUser ? !foundUser.leftChat : false
  }, [conversationDetails, meData, isJoinChannelSuccess])

  const isLoading = isConversationLoading || isMeDataLoading

  const isMessageInputLoading = isMeDataLoading

  const showJoinChannel = !isMeDataLoading && !isMeJoined && conversationDetails

  const isSenderShown =
    users.length > 2 || conversationDetails?.conversationType !== ConversationType.CHAT

  // Reset trigger is in view when conversation is changing
  useEffect(() => {
    setTriggerIsInView(false)
  }, [conversationId])

  useEffect(() => {
    if (triggerInView) fetchNextPageMessageList()
  }, [triggerInView, fetchNextPageMessageList])

  useEffect(() => {
    const hasUnreadMessages = conversationDetails && conversationDetails.unreadMessagesCount > 0
    if (inView && hasUnreadMessages && conversationId) {
      markAsRead(conversationId)
    }
  }, [inView, conversationDetails])

  useEffect(() => {
    if (isLoading) {
      showSkeleton(SkeletonComponentNames.CONVERSATION_BODY_1)
      showSkeleton(SkeletonComponentNames.CHAT_HEADER_1)
    } else {
      hideSkeleton(SkeletonComponentNames.CONVERSATION_BODY_1)
      hideSkeleton(SkeletonComponentNames.CHAT_HEADER_1)
    }
  }, [hideSkeleton, isLoading, showSkeleton])

  if (conversationDetails?.isDeleted || (isChatDetailsError && !isConversationLoading)) {
    return <ChannelDenied />
  }

  return (
    <Container position="relative" flex="1 1 auto" height="100%" {...dragHandlers}>
      {isDragging && <DragAndDropPreview />}
      <PageContainer>
        <ConversationHeaderWithSkeleton
          mb="2rem"
          componentName={SkeletonComponentNames.CHAT_HEADER_1}
          conversationName={conversationName}
        />
        {conversationDetails && (
          <ConversationBody
            lastPageFetched={pages === lastFetchedPage}
            setTriggerIsInView={setTriggerIsInView}
            setIsInView={setIsInView}
            isLoading={isLoading}
            messages={formattedMessages}
            conversation={{
              id: conversationDetails.id,
              type: conversationDetails.conversationType,
              name: conversationName,
            }}
            messageListRef={messageListRef}
            users={users.map(({ user }) => user)}
            isSenderShown={isSenderShown}
          />
        )}
        <Container pt="3.2rem" position="relative">
          {typingUsers.length > 0 && typingChat === conversationId && (
            <TypingIndicator
              text={typingUsersString}
              position="absolute"
              top="0"
              p="0.6rem 0.8rem 0.6rem 4.4rem"
            />
          )}
          {isMessageInputLoading ? (
            <MessageInputSkeleton />
          ) : showJoinChannel ? (
            <JoinChannelCta
              id={conversationDetails.id}
              title={conversationDetails.name}
              subtitle={conversationDetails.description}
              isDisabled={isJoinChannelLoading}
              onClick={handleOnJoinChannel}
              onGoBack={handleOnGoBack}
              buttonLabels={[t('back'), t('join')]}
            />
          ) : (
            <MessageInput
              {...messageInputProps}
              inView={inView}
              scrollDownHandle={scrollDownHandle}
              messageInputMode={MessageInputMode.SEND}
              conversationId={conversationId || ''}
            />
          )}
        </Container>
      </PageContainer>
    </Container>
  )
}

const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`
